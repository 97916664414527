import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Custom scripts
import {
  aDistance,
  aTstage,
  aTumorType,
  aTumorContent,
  aTumorStart,
  aTumorEnd,
  aMRF,
  aLNsize,
  aLN,
  aLNcount,
} from './MR_rectum_arrays';
import { generateReport } from './MR_rectum_functions';

// General scripts
import {
  contentWidth,
  aCardColors,
  aTrueFalse,
  green,
  red,
} from '../scripts/GeneralArrays';
import { formatCustomInput } from '../scripts/GeneralFunctions';

// Custom components
import CustomCards from '../components/CustomCards';
import DropDown from '../components/DropDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';

// 3rd party components
import { Form, Row, Col, Select, Button, Space, Switch, Input } from 'antd';
import { debounce } from 'lodash';
import ButtonUpDown from '../components/ButtonUpDown';
const { Option } = Select;
const { TextArea } = Input;

class MR_rectum extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      tumorType: 0,
      tumorContent: 0,
      tumorStart: 11,
      tumorEnd: 1,
      Tstage: 0,
      invasionStart: 9,
      invasionEnd: 3,
      MRF: 0,
      vascularInvasion: 0,
      ARADistance: 5,
      tumorLength: 5,
      LNparaaortic: 0,
      LNiliacComR: 0,
      LNiliacComL: 0,
      LNiliacExtR: 0,
      LNiliacExtL: 0,
      LNiliacIntR: 0,
      LNiliacIntL: 0,
      LNobturatorR: 0,
      LNobturatorL: 0,
      LNupperRectal: 0,
      LNmesorectal: 0,
      LNparaaorticCount: 0,
      LNiliacComRCount: 0,
      LNiliacComLCount: 0,
      LNiliacExtRCount: 0,
      LNiliacExtLCount: 0,
      LNiliacIntRCount: 0,
      LNiliacIntLCount: 0,
      LNobturatorRCount: 0,
      LNobturatorLCount: 0,
      LNupperRectalCount: 0,
      LNmesorectalCount: 0,
      LNcustomDescription: '',
      LNcustomConclusion: '',
      otherFindingsConclusion: '',
      analCanalInvasion: 0,
      internalSphincterInvasion: false,
      intersphinctericFatInvasion: false,
      externalSphincterInvasion: false,
      // metastázy
      metastasisBone: false,
      metastasisLiver: false,
      metastasisPeritoneal: false,
      metastasisOther: '',
      // vedlejší nálezy
      diverticulosis: false,
      uterineFibroids: false,
      prostateHypertrophy: false,
      otherFindingsDescription: '',
    },
    LN: [],
  };

  initialState = cloneDeep(this.state);

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = value => {
    let size = value;
    let indistinct = false;
    let heterogenous = false;
    let rounded = false;

    let newRecord = {
      size: size,
      indistinct: indistinct,
      heterogenous: heterogenous,
      rounded: rounded,
    };

    this.setState(prevState => ({
      LN: [...prevState.LN, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });

    console.log(this.state.LN);
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  showCustomInput = region => {
    this.setState(prevState => ({
      [region]: !prevState[region],
    }));
  };

  render() {
    const myContent1 = [
      {
        title: 'tumor rekta obecně',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'typ tumoru',
              input: (
                <ButtonUpDown
                  name='tumorContent'
                  segment='general'
                  defaultValue={this.state.general.tumorContent}
                  options={aTumorContent}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'od AR úhlu',
              input: (
                <DropDown
                  name='ARADistance'
                  segment='general'
                  defaultValue={this.state.general.ARADistance}
                  options={aDistance}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'tvar tumoru',
              input: (
                <ButtonUpDown
                  name='tumorType'
                  segment='general'
                  defaultValue={this.state.general.tumorType}
                  options={aTumorType}
                  onChange={this.onChange}
                />
              ),
            },

            {
              label: 'délka tu',
              input: (
                <DropDown
                  name='tumorLength'
                  segment='general'
                  defaultValue={this.state.general.tumorLength}
                  options={aDistance}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'od perit. refl.',
              input: '',
            },
            {
              label: 'rozsah tu',
              input: (
                <Row>
                  <Col span={12}>
                    <DropDown
                      name='tumorStart'
                      segment='general'
                      defaultValue={this.state.general.tumorStart}
                      options={aTumorStart}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <DropDown
                      name='tumorEnd'
                      segment='general'
                      defaultValue={this.state.general.tumorEnd}
                      options={aTumorEnd}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: 'T stage',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'invaze tu',
              input: (
                <ButtonUpDown
                  name='Tstage'
                  segment='general'
                  defaultValue={this.state.general.Tstage}
                  options={aTstage}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: this.state.general.Tstage > 0 ? 'od MRF' : '',
              input:
                this.state.general.Tstage > 0 ? (
                  <ButtonUpDown
                    name='MRF'
                    segment='general'
                    defaultValue={this.state.general.MRF}
                    options={aMRF}
                    onChange={this.onChange}
                  />
                ) : (
                  ''
                ),
            },
          ],
          [
            {
              label: this.state.general.Tstage > 0 ? 'místo invaze' : '',
              input:
                this.state.general.Tstage > 0 ? (
                  <Row>
                    <Col span={12}>
                      <DropDown
                        name='invasionStart'
                        segment='general'
                        defaultValue={this.state.general.invasionStart}
                        options={aTumorStart}
                        onChange={this.onChange}
                      />
                    </Col>
                    <Col span={12}>
                      <DropDown
                        name='invasionEnd'
                        segment='general'
                        defaultValue={this.state.general.invasionEnd}
                        options={aTumorEnd}
                        onChange={this.onChange}
                      />
                    </Col>
                  </Row>
                ) : (
                  ''
                ),
            },
            {
              label: this.state.general.Tstage > 0 ? 'cévní invaze' : '',
              input:
                this.state.general.Tstage > 0 ? (
                  <ButtonTrueFalse
                    name='vascularInvasion'
                    segment='general'
                    value={this.state.general.vascularInvasion}
                    options={aTrueFalse}
                    onChange={this.onChange}
                  />
                ) : (
                  ''
                ),
            },
          ],
        ],
      },
    ];

    const myContent2 = [
      {
        title: 'N stage - regionální lymfatické uzliny',
        color: aCardColors.green,
        rows: [
          [
            {
              label: 'ilické int.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacIntR'
                      segment='general'
                      value={this.state.general.LNiliacIntR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacIntRCount'
                        segment='general'
                        value={this.state.general.LNiliacIntRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'ilické int.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacIntL'
                      segment='general'
                      value={this.state.general.LNiliacIntL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacIntLCount'
                        segment='general'
                        value={this.state.general.LNiliacIntLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'obturátorové',
              input: (
                <Row>
                  <Col span={this.state.general.LNobturatorR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNobturatorR'
                      segment='general'
                      value={this.state.general.LNobturatorR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNobturatorR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNobturatorRCount'
                        segment='general'
                        value={this.state.general.LNobturatorRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'obturátorové',
              input: (
                <Row>
                  <Col span={this.state.general.LNobturatorL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNobturatorL'
                      segment='general'
                      value={this.state.general.LNobturatorL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNobturatorL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNobturatorLCount'
                        segment='general'
                        value={this.state.general.LNobturatorLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'horní rektální',
              input: (
                <Row>
                  <Col span={7}></Col>
                  <Col span={this.state.general.LNupperRectal > 0 ? 8 : 10}>
                    <ButtonUpDown
                      name='LNupperRectal'
                      segment='general'
                      value={this.state.general.LNupperRectal}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNupperRectal > 0 ? (
                    <Col span={2}>
                      <ButtonUpDown
                        name='LNupperRectalCount'
                        segment='general'
                        value={this.state.general.LNupperRectalCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                  <Col span={7}></Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'mezorektální',
              input: (
                <Row>
                  <Col span={7}></Col>
                  <Col span={this.state.general.LNmesorectal > 0 ? 8 : 10}>
                    <ButtonUpDown
                      name='LNmesorectal'
                      segment='general'
                      value={this.state.general.LNmesorectal}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNmesorectal > 0 ? (
                    <Col span={2}>
                      <ButtonUpDown
                        name='LNmesorectalCount'
                        segment='general'
                        value={this.state.general.LNmesorectalCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                  <Col span={7}></Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní popis' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.LNcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní závěr' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.LNcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>
              M stage - non-regionální lymfatické uzliny, metastázy
            </Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('LNcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.orange,
        rows: [
          [
            {
              label: 'paraaortální',
              input: (
                <Row>
                  <Col span={7}></Col>
                  <Col span={this.state.general.LNparaaortic > 0 ? 8 : 10}>
                    <ButtonUpDown
                      name='LNparaaortic'
                      segment='general'
                      value={this.state.general.LNparaaortic}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNparaaortic > 0 ? (
                    <Col span={2}>
                      <ButtonUpDown
                        name='LNparaaorticCount'
                        segment='general'
                        value={this.state.general.LNparaaorticCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                  <Col span={7}></Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'ilické spol.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacComR'
                      segment='general'
                      value={this.state.general.LNiliacComR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacComRCount'
                        segment='general'
                        value={this.state.general.LNiliacComRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'ilické spol.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacComL'
                      segment='general'
                      value={this.state.general.LNiliacComL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacComLCount'
                        segment='general'
                        value={this.state.general.LNiliacComLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'ilické ext.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacExtR'
                      segment='general'
                      value={this.state.general.LNiliacExtR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacExtRCount'
                        segment='general'
                        value={this.state.general.LNiliacExtRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'ilické ext.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacExtL'
                      segment='general'
                      value={this.state.general.LNiliacExtL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacExtLCount'
                        segment='general'
                        value={this.state.general.LNiliacExtLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: '',
              input:
                '-----------------------------------------------metastázy-----------------------------------------------',
            },
          ],
          [
            {
              label: 'kostní',
              input: (
                <ButtonTrueFalse
                  name='metastasisBone'
                  segment='general'
                  value={this.state.general.metastasisBone}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'jaterní',
              input: (
                <ButtonTrueFalse
                  name='metastasisLiver'
                  segment='general'
                  value={this.state.general.metastasisLiver}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'peritoneální',
              input: (
                <ButtonTrueFalse
                  name='metastasisPeritoneal'
                  segment='general'
                  value={this.state.general.metastasisPeritoneal}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'ostatní',
              input: (
                <TextArea
                  name='metastasisOther'
                  placeholder='jiné metastázy...'
                  defaultValue={this.state.general.metastasisOther}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'metastasisOther',
                      segment: 'general',
                      value: event.target.value,
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní popis' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.LNcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní závěr' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.LNcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const otherFindings = [
      {
        title: (
          <Row>
            <Col span={12}>vedlejší nálezy</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('otherCustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'divert. sigm.',
              input: (
                <ButtonTrueFalse
                  name='diverticulosis'
                  segment='general'
                  value={this.state.general.diverticulosis}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'myomy děl.',
              input: (
                <ButtonTrueFalse
                  name='uterineFibroids'
                  segment='general'
                  value={this.state.general.uterineFibroids}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'hy prostaty',
              input: (
                <ButtonTrueFalse
                  name='prostateHypertrophy'
                  segment='general'
                  value={this.state.general.prostateHypertrophy}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.otherCustomShow ? 'jiný nález' : '',
              input: this.state.otherCustomShow ? (
                <TextArea
                  name='otherFindingsDescription'
                  placeholder=''
                  defaultValue={this.state.general.otherFindingsDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'otherFindingsDescription',
                      segment: 'general',
                      value: event.target.value,
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={myContent1} />
        <CustomCards content={myContent2} />
        <CustomCards content={otherFindings} />
      </Form>
    );
  }
}

export default MR_rectum;
