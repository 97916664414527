import React from "react";
import cloneDeep from "lodash/cloneDeep";
import { Row, Col, Input, Button, Tabs, Select, Dropdown, Menu } from "antd";

// Custom scripts

// General scripts
import {
  aContrastMR,
  aContrastMRamount,
  colorMRI,
  colorXRAY,
} from "../scripts/GeneralArrays";
import { removeAllNewLines } from "../scripts/GeneralFunctions";

// Custom components
import XRAY_chest from "./XRAY_chest";
import XRAY_abdomen from "./XRAY_abdomen";
import DropDown from "../components/DropDown";
import Report_new from "../components/Report_new";
import { PlusOutlined } from "@ant-design/icons";
import Form from "antd/lib/form/Form";

// 3rd party components
const TabPane = Tabs.TabPane;
const { TextArea } = Input;
const Option = Select.Option;

const initialPanes = [];

const aXRAYExams = [
  {
    value: 0,
    Group: "interní",
    Selector: "RTG hrudníku",
    TabName: "RTG hrudníku ",
    TagName: XRAY_chest,
    protocols: [
      {
        value: 0,
        tab: "vleže",
        report: "hrudníku vleže (AP projekce)",
        contrast: false,
      },
      {
        value: 1,
        tab: "vstoje",
        report: "hrudníku vstoje (PA projekce)",
        contrast: false,
      },
      {
        value: 2,
        tab: "vsedě",
        report: "hrudníku vsedě (PA projekce)",
        contrast: false,
      },
    ],
  },
  {
    value: 1,
    Group: "interní",
    Selector: "RTG břicha",
    TabName: "RTG břicha ",
    TagName: XRAY_abdomen,
    protocols: [
      {
        value: 0,
        tab: "vstoje",
        report: "břicha vstoje (AP projekce)",
      },
      {
        value: 1,
        tab: "vleže",
        report: "břicha vleže (AP projekce)",
      },
    ],
  },
];

const defaultReport = {
  exam: [],
  examList: aXRAYExams,
  indication: "",
  contrast: 0,
  contrastAmount: 5,
  contrastArray: aContrastMR,
  contrastAmountArray: aContrastMRamount,
};

class ExamHolder_XRAY extends React.Component {
  newTabIndex = 0;
  state = {
    key: 0,
    report: defaultReport,
    reports: [],
    panes: initialPanes,
    activeKey: 0,
    reset: false,
  };

  examRef = React.createRef();

  resetExam = () => {
    this.setState({ reset: true });
  };

  resetReply = () => {
    var key = this.state.key + 1;
    this.setState({ key: key, report: defaultReport, reset: false });
  };

  renderComponent = (TagName, TagFunction, examID, paneIndex) => {
    return (
      <TagName
        askReport={TagFunction}
        exam={examID}
        protocol={this.state.panes[paneIndex].protocol}
        contrast={this.state.report.contrast}
        ref={this.examRef}
        reset={this.state.reset}
        resetReply={this.resetReply}
      />
    );
  };

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;
    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  onChangeTabs = (activeKey) => {
    this.setState({ activeKey });
  };

  onEdit = (targetKey, action) => {
    this[action](targetKey);
  };

  remove = (targetKey) => {
    const { panes, activeKey } = this.state;
    let newActiveKey = activeKey;
    let lastIndex;
    panes.forEach((pane, index) => {
      if (pane.key === targetKey) {
        lastIndex = index - 1;
      }
    });
    const newPanes = panes.filter((pane) => pane.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    this.setState({
      panes: newPanes,
      activeKey: newActiveKey,
      report: panes.length === 1 ? defaultReport : this.state.report,
    });
  };

  getReport = (result) => {
    let { panes } = this.state;
    const {
      exam,
      description_1_for_UI,
      description_2_for_UI,
      description_1_for_Paste,
      description_2_for_Paste,
      conclusion_1_for_UI,
      conclusion_1_for_Paste,
      conclusion_2_for_UI,
      conclusion_2_for_Paste,
    } = result;
    for (let i = 0; i < panes.length; i++) {
      if (panes[i].value === exam) {
        panes[i].description_1_for_UI = description_1_for_UI;
        panes[i].description_1_for_Paste = description_1_for_Paste;
        panes[i].description_2_for_UI = description_2_for_UI;
        panes[i].description_2_for_Paste = description_2_for_Paste;
        panes[i].conclusion_1_for_UI = conclusion_1_for_UI;
        panes[i].conclusion_1_for_Paste = conclusion_1_for_Paste;
        panes[i].conclusion_2_for_UI = conclusion_2_for_UI;
        panes[i].conclusion_2_for_Paste = conclusion_2_for_Paste;
        this.setState({ panes: panes });
        break;
      }
    }
  };

  add = (event) => {
    const selectedKey = event.key;
    const { panes } = this.state;
    const activeKey = `${this.newTabIndex++}`;
    const newPanes = [...panes];
    newPanes.push({
      key: activeKey,
      value: aXRAYExams[selectedKey].value,
      TabName: aXRAYExams[selectedKey].TabName,
      protocol: 0,
      protocols: aXRAYExams[selectedKey].protocols,
      TagName: aXRAYExams[selectedKey].TagName,
      description_1_for_UI: null,
      description_2_for_UI: null,
      description_1_for_Paste: null,
      description_2_for_Paste: null,
      conclusion_for_UI: null,
      conclusion_for_Paste: null,
    });

    newPanes.sort((a, b) => (a.value > b.value ? 1 : -1));

    this.setState({
      panes: newPanes,
      activeKey,
    });
  };

  updateProtocol = ({ pane, protocol }) => {
    var newState = { ...this.state };
    var { panes } = this.state;
    panes[pane].protocol = protocol;
    newState.panes = panes;
    if (protocol === 4) {
      newState.report.contrast = 1;
    } else {
      newState.report.contrast = 0;
    }
    this.setState({ newState });
  };

  render() {
    {
      //console.log({ ...this.state });
    }
    const { panes, activeKey } = this.state;

    let examGroups = [];
    examGroups.internal = aXRAYExams.filter((item) => item.Group === "interní");

    let menu = (
      <Menu onClick={this.add}>
        <Menu.ItemGroup title="interní">
          {examGroups.internal.map((item) => (
            <Menu.Item key={item.value} value={item.value}>
              {item.Selector}
            </Menu.Item>
          ))}
        </Menu.ItemGroup>
      </Menu>
    );

    return (
      <Form key={this.state.key}>
        <Row style={{ backgroundColor: colorXRAY }} align="middle">
          <Col flex="310px">
            <Dropdown placement="bottomLeft" trigger={["click"]} overlay={menu}>
              <Button
                size="large"
                type="primary"
                style={{ background: colorXRAY, borderColor: colorXRAY }}
                icon={<PlusOutlined />}
              >
                {this.state.panes.length === 0
                  ? "nové vyšetření..."
                  : "přidat vyšetření..."}
              </Button>
            </Dropdown>
          </Col>
          <Col flex="210px" style={{ paddingRight: "5px" }}></Col>
          <Col flex="auto" style={{ paddingRight: "5px" }}>
            {this.state.panes.length > 0 && this.state.report.contrast > 0 ? (
              <DropDown
                defaultValue={this.state.report.contrastAmount}
                name="contrastAmount"
                segment="report"
                options={aContrastMRamount}
                onChange={this.onChange}
              />
            ) : null}
          </Col>
          <Col flex="536px" style={{ padding: "2px 10px 5px 0px" }}>
            {this.state.panes.length > 0 && (
              <TextArea
                size="small"
                name="indication"
                segment="report"
                defaultValue={this.state.report.indication}
                placeholder="indikace..."
                autoSize={{ minRows: 1, maxRows: 2 }}
                onBlur={(event) =>
                  this.onChange({
                    name: "indication",
                    segment: "report",
                    value: removeAllNewLines(event.target.value),
                  })
                }
              />
            )}
          </Col>
          <Col>
            {this.state.panes.length > 0 ? (
              <Button
                type="primary"
                size="small"
                danger
                style={{ width: 65, marginRight: 12 }}
                onClick={this.resetExam}
              >
                reset
              </Button>
            ) : null}
          </Col>
        </Row>

        {panes.length > 0 ? (
          <Row>
            <Col>
              <Tabs
                type="editable-card"
                hideAdd={true}
                onChange={this.onChangeTabs}
                activeKey={activeKey}
                onEdit={this.onEdit}
              >
                {panes.map((pane, index) => (
                  <TabPane
                    tab={
                      <>
                        {pane.TabName}
                        {pane.protocols.length > 1 ? (
                          <Select
                            size="small"
                            style={{ width: "175px" }}
                            defaultValue={0}
                            onChange={(event) =>
                              this.updateProtocol({
                                pane: index,
                                protocol: event || 0,
                              })
                            }
                          >
                            {pane.protocols.map((item, i) => {
                              return (
                                <Option key={i} value={item.value}>
                                  {item.tab}
                                </Option>
                              );
                            })}
                          </Select>
                        ) : null}
                      </>
                    }
                    key={pane.key}
                  >
                    {this.renderComponent(
                      pane.TagName,
                      this.getReport,
                      pane.value,
                      index
                    )}
                  </TabPane>
                ))}
              </Tabs>
              <Report_new data={this.state} />
            </Col>
          </Row>
        ) : null}
      </Form>
    );
  }
}

export default ExamHolder_XRAY;
