import { green, yellow, orange, red, grey } from "../scripts/GeneralArrays";

export const aSpineCor = [
  {
    value: 0,
    text: "v ose",
    description: "Krční páteř v ose. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "mírná dextroskolióza",
    description: "Mírné pravostranné vybočení C páteře. ",
    conclusion: "Mírná dextroskolióza C páteře. ",
  },
  {
    value: 2,
    text: "mírná sinistroskolióza",
    description: "Mírné levostranné vybočení C páteře. ",
    conclusion: "Mírná sinistroskolióza C páteře. ",
  },
  {
    value: 3,
    text: "dextroskolióza",
    description: "Pravostranné vybočení C páteře. ",
    conclusion: "Dextroskolióza C páteře. ",
  },
  {
    value: 4,
    text: "sinistroskolióza",
    description: "Levostranné vybočení C páteře. ",
    conclusion: "Sinistroskolióza C páteře. ",
  },
  {
    value: 5,
    text: "výrazná dextroskolióza",
    description: "Výrazné pravostranné vybočení C páteře. ",
    conclusion: "Výrazná dextroskolióza C páteře. ",
  },
  {
    value: 6,
    text: "výrazná sinistroskolióza",
    description: "Výrazné levostranné vybočení C páteře. ",
    conclusion: "Výrazná sinistroskolióza C páteře. ",
  },
];

export const aSpineSag = [
  {
    value: 0,
    text: "přiměřená",
    description: "Plynulá krční lordóza. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "plošší",
    description: "Plošší krční lordóza. ",
    conclusion: "Plošší krční lordóza. ",
  },
  {
    value: 2,
    text: "vyrovnaná",
    description: "Vyrovnaná krční lordóza. ",
    conclusion: "Vyrovnaná krční lordóza. ",
  },
  {
    value: 3,
    text: "paradoxní kyfotizace",
    description: "Mírná paradoxní kyfotizace krční lordózy. ",
    conclusion: "Mírná paradoxní kyfotizace krční lordózy. ",
  },
];

export const aAddFinds = [
  {
    value: 0,
    text: "struma",
    description: "Zvětšená štítná žláza. ",
    conclusion: "Zvětšená štítná žláza. ",
  },
];

export const aListesis = [
  {
    value: 0,
    text: "bez posunu",
    description: "",
    conclusion: "",
  },
  {
    value: 1,
    text: "ventrolistéza",
    description: "ventrální posun obratlového těla ",
    conclusion: "ventrolistéza ",
  },
  {
    value: 2,
    text: "dorzolistéza",
    description: "dorzální posun obratlového těla ",
    conclusion: "dorzolistéza ",
  },
];

export const aDiskDegen = [
  {
    value: 0,
    text: "normal",
    color: green,
    description: "zachovalý signál a výše disku, ",
  },
  {
    value: 1,
    text: "grade 2",
    color: yellow,
    description: "disk na T2 nižší SI bez snížení jeho výše (grade 2), ",
  },
  {
    value: 2,
    text: "grade 3",
    color: yellow,
    description:
      "disk na T2 nižší SI snížen o méně než 1/2 původní výše (grade 3), ",
  },
  {
    value: 3,
    text: "grade 4",
    color: orange,
    description:
      "disk na T2 nižší SI snížen o více než 1/2 původní výše (grade 4), ",
  },
  {
    value: 4,
    text: "grade 5",
    color: orange,
    description: "disk je snížením téměř či úplně zaniklý (grade 5), ",
  },
];

export const aModic = [
  {
    value: 0,
    text: "normal",
    color: green,
    description: "",
  },
  {
    value: 1,
    text: "Modic 1",
    color: red,
    description: "na STIR vyšší signál kostní dřeně krycích ploten (Modic 1), ",
  },
  {
    value: 2,
    text: "Modic 2",
    color: yellow,
    description: "na T1 vyšší signál kostní dřeně krycích ploten (Modic 2), ",
  },
  {
    value: 3,
    text: "Modic 3",
    color: grey,
    description:
      "na všech sekvencích nízký signál kostní dřeně krycích ploten (Modic 3), ",
  },
];

export const aDiskPat = [
  {
    value: 0,
    text: "normal",
    description: "",
    conclusion: "",
  },
  {
    value: 1,
    text: "OD komplex",
    description: "osteofyt-diskogenní komplex prominující ",
    conclusion: "osteofyt-diskogenního komplexu ",
  },
  {
    value: 2,
    text: "hernie",
    description: "hernie disku prominující ",
    conclusion: "hernie disku ",
  },
  {
    value: 3,
    text: "osteofyty",
    description: "osteofyty krycích ploten prominující ",
    conclusion: "osteofytů krycích ploten ",
  },
  {
    value: 4,
    text: "bulging",
    description: "bulging disku",
    conclusion: "bulgingu disku",
  },
  {
    value: 5,
    text: "asym. bulg.",
    description: "bulging disku asymetricky výraznější ",
    conclusion: "bulgingu disku asymetricky výraznějšího ",
  },
];

export const aDiskDirection = [
  {
    value: 0,
    text: "F",
    symbol: "FR",
    description: "vpravo foraminálně",
  },
  {
    value: 1,
    text: "P",
    symbol: "PR",
    description: "vpravo paracentrálně",
  },
  {
    value: 2,
    text: "C",
    symbol: "C",
    description: "centrálně",
  },
  {
    value: 3,
    text: "P",
    symbol: "PL",
    description: "vlevo paracentrálně",
  },
  {
    value: 4,
    text: "F",
    symbol: "FL",
    description: "vlevo foraminálně",
  },
];

export const aDiskMigration = [
  {
    value: 0,
    text: "nemigruje",
    description: "",
  },
  {
    value: 1,
    text: "kraniálně",
    description: "migrující kraniálně",
  },
  {
    value: 2,
    text: "kaudálně",
    description: "migrující kaudálně",
  },
];

export const aStenosis = [
  {
    value: 0,
    text: "0",
    foramenLS: "",
    recessLS: "",
    canalC: "",
    canalCConclusion: "",
    canalLS: "",
    canalLSConclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "1",
    foramenLS: "v jednom rozměru zaniklý perineurální tuk (grade 1), ",
    foramenC:
      "zúžení s ještě patrným likvorem v durální pochvě míšního kořene (grade 1), ",
    foramenLSConclusion: "(grade 1)",
    recessLS: "bez odtlačení míšního kořene (grade 1), ",
    recessLSConclusion: "(grade 1)",
    canalLS:
      "zúžení páteřního kanálu bez shlukování míšních kořenů durálního vaku (grade 1), ",
    canalC: "mírné zúžení páteřního kanálu (grade 1), ",
    canalCConclusion: "lehká stenóza páteřního kanálu z důvodu ",
    canalLSConclusion: "lehká stenóza páteřního kanálu z důvodu ",
    color: yellow,
  },
  {
    value: 2,
    text: "2",
    foramenLS: "téměř zaniklý perineurální tuk (grade 2), ",
    foramenC:
      "zúžení již bez patrného likvoru v durální pochvě míšního kořene, který ale není přesvědčivě komprimován (grade 2), ",
    foramenLSConclusion: "(grade 2)",
    recessLS: "s odtlačením nedeformovaného míšního kořene (grade 2), ",
    recessLSConclusion: "(grade 2)",
    canalLS:
      "zúžení páteřního kanálu způsobující shlukování míšních kořenů durálního vaku, mezi kterými je ale stále patrný likvor (grade 2), ",
    canalC: "středně těžké zúžení páteřního kanálu (grade 2), ",
    canalCConclusion:
      "středně těžká stenóza páteřního kanálu (bez přesvědčivé myelopatie) z důvodu ",
    canalLSConclusion: "středně těžká stenóza páteřního kanálu z důvodu ",
    color: orange,
  },
  {
    value: 3,
    text: "3",
    foramenLS: "z útlaku deformita durální pochvy míšního kořene (grade 3), ",
    foramenC:
      "zúžení již bez patrného likvoru v durální pochvě míšního kořene, který je susp. komprimován (grade 3), ",
    foramenLSConclusion: "(grade 3)",
    recessLS: "s odtlačením a deformací míšního kořene (grade 3), ",
    recessLSConclusion: "(grade 3)",
    canalLS:
      "zúžení páteřního kanálu způsobující způsobující shlukování míšních kořenů durální vaku, mezi kterými již není patrný likvor (grade 3), ",
    canalC: "těžké zúžení páteřního kanálu (grade 3), ",
    canalCConclusion: "těžká stenóza páteřního kanálu z důvodu ",
    canalLSConclusion: "těžká stenóza páteřního kanálu z důvodu ",
    color: red,
  },
];

export const aCustom = [
  {
    value: 0,
    text: "ložisko",
  },
  {
    value: 1,
    text: "komprese",
  },
  {
    value: 2,
    text: "operace",
  },
];

export const aSurgery = [
  {
    value: 0,
    text: "přední stabilizace",
  },
  {
    value: 1,
    text: "zadní stabilizace",
  },
  {
    value: 2,
    text: "hemilaminektomie",
  },
];

export const aFacetArthrosis = [
  {
    value: 0,
    text: "ne",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "ano",
    description: " s osteofyty okrajů kloubních ploch, ",
    conclusion: "facetovou",
    color: orange,
  },
  {
    value: 2,
    text: "edém",
    description: " se zvýšením signálu kostní dřeně na STIR, ",
    conclusion: "facetovou",
    color: red,
  },
];

export const aUncoArthrosis = [
  {
    value: 0,
    text: "ne",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "ano",
    description: " s osteofyty okrajů kloubních ploch, ",
    conclusion: "unkovertebrální",
    color: orange,
  },
  {
    value: 2,
    text: "edém",
    description: " se zvýšením signálu kostní dřeně na STIR, ",
    conclusion: "unkovertebrální",
    color: red,
  },
];

export const aADArthrosis = [
  {
    value: 0,
    text: "není",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehká",
    description:
      "Drobné osteofyty a přihrocení okrajů kloubních ploch atlantodentálního kloubu. ",
    conclusion: "Lehká atlantodentální artróza. ",
    color: yellow,
  },
  {
    value: 2,
    text: "střední",
    description: "Osteofyty okrajů kloubních ploch atlantodentálního kloubu. ",
    conclusion: "Středně těžká atlantodentální artróza. ",
    color: orange,
  },
  {
    value: 3,
    text: "těžká",
    description:
      "Výrazné osteofyty okrajů kloubních ploch atlantodentálního kloubu. ",
    conclusion: "Těžká atlantodentální artróza. ",
    color: red,
  },
];

export const aFractureSite = [
  {
    value: 0,
    text: "ventrální snížení",
    description: "Ventrální klínovité snížení obratlového těla ",
    conclusion: "ventrální komprese obratlového těla ",
    color: green,
  },
  {
    value: 1,
    text: "centrální snížení",
    description: "Centrální snížení obratlového těla ",
    conclusion: "centrální komprese obratlového těla ",
    color: orange,
  },
  {
    value: 2,
    text: "dorzální snížení",
    description: "Dorzální klínovité snížení obratlového těla ",
    conclusion: "dorzální komprese obratlového těla ",
    color: red,
  },
];

export const aFractureEndplate = [
  {
    value: 0,
    text: "kompresí horní krycí plotny",
    description: " následkem prolomení horní krycí plotny ",
    conclusion: "horní krycí plotny ",
    color: green,
  },
  {
    value: 1,
    text: "kompresí dolní krycí plotny",
    description: " následkem prolomení dolní krycí plotny ",
    conclusion: "dolní krycí plotny ",
    color: orange,
  },
  {
    value: 2,
    text: "kompresí obou krycích ploten",
    description: " následkem prolomení obou krycích ploten",
    conclusion: "obou krycích ploten",
    color: red,
  },
];

export const aFractureDepth = [
  {
    value: 0,
    text: "o <25%",
    description: " o méně než 25% jeho původní výše, ",
    conclusion: "lehká ",
    color: green,
  },
  {
    value: 1,
    text: "o 25 až 40%",
    description: " o 25-40% jeho původní výše, ",
    conclusion: "středně těžká ",
    color: orange,
  },
  {
    value: 2,
    text: "o >40%",
    description: " o více než 40% jeho původní výše, ",
    conclusion: "těžká ",
    color: red,
  },
];

export const aFractureEdema = [
  {
    value: 0,
    text: "bez edému",
    description:
      "bez přesvědčivých ostrých lomných linií či prosáknutí okolních měkkých tkání, ",
    conclusion: "Spíše starší ",
    color: green,
  },
  {
    value: 1,
    text: "s edémem",
    description: "ostré lomné linie a prosáknutí okolních měkkých tkání, ",
    conclusion: "Spíše nedávná ",
    color: orange,
  },
];

export const aFractureProminence = [
  {
    value: 0,
    text: "bez prominence do páteřního kanálu",
    description: "bez prominence zadní hrany do páteřního kanálu. ",
    conclusion: ". ",
    color: green,
  },
  {
    value: 1,
    text: "s prominencí bez stenózy páteřního kanálu",
    description:
      "s diskrétní prominencí zadní hrany, ale bez stenózy páteřního kanálu, ",
    conclusion:
      ", s pouze diskrétní prominencí zadní hrany do páteřního kanálu. ",
    color: orange,
  },
  {
    value: 2,
    text: "s prominencí a lehkou stenózou",
    description:
      "s lehkou stenózou páteřního kanálu prominencí zadní hrany obratlového těla. ",
    conclusion: ", způsobující lehkou stenózu páteřního kanálu. ",
    color: orange,
  },
  {
    value: 3,
    text: "s prominencí a středně těžkou stenózou",
    description:
      "se středně těžkou stenózou páteřního kanálu prominencí zadní hrany obratlového těla. ",
    conclusion: ", způsobující středně těžkou stenózu páteřního kanálu. ",
    color: orange,
  },
  {
    value: 2,
    text: "s prominencí a těžkou stenózou",
    description:
      "s těžkou stenózou páteřního kanálu prominencí zadní hrany obratlového těla. ",
    conclusion: ", způsobující těžkou stenózu páteřního kanálu. ",
    color: orange,
  },
];
