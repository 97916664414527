import { green, yellow, orange, red } from '../scripts/GeneralArrays';

export const aEdema = [
  {
    value: 0,
    text: 'bez edému',
    description: 'Na T2FS bez zvýšení SI subchondrální kostní dřeně',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'okrsek edému',
    description: 'Na T2FS okrsek vyšší SI subchondrální kostní dřeně',
    conclusion: 'mírné',
    color: yellow,
  },
  {
    value: 2,
    text: 'okrsky edému',
    description: 'Na T2FS okrsky vyšší SI subchondrální kostní dřeně',
    conclusion: 'středně pokročilé',
    color: orange,
  },
  {
    value: 3,
    text: 'rozsáhlý edém',
    description:
      'Na T2FS rozsáhlé mapovité zvýšení SI subchondrální kostní dřeně',
    conclusion: 'pokročilé',
    color: red,
  },
];

export const aFattyMarrow = [
  {
    value: 0,
    text: 'bez tukové degen.',
    description: 'Na T1 bez zvýšení SI subchondrální kostní dřeně',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'okrsek tukové degen.',
    description: 'Na T1 okrsek vyšší SI subchondrální kostní dřeně',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'okrsky tukové degen.',
    description: 'Na T1 okrsky vyšší SI subchondrální kostní dřeně',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'rozsáhlá tuková degen.',
    description:
      'Na T1 rozsáhlé mapovité zvýšení SI subchondrální kostní dřeně',
    conclusion: '',
    color: red,
  },
];

export const aErosions = [
  {
    value: 0,
    text: 'bez erozí',
    description: 'Na T1 bez defektů kloubních ploch',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'jedna eroze',
    description: 'Na T1 jedna eroze kloubní plochy',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'více erozí',
    description: 'Na T1 více erozí kloubní plochy',
    conclusion: '',
    color: red,
  },
];
