import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Custom scripts
import { aEdema, aFattyMarrow, aErosions } from './MR_SIJ_arrays';
import { generateReport } from './MR_SIJ_functions';

// General scripts
import {
  aGeneral,
  contentWidth,
  aContrastMR,
  aContrastMRamount,
  aCardColors,
  aTrueFalse,
} from '../scripts/GeneralArrays';
import { formatCustomInput } from '../scripts/GeneralFunctions';

// Custom components
import CustomCards from '../components/CustomCards';
import ButtonUpDown from '../components/ButtonUpDown';
import DropDown from '../components/DropDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';

// 3rd party components
import { Form, Row, Col, Select, Input, Button } from 'antd';
import { debounce } from 'lodash';
const { Option } = Select;

class MR_SIJ extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      edemaR: 0,
      edemaL: 0,
      fatR: 0,
      fatL: 0,
      erosionR: 0,
      erosionL: 0,
    },
  };

  initialState = cloneDeep(this.state);

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = value => {
    let valueNum = value;
    let valueText = '';
    let valueBoolean = false;

    let newRecord = {
      valueNum: valueNum,
      valueText: valueText,
      valueBoolean: valueBoolean,
    };

    this.setState(prevState => ({
      lesions: [...prevState.lesions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const myContent1 = [
      {
        title: 'edém kostní dřeně',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'pravý SI kloub',
              input: (
                <ButtonUpDown
                  name='edemaR'
                  segment='general'
                  options={aEdema}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'levý SI kloub',
              input: (
                <ButtonUpDown
                  name='edemaL'
                  segment='general'
                  options={aEdema}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'tuková degenerace kostní dřeně',
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 'pravý SI kloub',
              input: (
                <ButtonUpDown
                  name='fatR'
                  segment='general'
                  options={aFattyMarrow}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'levý SI kloub',
              input: (
                <ButtonUpDown
                  name='fatL'
                  segment='general'
                  options={aFattyMarrow}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];
    const myContent2 = [
      {
        title: 'eroze kloubních ploch',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'pravý SI kloub',
              input: (
                <ButtonUpDown
                  name='erosionR'
                  segment='general'
                  options={aErosions}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'levý SI kloub',
              input: (
                <ButtonUpDown
                  name='erosionL'
                  segment='general'
                  options={aErosions}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: '',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: '',
              input: '',
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={myContent1} />
        <CustomCards content={myContent2} />
      </Form>
    );
  }
}

export default MR_SIJ;
