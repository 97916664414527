import { green, yellow, orange, red, grey } from "../scripts/GeneralArrays";

export const aSpineCor = [
  {
    value: 0,
    text: "v ose",
    description: "Páteř v ose. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "mírná dextroskolióza",
    description: "Mírné pravostranné vybočení LS páteře. ",
    conclusion: "Mírná dextroskolióza LS páteře. ",
  },
  {
    value: 2,
    text: "mírná sinistroskolióza",
    description: "Mírné levostranné vybočení LS páteře. ",
    conclusion: "Mírná sinistroskolióza LS páteře. ",
  },
  {
    value: 3,
    text: "dextroskolióza",
    description: "Pravostranné vybočení LS páteře. ",
    conclusion: "Dextroskolióza LS páteře. ",
  },
  {
    value: 4,
    text: "sinistroskolióza",
    description: "Levostranné vybočení LS páteře. ",
    conclusion: "Sinistroskolióza LS páteře. ",
  },
  {
    value: 5,
    text: "výrazná dextroskolióza",
    description: "Výrazné pravostranné vybočení LS páteře. ",
    conclusion: "Výrazná dextroskolióza LS páteře. ",
  },
  {
    value: 6,
    text: "výrazná sinistroskolióza",
    description: "Výrazné levostranné vybočení LS páteře. ",
    conclusion: "Výrazná sinistroskolióza LS páteře. ",
  },
];

export const aSpineSag = [
  {
    value: 0,
    text: "přiměřená",
    description: "Plynulá bederní lordóza. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "plošší",
    description: "Plošší bederní lordóza. ",
    conclusion: "Plošší bederní lordóza. ",
  },
  {
    value: 2,
    text: "vyrovnaná",
    description: "Vyrovnaná bederní lordóza. ",
    conclusion: "Vyrovnaná bederní lordóza. ",
  },
  {
    value: 3,
    text: "paradoxní kyfotizace",
    description: "Paradoxní kyfotizace bederní lordózy. ",
    conclusion: "Paradoxní kyfotizace bederní lordózy. ",
  },
];

export const aSegments = [
  { value: 0, text: "Th10/11" },
  { value: 1, text: "Th11/12" },
  { value: 2, text: "Th12/L1" },
  { value: 3, text: "L1/2" },
  { value: 4, text: "L2/3" },
  { value: 5, text: "L3/4" },
  { value: 6, text: "L4/5" },
  { value: 7, text: "L5/S1" },
];

export const aVertebrae = [
  { value: 0, text: "Th10" },
  { value: 1, text: "Th11" },
  { value: 2, text: "Th12" },
  { value: 3, text: "L1" },
  { value: 4, text: "L2" },
  { value: 5, text: "L3" },
  { value: 6, text: "L4" },
  { value: 7, text: "L5" },
  { value: 8, text: "S1" },
  { value: 9, text: "S2" },
];

export const aSpine = [
  { value: 0, text: "C1/2" },
  { value: 1, text: "C2/3" },
  { value: 2, text: "C3/4" },
  { value: 3, text: "C4/5" },
  { value: 4, text: "C5/6" },
  { value: 5, text: "C6/7" },
  { value: 6, text: "C7/Th1" },
  { value: 7, text: "Th1/2" },
  { value: 8, text: "Th2/3" },
  { value: 9, text: "Th3/4" },
  { value: 10, text: "Th4/5" },
  { value: 11, text: "Th5/6" },
  { value: 12, text: "Th6/7" },
  { value: 13, text: "Th7/8" },
  { value: 14, text: "Th8/9" },
  { value: 15, text: "Th9/10" },
  { value: 16, text: "Th10/11" },
  { value: 17, text: "Th11/12" },
  { value: 18, text: "Th12/L1" },
  { value: 19, text: "L1/2" },
  { value: 20, text: "L2/3" },
  { value: 21, text: "L3/4" },
  { value: 22, text: "L4/5" },
  { value: 23, text: "L5/S1" },
];

export const aAddFinds = [
  {
    value: 0,
    text: "divertikly sigmatu",
    description: "Výchlipky stěny sigmatu. ",
    conclusion: "divertikly sigmatu",
  },
  {
    value: 1,
    text: "zvětšená prostata",
    description: "Zvětšená prostata. ",
    conclusion: "zvětšená prostata",
  },
  {
    value: 2,
    text: "myomy dělohy",
    description: "Na T2 hyposignální ložiska v myometriu dělohy. ",
    conclusion: "myomy dělohy",
  },
  {
    value: 3,
    text: "cysty pravé ledviny",
    description:
      "Tenkou stěnou ohraničená kulovitá ložiska pravé ledviny signálu tekutiny. ",
    conclusion: "cysty pravé ledviny",
  },
  {
    value: 4,
    text: "cysty levé ledviny",
    description:
      "Tenkou stěnou ohraničená kulovitá ložiska levé ledviny signálu tekutiny. ",
    conclusion: "cysty levé ledviny",
  },
];

export const aListesis = [
  {
    value: 0,
    text: "bez posunu",
    description: "",
    conclusion: "",
  },
  {
    value: 1,
    text: "ventrolistéza",
    description: "ventrální posun obratlového těla ",
    conclusion: "ventrolistéza ",
  },
  {
    value: 2,
    text: "dorzolistéza",
    description: "dorzální posun obratlového těla ",
    conclusion: "dorzolistéza ",
  },
];

export const aDiskDegen = [
  {
    value: 0,
    text: "normal",
    color: green,
    description: "přiměřená výše disku, ",
  },
  {
    value: 1,
    text: "lehká",
    color: yellow,
    description: "disk je snížen o < 1/2 výše, ",
  },
  {
    value: 2,
    text: "střední",
    color: yellow,
    description: "disk je snížen o > 1/2 výše, ",
  },
  {
    value: 3,
    text: "těžká",
    color: orange,
    description: "disk je snížením zaniklý, ",
  },
];

export const aDiskPat = [
  {
    value: 0,
    text: "normal",
    description: "",
    conclusion: "",
  },
  {
    value: 1,
    text: "hernie",
    description: "hernie disku prominující ",
    conclusion: "hernie disku ",
  },
  {
    value: 2,
    text: "bulging",
    description: "bulging disku ",
    conclusion: "bulgingu disku ",
  },
  {
    value: 3,
    text: "asym. bulging",
    description: "bulging disku asymetricky výraznější ",
    conclusion: "bulgingu disku asymetricky výraznější ",
  },
  {
    value: 4,
    text: "osteofyty",
    description: "osteofyty krycích ploten prominující ",
    conclusion: "osteofytů krycích ploten ",
  },
  {
    value: 5,
    text: "anul. fisura",
    description: "na T2 plošné zvýšení signálu okraje disku ",
    conclusion: "anulární fisura disku ",
  },
];

export const aDiskDirection = [
  {
    value: 0,
    text: "F",
    symbol: "FR",
    description: "vpravo foraminálně",
  },
  {
    value: 1,
    text: "P",
    symbol: "PR",
    description: "vpravo paracentrálně",
  },
  {
    value: 2,
    text: "C",
    symbol: "C",
    description: "centrálně",
  },
  {
    value: 3,
    text: "P",
    symbol: "PL",
    description: "vlevo paracentrálně",
  },
  {
    value: 4,
    text: "F",
    symbol: "FL",
    description: "vlevo foraminálně",
  },
];

export const aDiskMigration = [
  {
    value: 0,
    text: "nemigruje",
    description: "",
  },
  {
    value: 1,
    text: "kraniálně",
    description: "migrující kraniálně",
  },
  {
    value: 2,
    text: "kaudálně",
    description: "migrující kaudálně",
  },
];

export const aStenosis = [
  {
    value: 0,
    text: "0",
    foramenLS: "",
    recessLS: "",
    canalLS: "",
    canalLSConclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "1",
    foramenLS: "v jednom rozměru zaniklý perineurální tuk (grade 1), ",
    foramenLSConclusion: "(grade 1)",
    recessLS: "bez odtlačení míšního kořene (grade 1), ",
    recessLSConclusion: "(grade 1)",
    canalLS: "lehká komprese durálního vaku (grade 1), ",
    canalLSConclusion: "lehká stenóza páteřního kanálu z důvodu ",
    color: yellow,
  },
  {
    value: 2,
    text: "2",
    foramenLS: "téměř zaniklý perineurální tuk (grade 2), ",
    foramenLSConclusion: "(grade 2)",
    recessLS: "s odtlačením nedeformovaného míšního kořene (grade 2), ",
    recessLSConclusion: "(grade 2)",
    canalLS: "středně těžká komprese durálního vaku (grade 2), ",
    canalLSConclusion: "středně těžká stenóza páteřního kanálu z důvodu ",
    color: orange,
  },
  {
    value: 3,
    text: "3",
    foramenLS: "z útlaku deformita durální pochvy míšního kořene (grade 3), ",
    foramenLSConclusion: "(grade 3)",
    recessLS: "s odtlačením a deformací míšního kořene (grade 3), ",
    recessLSConclusion: "(grade 3)",
    canalLS: "výrazná komprese durálního vaku (grade 3), ",
    canalLSConclusion: "těžká stenóza páteřního kanálu z důvodu ",
    color: red,
  },
];

export const aSize = [
  { value: 0, text: "0mm", color: green },
  { value: 1, text: "1mm", color: yellow },
  { value: 2, text: "2mm", color: yellow },
  { value: 3, text: "3mm", color: yellow },
  { value: 4, text: "4mm", color: yellow },
  { value: 5, text: "5mm", color: yellow },
  { value: 6, text: "6mm", color: orange },
  { value: 7, text: "7mm", color: orange },
  { value: 8, text: "8mm", color: orange },
  { value: 9, text: "9mm", color: orange },
  { value: 10, text: "10mm", color: red },
  { value: 11, text: "11mm", color: red },
  { value: 12, text: "12mm", color: red },
  { value: 13, text: "13mm", color: red },
  { value: 14, text: "14mm", color: red },
  { value: 15, text: "15mm", color: red },
];

export const aCustom = [
  {
    value: 0,
    text: "komprese obratle",
    description: "",
  },
];
