import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import {
  aLiverSize,
  aLiverParenchyma,
  aLiverLesions,
  aLiverSegments,
  aGB,
  aBiliaryTract,
} from "./MR_liver_arrays";
import { generateReport } from "./MR_liver_functions";

// General scripts
import {
  aGeneral,
  contentWidth,
  aContrastMRLiver,
  aContrastMRamount,
  aCardColors,
  aTrueFalse,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";
import DropDown from "../components/DropDown";
import ButtonUpDown from "../components/ButtonUpDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";

// 3rd party components
import { Form, Row, Col, Select, Input, Button, Space, Tag } from "antd";
import { debounce } from "lodash";
const { Option } = Select;
const { TextArea } = Input;

class MR_liver extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      liverSize: 0,
      liverParenchyma: 0,
      otherLiverDescription: "",
      otherLiverConclusion: "",
      GB: 0,
      biliaryTract: 0,
      otherBiliaryDescription: "",
      otherBiliaryConclusion: "",
      otherCustomDescription: "",
      otherCustomConclusion: "",
    },
    liverLesions: [],
  };

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value) => {
    let newRecord = {
      lesion: value,
      segment: 0,
      size: "",
    };

    this.setState((prevState) => ({
      liverLesions: [...prevState.liverLesions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const liverLesions = [
      {
        title: (
          <Select
            value="přidat ložisko jaterní"
            size="small"
            onChange={(value) => {
              this.newRow(value);
            }}
          >
            {aLiverLesions.map((item) => (
              <Option value={item.value}>{item.text}</Option>
            ))}
          </Select>
        ),
        color: aCardColors.red,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {this.state.liverLesions
                    .sort(
                      (a, b) =>
                        b["lesion"] - a["lesion"] || a["segment"] - b["segment"]
                    )
                    .map((item, index) => {
                      return (
                        <Row>
                          <Col
                            span={23}
                            style={{ marginBottom: "10px" }}
                            key={index}
                          >
                            <Space size={5}>
                              {index + 1 + ")"}
                              <Select
                                key={index}
                                value={item.lesion}
                                defaultValue={item.lesion}
                                size="small"
                                style={{ width: "180px" }}
                                onChange={(value) =>
                                  this.updateRow(
                                    index,
                                    "liverLesions",
                                    "lesion",
                                    value
                                  )
                                }
                              >
                                {aLiverLesions.map((item) => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <Select
                                key={index}
                                value={item.segment}
                                defaultValue={item.segment}
                                size="small"
                                style={{ width: "180px" }}
                                onChange={(value) =>
                                  this.updateRow(
                                    index,
                                    "liverLesions",
                                    "segment",
                                    value
                                  )
                                }
                              >
                                {aLiverSegments.map((item) => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                              </Select>
                              <Input
                                key={index}
                                value={item.size}
                                size="small"
                                placeholder="velikost..."
                                suffix="mm"
                                style={{ width: "180px" }}
                                onChange={(event) =>
                                  this.updateRowText(
                                    index,
                                    "liverLesions",
                                    "size",
                                    event.target.value
                                  )
                                }
                              />
                            </Space>
                          </Col>
                          <Col span={1} align="right">
                            <Button
                              type="primary"
                              size="small"
                              danger
                              onClick={() =>
                                this.deleteRow(index, "liverLesions")
                              }
                            >
                              x
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    const liverGeneral = [
      {
        title: "játra obecně",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "velikost",
              input: (
                <ButtonUpDown
                  name="liverSize"
                  segment="general"
                  options={aLiverSize}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "parenchym",
              input: (
                <ButtonUpDown
                  name="liverParenchyma"
                  segment="general"
                  options={aLiverParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "popis",
              input: (
                <TextArea
                  name="otherLiverDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.otherLiverDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "otherLiverDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "závěr",
              input: (
                <TextArea
                  name="otherLiverConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.otherLiverConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "otherLiverConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
      {
        title: "žlučové cesty",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "žlučník",
              input: (
                <ButtonUpDown
                  name="GB"
                  segment="general"
                  options={aGB}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "žluč. cesty",
              input: (
                <ButtonUpDown
                  name="biliaryTract"
                  segment="general"
                  options={aBiliaryTract}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "popis",
              input: (
                <TextArea
                  name="otherBiliaryDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.otherBiliaryDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "otherBiliaryDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "závěr",
              input: (
                <TextArea
                  name="otherBiliaryConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.otherBiliaryConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "otherBiliaryConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    const otherFindings = [
      {
        title: "ostatní nálezy",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "popis",
              input: (
                <TextArea
                  name="otherCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.otherCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "otherCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "závěr",
              input: (
                <TextArea
                  name="otherCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.otherCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "otherCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={liverLesions} />
        <CustomCards content={liverGeneral} />
        <CustomCards content={otherFindings} />
      </Form>
    );
  }
}

export default MR_liver;
