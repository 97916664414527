import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import { aStenosis } from "./CT_CTA_lower_limbs_arrays";
import { generateReport } from "./CT_CTA_lower_limbs_functions";

// General scripts
import {
  contentWidth,
  aContrastCT,
  aContrastCTamount,
  aCardColors,
} from "../scripts/GeneralArrays";
import {} from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";

import { Form, Row, Col } from "antd";
import { debounce } from "lodash";
import LowerLimbArteries from "../SVGs/LowerLimbArteries";

class CT_CTA_lower_limbs extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      valueNum: 0,
      valueText: "",
      valueBoolean: 0,
    },
    arteries: {
      R_AIC: 0,
      R_AIE: 0,
      R_AII: 0,
      R_AFC: 0,
      R_AFSprox: 0,
      R_AFSmid: 0,
      R_AFSdist: 0,
      R_APF: 0,
      R_APprox: 0,
      R_APdist: 0,
      R_TTF: 0,
      R_AF: 0,
      R_ATP: 0,
      R_ATA: 0,
      R_ADP: 0,
      R_APP: 0,
      L_AIC: 0,
      L_AIE: 0,
      L_AII: 0,
      L_AFC: 0,
      L_AFSprox: 0,
      L_AFSmid: 0,
      L_AFSdist: 0,
      L_APF: 0,
      L_APprox: 0,
      L_APdist: 0,
      L_TTF: 0,
      L_AF: 0,
      L_ATP: 0,
      L_ATA: 0,
      L_ADP: 0,
      L_APP: 0,
    },
    lesions: [],
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value) => {
    let valueNum = value;
    let valueText = "";
    let valueBoolean = false;

    let newRecord = {
      valueNum: valueNum,
      valueText: valueText,
      valueBoolean: valueBoolean,
    };

    this.setState((prevState) => ({
      lesions: [...prevState.lesions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const myContent3 = [
      {
        title: "hodnocení stenóz",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "",
              input: (
                <Row>
                  <Col>
                    <LowerLimbArteries
                      name="LowerLimbArteries"
                      options={aStenosis}
                      arteries={this.state.arteries}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={myContent3} />
      </Form>
    );
  }
}

export default CT_CTA_lower_limbs;
