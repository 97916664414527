// Custom scripts
import {
  aLateralEpicondylitis,
  aMedialEpicondylitis,
  aSynovialFluid,
} from "./MR_elbow_arrays";

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  contentWidth,
  aSizemm,
  aSizecm,
} from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //

  const { latEpicondylitis, medEpicondylitis, synovialFluid } = props.general;

  // Náplň loketního kloubu
  let descriptionSynovialFluid = aSynovialFluid[synovialFluid].description;
  let conclusionSynovialFluid =
    synovialFluid > 0 ? aSynovialFluid[synovialFluid].conclusion : "";

  // Šlachy
  // Popis
  let descriptionTendons = "";
  let normalTendons =
    "Přiměřená šíře i signál šlach všech kompartmentů, bez výraznější náplně jejich pochev. ";
  let otherTendons =
    "Jinak přiměřená šíře i signál šlach ostatních kompartmentů, bez výraznější náplně jejich pochev. ";
  // Epikondylitidy
  let descriptionLatEpicondylitis =
    aLateralEpicondylitis[latEpicondylitis].description;
  let descriptionMedEpicondylitis =
    aMedialEpicondylitis[medEpicondylitis].description;
  if (latEpicondylitis + medEpicondylitis > 0) {
    if (latEpicondylitis > 0 && medEpicondylitis === 0) {
      descriptionTendons =
        aLateralEpicondylitis[latEpicondylitis].description + otherTendons;
    } else if (latEpicondylitis === 0 && medEpicondylitis > 0) {
      descriptionTendons =
        aMedialEpicondylitis[medEpicondylitis].description + otherTendons;
    } else {
      descriptionTendons =
        aLateralEpicondylitis[latEpicondylitis].description +
        aMedialEpicondylitis[medEpicondylitis].description +
        otherTendons;
    }
  } else {
    descriptionTendons = normalTendons;
  }
  // Závěr
  let conclusionTendons = "";
  if (latEpicondylitis + medEpicondylitis > 0) {
    conclusionTendons =
      aLateralEpicondylitis[latEpicondylitis].conclusion +
      aMedialEpicondylitis[medEpicondylitis].conclusion;
  }

  let isPathology = false;
  if (conclusionTendons + conclusionSynovialFluid != "") {
    isPathology = true;
  }

  importDescription1(
    "Postavení v humeroulnárním i humeroradiálním kloubu není porušeno. " +
      descriptionSynovialFluid +
      descriptionTendons +
      "Na PDFS přiměřená SI kostní dřeně. Bez strukturálních či signálových změn mediálního či laterálního kolaterálního vazu. Zachovalá šíře i SI kloubní chrupavky. "
  );

  importConclusion1(
    isPathology
      ? conclusionTendons + conclusionSynovialFluid
      : "Přiměřený nález na MR " +
          (protocol === 0
            ? "pravého loketního kloubu. "
            : "levého loketního kloubu. ")
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
