import React from 'react';

// Custom scripts
import {
  aOrganSize,
  aOrganAtrophy,
  aLiverSize,
  aLiverParenchyma,
  aSpleenSize,
  aKidneyParenchyma,
  aKidneyKPS,
  aPancreasParenchyma,
  aLN,
  aPancreasSize,
  aApendix,
  aColon,
  aOvarium,
  aSigma,
  aUrinaryBladder,
  aUterus,
  aProstate,
  aGBsize,
  aGBcontent,
  aGBwall,
  aBTextraSize,
  aBTintraSize,
  aAscites,
} from './CT_abdomen_arrays';
import { generateReport } from './CT_abdomen_functions';

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from '../scripts/GeneralArrays';
import {
  formatCustomInput,
  formatCustomComment,
} from '../scripts/GeneralFunctions';

// Custom components
import DropDown from '../components/DropDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';
import ButtonUpDown from '../components/ButtonUpDown';
import CustomCards from '../components/CustomCards';

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
  Switch,
} from 'antd';
import { debounce } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;

class CT_abdomen extends React.Component {
  state = {
    key: 0,
    report: {
      exam: this.props.exam,
      protocol: this.props.protocol,
      contrast: this.props.contrast,
    },
    general: {
      liverSize: 0,
      liverParenchyma: 0,
      liverLesions: [],
      liverCustomDescription: '',
      liverCustomConclusion: '',
      GBCHCE: false,
      GBSize: 0,
      GBcontent: 0,
      GBwall: 0,
      GBcustomDescription: '',
      GBcustomConclusion: '',
      BTextraSize: 0,
      BTintraSize: 0,
      BTaerobilia: false,
      BTcustomDescription: '',
      BTcustomConclusion: '',
      apendix: 0,
      colon: 0,
      GITcustomDescription: '',
      GITcustomConclusion: '',
      LNparaaortic: 0,
      LNiliacComR: 0,
      LNiliacComL: 0,
      LNiliacExtR: 0,
      LNiliacExtL: 0,
      LNiliacIntR: 0,
      LNiliacIntL: 0,
      LNcustomDescription: '',
      LNcustomConclusion: '',
      spleenSize: 0,
      spleenAcc: false,
      spleenCustomDescription: '',
      spleenCustomConclusion: '',
      kidneyParenchymaR: 0,
      kidneyKPSR: 0,
      kidneyCustomDescriptionR: '',
      kidneyCustomConclusionR: '',
      kidneyParenchymaL: 0,
      kidneyKPSL: 0,
      kidneyCustomDescriptionL: '',
      kidneyCustomConclusionL: '',
      pancreasSize: 0,
      pancreasParenchyma: 0,
      pancreasCustomDescription: '',
      pancreasCustomConclusion: '',
      appendix: 0,
      ovariumR: 0,
      ovariumL: 0,
      ovariumSizeR: '',
      ovariumSizeL: '',
      sigma: 0,
      urinaryBladder: 0,
      uterus: 0,
      prostate: 0,
      ascites: 0,
      hypogastriumCustomDescriptionR: '',
      hypogastriumCustomConclusionR: '',
      hypogastriumCustomDescriptionL: '',
      hypogastriumCustomConclusionL: '',
      pelvisCustomDescription: '',
    },
    LNEnlarged: [],
    LNMarginal: [],
    liverCustomShow: false,
    GBcustomShow: false,
    BTcustomShow: false,
    pancreasCustomShow: false,
    spleenCustomShow: false,
    kidneyRcustomShow: false,
    kidneyLcustomShow: false,
    GITcustomShow: false,
    LNcustomShow: false,
  };

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState(
      {
        [segment]: {
          ...this.state[segment],
          [name]: value,
        },
      },
      () =>
        this.props.askReport(
          generateReport(this.state, this.props.protocol, this.props.contrast)
        )
    );
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  showCustomInput = region => {
    this.setState(prevState => ({
      [region]: !prevState[region],
    }));
  };

  render() {
    const { GBCHCE } = this.state.general;
    const liver = [
      {
        title: (
          <Row>
            <Col span={12}>játra</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('liverCustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'velikost',
              input: (
                <ButtonUpDown
                  name='liverSize'
                  segment='general'
                  value={this.state.general.liverSize}
                  options={aLiverSize}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='liverParenchyma'
                  segment='general'
                  value={this.state.general.liverParenchyma}
                  options={aLiverParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: '',
              input: '',
            },
            {
              label: '',
              input: '',
            },
          ],
          [
            {
              label: this.state.liverCustomShow ? 'vlastní popis' : '',
              input: this.state.liverCustomShow ? (
                <TextArea
                  name='liverCustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.liverCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'liverCustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.liverCustomShow ? 'vlastní závěr' : '',
              input: this.state.liverCustomShow ? (
                <TextArea
                  name='liverCustomConclusion'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.liverCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'liverCustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const gallbladder_BT = [
      {
        title: (
          <Row>
            <Col span={12}>žlučník</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('GBcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.limeGreen,
        rows: [
          [
            {
              label: 'CHCE',
              input: (
                <ButtonTrueFalse
                  name='GBCHCE'
                  segment='general'
                  value={this.state.general.GBCHCE}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: GBCHCE ? '' : 'náplň žlučníku',
              input: (
                <>
                  {GBCHCE ? (
                    ''
                  ) : (
                    <ButtonUpDown
                      name='GBsize'
                      segment='general'
                      value={this.state.general.GBsize}
                      options={aGBsize}
                      onChange={this.onChange}
                    />
                  )}
                </>
              ),
            },
          ],
          GBCHCE
            ? []
            : [
                {
                  label: 'obsah žlučníku',
                  input: (
                    <ButtonUpDown
                      name='GBcontent'
                      segment='general'
                      value={this.state.general.GBcontent}
                      options={aGBcontent}
                      onChange={this.onChange}
                    />
                  ),
                },
                {
                  label: 'stěna žlučníku',
                  input: (
                    <ButtonUpDown
                      name='GBwall'
                      segment='general'
                      value={this.state.general.GBwall}
                      options={aGBwall}
                      onChange={this.onChange}
                    />
                  ),
                },
              ],
          [
            {
              label: this.state.GBcustomShow ? 'vlastní popis' : '',
              input: this.state.GBcustomShow ? (
                <TextArea
                  name='GBcustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.GBcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'GBcustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.GBcustomShow ? 'vlastní závěr' : '',
              input: this.state.GBcustomShow ? (
                <TextArea
                  name='GBcustomConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.GBcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'GBcustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>žlučové cesty</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('BTcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.limeGreen,
        rows: [
          [
            {
              label: 'd. hepatochol.',
              input: (
                <ButtonUpDown
                  name='BTextraSize'
                  segment='general'
                  value={this.state.general.BTextraSize}
                  options={aBTextraSize}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'intrahepat. ŽC',
              input: (
                <ButtonUpDown
                  name='BTintraSize'
                  segment='general'
                  value={this.state.general.BTintraSize}
                  options={aBTintraSize}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'aerobilie',
              input: (
                <ButtonTrueFalse
                  name='BTaerobilia'
                  segment='general'
                  value={this.state.general.BTaerobilia}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: '',
              input: '',
            },
          ],
          [
            {
              label: this.state.BTcustomShow ? 'vlastní popis' : '',
              input: this.state.BTcustomShow ? (
                <TextArea
                  name='BTcustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.BTcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'BTcustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.BTcustomShow ? 'vlastní závěr' : '',
              input: this.state.BTcustomShow ? (
                <TextArea
                  name='BTcustomConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.BTcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'BTcustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const pancreas_spleen = [
      {
        title: (
          <Row>
            <Col span={12}>pankreas</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('pancreasCustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 'velikost',
              input: (
                <ButtonUpDown
                  name='pancreasSize'
                  segment='general'
                  value={this.state.general.pancreasSize}
                  options={aPancreasSize}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='pancreasParenchyma'
                  segment='general'
                  value={this.state.general.pancreasParenchyma}
                  options={aPancreasParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.pancreasCustomShow ? 'vlastní závěr' : '',
              input: this.state.pancreasCustomShow ? (
                <TextArea
                  name='pancreasCustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.pancreasCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'pancreasCustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.pancreasCustomShow ? 'vlastní závěr' : '',
              input: this.state.pancreasCustomShow ? (
                <TextArea
                  name='pancreasCustomConclusion'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.pancreasCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'pancreasCustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>slezina</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('spleenCustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'velikost',
              input: (
                <ButtonUpDown
                  name='spleenSize'
                  segment='general'
                  value={this.state.general.spleenSize}
                  options={aSpleenSize}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'splenunculus',
              input: (
                <ButtonTrueFalse
                  name='spleenAcc'
                  segment='general'
                  value={this.state.general.spleenAcc}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.spleenCustomShow ? 'vlastní popis' : '',
              input: this.state.spleenCustomShow ? (
                <TextArea
                  name='spleenCustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.spleenCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'spleenCustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.spleenCustomShow ? 'vlastní závěr' : '',
              input: this.state.spleenCustomShow ? (
                <TextArea
                  name='spleenCustomConclusion'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.spleenCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'spleenCustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const GIT_LN = [
      {
        title: (
          <Row>
            <Col span={12}>gastrointestinální trakt</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('GITcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.purple,
        rows: [
          [
            {
              label: 'apendix',
              input: (
                <ButtonUpDown
                  name='apendix'
                  segment='general'
                  value={this.state.general.apendix}
                  options={aApendix}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'tračník',
              input: (
                <ButtonUpDown
                  name='colon'
                  segment='general'
                  value={this.state.general.colon}
                  options={aColon}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.GITcustomShow ? 'vlastní popis' : '',
              input: this.state.GITcustomShow ? (
                <TextArea
                  name='GITcustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.GITcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'GITcustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.GITcustomShow ? 'vlastní závěr' : '',
              input: this.state.GITcustomShow ? (
                <TextArea
                  name='GITcustomConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.GITcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'GITcustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>lymfatické uzliny</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('LNcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.orange,
        rows: [
          [
            {
              label: 'paraaortální',
              input: (
                <ButtonUpDown
                  name='LNparaaortic'
                  segment='general'
                  value={this.state.general.LNparaaortic}
                  options={aLN}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'ilické spol.',
              input: (
                <ButtonUpDown
                  name='LNiliacComR'
                  segment='general'
                  value={this.state.general.LNiliacComR}
                  options={aLN}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'ilické spol.',
              input: (
                <ButtonUpDown
                  name='LNiliacComL'
                  segment='general'
                  value={this.state.general.LNiliacComL}
                  options={aLN}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'ilické ext.',
              input: (
                <ButtonUpDown
                  name='LNiliacExtR'
                  segment='general'
                  value={this.state.general.LNiliacExtR}
                  options={aLN}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'ilické ext.',
              input: (
                <ButtonUpDown
                  name='LNiliacExtL'
                  segment='general'
                  value={this.state.general.LNiliacExtL}
                  options={aLN}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'ilické int.',
              input: (
                <ButtonUpDown
                  name='LNiliacIntR'
                  segment='general'
                  value={this.state.general.LNiliacIntR}
                  options={aLN}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'ilické int.',
              input: (
                <ButtonUpDown
                  name='LNiliacIntL'
                  segment='general'
                  value={this.state.general.LNiliacIntL}
                  options={aLN}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní popis' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.LNcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní závěr' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.LNcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const kidneys = [
      {
        title: (
          <Row>
            <Col span={12}>pravá ledvina</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('kidneyRcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='kidneyParenchymaR'
                  segment='general'
                  value={this.state.general.kidneyParenchymaR}
                  options={aKidneyParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'KPS',
              input: (
                <ButtonUpDown
                  name='kidneyKPSR'
                  segment='general'
                  value={this.state.general.kidneyKPSR}
                  options={aKidneyKPS}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.kidneyRcustomShow ? 'vlastní popis' : '',
              input: this.state.kidneyRcustomShow ? (
                <TextArea
                  name='kidneyCustomDescriptionR'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.kidneyCustomDescriptionR}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'kidneyCustomDescriptionR',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.kidneyRcustomShow ? 'vlastní závěr' : '',
              input: this.state.kidneyRcustomShow ? (
                <TextArea
                  name='kidneyCustomConclusionR'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.kidneyCustomConclusionR}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'kidneyCustomConclusionR',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },

      {
        title: (
          <Row>
            <Col span={12}>levá ledvina</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('kidneyLcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: 'parenchym',
              input: (
                <ButtonUpDown
                  name='kidneyParenchymaL'
                  segment='general'
                  value={this.state.general.kidneyParenchymaL}
                  options={aKidneyParenchyma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'KPS',
              input: (
                <ButtonUpDown
                  name='kidneyKPSL'
                  segment='general'
                  value={this.state.general.kidneyKPSL}
                  options={aKidneyKPS}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.kidneyLcustomShow ? 'vlastní popis' : '',
              input: this.state.kidneyLcustomShow ? (
                <TextArea
                  name='kidneyCustomDescriptionL'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.kidneyCustomDescriptionL}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'kidneyCustomDescriptionL',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.kidneyLcustomShow ? 'vlastní závěr' : '',
              input: this.state.kidneyLcustomShow ? (
                <TextArea
                  name='kidneyCustomConclusionL'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.kidneyCustomConclusionL}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'kidneyCustomConclusionL',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const hypogastrium = [
      {
        title: 'pravé hypogastrium',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'appendix',
              input: (
                <ButtonUpDown
                  name='appendix'
                  segment='general'
                  value={this.state.general.appendix}
                  options={aApendix}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'pravé ovarium',
              input: (
                <ButtonUpDown
                  name='ovariumR'
                  segment='general'
                  value={this.state.general.ovariumR}
                  options={aOvarium}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'popis obecně',
              input: (
                <TextArea
                  name='hypogastriumCustomDescriptionR'
                  placeholder='vlastní text do popisu...'
                  defaultValue={
                    this.state.general.hypogastriumCustomDescriptionR
                  }
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'hypogastriumCustomDescriptionR',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: 'závěr obecně',
              input: (
                <TextArea
                  name='hypogastriumCustomConclusionR'
                  placeholder='vlastní text do popisu...'
                  defaultValue={
                    this.state.general.hypogastriumCustomConclusionR
                  }
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'hypogastriumCustomConclusionR',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'malá pánev',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'moč. měchýř',
              input: (
                <ButtonUpDown
                  name='urinaryBladder'
                  segment='general'
                  value={this.state.general.urinaryBladder}
                  options={aUrinaryBladder}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'děloha',
              input: (
                <ButtonUpDown
                  name='uterus'
                  segment='general'
                  value={this.state.general.uterus}
                  options={aUterus}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'prostata',
              input: (
                <ButtonUpDown
                  name='prostate'
                  segment='general'
                  value={this.state.general.prostate}
                  options={aProstate}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'volná tekutina',
              input: (
                <ButtonUpDown
                  name='ascites'
                  segment='general'
                  value={this.state.general.ascites}
                  options={aAscites}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'popis obecně',
              input: (
                <TextArea
                  name='pancreasCustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.pancreasCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'pancreasCustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: 'závěr obecně',
              input: (
                <TextArea
                  name='pancreasCustomConclusion'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.pancreasCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'pancreasCustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'levé hypogastrium',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'sigma',
              input: (
                <ButtonUpDown
                  name='sigma'
                  segment='general'
                  value={this.state.general.sigma}
                  options={aSigma}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'levé ovarium',
              input: (
                <ButtonUpDown
                  name='ovariumL'
                  segment='general'
                  value={this.state.general.ovariumL}
                  options={aOvarium}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'popis obecně',
              input: (
                <TextArea
                  name='hypogastriumCustomDescriptionL'
                  placeholder='vlastní text do popisu...'
                  defaultValue={
                    this.state.general.hypogastriumCustomDescriptionL
                  }
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'hypogastriumCustomDescriptionL',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: 'závěr obecně',
              input: (
                <TextArea
                  name='hypogastriumCustomConclusionL'
                  placeholder='vlastní text do popisu...'
                  defaultValue={
                    this.state.general.hypogastriumCustomConclusionL
                  }
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'hypogastriumCustomConclusionL',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={liver} />
        <CustomCards content={gallbladder_BT} />
        <CustomCards content={pancreas_spleen} />
        <CustomCards content={kidneys} />
        <CustomCards content={GIT_LN} />
        <CustomCards content={hypogastrium} />
      </Form>
    );
  }
}

export default CT_abdomen;
