import { green, yellow, orange, red, neutral } from "../scripts/GeneralArrays";

// Custom

export const black = "#000000";
export const darkred = "#a8071a";
export const white = "#ffffff";

export const aArteries = [
  {
    value: 0,
    color: "none",
    text: "RCA1",
    abb: "RCA1",
    description: "prox. ACD",
    path: "m 58.073658,118.43216 c -8.774572,-6.8005 -18.731956,-12.33933 -28.293121,-7.73477 l 0.979622,2.74294 c 8.057822,-3.38732 15.83115,-0.60325 23.367551,5.39502 1.315316,-0.18226 2.630632,-0.35823 3.945948,-0.40319 z",
  },
  {
    value: 1,
    color: "none",
    text: "RCA2",
    abb: "RCA2",
    description: "střední ACD",
    path: "m 29.780537,110.69739 c -5.508382,3.13716 -9.520861,7.4378 -12.520909,12.52589 l 2.196679,1.67366 c 2.603951,-4.03051 5.667129,-7.97752 11.303852,-11.45661 z",
  },
  {
    value: 2,
    color: "none",
    text: "RCA3",
    abb: "RCA3",
    description: "dist. ACD",
    path: "m 17.259628,123.22328 c -4.242994,8.35895 -5.386118,24.89944 7.636077,22.48982 l -1.359849,-2.19668 c -7.321496,0.92587 -8.339323,-11.02679 -4.079549,-18.61948 z",
  },
  {
    value: 3,
    color: "none",
    text: "RCA4",
    abb: "RCA4",
    description: "RIVP",
    path: "m 23.535856,143.51642 c 5.780438,-1.52356 12.064038,-4.71582 20.083931,-7.84529 l 0.523018,1.67366 c -7.771081,3.28211 -16.243467,7.73386 -19.2471,8.36831 z",
  },
  {
    value: 4,
    color: "none",
    text: "RCA16",
    abb: "RCA16",
    description: "RPLD",
    path: "m 24.895705,145.7131 c 4.318625,7.57526 9.093051,15.48346 14.093425,20.93199 3.395971,3.70033 7.583758,4.35228 14.672622,1.76703 l 0.732226,1.67367 c -9.06611,3.33652 -12.416445,1.89967 -17.202329,-2.15098 -4.708479,-3.98514 -7.626827,-10.20709 -14.806435,-21.9079 z",
  },
  {
    value: 5,
    color: "none",
    text: "LCA5",
    abb: "LCA5",
    description: "ACS",
    path: "m 68.828413,135.30524 c 2.789434,0.6249 5.788967,0.78703 8.578401,0.5751 l 0.418415,3.34732 c -1.504652,0.40415 -3.140479,0.55071 -4.811774,0.62762 -1.434451,-0.97144 -3.993236,-0.97611 -6.224593,-1.15064 0.731662,-1.13313 1.446069,-2.26626 2.039551,-3.3994 z",
  },
  {
    value: 6,
    color: "none",
    text: "LCA6",
    abb: "LCA6",
    description: "prox. RIA",
    path: "m 77.406814,135.88034 c 5.050318,-0.041 10.781359,0.58895 17.259628,-2.6151 l 0.313811,3.76574 c -5.514234,2.37337 -11.380115,2.0867 -17.155024,2.19668 z",
  },
  {
    value: 7,
    color: "none",
    text: "LCA7",
    abb: "LCA7",
    description: "střední RIA",
    path: "m 94.666442,133.26524 c 5.613738,-2.69581 11.227468,-6.16099 16.841208,-10.25117 l 2.09208,2.7197 c -6.4823,5.14475 -12.69506,8.94182 -18.619479,11.29721 z",
  },
  {
    value: 8,
    color: "none",
    text: "LCA8",
    abb: "LCA8",
    description: "dist. RIA",
    path: "m 111.50765,123.01407 c 5.89048,-4.39557 15.06086,-11.99665 14.95835,-15.27215 -4.09317,-1.82057 -17.24712,1.20156 -26.883179,3.97494 l -0.627624,-1.77826 c 18.096643,-4.75054 27.578793,-5.63941 29.498273,-3.13812 1.72878,3.30509 -5.52889,10.65407 -14.85374,18.93329 z",
  },
  {
    value: 9,
    color: "none",
    text: "LCA9",
    abb: "LCA9",
    description: "RD 1",
    path: "m 99.478218,137.34479 c 5.339342,4.0465 6.352582,6.36256 7.636072,8.78672 -3.20785,-3.09485 -6.4157,-5.86179 -9.623546,-7.84528 z",
  },
  {
    value: 10,
    color: "none",
    text: "LCA10",
    abb: "LCA10",
    description: "RD 2",
    path: "m 112.34448,129.3949 c 6.52324,5.38358 7.99912,7.69485 8.1591,9.20514 -2.59265,-2.64996 -5.32191,-5.29993 -9.83276,-7.94989 z",
  },
  {
    value: 11,
    color: "none",
    text: "LCA11",
    abb: "LCA11",
    description: "prox. RCx",
    path: "m 73.013455,139.85528 c 0.596199,5.01006 -0.03886,9.25058 -1.673661,12.86627 l 2.719699,0.62762 c 1.613666,-4.5677 1.902005,-9.13539 1.569056,-13.70309 z",
  },
  {
    value: 12,
    color: "none",
    text: "LCA12",
    abb: "LCA12",
    description: "RMS 1",
    path: "m 74.687117,151.2571 c 15.322964,6.07021 18.252517,9.66173 21.339175,13.28468 -10.467734,-7.53854 -16.755016,-10.01653 -21.966801,-11.19261 z",
  },
  {
    value: 13,
    color: "none",
    text: "LCA13",
    abb: "LCA13",
    description: "dist. RCx",
    path: "M 71.339794,152.72155 C 64.245892,166.09603 37.60263,167.92052 30.2305,147.80518 l -3.033511,-0.94144 c 15.024301,32.02646 45.563192,14.46981 46.862502,6.48543 z",
  },
  {
    value: 14,
    color: "none",
    text: "LCA14",
    abb: "LCA14",
    description: "RMS 2",
    path: "m 72.385832,160.56684 c 12.575639,5.45101 17.343952,9.21394 19.665516,12.44785 -5.955126,-4.7842 -13.047918,-8.43073 -21.234572,-10.9834 z",
  },
  {
    value: 15,
    color: "none",
    text: "LCA15",
    abb: "LCA15",
    description: "RPVS",
    path: "m 30.2305,147.80518 c 0.179891,-1.71271 9.557424,-6.07931 15.272155,-8.36831 l -0.541713,-1.50763 c -5.87285,2.5534 -14.505301,5.90757 -17.763953,8.9345 z",
  },
  {
    value: 16,
    color: "none",
    text: "LCA17",
    abb: "LCA17",
    description: "RIM",
    path: "m 77.825229,139.22766 c 18.041832,8.46052 41.716051,25.1918 42.140641,28.53547 -12.8156,-9.51843 -30.9263,-21.70383 -44.225595,-26.68242 l -0.111726,-1.43463 2.19668,-0.41842",
  },
  {
    value: 17,
    color: "none",
    text: "LCA18",
    abb: "LCA18",
    description: "RPLS",
    path: "m 37.502467,155.78756 c 0.01896,-2.16316 2.012582,-4.63011 5.67264,-7.35343 -2.058478,0.40113 -4.155915,1.02304 -7.038276,6.09284 z",
  },
];

export const aCADRADS = [
  {
    value: 0,
    text: "CAD-RADS 0",
    description1: "bez stenózy",
    conclusion: "",
    color: white,
  },
  {
    value: 1,
    text: "CAD-RADS 1",
    description1: "stenóza < 24%",
    conclusion: "",
    color: "yellow",
  },
  {
    value: 2,
    text: "CAD-RADS 2",
    description1: "stenóza 25-49%",
    conclusion: "",
    color: "darkorange",
  },
  {
    value: 3,
    text: "CAD-RADS 3",
    description1: "stenóza 50-69%",
    conclusion: "",
    color: "red",
  },
  {
    value: 4,
    text: "CAD-RADS 4",
    description1: "stenóza 70-99%",
    conclusion: "",
    color: darkred,
  },
  {
    value: 5,
    text: "CAD-RADS 5",
    description1: "úplný uzávěr",
    conclusion: "",
    color: black,
  },
  {
    value: 6,
    text: "CAD-RADS N",
    description1: "nediagnostický segment",
    conclusion: "",
    color: "lightgrey",
  },
];

export const aStenosis = [
  {
    value: 0,
    text: "bez stenózy",
    description: "bez stenózy",
    color: "white",
  },
  {
    value: 1,
    text: "významná",
    description: "významná stenóza",
    color: red,
  },
  {
    value: 2,
    text: "uzávěr",
    description: "uzávěr",
    color: black,
  },
];

export const aPremedication = [
  {
    value: 0,
    text: "bez premedikace",
    description: "bez stenózy",
    color: green,
  },
  {
    value: 1,
    text: "Betaloc",
    description: "významná stenóza",
    color: red,
  },
];

export const aAcquisition = [
  {
    value: 0,
    text: "prospektivně",
    description: "Prospektivně ",
    color: green,
  },
  {
    value: 1,
    text: "retrospektivně",
    description: "Retrospektivně ",
    color: green,
  },
];

export const aQuality = [
  {
    value: 0,
    text: "výborná",
    description: ", s výbornou obrazovou kvalitou, bez artefaktů",
    color: green,
  },
  {
    value: 1,
    text: "dobrá",
    description:
      ", s dobrou obrazovou kvalitou, s min. artefakty neovlivňujícími hodnotitelnost",
    color: yellow,
  },
  {
    value: 2,
    text: "hraniční",
    description:
      ", s obrazovou kvalitou na hranici hodnotitelnosti pro četné artefakty",
    color: orange,
  },
  {
    value: 3,
    text: "nedostatečná",
    description:
      ", s četnými výraznými artefakty, pro které je vyšetření nehodnotitelné",
    color: red,
  },
];

export const aDomination = [
  {
    value: 0,
    text: "pravotyp",
    description: "bez stenózy",
    color: green,
  },
  {
    value: 1,
    text: "levotyp",
    description: "významná stenóza",
    color: green,
  },
  {
    value: 2,
    text: "vyvážený",
    description: "významná stenóza",
    color: green,
  },
];

export const aBetablocker = [
  {
    value: 0,
    text: "Metoprolol ",
    description: "Metoprolol ",
    color: green,
  },
  {
    value: 1,
    text: "Esmolol ",
    description: "Esmolol ",
    color: green,
  },
];

export const aMetoprololAmount = [
  {
    value: 0,
    text: "10mg",
    description: "10mg",
    color: green,
  },
  {
    value: 1,
    text: "20mg",
    description: "20mg",
    color: green,
  },
  {
    value: 2,
    text: "30mg",
    description: "30mg",
    color: green,
  },
];

export const aEsmololAmount = [
  {
    value: 0,
    text: "100mg",
    description: "10mg",
    color: green,
  },
  {
    value: 1,
    text: "200mg",
    description: "200mg",
    color: green,
  },
  {
    value: 2,
    text: "300mg",
    description: "300mg",
    color: green,
  },
];

export const aFlow = [
  {
    value: 0,
    text: "4ml/s",
    description: "4ml/s",
    color: green,
  },
  {
    value: 1,
    text: "5ml/s",
    description: "5ml/s",
    color: green,
  },
  {
    value: 2,
    text: "6ml/s",
    description: "6ml/s",
    color: green,
  },
];
