export const formatArrayToText = (array) => {
  if (Array.isArray(array)) {
    if (array.length > 2) {
      return (
        array.slice(0, array.length - 1).join(", ") + " a " + array.slice(-1)
      );
    } else if (array.length === 2) {
      return array.join(" a ");
    } else {
      return array.join("");
    }
  } else return array;
};

export const capitelizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getFirstVertebraFromSegment = (label) => {
  return label.substring(0, label.indexOf("/"));
};

export const formatCustomInput = (string) => {
  let customString = string;
  customString = customString.charAt(0).toUpperCase() + customString.slice(1);
  if (customString !== "") {
    if (customString.slice(-2) !== ". ") {
      if (customString.slice(-1) === ".") {
        customString += " ";
      } else if (customString.slice(-1) !== ".") customString += ". ";
    }
  }
  return customString;
};

export const formatCustomComment = (string) => {
  let customString = string;
  customString = customString.charAt(0).toLowerCase() + customString.slice(1);
  if (customString !== "") {
    if (customString.slice(-1) === ".") {
      customString = customString.slice(0, customString.length - 1);
    } else if (customString.slice(-2) === ". ") {
      customString = customString.slice(0, customString.length - 2);
    } else if (customString.slice(-1) === " ") {
      customString = customString.slice(0, customString.length - 1);
    }
  }
  return customString;
};

export const subtractArray = (array1, array2) => {
  let editedArray = [];
  let addItem = true;
  if (array2.length > 0) {
    for (let i = 0; i < array1.length; i++) {
      for (let j = 0; j < array2.length; j++) {
        if (
          array1[i].value === array2[j].vertebra ||
          array1[i].value === array2[j].segment ||
          array1[i].value === array2[j].vertebraFirst
        ) {
          addItem = false;
          break;
        } else {
          addItem = true;
        }
      }
      if (addItem) {
        editedArray.push(array1[i]);
      }
    }
    return editedArray;
  } else {
    return array1;
  }
};

export const removeAllNewLines = (string) => {
  return string.replace(/(\r\n|\n|\r)/gm, " ");
};

export const compareNumbers = (a, b) => {
  return a - b;
};

export const getDiskDirection = (directions) => {
  let directionsText = "";
  if (directions.length > 0) {
    if (directions.length === 3) {
      if (
        directions.includes("PR") &&
        directions.includes("C") &&
        directions.includes("PL")
      ) {
        directionsText = "centrálně až bilat. paracentrálně";
      } else if (
        directions.includes("C") &&
        directions.includes("PR") &&
        directions.includes("FR")
      ) {
        directionsText = "centrálně až vpravo foraminálně";
      } else if (
        directions.includes("C") &&
        directions.includes("PL") &&
        directions.includes("FL")
      ) {
        directionsText = "centrálně až vlevo foraminálně";
      } else {
        let customDirections = [];
        if (directions.includes("FR")) {
          customDirections.push("vpravo foraminálně");
        }
        if (directions.includes("PR")) {
          customDirections.push("vpravo paracentrálně");
        }
        if (directions.includes("C")) {
          customDirections.push("centrálně");
        }
        if (directions.includes("PL")) {
          customDirections.push("vlevo paracentrálně");
        }
        if (directions.includes("FL")) {
          customDirections.push("vlevo foraminálně");
        }
        directionsText = formatArrayToText(customDirections);
      }
    } else if (directions.length === 2) {
      if (directions.includes("C") && directions.includes("PR")) {
        directionsText = "centrálně až vpravo paracentrálně";
      } else if (directions.includes("C") && directions.includes("PL")) {
        directionsText = "centrálně až vlevo paracentrálně";
      } else if (directions.includes("PR") && directions.includes("FR")) {
        directionsText = "vpravo paracentrálně až foraminálně";
      } else if (directions.includes("PL") && directions.includes("FL")) {
        directionsText = "vlevo paracentrálně až foraminálně";
      } else if (directions.includes("FR") && directions.includes("FL")) {
        directionsText = "bilat. foraminálně";
      } else if (directions.includes("PR") && directions.includes("PL")) {
        directionsText = "bilat. paracentrálně";
      } else {
        let customDirections = [];
        if (directions.includes("FR")) {
          customDirections.push("vpravo foraminálně");
        }
        if (directions.includes("PR")) {
          customDirections.push("vpravo paracentrálně");
        }
        if (directions.includes("C")) {
          customDirections.push("centrálně");
        }
        if (directions.includes("PL")) {
          customDirections.push("vlevo paracentrálně");
        }
        if (directions.includes("FL")) {
          customDirections.push("vlevo foraminálně");
        }
        directionsText = formatArrayToText(customDirections);
      }
    } else if (directions.length === 1) {
      if (directions.includes("C")) {
        directionsText = "centrálně";
      } else if (directions.includes("PR")) {
        directionsText = "vpravo paracentrálně";
      } else if (directions.includes("PL")) {
        directionsText = "vlevo paracentrálně";
      } else if (directions.includes("FR")) {
        directionsText = "vpravo foraminálně";
      } else if (directions.includes("FL")) {
        directionsText = "vlevo foraminálně";
      }
    } else {
      let customDirections = [];
      if (directions.includes("FR")) {
        customDirections.push("vpravo foraminálně");
      }
      if (directions.includes("PR")) {
        customDirections.push("vpravo paracentrálně");
      }
      if (directions.includes("C")) {
        customDirections.push("centrálně");
      }
      if (directions.includes("PL")) {
        customDirections.push("vlevo paracentrálně");
      }
      if (directions.includes("FL")) {
        customDirections.push("vlevo foraminálně");
      }
      directionsText = formatArrayToText(customDirections);
    }
  }
  return directionsText;
};
