import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Custom scripts
import {
  aCustom,
  aProtocols,
  aFazekas,
  aGliosisInfratentorial,
  aPerivascSpaces,
  aSeptumPellucidum,
  aGCA,
  aMTA,
  aKoedam,
  aFocalAtrophy,
  aArachnoidCyst,
  aCalvarium,
  aPituitary,
  aMastoid,
  aSeptumNasi,
  aConchaBullosa,
  aLymbic,
  aIntraaxial,
  aArteriesVariety,
  aDemyelinSupra,
  aDemyelinSupraFilled,
  aDemyelinInfra,
  aDemyelinInfraFilled,
  aDemyelinIntramed,
  aDemyelinIntramedFilled,
  aLatVentAsymetry,
  aSinusFR,
  aSinusFL,
  aSinusMR,
  aSinusML,
  aSinusER,
  aSinusEL,
  aSinusSR,
  aSinusSL,
  aIAC,
} from './MR_brain_arrays';
import { generateReport } from './MR_brain_functions';

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from '../scripts/GeneralArrays';
import { formatCustomInput } from '../scripts/GeneralFunctions';

// Custom components
import DropDown from '../components/DropDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';
import ButtonUpDown from '../components/ButtonUpDown';
import CustomCards from '../components/CustomCards';

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
} from 'antd';
import { debounce } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;

class MR_brain extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      // Glióza
      Fazekas: 0,
      gliosisInfratentorial: 0,
      // Zadní jáma
      posteriorFossaDescription: '',
      posteriorFossaConclusion: '',
      IAC_AICA_R: 0,
      IAC_AICA_L: 0,
      // Orbity
      orbitsDescription: '',
      orbitsConclusion: '',
      // Demyelinizace
      demyelinSupraDescription: '',
      demyelinSupraConclusion: '',
      demyelinSupraButton: 0,
      demyelinInfraDescription: '',
      demyelinInfraConclusion: '',
      demyelinInfraButton: 0,
      demyelinIntramedDescription: '',
      demyelinIntramedConclusion: '',
      demyelinIntramedButton: 0,
      demyelinInfra: '',
      demyelinIntramed: '',
      // Atrofie
      GCA: 0,
      MTAright: 0,
      MTAleft: 0,
      Koedam: 0,
      focalAtrophy: 0,
      arachnoidCystR: 0,
      arachnoidCystL: 0,
      // Ostatní
      pituitary: 0,
      RathkeCystSize: '',
      pinealCyst: false,
      pinealCystSize: '',
      cavernoma: 0,
      DVA: 0,
      xanthogranulomas: false,
      lensRight: false,
      lensLeft: false,
      septumNasi: 0,
      concha: 0,
      mastoidRight: 0,
      mastoidLeft: 0,
      // Variety
      perivascSpaces: 0,
      septumPellucidum: 0,
      calvarium: 0,
      ependymitisGranularis: false,
      lymbic: 0,
      megacisterna: false,
      falxCalc: false,
      latVentAsymetry: 0,
      // VDN
      sinusFR: 0,
      sinusFL: 0,
      sinusMR: 0,
      sinusML: 0,
      sinusER: 0,
      sinusEL: 0,
      sinusSR: 0,
      sinusSL: 0,
      // Nosní dutina
      septumDeviation: 0,
      conchaBulosa: 0,
      // Hypofýza
      pituitaryDescription: '',
      pituitaryConclusion: '',
      // Mozkové tepny
      brainArtDescription: '',
      brainArtConclusion: '',
      A1R: 0,
      A1L: 0,
      P1R: 0,
      P1L: 0,
      VR: 0,
      VL: 0,
      // Žilní splavy
      brainVeinsDescription: '',
      brainVeinsConclusion: '',
      sinusTransR: 0,
      sinusTransL: 0,
      sinusSigmoidR: 0,
      sinusSigmoidL: 0,
      // Protokoly
      arterialMRA: false,
      venousMRA: false,
      posterioFossa: false,
      orbits: false,
    },
    custom: [],
  };

  initialState = cloneDeep(this.state);

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = event => {
    let patType = event.key;
    let description = '';
    let conclusion = '';

    let newRecord = {
      patType: patType,
      description: description,
      conclusion: conclusion,
    };

    this.setState(prevState => ({
      custom: [...prevState.custom, newRecord],
    }));
  };

  updateRow = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;

    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    {
      //console.log({ ...this.state });
    }
    const { protocol } = this.props;
    const myContent = [
      {
        title: 'Glióza',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'Fazekas',
              input:
                this.state.report.exam !== 5 ? (
                  <ButtonUpDown
                    name='Fazekas'
                    segment='general'
                    value={this.state.general.Fazekas}
                    options={aFazekas}
                    onChange={this.onChange}
                  />
                ) : null,
            },
          ],
          [
            {
              label: 'infratent.',
              input:
                this.state.report.exam !== 5 ? (
                  <DropDown
                    name='gliosisInfratentorial'
                    segment='general'
                    defaultValue={this.state.general.gliosisInfratentorial}
                    options={aGliosisInfratentorial}
                    onChange={this.onChange}
                  />
                ) : null,
            },
          ],
        ],
      },
      {
        title: 'Atrofie, arachnoidální cysta',
        color: aCardColors.green,
        rows: [
          [
            {
              label: 'GCA',
              input: (
                <ButtonUpDown
                  name='GCA'
                  segment='general'
                  value={this.state.general.GCA}
                  options={aGCA}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'Koedam',
              input: (
                <ButtonUpDown
                  name='Koedam'
                  segment='general'
                  value={this.state.general.Koedam}
                  options={aKoedam}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'MTA dx/sin',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='MTAright'
                      segment='general'
                      value={this.state.general.MTAright}
                      options={aMTA}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='MTAleft'
                      segment='general'
                      value={this.state.general.MTAleft}
                      options={aMTA}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'fokální',
              input: (
                <DropDown
                  name='focalAtrophy'
                  segment='general'
                  defaultValue={this.state.general.focalAtrophy}
                  options={aFocalAtrophy}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: '----------------',
              input: '--------------------------------',
            },
          ],
          [
            {
              label: 'arachn. cysta',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='arachnoidCystR'
                      segment='general'
                      value={this.state.general.arachnoidCystR}
                      options={aArachnoidCyst}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='arachnoidCystL'
                      segment='general'
                      value={this.state.general.arachnoidCystL}
                      options={aArachnoidCyst}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: 'Ostatní',
        color: aCardColors.orange,
        rows: [
          [
            {
              label: 'hypofýza',
              input: (
                <Row>
                  {this.state.general.pituitary < 3 ? (
                    <Col span={24}>
                      <ButtonUpDown
                        name='pituitary'
                        segment='general'
                        value={this.state.general.pituitary}
                        options={aPituitary}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name='pituitary'
                          segment='general'
                          value={this.state.general.pituitary}
                          options={aPituitary}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col>
                        <Input
                          name='RathkeCystSize'
                          value={this.state.general.RathkeCystSize}
                          size='small'
                          suffix='mm'
                          autoFocus
                          onChange={event =>
                            this.onChange({
                              segment: 'general',
                              name: 'RathkeCystSize',
                              value: event.target.value,
                            })
                          }
                          style={{ width: 90 }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'pineální cysta',
              input: (
                <Row>
                  {this.state.general.pinealCyst ? (
                    <>
                      <Col span={12}>
                        <ButtonTrueFalse
                          name='pinealCyst'
                          segment='general'
                          value={this.state.general.pinealCyst}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col>
                        <Input
                          name='pinealCystSize'
                          value={this.state.general.pinealCystSize}
                          size='small'
                          suffix='mm'
                          autoFocus
                          onChange={event =>
                            this.onChange({
                              segment: 'general',
                              name: 'pinealCystSize',
                              value: event.target.value,
                            })
                          }
                          style={{ width: 90 }}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col span={24}>
                      <ButtonTrueFalse
                        name='pinealCyst'
                        segment='general'
                        value={this.state.general.pinealCyst}
                        options={aTrueFalse}
                        onChange={this.onChange}
                      />
                    </Col>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'kavernom',
              input: (
                <DropDown
                  name='cavernoma'
                  segment='general'
                  defaultValue={this.state.general.cavernoma}
                  options={aIntraaxial}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'DVA',
              input: (
                <DropDown
                  name='DVA'
                  segment='general'
                  defaultValue={this.state.general.DVA}
                  options={aIntraaxial}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'xanthogr. ChP',
              input: (
                <ButtonTrueFalse
                  name='xanthogranulomas'
                  segment='general'
                  value={this.state.general.xanthogranulomas}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'náhrada čoček',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='lensRight'
                      segment='general'
                      value={this.state.general.lensRight}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='lensLeft'
                      segment='general'
                      value={this.state.general.lensLeft}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'frontální siny',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusFR'
                      segment='general'
                      value={this.state.general.sinusFR}
                      options={aSinusFR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusFL'
                      segment='general'
                      value={this.state.general.sinusFL}
                      options={aSinusFL}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'etmoid. siny',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusER'
                      segment='general'
                      value={this.state.general.sinusER}
                      options={aSinusER}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusEL'
                      segment='general'
                      value={this.state.general.sinusEL}
                      options={aSinusEL}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'maxilární siny',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusMR'
                      segment='general'
                      value={this.state.general.sinusMR}
                      options={aSinusMR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusML'
                      segment='general'
                      value={this.state.general.sinusML}
                      options={aSinusML}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'sfenoid. siny',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusSR'
                      segment='general'
                      value={this.state.general.sinusSR}
                      options={aSinusSR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusSL'
                      segment='general'
                      value={this.state.general.sinusSL}
                      options={aSinusSL}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],

          [
            {
              label: 'mastoidy',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='mastoidRight'
                      segment='general'
                      value={this.state.general.mastoidRight}
                      options={aMastoid}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='mastoidLeft'
                      segment='general'
                      value={this.state.general.mastoidLeft}
                      options={aMastoid}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: 'Variety',
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 'perivask. pr.',
              input: (
                <ButtonUpDown
                  name='perivascSpaces'
                  segment='general'
                  value={this.state.general.perivascSpaces}
                  options={aPerivascSpaces}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 's. pellucidum',
              input: (
                <DropDown
                  name='septumPellucidum'
                  segment='general'
                  defaultValue={this.state.general.septumPellucidum}
                  options={aSeptumPellucidum}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'kalva',
              input: (
                <ButtonUpDown
                  name='calvarium'
                  segment='general'
                  value={this.state.general.calvarium}
                  options={aCalvarium}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'epend. granul.',
              input: (
                <ButtonTrueFalse
                  name='ependymitisGranularis'
                  segment='general'
                  value={this.state.general.ependymitisGranularis}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'mesial. oblast',
              input: (
                <ButtonUpDown
                  name='lymbic'
                  segment='general'
                  value={this.state.general.lymbic}
                  options={aLymbic}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'megacisterna',
              input: (
                <ButtonTrueFalse
                  name='megacisterna'
                  segment='general'
                  value={this.state.general.megacisterna}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'kalc. falx',
              input: (
                <ButtonTrueFalse
                  name='falxCalc'
                  segment='general'
                  value={this.state.general.falxCalc}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'septum nasi',
              input: (
                <ButtonUpDown
                  name='septumNasi'
                  segment='general'
                  value={this.state.general.septumNasi}
                  options={aSeptumNasi}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'con. bullosa',
              input: (
                <ButtonUpDown
                  name='concha'
                  segment='general'
                  value={this.state.general.concha}
                  options={aConchaBullosa}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'asym. komor',
              input: (
                <ButtonUpDown
                  name='latVentAsymetry'
                  segment='general'
                  value={this.state.general.latVentAsymetry}
                  options={aLatVentAsymetry}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];

    const pituitaryGland = [
      {
        title: 'hypofýza',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'vlastní popis',
              input: (
                <TextArea
                  name='pituitaryDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.pituitaryDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'pituitaryDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: 'vlastní závěr',
              input: (
                <TextArea
                  name='pituitaryConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.pituitaryConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'pituitaryConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    const brainArteries = [
      {
        title: 'mozkové tepny - vlastní popis',
        color: aCardColors.red,
        rows: [
          [
            {
              label: '',
              input: (
                <TextArea
                  name='brainArtDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.brainArtDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'brainArtDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: '',
              input: (
                <TextArea
                  name='brainArtConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.brainArtConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'brainArtConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'mozkové tepny - variety',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'A1 segmenty',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='A1R'
                      segment='general'
                      value={this.state.general.A1R}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='A1L'
                      segment='general'
                      value={this.state.general.A1L}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'P1 segmenty',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='P1R'
                      segment='general'
                      value={this.state.general.P1R}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='P1L'
                      segment='general'
                      value={this.state.general.P1L}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'a. vertebrales',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='VR'
                      segment='general'
                      value={this.state.general.VR}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='VL'
                      segment='general'
                      value={this.state.general.VL}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const brainVeins = [
      {
        title: 'žilní splavy - vlastní popis',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: '',
              input: (
                <TextArea
                  name='brainVeinsDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.brainVeinsDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'brainVeinsDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: '',
              input: (
                <TextArea
                  name='brainVeinsConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.brainVeinsConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'brainVeinsConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'žilní splavy - variety',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'sinus trans.',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusTransR'
                      segment='general'
                      value={this.state.general.sinusTransR}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusTransL'
                      segment='general'
                      value={this.state.general.sinusTransL}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'sinus sigm.',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusSigmoidR'
                      segment='general'
                      value={this.state.general.sinusSigmoidR}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='sinusSigmoidL'
                      segment='general'
                      value={this.state.general.sinusSigmoidL}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const Block2 = [
      {
        title: 'MR mozku - vlastní popis',
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: '',
              input: (
                <>
                  {this.state.custom.map((item, index) => {
                    return (
                      <Col
                        span={24}
                        style={{ marginBottom: '10px' }}
                        key={index}
                      >
                        <Row align='middle'>
                          <Col flex='100px'>
                            <Divider
                              plain
                              orientation='center'
                              style={{ margin: '0px' }}
                            >
                              <Tag
                                color={aCustom[item.patType].color}
                                style={{ color: 'black' }}
                              >
                                {aCustom[item.patType].label}
                              </Tag>
                            </Divider>
                          </Col>
                          <Col flex='auto'>
                            <Divider style={{ margin: '0' }}></Divider>
                          </Col>
                          <Col flex='25px' align='middle'>
                            <CloseCircleOutlined
                              style={{ fontSize: '17px', color: 'red' }}
                              onClick={() => this.deleteRow(index, 'custom')}
                            />
                          </Col>
                        </Row>
                        <Row align='middle'>
                          <Col
                            flex='100px'
                            style={{
                              textAlign: 'right',
                              paddingRight: '5px',
                            }}
                          >
                            popis:
                          </Col>
                          <Col flex='auto'>
                            <TextArea
                              name='description'
                              placeholder='popis nálezu...'
                              defaultValue={
                                this.state.custom[index].description
                              }
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onChange={event =>
                                this.updateRow(
                                  index,
                                  'custom',
                                  'description',
                                  formatCustomInput(event.target.value)
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row align='middle'>
                          <Col
                            flex='100px'
                            style={{
                              textAlign: 'right',
                              paddingRight: '5px',
                            }}
                          >
                            závěr:
                          </Col>
                          <Col flex='auto'>
                            <TextArea
                              name='conclusion'
                              placeholder='závěr nálezu...'
                              defaultValue={this.state.custom[index].conclusion}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onChange={event =>
                                this.updateRow(
                                  index,
                                  'custom',
                                  'conclusion',
                                  formatCustomInput(event.target.value)
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              ),
            },
          ],
          [
            {
              label: '',
              input: (
                <Row>
                  <Col flex='100px'>
                    <Dropdown
                      placement='bottomLeft'
                      trigger={['click']}
                      style={{ width: '100%' }}
                      overlay={
                        <Menu onClick={this.newRow}>
                          {aCustom.map(item => (
                            <Menu.Item key={item.value} value={item.value}>
                              {item.label}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                    >
                      <Button
                        size='small'
                        type='dashed'
                        style={{ width: '100%' }}
                      >
                        nový nález...
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col flex='auto'></Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const blockMS = [
      {
        title: 'demyelinizace',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'supratentoriální',
              input: (
                <Row>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinSupraDescription'
                      segment='general'
                      placeholder='popis...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinSupraDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinSupraConclusion'
                      segment='general'
                      placeholder='závěr...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinSupraConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col flex='auto'>
                    <ButtonUpDown
                      name='demyelinSupraButton'
                      segment='general'
                      value={this.state.general.demyelinSupraButton}
                      options={
                        this.state.general.demyelinSupraDescription != '' ||
                        this.state.general.demyelinSupraConclusion != ''
                          ? aDemyelinSupraFilled
                          : aDemyelinSupra
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'infratentoriální',
              input: (
                <Row>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinInfraDescription'
                      segment='general'
                      placeholder='popis...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinInfraDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinInfraConclusion'
                      segment='general'
                      placeholder='závěr...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinInfraConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col flex='auto'>
                    <ButtonUpDown
                      name='demyelinInfraButton'
                      segment='general'
                      value={this.state.general.demyelinInfraButton}
                      options={
                        this.state.general.demyelinInfraDescription != '' ||
                        this.state.general.demyelinInfraConclusion != ''
                          ? aDemyelinInfraFilled
                          : aDemyelinInfra
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'intramedulární',
              input: (
                <Row>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinIntramedDescription'
                      segment='general'
                      placeholder='popis...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinIntramedDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinIntramedConclusion'
                      segment='general'
                      placeholder='závěr...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinIntramedConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col flex='auto'>
                    <ButtonUpDown
                      name='demyelinIntramedButton'
                      segment='general'
                      value={this.state.general.demyelinIntramedButton}
                      options={
                        this.state.general.demyelinIntramedDescription != '' ||
                        this.state.general.demyelinIntramedConclusion != ''
                          ? aDemyelinIntramedFilled
                          : aDemyelinIntramed
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const posteriorFossa = [
      {
        title: 'zadní jáma',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'vlastní popis',
              input: (
                <Row>
                  <Col span={24}>
                    <TextArea
                      size='small'
                      name='posteriorFossaDescription'
                      segment='general'
                      placeholder='vlastní popis...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'posteriorFossaDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'vlastní závěr',
              input: (
                <Row>
                  <Col span={24}>
                    <TextArea
                      size='small'
                      name='posteriorFossaConclusion'
                      segment='general'
                      placeholder='vlastní závěr...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'posteriorFossaConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'meatus a AICA',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='IAC_AICA_R'
                      segment='general'
                      value={this.state.general.IAC_AICA_R}
                      options={aIAC}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='IAC_AICA_L'
                      segment='general'
                      value={this.state.general.IAC_AICA_L}
                      options={aIAC}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: '',
              input: '',
            },
            {
              label: '',
              input: '',
            },
          ],
        ],
      },
    ];

    const orbits = [
      {
        title: 'orbity',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'vlastní popis',
              input: (
                <Row>
                  <Col span={24}>
                    <TextArea
                      size='small'
                      name='orbitsDescription'
                      segment='general'
                      placeholder='vlastní popis...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'orbitsDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'vlastní závěr',
              input: (
                <Row>
                  <Col span={24}>
                    <TextArea
                      size='small'
                      name='orbitsConclusion'
                      segment='general'
                      placeholder='vlastní závěr...'
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'orbitsConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const protocols = [
      {
        title: 'další rozšíření protokolu',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'MRA tepen',
              input: (
                <ButtonTrueFalse
                  name='arterialMRA'
                  segment='general'
                  value={this.state.general.arterialMRA}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'MRA žil',
              input: (
                <ButtonTrueFalse
                  name='venousMRA'
                  segment='general'
                  value={this.state.general.venousMRA}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'zadní jáma',
              input: (
                <ButtonTrueFalse
                  name='posterioFossa'
                  segment='general'
                  value={this.state.general.posterioFossa}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'orbity',
              input: (
                <ButtonTrueFalse
                  name='orbits'
                  segment='general'
                  value={this.state.general.orbits}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        {protocol === 6 || this.state.general.arterialMRA ? (
          <CustomCards content={brainArteries} />
        ) : null}
        {protocol === 7 || this.state.general.venousMRA ? (
          <CustomCards content={brainVeins} />
        ) : null}
        {protocol === 1 || this.state.general.posterioFossa ? (
          <CustomCards content={posteriorFossa} />
        ) : null}
        {protocol === 9 || this.state.general.orbits ? (
          <CustomCards content={orbits} />
        ) : null}
        {protocol === 3 ? <CustomCards content={pituitaryGland} /> : null}
        {protocol === 5 ? <CustomCards content={blockMS} /> : null}
        <CustomCards content={myContent} />
        <CustomCards content={Block2} />
        <CustomCards content={protocols} />
      </Form>
    );
  }
}

export default MR_brain;
