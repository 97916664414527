import { green, yellow, orange, red } from "../scripts/GeneralArrays";

export const aInvasionWall = [
  {
    value: 0,
    text: "bez tumoru",
    description:
      "V oblasti endocervixu až stromatu krčku dělohy na T2 bez přesvědčivého infiltrativního zvýšení SI, bez známek restrikce difuze",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "invaze <3mm",
    description:
      "V oblasti endocervixu až stromatu krčku dělohy na T2 infiltrativní zvýšení SI do hloubky <3mm, vykazující restrikci difuze",
    conclusion: "IA1",
    color: yellow,
  },
  {
    value: 2,
    text: "invaze ≥3mm a <5mm",
    description:
      "V oblasti endocervixu až stromatu krčku dělohy na T2 infiltrativní zvýšení SI do hloubky 3mm a více, ale méně než 5mm, vykazující restrikci difuze",
    conclusion: "IA2",
    color: yellow,
  },
  {
    value: 3,
    text: "invaze ≥5mm, vel. <2cm",
    description:
      "V oblasti endocervixu až stromatu krčku dělohy na T2 infiltrativní zvýšení SI do hloubky 5mm a více, v největším rozměru celkové velikosti do 2cm, vykazující restrikci difuze",
    conclusion: "IB1",
    color: orange,
  },
  {
    value: 4,
    text: "velikost ≥2cm a <4cm",
    description:
      "V oblasti krčku dělohy na T2 infiltrativní zvýšení SI v největším rozměru 2cm a více, ale méně než 4cm, vykazující restrikci difuze",
    conclusion: "IB2",
    color: red,
  },
  {
    value: 5,
    text: "velikost ≥4cm",
    description:
      "V oblasti krčku dělohy na T2 infiltrativní zvýšení SI v největším rozměru ≥4cm, vykazující restrikci difuze",
    conclusion: "IB3",
    color: red,
  },
];

export const aInvasionVagina = [
  {
    value: 0,
    text: "bez invaze",
    description:
      "Bez přesvědčivého šíření nádorové infiltrace do stěny pochvy. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "invaze pouze horních 2/3",
    description:
      "Nádorová infiltrace se šíří do stěny pochvy, ale nezasahuje do její dolní třetiny. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "invaze i dolní 1/3",
    description:
      "Nádorová infiltrace se šíří do stěny pochvy a zasahuje až do její dolní třetiny. ",
    conclusion: "",
    color: red,
  },
];

export const aInvasionPelvicWall = [
  {
    value: 0,
    text: "bez invaze",
    description: "Bez jasných známek nádorové infiltrace pánevní stěny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "invaze pánevní stěny",
    description: "Nádorovou infiltrací je susp. postižena pánevní stěna. ",
    conclusion: "",
    color: red,
  },
];

export const aInvasionParametria = [
  {
    value: 0,
    text: "bez invaze",
    description: "Parametria bilat. bez přesvědčivé nádorové infiltrace. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "invaze pravého",
    description:
      "Nádorová infiltrace pokračuje do pravostranných parametrií. Levostranná parametria bez přesvědčivé infiltrace. ",
    conclusion: "",
    color: red,
  },
  {
    value: 2,
    text: "invaze levého",
    description:
      "Nádorová infiltrace pokračuje do levostranných parametrií. Pravostranná parametria bez přesvědčivé infiltrace. ",
    conclusion: "",
    color: red,
  },
  {
    value: 3,
    text: "invaze obou",
    description:
      "Nádorová infiltrace pokračuje do pravostranných i levostranných parametrií. ",
    conclusion: "",
    color: red,
  },
];

export const aHydronephrosis = [
  {
    value: 0,
    text: "bez hydronefrózy",
    description: "Bez dilatace dutého systému ledvin. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hydronefróza vpravo",
    description: "Dilatace dutého systému pravé ledviny. ",
    conclusion: "",
    color: red,
  },
  {
    value: 2,
    text: "hydronefróza vlevo",
    description: "Dilatace dutého systému levé ledviny. ",
    conclusion: "",
    color: red,
  },
  {
    value: 3,
    text: "hydronefróza bilat.",
    description: "Dilatace dutého systému obou ledvin. ",
    conclusion: "",
    color: red,
  },
];

export const aLNpelvic = [
  {
    value: 0,
    text: "normální",
    description: "přiměřené velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční",
    description: "hraniční velikosti",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšené",
    description: "zvětšené",
    conclusion: "",
    color: red,
  },
];

export const aUrinaryBladder = [
  {
    value: 0,
    text: "bez invaze",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "invaze tumoru",
    description:
      "Susp. šíření nádorové infiltrace krčku dělohy do stěny močového měchýře. ",
    conclusion: "",
    color: red,
  },
];

export const aRectum = [
  {
    value: 0,
    text: "bez invaze",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "invaze tumoru",
    description:
      "Susp. šíření nádorové infiltrace krčku dělohy do stěny rekta. ",
    conclusion: "",
    color: red,
  },
];

export const aAscites = [
  {
    value: 0,
    text: "bez volné tekutiny",
    description: "V zobrazeném rozsahu intraperitoneálně bez volné tekutiny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "malé množství v DP",
    description:
      "Na dně malé pánve v Douglassově prostoru malé množství volné tekutiny. ",
    conclusion: "Malé množství volné tekutiny v Douglassově prostoru. ",
    color: yellow,
  },
  {
    value: 2,
    text: "ascites",
    description: "Volná tekutina mezi kličkami střevními. ",
    conclusion: "Ascites. ",
    color: red,
  },
];

export const aOvary = [
  {
    value: 0,
    text: "nedif.",
    description: "není spolehlivě diferencovatelné",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description: "s přiměřeným nálezem",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "cysta",
    description: "Cysta",
    conclusion: "",
    color: yellow,
  },
  {
    value: 3,
    text: "cysty",
    description: "Cysty",
    conclusion: "",
    color: yellow,
  },
];

export const aLN = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční",
    description: "hraniční velikosti",
    conclusion: "hraniční velikosti",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšené",
    description: "zvětšené",
    conclusion: "zvětšené",
    color: red,
  },
];

export const aLNcount = [
  {
    value: 0,
    text: "1",
    description: "1",
    conclusion: "jedna ",
    color: orange,
  },
  {
    value: 1,
    text: "2",
    description: "2",
    conclusion: "dvě ",
    color: orange,
  },
  {
    value: 2,
    text: "3",
    description: "3",
    conclusion: "tři ",
    color: orange,
  },
  {
    value: 3,
    text: ">3",
    description: ">3",
    conclusion: "více než 3 ",
    color: orange,
  },
];
