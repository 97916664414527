// Custom scripts
import {
  aAscites,
  aProstateSize,
  aUterusGeneral,
  aUterusPosition,
  aOvarium,
  aLimitations,
  aNormalFindings,
  aLNcount,
} from './MR_pelvis_arrays';
import { aLNpelvic } from './MR_pelvic_arrays';

// General scripts
import { indent, br } from '../scripts/GeneralArrays';
import { capitelizeFirstLetter } from '../scripts/GeneralFunctions';
import { formatArrayToText } from '../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const {
    prostateSize,
    prostateCustomDescription,
    prostateCustomConclusion,
    scrotumHydrops,
    scrotumCustomDescription,
    scrotumCustomConclusion,
    uterusGeneral,
    uterusPosition,
    ovulaNabothi,
    uterusCustomDescription,
    uterusCustomConclusion,
    ovariumR,
    ovariumL,
    ovariumCustomDescription,
    ovariumCustomConclusion,
    vesicalWall,
    urinaryBladderCustomDescription,
    urinaryBladderCustomConclusion,
    ascites,
    sigmoidDiverticles,
    sigmoidCustomDescription,
    sigmoidCustomConclusion,
    softTissuesCustomDescription,
    softTissuesCustomConclusion,
    bonesCustomDescription,
    bonesCustomConclusion,
    LNparaaortic,
    LNiliacComR,
    LNiliacComL,
    LNiliacExtR,
    LNiliacExtL,
    LNiliacIntR,
    LNiliacIntL,
    LNinguinalR,
    LNinguinalL,
    LNparaaorticCount,
    LNiliacComRCount,
    LNiliacComLCount,
    LNiliacExtRCount,
    LNiliacExtLCount,
    LNiliacIntRCount,
    LNiliacIntLCount,
    LNinguinalRCount,
    LNinguinalLCount,
    LNcustomDescription,
    LNcustomConclusion,
    limitations,
    normalFindings,
  } = props.general;
  const { LN } = props;

  // Prostata
  let prostateDescription =
    prostateCustomDescription + aProstateSize[prostateSize].description;
  let prostateConclusion =
    prostateCustomConclusion + aProstateSize[prostateSize].conclusion;

  // Skrótum
  let scrotumDescription =
    scrotumCustomDescription +
    (scrotumHydrops ? 'Tekutina v skrotálním vaku kolem varlat. ' : '') +
    (scrotumHydrops || scrotumCustomDescription !== ''
      ? 'Jinak skrótum s normálním obsahem. '
      : 'Skrótum bez patologického obsahu. ');
  let scrotumConclusion =
    scrotumCustomConclusion + (scrotumHydrops ? 'Hydrokéla. ' : '');

  // Děloha
  let uterusDescription =
    uterusCustomDescription +
    'Děloha ' +
    (uterusGeneral != 1 ? aUterusPosition[uterusPosition].description : '') +
    aUterusGeneral[uterusGeneral].description +
    (ovulaNabothi ? 'Ovula Nabothi. ' : '');
  let uterusConclusion = uterusCustomConclusion;

  // Ovaria
  let ovariumDescription = '';
  let ovariumConclusion = '';
  ovariumDescription += ovariumCustomDescription;
  ovariumConclusion += ovariumCustomConclusion;
  if (ovariumR === ovariumL && ovariumR <= 1) {
    ovariumDescription +=
      'Pravé i levé ovarium ' + aOvarium[ovariumR].description;
  } else {
    if (ovariumR === ovariumL) {
      ovariumDescription +=
        'Pravé i levé ovarium ' + aOvarium[ovariumR].description;
      ovariumConclusion +=
        'Pravé i levé ovarium ' + aOvarium[ovariumR].conclusion;
    } else {
      ovariumDescription += 'Pravé ovarium ' + aOvarium[ovariumR].description;
      ovariumDescription += 'Levé ovarium ' + aOvarium[ovariumL].description;
      ovariumConclusion +=
        ovariumR > 1 ? 'Pravé ovarium ' + aOvarium[ovariumR].conclusion : '';
      ovariumConclusion +=
        ovariumL > 1 ? 'Levé ovarium ' + aOvarium[ovariumL].conclusion : '';
    }
  }

  // Močový měchýř
  let urinaryBladderDescription =
    urinaryBladderCustomDescription +
    (vesicalWall
      ? 'Difuzní nepravidelné zesílení vnitřní stěny močového měchýře. '
      : '') +
    (!vesicalWall && urinaryBladderCustomDescription === ''
      ? 'Močový měchýř s normální šíří stěny, bez patologického obsahu. '
      : '');
  let urinaryBladderConclusion =
    urinaryBladderCustomConclusion +
    (vesicalWall
      ? 'Trabekularizace stěny močového měchýře' +
        (protocol === 1 ? ', susp. při zvětšené prostatě. ' : '. ')
      : '');

  // Střeva
  let sigmoidDescription =
    sigmoidCustomDescription +
    (sigmoidDiverticles ? 'Výchlipky stěny sigmatu. ' : '') +
    (sigmoidDiverticles || sigmoidCustomDescription !== ''
      ? 'Jinak přiměřený nález na střevních kličkách. '
      : 'Přiměřený nález na střevních kličkách. ');
  let sigmoidConclusion =
    sigmoidCustomConclusion +
    (sigmoidDiverticles ? 'Divertikly sigmatu. ' : '');
  // Ascites
  let ascitesDesription = aAscites[ascites].description;
  let ascitesConclusion =
    protocol === 0 && ascites === 1
      ? 'Malé množství volné tektuiny v Douglassově prostoru (premenopauzálně fyziologické). '
      : aAscites[ascites].conclusion;

  // lymfatické uzliny
  let LNDescription = '';
  let LNConclusion = '';
  let LNisPat = false;
  let aEnlargedLN = [];
  let aEnlargedLNcount = [];
  let aBorderlineLN = [];
  let aBorderlineLNcount = [];

  if (LNparaaortic > 1) {
    aEnlargedLNcount.push(LNparaaorticCount);
    aEnlargedLN.push(aLNcount[LNparaaorticCount].conclusion + 'paraaortální');
  } else if (LNparaaortic === 1) {
    aBorderlineLNcount.push(LNparaaorticCount);
    aBorderlineLN.push(aLNcount[LNparaaorticCount].conclusion + 'paraaortální');
  }
  if (LNiliacComR > 1) {
    aEnlargedLNcount.push(LNiliacComRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? 'pravostranné společné ilické'
          : 'pravostranná společná ilická')
    );
  } else if (LNiliacComR === 1) {
    aBorderlineLNcount.push(LNiliacComRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? 'pravostranné společné ilické'
          : 'pravostranná společná ilická')
    );
  }
  if (LNiliacComL > 1) {
    aEnlargedLNcount.push(LNiliacComLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? 'levostranné společné ilické'
          : 'levostranná společná ilická')
    );
  } else if (LNiliacComL === 1) {
    aBorderlineLNcount.push(LNiliacComLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? 'levostranné společné ilické'
          : 'levostranná společná ilická')
    );
  }
  if (LNiliacExtR > 1) {
    aEnlargedLNcount.push(LNiliacExtRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? 'pravostranné zevní ilické'
          : 'pravostranná zevní ilická')
    );
  } else if (LNiliacExtR === 1) {
    aBorderlineLNcount.push(LNiliacExtRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? 'pravostranné zevní ilické'
          : 'pravostranná zevní ilická')
    );
  }
  if (LNiliacExtL > 1) {
    aEnlargedLNcount.push(LNiliacExtLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? 'levostranné zevní ilické'
          : 'levostranná zevní ilická')
    );
  } else if (LNiliacExtL === 1) {
    aBorderlineLNcount.push(LNiliacExtLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? 'levostranné zevní ilické'
          : 'levostranná zevní ilická')
    );
  }
  if (LNiliacIntR > 1) {
    aEnlargedLNcount.push(LNiliacIntRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? 'pravostranné vnitřní ilické'
          : 'pravostranná vnitřní ilická')
    );
  } else if (LNiliacIntR === 1) {
    aBorderlineLNcount.push(LNiliacIntRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? 'pravostranné vnitřní ilické'
          : 'pravostranná vnitřní ilická')
    );
  }
  if (LNiliacIntL > 1) {
    aEnlargedLNcount.push(LNiliacIntLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? 'levostranné vnitřní ilické'
          : 'levostranná vnitřní ilická')
    );
  } else if (LNiliacIntL === 1) {
    aBorderlineLNcount.push(LNiliacIntLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? 'levostranné vnitřní ilické'
          : 'levostranná vnitřní ilická')
    );
  }
  if (LNinguinalR > 1) {
    aEnlargedLNcount.push(LNinguinalRCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? 'pravostranné inguinální'
          : 'pravostranná inguinální')
    );
  } else if (LNinguinalR === 1) {
    aBorderlineLNcount.push(LNinguinalRCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? 'pravostranné inguinální'
          : 'pravostranná inguinální')
    );
  }
  if (LNinguinalL > 1) {
    aEnlargedLNcount.push(LNinguinalLCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? 'levostranné inguinální'
          : 'levostranná inguinální')
    );
  } else if (LNinguinalL === 1) {
    aBorderlineLNcount.push(LNinguinalLCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? 'levostranné inguinální'
          : 'levostranná inguinální')
    );
  }

  if (aEnlargedLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aEnlargedLN) +
        (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
          ? ' lymfatické uzliny '
          : ' lymfatická uzlina ') +
        'v krátké ose větší než 10mm. '
    );
    LNConclusion +=
      (aEnlargedLNcount[0] > 0 ? 'Zvětšené ' : 'Zvětšená ') +
      formatArrayToText(aEnlargedLN) +
      (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
        ? ' lymfatické uzliny. '
        : ' lymfatická uzlina. ');
    LNisPat = true;
  }

  if (aBorderlineLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aBorderlineLN) +
        (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
          ? ' lymfatické uzliny '
          : ' lymfatická uzlina ') +
        'v krátké ose přibližně 10mm. '
    );
    LNConclusion +=
      'Velikostí hraniční ' +
      formatArrayToText(aBorderlineLN) +
      (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
        ? ' lymfatické uzliny. '
        : ' lymfatická uzlina. ');
    LNisPat = true;
  }

  if (
    aEnlargedLN.length > 0 ||
    aBorderlineLN.length > 0 ||
    LNcustomDescription !== ''
  ) {
    LNDescription +=
      LNcustomDescription +
      'Jinak v zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ';
  } else {
    LNDescription +=
      'V zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ';
  }

  if (LNcustomConclusion !== '') {
    LNConclusion += LNcustomConclusion;
  }

  // Měkké tkáně
  let softTissusDescription = softTissuesCustomDescription;
  let softTissuesConclusion = softTissuesCustomConclusion;

  // Skelet
  let bonesDescription =
    bonesCustomDescription === ''
      ? 'Bez přesvědčivých ložiskových změn skeletu. '
      : bonesCustomDescription;
  let bonesConclusion = bonesCustomConclusion;

  // Normální nález
  let normalFindingsConclusion = '';
  if (normalFindings === 0) {
    if (
      prostateConclusion +
        scrotumConclusion +
        uterusConclusion +
        ovariumConclusion +
        LNConclusion +
        urinaryBladderConclusion +
        sigmoidConclusion +
        ascitesConclusion +
        softTissuesConclusion +
        bonesConclusion !==
      ''
    ) {
      normalFindingsConclusion = 'Jinak přiměřený nález na MR pánve. ';
    } else {
      normalFindingsConclusion = 'Přiměřený nález na MR pánve. ';
    }
  }

  // Limitace
  let limitationsDescription =
    limitations > 0 ? aLimitations[limitations].description : '';

  importDescription1(limitationsDescription);
  importDescription1(
    (protocol === 1
      ? prostateDescription + scrotumDescription
      : uterusDescription + ovariumDescription) +
      LNDescription +
      urinaryBladderDescription +
      sigmoidDescription +
      ascitesDesription +
      softTissusDescription +
      bonesDescription
  );

  importConclusion1(
    (protocol === 1
      ? prostateConclusion + scrotumConclusion
      : uterusConclusion + ovariumConclusion) +
      LNConclusion +
      urinaryBladderConclusion +
      sigmoidConclusion +
      ascitesConclusion +
      softTissuesConclusion +
      bonesConclusion +
      normalFindingsConclusion
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
