// Custom scripts
import {
  aSpineSag,
  aSpineCor,
  aSegments,
  aAddFinds,
  aListesis,
  aDiskDegen,
  aDiskPat,
  aDiskDirection,
  aDiskMigration,
  aStenosis,
  aFractureSite,
  aFractureDepth,
} from "./CT_spine_LS_arrays";

// General scripts
import { indent, br, aSize, aVertebrae } from "../scripts/GeneralArrays";
import {
  formatArrayToText,
  capitelizeFirstLetter,
  getFirstVertebraFromSegment,
} from "../scripts/GeneralFunctions";

export const generateReport = (props) => {
  const { exam, indication, contrast, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // Páteř segmenty

  let segments = props.segments.filter(
    (item) =>
      item.listesis > 0 ||
      item.diskDegen > 0 ||
      item.diskPat > 0 ||
      item.facetArthrosis > 0 ||
      item.foramenR + item.foramenL > 0 ||
      item.recessR + item.recessL > 0 ||
      item.spinalCanal > 0
  );

  segments.map((item) => {
    let directionsText = "";
    let directions = item.diskDirection.map((direction) => direction.symbol);
    if (directions.length > 0) {
      if (directions.length === 3) {
        let directions = item.diskDirection.map(
          (direction) => direction.symbol
        );
        if (
          directions.includes("PR") &&
          directions.includes("C") &&
          directions.includes("PL")
        ) {
          directionsText = "centrálně až bilat. paracentrálně";
        } else if (
          directions.includes("C") &&
          directions.includes("PR") &&
          directions.includes("FR")
        ) {
          directionsText = "centrálně až vpravo foraminálně";
        } else if (
          directions.includes("C") &&
          directions.includes("PL") &&
          directions.includes("FL")
        ) {
          directionsText = "centrálně až vlevo foraminálně";
        } else {
          directionsText = formatArrayToText(
            item.diskDirection.map((direction) => direction.description)
          );
        }
      } else if (directions.length === 2) {
        if (directions.includes("C") && directions.includes("PR")) {
          directionsText = "centrálně až vpravo paracentrálně";
        } else if (directions.includes("C") && directions.includes("PL")) {
          directionsText = "centrálně až vlevo paracentrálně";
        } else if (directions.includes("PR") && directions.includes("FR")) {
          directionsText = "vpravo paracentrálně až foraminálně";
        } else if (directions.includes("PL") && directions.includes("FL")) {
          directionsText = "vlevo paracentrálně až foraminálně";
        } else if (directions.includes("FR") && directions.includes("FL")) {
          directionsText = "bilat. foraminálně";
        } else {
          directionsText = formatArrayToText(
            item.diskDirection.map((direction) => direction.description)
          );
        }
      } else {
        directionsText = formatArrayToText(
          item.diskDirection.map((direction) => direction.description)
        );
      }
    }
    importDescription1(
      item.name +
        " - " +
        (item.listesis > 0
          ? aListesis[item.listesis].description +
            getFirstVertebraFromSegment(item.name)
          : "") +
        (item.listesisSize > 0
          ? " o " + aSize[item.listesisSize].text + ", "
          : "") +
        (item.diskDegen > 0 ? aDiskDegen[item.diskDegen].description : "") +
        (item.diskPat > 0 ? aDiskPat[item.diskPat].description : "") +
        directionsText +
        (item.diskPatSize > 0
          ? " max. " + aSize[item.diskPatSize].text + ", "
          : "") +
        (item.diskMigration > 0
          ? aDiskMigration[item.diskMigration].description
          : "") +
        (item.diskMigrationSize > 0
          ? " max. " + aSize[item.diskMigrationSize].text + ", "
          : "") +
        (item.facetArthrosis > 0
          ? "osteofyty facetových kloubů a hypertrofie žlutých vazů, "
          : "") +
        (item.foramenR > 0 || item.foramenL > 0
          ? item.foramenR === item.foramenL
            ? "v obou foraminech " + aStenosis[item.foramenR].foramenLS
            : item.foramenR > 0 && item.foramenL > 0
            ? "v pravém foramen " +
              aStenosis[item.foramenR].foramenLS +
              "v levém foramen " +
              aStenosis[item.foramenL].foramenLS
            : item.foramenR > 0
            ? "v pravém foramen " + aStenosis[item.foramenR].foramenLS
            : "v levém foramen " + aStenosis[item.foramenL].foramenLS
          : "") +
        (item.recessR > 0 || item.recessL > 0
          ? item.recessR === item.recessL
            ? "zúžení obou laterálních recesů " +
              aStenosis[item.recessR].recessLS
            : item.recessR > 0 && item.recessL > 0
            ? "zúžení pravého laterálního recesu " +
              aStenosis[item.recessR].recessLS +
              "zúžení levého laterálního recesu " +
              aStenosis[item.recessL].recessLS
            : item.recessR > 0
            ? "zúžení pravého laterálního recesu " +
              aStenosis[item.recessR].recessLS
            : "zúžení levého laterálního recesu " +
              aStenosis[item.recessL].recessLS
          : "") +
        (item.spinalCanal > 0 ? aStenosis[item.spinalCanal].canalLS : "")
    );
    importConclusion1(
      item.listesis +
        item.diskPat +
        item.foramenR +
        item.foramenL +
        item.recessR +
        item.recessL +
        item.spinalCanal >
        0
        ? item.name +
            " - " +
            (item.spinalCanal > 0
              ? aStenosis[item.spinalCanal].canalLSConclusion +
                (item.diskPat > 0 && item.diskPat !== 5 /*anulární fisura*/
                  ? aDiskPat[item.diskPat].conclusion
                  : "") +
                (item.diskPat !== 5 ? directionsText : "") +
                (item.diskPatSize > 0
                  ? " (" + aSize[item.diskPatSize].text + ")"
                  : "") +
                (item.diskMigration > 0
                  ? " " + aDiskMigration[item.diskMigration].description
                  : "") +
                (item.diskMigrationSize > 0
                  ? " (" + aSize[item.diskMigrationSize].text + ")"
                  : "") +
                (item.diskPat > 0 &&
                (item.listesis > 0 || item.facetArthrosis > 0)
                  ? " a "
                  : "") +
                (item.listesis > 0 || item.facetArthrosis > 0
                  ? "degenerativních změn (" +
                    (item.listesis > 0
                      ? aListesis[item.listesis].conclusion +
                        getFirstVertebraFromSegment(item.name) +
                        (item.listesisSize > 0
                          ? " o " + aSize[item.listesisSize].text
                          : "")
                      : "") +
                    (item.listesis > 0 && item.facetArthrosis > 0 ? ", " : "") +
                    (item.facetArthrosis > 0
                      ? "facetová artróza, hypertrofie žlutých vazů"
                      : "") +
                    ")"
                  : "") +
                ", "
              : (item.diskPat > 0
                  ? (item.diskPat !== 5
                      ? aDiskPat[item.diskPat].description
                      : aDiskPat[item.diskPat].conclusion) +
                    directionsText +
                    (item.diskPatSize > 0
                      ? " (" + aSize[item.diskPatSize].text + ")"
                      : "") +
                    (item.diskMigration > 0 ? " " : ", ") +
                    (item.diskMigration > 0
                      ? aDiskMigration[item.diskMigration].description
                      : "") +
                    (item.diskMigrationSize > 0
                      ? " (" + aSize[item.diskMigrationSize].text + "), "
                      : "")
                  : "") +
                (item.listesis > 0
                  ? aListesis[item.listesis].conclusion +
                    getFirstVertebraFromSegment(item.name) +
                    (item.listesisSize > 0
                      ? " (" + aSize[item.listesisSize].text + "), "
                      : "")
                  : "")) +
            (item.recessR > 0 || item.recessL > 0
              ? item.recessR === item.recessL
                ? "stenóza obou laterálních recesů " +
                  aStenosis[item.recessR].recessLSConclusion +
                  ", "
                : item.recessR > 0 && item.recessL > 0
                ? "stenóza pravého laterálního recesu " +
                  aStenosis[item.recessR].recessLSConclusion +
                  " a levého laterálního recesu " +
                  aStenosis[item.recessL].recessLSConclusion +
                  ", "
                : item.recessR > 0
                ? "stenóza pravého laterálního recesu " +
                  aStenosis[item.recessR].recessLSConclusion +
                  ", "
                : "stenóza levého laterálního recesu " +
                  aStenosis[item.recessL].recessLSConclusion +
                  ", "
              : "") +
            (item.foramenR > 0 || item.foramenL > 0
              ? item.foramenR === item.foramenL
                ? "stenóza obou foramin " +
                  aStenosis[item.foramenR].foramenLSConclusion +
                  ", "
                : item.foramenR > 0 && item.foramenL > 0
                ? "stenóza pravého foramina " +
                  aStenosis[item.foramenR].foramenLSConclusion +
                  " a levého foramina " +
                  aStenosis[item.foramenL].foramenLSConclusion +
                  ", "
                : item.foramenR > 0
                ? "stenóza pravého foramina " +
                  aStenosis[item.foramenR].foramenLSConclusion +
                  ", "
                : "stenóza levého foramina " +
                  aStenosis[item.foramenL].foramenLSConclusion +
                  ", "
              : "")
        : ""
    );
  });

  // Páteř obecně
  const { spineSag, spineCor, hemangiomas } = props.general;

  let hemangiomasFinal = hemangiomas
    .sort()
    .map((item) => aVertebrae[item].text);
  hemangiomasFinal = formatArrayToText(hemangiomasFinal);

  importDescription2(
    aSpineCor[spineCor].description +
      (segments.filter((item) => item.listesis > 0).length > 0
        ? "Jinak plynulá bederní lordóza. "
        : aSpineSag[spineSag].description) +
      "Přiměřená výše obratlových těl. " +
      (hemangiomas.length > 0
        ? "Ložisko nižší denzity s výraznější trámčinou v kostní dřeni obratlového těla " +
          hemangiomasFinal +
          ". Jinak bez přesvědčivých ložiskových změn zobrazeného skeletu. "
        : "Bez přesvědčivých ložisek v zobrazeném skeletu. ")
  );
  importConclusion2(
    aSpineCor[spineCor].conclusion +
      aSpineSag[spineSag].conclusion +
      (segments.filter((item) => item.diskDegen === 3).length > 0
        ? "Těžká degenerace disku " +
          formatArrayToText(
            segments
              .filter((item) => item.diskDegen === 3)
              .map((item) => item.name)
          ) +
          ". "
        : "") +
      (segments.filter((item) => item.diskDegen === 2).length > 0
        ? "Středně těžká degenerace disku " +
          formatArrayToText(
            segments
              .filter((item) => item.diskDegen === 2)
              .map((item) => item.name)
          ) +
          ". "
        : "") +
      (segments.filter((item) => item.diskDegen === 1).length > 0
        ? "Lehká degenerace disku " +
          formatArrayToText(
            segments
              .filter((item) => item.diskDegen === 1)
              .map((item) => item.name)
          ) +
          ". "
        : "") +
      (hemangiomas.length > 0
        ? "Hemangiom obratle " + hemangiomasFinal + ". "
        : "")
  );

  // Páteř ostatní
  const { schmorls, addFinds } = props.general;

  let schmorlsFinal = schmorls.sort().map((item) => aSegments[item].text);
  schmorlsFinal = formatArrayToText(schmorlsFinal);

  importDescription2(
    (schmorls.length > 0
      ? "Povrchové defekty centrální části krycích ploten segmentu " +
        schmorlsFinal +
        " obrazu Schmorlových uzlů. "
      : "") +
      (addFinds.length > 0
        ? addFinds
            .sort()
            .map((item) => aAddFinds[item].description)
            .join("")
        : "")
  );
  importConclusion2(
    addFinds.length > 0
      ? "Vedlejší nálezy: " +
          formatArrayToText(
            addFinds.sort().map((item) => aAddFinds[item].conclusion)
          )
      : ""
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
