import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// images
import cervicalCanalStenosis from '../images/cervical_canal_stenosis.png';

// Custom scripts
import {
  aSpineSag,
  aSpineCor,
  aAddFinds,
  aDiskPat,
  aFacetArthrosis,
  aUncoArthrosis,
  aADArthrosis,
} from './MR_spine_C_arrays';
import {
  aListesis,
  aDiskDegen,
  aModic,
  aDiskDirection,
  aDiskMigration,
  aStenosis,
  aFractureSite,
  aFractureEndplate,
  aFractureDepth,
  aFractureEdema,
  aFractureProminence,
  aSignal,
  aLaminectomy,
} from './MR_spine_arrays';
import { generateReport } from './MR_spine_C_functions';

// General scripts
import {
  contentWidth,
  aCardColors,
  aTrueFalse,
  aSpine,
  aVertebrae,
  aSize,
} from '../scripts/GeneralArrays';
import { formatCustomInput, subtractArray } from '../scripts/GeneralFunctions';

// Custom components
import CustomCards from '../components/CustomCards';
import DropDown from '../components/DropDown';
import ButtonUpDownRow from '../components/ButtonUpDownRow';
import ButtonUpDown from '../components/ButtonUpDown';

import {
  Form,
  Row,
  Col,
  Select,
  Tag,
  Space,
  Button,
  Input,
  Divider,
  Popover,
  Image,
} from 'antd';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const { Option } = Select;
const { CheckableTag } = Tag;
const { TextArea } = Input;

// Default
let firstSegment = 0;
let lastSegment = 9;
let aExamSegments = aSpine.filter(
  item => item.value >= firstSegment && item.value <= lastSegment
);

let aExamVertebrae = aVertebrae.filter(
  item => item.value >= firstSegment && item.value <= lastSegment
);

let aStabilisationRange = aVertebrae.filter(
  item => item.value >= firstSegment && item.value <= lastSegment + 1
);

class MR_spine_C extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      firstSegment: 0,
      lastSegment: 9,
      spineCor: 3,
      spineSag: 0,
      hemangiomas: [],
      schmorls: [],
      spineCustomDescription: '',
      spineCustomConclusion: '',
      addFinds: [],
      ADArthrosis: 0,
      valueNum: 0,
      valueText: '',
      valueBoolean: 0,
    },
    segments: aExamSegments.map(item => {
      return {
        value: item.value,
        name: item.text,
        listesis: 0,
        listesisSize: 0,
        diskDegen: 0,
        Modic: 0,
        diskPat: 0,
        diskDirection: [],
        diskPatSize: 0,
        diskMigration: 0,
        diskMigrationSize: 0,
        yellowLigament: 0,
        foramenR: 0,
        foramenL: 0,
        facetArthrosisR: 0,
        facetArthrosisL: 0,
        uncoArthrosisR: 0,
        uncoArthrosisL: 0,
        spinalCanal: 0,
        active: false,
      };
    }),
    compressions: [],
    boneLesions: [],
    laminectomy: [],
    stabilisation: [],
    customVertebra: [],
    customSegment: [],
  };

  initialState = cloneDeep(this.state);

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;
    this.setState(
      {
        [segment]: {
          ...this.state[segment],
          [name]: value,
        },
      },
      () => this.props.askReport(generateReport(this.state))
    );
  };

  componentDidMount = () => {
    let result = generateReport(this.state);
    this.setState({ result: result }, () =>
      this.props.askReport(generateReport(this.state))
    );
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
      console.log('Reset C spine. ');
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value, segment) => {
    let newRecord;
    let array = [...this.state[segment]];
    if (segment === 'compressions') {
      newRecord = {
        vertebra: value,
        endplate: 0,
        area: 0,
        compression: 0,
        edema: 0,
        prominence: 0,
      };
      array.push(newRecord);
      array.sort((a, b) => a.vertebra - b.vertebra);
    } else if (segment === 'boneLesions') {
      newRecord = {
        vertebra: value,
        T2: 3,
        T1: 3,
        STIR: 1,
        size: '',
        description: '',
        conclusion: '',
      };
      array.push(newRecord);
      array.sort((a, b) => a.vertebra - b.vertebra);
    } else if (segment === 'laminectomy') {
      newRecord = {
        segment: value,
        side: 0,
        description: '',
        conclusion: '',
      };
      array.push(newRecord);
      array.sort((a, b) => a.segment - b.segment);
    } else if (segment === 'stabilisation') {
      newRecord = {
        vertebraFirst: value,
        vertebraLast: value + 1,
        type: 0,
        description: '',
        conclusion: '',
      };
      array.push(newRecord);
      array.sort((a, b) => a.vertebraFirst - b.vertebraFirst);
    } else if (segment === 'customSegment') {
      newRecord = {
        segment: value,
        description: '',
        conclusion: '',
      };
      array.push(newRecord);
      array.sort((a, b) => a.segment - b.segment);
    } else if (segment === 'customVertebra') {
      newRecord = {
        vertebra: value,
        description: '',
        conclusion: '',
      };
      array.push(newRecord);
      array.sort((a, b) => a.vertebra - b.vertebra);
    }

    this.setState(() => ({
      [segment]: array,
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];
    array.sort((a, b) => a.vertebra - b.vertebra);

    array[index][name] = value;
    this.setState({ [segment]: array }, () =>
      this.props.askReport(generateReport(this.state))
    );
  };

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  handleTags = (item, checked, index) => {
    let array = [...this.state.segments];
    let selectedTags = array[index].diskDirection;
    if (selectedTags.indexOf(item) === -1) {
      selectedTags.push(item);
    } else {
      selectedTags.splice(selectedTags.indexOf(item), 1);
    }
    selectedTags.sort(function (a, b) {
      return a.value - b.value;
    });
    array[index].diskDirection = selectedTags;
    this.setState({ segments: array }, () =>
      this.props.askReport(generateReport(this.state))
    );
  };

  render() {
    const tagWidth = '87px';
    const {
      compressions,
      boneLesions,
      laminectomy,
      stabilisation,
      customVertebra,
      customSegment,
    } = this.state;
    let showListesisSize =
      this.state.segments.filter(item => item.listesis > 0).length > 0
        ? true
        : false;
    let showDiskPatSize =
      this.state.segments.filter(
        item => item.diskPat === 1 || item.diskPat === 2 || item.diskPat === 3
      ).length > 0
        ? true
        : false;

    let showDiskDirection =
      this.state.segments.filter(
        item =>
          (item.diskPat === 1 ||
            item.diskPat === 2 ||
            item.diskPat === 3 ||
            item.diskPat === 4) &&
          (item.diskPatSize > 0 || item.diskPat === 4)
      ).length > 0
        ? true
        : false;

    let showDiskMigration =
      this.state.segments.filter(
        item =>
          item.diskPat === 2 &&
          item.diskPatSize > 0 &&
          item.diskDirection.length > 0
      ).length > 0
        ? true
        : false;

    let showDiskMigrationSize =
      this.state.segments.filter(
        item =>
          item.diskPat === 2 &&
          item.diskPatSize > 0 &&
          item.diskDirection.length > 0 &&
          item.diskMigration > 0
      ).length > 0
        ? true
        : false;

    // Popups
    const cervicalCanalPopup = (
      <div>
        stenóza páteřního kanálu
        <Image
          width='5%'
          //style={{ marginLeft: "125px" }}
          src={cervicalCanalStenosis}
          alt='stenóza páteřního kanálu'
        />
      </div>
    );

    const labelEmpty = 65;
    const labelListesis = 100;
    const labelListesisSize = 65;

    const labelDiskDegen = 77;
    const labelModic = 77;

    const labelDiskPat = 100;
    const labelDiskPatSize = 65;
    const labelDiskDirection = 113;
    const labelDiskMigration = 78;
    const labelDiskMigrationSize = 65;

    const labelFacetArthrosis = 61;
    const labelUncoArthrosis = 61;
    const labelYellowLigament = 50;

    const labelStenosis = 34;

    const spineSegments = [
      {
        title: 'segmenty C páteře',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: '',
              input: (
                <>
                  {
                    <Row>
                      <Col flex={labelEmpty + 'px'}></Col>
                      <Col
                        flex={
                          showListesisSize
                            ? labelListesis + labelListesisSize + 'px'
                            : labelListesis + 'px'
                        }
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          posun obratle
                        </Tag>
                      </Col>
                      <Col
                        flex={labelDiskDegen + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          degen. disk
                        </Tag>
                      </Col>
                      <Col
                        flex={labelModic + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          Modic
                        </Tag>
                      </Col>
                      <Col
                        flex={
                          labelDiskPat +
                          (showDiskPatSize ? labelDiskPatSize : 0) +
                          (showDiskDirection ? labelDiskDirection : 0) +
                          (showDiskMigration ? labelDiskMigration : 0) +
                          (showDiskMigrationSize ? labelDiskMigrationSize : 0) +
                          'px'
                        }
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          segment pat.
                        </Tag>
                      </Col>

                      <Col
                        flex={labelFacetArthrosis + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={'facetová artróza vpravo'}>
                            FA P
                          </Popover>
                        </Tag>
                      </Col>
                      <Col
                        flex={labelUncoArthrosis + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={'unkovertebrální artróza vpravo'}>
                            UA P
                          </Popover>
                        </Tag>
                      </Col>
                      <Col
                        flex={labelYellowLigament + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={'hypertrofie žlutých vazů'}>
                            HŽV
                          </Popover>
                        </Tag>
                      </Col>
                      <Col
                        flex={labelUncoArthrosis + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={'unkovertebrální artróza vlevo'}>
                            UA L
                          </Popover>
                        </Tag>
                      </Col>
                      <Col
                        flex={labelFacetArthrosis + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={'facetová artróza vlevo'}>
                            FA L
                          </Popover>
                        </Tag>
                      </Col>

                      <Col
                        flex={labelStenosis + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={'stenóza pravého foramina'}>
                            PF
                          </Popover>
                        </Tag>
                      </Col>

                      <Col
                        flex={labelStenosis + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={cervicalCanalPopup}>PK</Popover>
                        </Tag>
                      </Col>

                      <Col
                        flex={labelStenosis + 'px'}
                        style={{ textAlign: 'center' }}
                      >
                        <Tag
                          style={{
                            width: '100%',
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          <Popover content={'stenóza levého foramina'}>
                            LF
                          </Popover>
                        </Tag>
                      </Col>
                    </Row>
                  }
                  {this.state.segments.map((item, index) => {
                    return (
                      <Row
                        key={index + this.state.key}
                        style={{ marginBottom: '1px' }}
                      >
                        <Col
                          flex={labelEmpty + 'px'}
                          style={{ textAlign: 'right', paddingRight: '5px' }}
                        >
                          <Tag
                            style={{
                              width: '100%',
                              margin: 0,
                              backgroundColor: aCardColors.blue.header,
                            }}
                          >
                            {item.name}
                          </Tag>
                        </Col>
                        <Col flex={labelListesis + 'px'}>
                          <ButtonUpDownRow
                            index={index}
                            name='listesis'
                            segment='segments'
                            value={item.listesis}
                            options={aListesis}
                            size='small'
                            style={{ width: labelListesis + 'px' }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        {showListesisSize ? (
                          <Col flex={labelListesisSize + 'px'}>
                            {item.listesis > 0 ? (
                              <ButtonUpDownRow
                                key={'listesisSize' + index + this.state.key}
                                index={index}
                                name='listesisSize'
                                segment='segments'
                                value={item.listesisSize}
                                options={aSize}
                                updateRow={this.updateRow}
                                isDisabled={item.listesis === 0 ? true : false}
                              />
                            ) : (
                              ''
                            )}
                          </Col>
                        ) : (
                          ''
                        )}
                        <Col flex={labelDiskDegen + 'px'}>
                          <ButtonUpDownRow
                            key={'diskDegen' + index + this.state.key}
                            index={index}
                            name='diskDegen'
                            segment='segments'
                            value={item.diskDegen}
                            options={aDiskDegen}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelModic + 'px'}>
                          <ButtonUpDownRow
                            key={'Modic' + index + this.state.key}
                            index={index}
                            name='Modic'
                            segment='segments'
                            value={item.Modic}
                            options={aModic}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelDiskPat + 'px'}>
                          <Select
                            key={'diskPat' + index + this.state.key}
                            value={item.diskPat}
                            defaultValue={item.diskPat}
                            name='diskPat'
                            size='small'
                            showArrow={false}
                            style={{ width: '100%' }}
                            onChange={value =>
                              this.updateRow(
                                index,
                                'segments',
                                'diskPat',
                                value
                              )
                            }
                          >
                            {aDiskPat.map(item => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>

                        {showDiskPatSize ? (
                          <Col flex={labelDiskPatSize + 'px'}>
                            {item.diskPat !== 0 && item.diskPat !== 4 ? (
                              <ButtonUpDownRow
                                index={index}
                                name='diskPatSize'
                                segment='segments'
                                value={item.diskPatSize}
                                options={aSize}
                                style={{ width: '100%' }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskPat === 0 || item.diskPat === 4
                                    ? true
                                    : false
                                }
                              />
                            ) : null}
                          </Col>
                        ) : null}
                        {showDiskDirection ? (
                          <Col flex={labelDiskDirection + 'px'}>
                            <Row>
                              {item.diskPat === 0
                                ? null
                                : aDiskDirection.map(item => (
                                    <Col>
                                      <CheckableTag
                                        key={item.value}
                                        checked={
                                          this.state.segments[
                                            index
                                          ].diskDirection.indexOf(item) > -1
                                        }
                                        onChange={checked =>
                                          this.handleTags(item, checked, index)
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        {item.text}
                                      </CheckableTag>
                                    </Col>
                                  ))}
                            </Row>
                          </Col>
                        ) : (
                          ''
                        )}
                        {showDiskMigration ? (
                          <Col flex={labelDiskMigration + 'px'}>
                            {item.diskPat > 0 &&
                            item.diskDirection.length > 0 ? (
                              <Select
                                key={index}
                                value={item.diskMigration}
                                defaultValue={item.diskMigration}
                                size='small'
                                showArrow={false}
                                style={{ width: '100%' }}
                                onChange={value =>
                                  this.updateRow(
                                    index,
                                    'segments',
                                    'diskMigration',
                                    value
                                  )
                                }
                                disabled={
                                  item.diskPat === 1 ||
                                  item.diskPat === 2 ||
                                  item.diskPat === 3
                                    ? false
                                    : true
                                }
                              >
                                {aDiskMigration.map(item => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                              </Select>
                            ) : null}
                          </Col>
                        ) : (
                          ''
                        )}
                        {showDiskMigrationSize ? (
                          <Col flex={labelDiskMigrationSize + 'px'}>
                            {item.diskMigration > 0 && item.diskPat > 0 ? (
                              <ButtonUpDownRow
                                index={index}
                                name='diskMigrationSize'
                                segment='segments'
                                value={item.diskMigrationSize}
                                options={aSize}
                                style={{ width: '100%' }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskMigration > 0 ? false : true
                                }
                              />
                            ) : null}
                          </Col>
                        ) : (
                          ''
                        )}

                        <Col flex={labelFacetArthrosis + 'px'}>
                          <ButtonUpDownRow
                            index={index}
                            name='facetArthrosisR'
                            segment='segments'
                            value={item.facetArthrosisR}
                            options={aFacetArthrosis}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelUncoArthrosis + 'px'}>
                          {index > 0 && index < 6 ? (
                            <ButtonUpDownRow
                              index={index}
                              name='uncoArthrosisR'
                              segment='segments'
                              value={item.uncoArthrosisR}
                              options={aUncoArthrosis}
                              style={{ width: '100%' }}
                              updateRow={this.updateRow}
                            />
                          ) : null}
                        </Col>
                        <Col flex={labelYellowLigament + 'px'}>
                          <ButtonUpDownRow
                            index={index}
                            name='yellowLigament'
                            segment='segments'
                            value={item.yellowLigament}
                            options={aTrueFalse}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelUncoArthrosis + 'px'}>
                          {index > 0 && index < 6 ? (
                            <ButtonUpDownRow
                              index={index}
                              name='uncoArthrosisL'
                              segment='segments'
                              value={item.uncoArthrosisL}
                              options={aUncoArthrosis}
                              style={{ width: '100%' }}
                              updateRow={this.updateRow}
                            />
                          ) : null}
                        </Col>
                        <Col flex={labelFacetArthrosis + 'px'}>
                          <ButtonUpDownRow
                            index={index}
                            name='facetArthrosisL'
                            segment='segments'
                            value={item.facetArthrosisL}
                            options={aFacetArthrosis}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>

                        <Col flex={labelStenosis + 'px'}>
                          <ButtonUpDownRow
                            index={index}
                            name='foramenR'
                            segment='segments'
                            value={item.foramenR}
                            options={aStenosis}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + 'px'}>
                          <ButtonUpDownRow
                            index={index}
                            name='spinalCanal'
                            segment='segments'
                            value={item.spinalCanal}
                            options={aStenosis}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>

                        <Col flex={labelStenosis + 'px'}>
                          <ButtonUpDownRow
                            index={index}
                            name='foramenL'
                            segment='segments'
                            value={item.foramenL}
                            options={aStenosis}
                            style={{ width: '100%' }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    const myContent1 = [
      {
        title: 'páteř obecně',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'C páteř',
              input: (
                <DropDown
                  name='spineCor'
                  segment='general'
                  defaultValue={this.state.general.spineCor}
                  options={aSpineCor}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'lordóza',
              input: (
                <DropDown
                  name='spineSag'
                  segment='general'
                  defaultValue={this.state.general.spineSag}
                  options={aSpineSag}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'hemangiomy',
              input: (
                <DropDown
                  name='hemangiomas'
                  segment='general'
                  defaultValue={this.state.general.hemangiomas}
                  options={aExamVertebrae}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'Schmorl. uzly',
              input: (
                <DropDown
                  name='schmorls'
                  segment='general'
                  defaultValue={this.state.general.schmorls}
                  options={aExamSegments}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'vedlejší nálezy',
              input: (
                <DropDown
                  name='addFinds'
                  segment='general'
                  defaultValue={this.state.general.addFinds}
                  options={aAddFinds}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'AD artróza',
              input: (
                <ButtonUpDown
                  name='ADArthrosis'
                  segment='general'
                  options={aADArthrosis}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: '',
              input: '',
            },
          ],
        ],
      },
      {
        title: 'páteř - vlastní záznam',
        color: aCardColors.green,
        rows: [
          [
            {
              label: 'popis',
              input: (
                <TextArea
                  size='small'
                  name='spineCustomDescription'
                  segment='general'
                  defaultValue={this.state.general.spineCustomDescription}
                  placeholder='...'
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'spineCustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: 'závěr',
              input: (
                <TextArea
                  size='small'
                  name='spineCustomConclusion'
                  segment='general'
                  defaultValue={this.state.general.spineCustomConclusion}
                  placeholder='...'
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'spineCustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    const customEntry = [
      {
        title: (
          <Space>
            <Select
              value='komprese obratle'
              size='small'
              onChange={value => {
                this.newRow(value, 'compressions');
              }}
            >
              {subtractArray(aExamVertebrae, compressions).map(item => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value='ložisko obratle'
              size='small'
              onChange={value => {
                this.newRow(value, 'boneLesions');
              }}
            >
              {subtractArray(aExamVertebrae, boneLesions).map(item => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value='stabilizace'
              size='small'
              onChange={value => {
                this.newRow(value, 'stabilisation');
              }}
            >
              {subtractArray(aStabilisationRange, stabilisation).map(item => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value='laminektomie'
              size='small'
              onChange={value => {
                this.newRow(value, 'laminectomy');
              }}
            >
              {subtractArray(aExamSegments, laminectomy).map(item => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value='vlastní - segment'
              size='small'
              onChange={value => {
                this.newRow(value, 'customSegment');
              }}
            >
              {subtractArray(aExamSegments, customSegment).map(item => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Select
              value='vlastní - obratel'
              size='small'
              onChange={value => {
                this.newRow(value, 'customVertebra');
              }}
            >
              {subtractArray(aExamVertebrae, customVertebra).map(item => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
          </Space>
        ),
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: '',
              input: (
                <>
                  {this.state.compressions.map((item, index) => {
                    return (
                      <Row>
                        <Col
                          span={23}
                          style={{ marginBottom: '10px' }}
                          key={index}
                        >
                          <Space size='0'>
                            <Tag color='#ff4d4f' style={{ width: tagWidth }}>
                              komprese
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebra}
                              size='small'
                              style={{ width: '90px' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'compressions',
                                  'vertebra',
                                  value
                                )
                              }
                            >
                              {aExamVertebrae.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            <Select
                              key={index}
                              value={item.area}
                              defaultValue={item.area}
                              size='small'
                              style={{ width: '138px' }}
                              bordered={false}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'compressions',
                                  'area',
                                  value
                                )
                              }
                            >
                              {aFractureSite.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            <Select
                              key={index}
                              value={item.compression}
                              defaultValue={item.compression}
                              size='small'
                              style={{ width: '120px' }}
                              bordered={false}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'compressions',
                                  'compression',
                                  value
                                )
                              }
                            >
                              {aFractureDepth.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            <Select
                              key={index}
                              value={item.endplate}
                              defaultValue={item.endplate}
                              size='small'
                              style={{ width: '230px' }}
                              bordered={false}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'compressions',
                                  'endplate',
                                  value
                                )
                              }
                            >
                              {aFractureEndplate.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            <Select
                              key={index}
                              value={item.edema}
                              defaultValue={item.edema}
                              size='small'
                              style={{ width: '110px' }}
                              bordered={false}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'compressions',
                                  'edema',
                                  value
                                )
                              }
                            >
                              {aFractureEdema.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            <Select
                              key={index}
                              value={item.prominence}
                              defaultValue={item.prominence}
                              size='small'
                              style={{ width: '320px' }}
                              bordered={false}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'compressions',
                                  'prominence',
                                  value
                                )
                              }
                            >
                              {aFractureProminence.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Space>
                        </Col>
                        <Col span={1} align='right'>
                          <Button
                            type='primary'
                            size='small'
                            danger
                            onClick={() =>
                              this.deleteRow(index, 'compressions')
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.boneLesions.map((item, index) => {
                    return (
                      <Row>
                        <Col
                          flex='auto'
                          style={{ marginBottom: '10px' }}
                          key={index}
                        >
                          <Space size='0'>
                            <Tag color='#ffa940' style={{ width: tagWidth }}>
                              ložisko
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebra}
                              size='small'
                              style={{ width: '90px' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'boneLesions',
                                  'vertebra',
                                  value
                                )
                              }
                            >
                              {aExamVertebrae.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            T2
                            <ButtonUpDownRow
                              index={index}
                              name='T2'
                              segment='boneLesions'
                              value={item.T2}
                              options={aSignal}
                              style={{ width: '100%' }}
                              updateRow={this.updateRow}
                            />
                            <Divider type='vertical' />
                            T1
                            <ButtonUpDownRow
                              index={index}
                              name='T1'
                              segment='boneLesions'
                              value={item.T1}
                              options={aSignal}
                              style={{ width: '100%' }}
                              updateRow={this.updateRow}
                            />
                            <Divider type='vertical' />
                            STIR
                            <ButtonUpDownRow
                              index={index}
                              name='STIR'
                              segment='boneLesions'
                              value={item.STIR}
                              options={aSignal}
                              style={{ width: '100%' }}
                              updateRow={this.updateRow}
                            />
                            <Divider type='vertical' />
                            <Input
                              name='size'
                              value={item.size}
                              size='small'
                              placeholder='velikost...'
                              suffix='mm'
                              onChange={event =>
                                this.updateRow(
                                  index,
                                  'boneLesions',
                                  'size',
                                  event.target.value
                                )
                              }
                              style={{ width: '100px' }}
                            />
                          </Space>
                        </Col>
                        <Col flex='auto' align='right'>
                          <Space size='0'>
                            <TextArea
                              defaultValue={item.description}
                              size='small'
                              placeholder='doplňující popis ložiska...'
                              style={{ width: '300px' }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'boneLesions',
                                  'description',
                                  event.target.value
                                )
                              }
                            />
                            <Divider type='vertical' />
                            <TextArea
                              defaultValue={item.conclusion}
                              size='small'
                              placeholder='doplňující závěr ložiska...'
                              style={{ width: '300px' }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'boneLesions',
                                  'conclusion',
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col flex='35px' align='right'>
                          <Button
                            type='primary'
                            size='small'
                            danger
                            onClick={() => this.deleteRow(index, 'boneLesions')}
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.stabilisation.map((item, index) => {
                    return (
                      <Row>
                        <Col flex='auto' key={index}>
                          <Space size='0'>
                            <Tag color='#096dd9' style={{ width: tagWidth }}>
                              stabilizace
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebraFirst}
                              size='small'
                              style={{ width: '90px' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'stabilisation',
                                  'vertebraFirst',
                                  value
                                )
                              }
                            >
                              {aStabilisationRange.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            <Select
                              key={index}
                              value={item.vertebraLast}
                              size='small'
                              style={{ width: '90px' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'stabilisation',
                                  'vertebraLast',
                                  value
                                )
                              }
                            >
                              {aStabilisationRange
                                .filter(
                                  vertebra =>
                                    vertebra.value >= item.vertebraFirst
                                )
                                .map(item => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </Space>
                        </Col>
                        <Col flex='auto' align='right'>
                          <Space size='0'>
                            <TextArea
                              defaultValue={item.description}
                              size='small'
                              placeholder='doplňující popis stabilizace...'
                              style={{ width: '300px' }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'stabilisation',
                                  'description',
                                  event.target.value
                                )
                              }
                            />
                            <Divider type='vertical' />
                            <TextArea
                              defaultValue={item.conclusion}
                              size='small'
                              placeholder='doplňující závěr stabilizace...'
                              style={{ width: '300px' }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'stabilisation',
                                  'conclusion',
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col flex='35px' align='right'>
                          <Button
                            type='primary'
                            size='small'
                            danger
                            onClick={() =>
                              this.deleteRow(index, 'stabilisation')
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                  {this.state.laminectomy.map((item, index) => {
                    return (
                      <Row>
                        <Col flex='auto' key={index}>
                          <Space size='0'>
                            <Tag color='#2db7f5' style={{ width: tagWidth }}>
                              laminektomie
                            </Tag>
                            <Select
                              key={index}
                              value={item.segment}
                              size='small'
                              style={{ width: '90px' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'laminectomy',
                                  'segment',
                                  value
                                )
                              }
                            >
                              {aExamSegments.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                            <ButtonUpDownRow
                              index={index}
                              name='side'
                              segment='laminectomy'
                              value={item.side}
                              options={aLaminectomy}
                              style={{ width: '100%' }}
                              updateRow={this.updateRow}
                            />
                          </Space>
                        </Col>
                        <Col flex='auto' align='right'>
                          <Space size='0px'>
                            <TextArea
                              defaultValue={item.description}
                              size='small'
                              placeholder='doplňující popis laminektomie...'
                              style={{ width: '300px' }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'laminectomy',
                                  'description',
                                  event.target.value
                                )
                              }
                            />
                            <Divider type='vertical' />
                            <TextArea
                              defaultValue={item.conclusion}
                              size='small'
                              placeholder='doplňující závěr laminektomie...'
                              style={{ width: '300px' }}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'laminectomy',
                                  'conclusion',
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col flex='35px' align='right'>
                          <Button
                            type='primary'
                            size='small'
                            danger
                            onClick={() => this.deleteRow(index, 'laminectomy')}
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.customSegment.map((item, index) => {
                    return (
                      <Row>
                        <Col flex='auto' key={index}>
                          <Space size='0'>
                            <Tag color='#2db7f5' style={{ width: tagWidth }}>
                              segment
                            </Tag>
                            <Select
                              key={index}
                              value={item.segment}
                              size='small'
                              style={{ width: '90px' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'customSegment',
                                  'segment',
                                  value
                                )
                              }
                            >
                              {aExamSegments.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                          </Space>
                        </Col>
                        <Col flex='auto' align='right'>
                          <Space size='0px'>
                            <TextArea
                              defaultValue={item.description}
                              size='small'
                              placeholder='popis...'
                              style={{ width: '475px' }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'customSegment',
                                  'description',
                                  event.target.value
                                )
                              }
                            />
                            <Divider type='vertical' />
                            <TextArea
                              defaultValue={item.conclusion}
                              size='small'
                              placeholder='závěr...'
                              style={{ width: '475px' }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'customSegment',
                                  'conclusion',
                                  event.target.value
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col flex='35px' align='right'>
                          <Button
                            type='primary'
                            size='small'
                            danger
                            onClick={() =>
                              this.deleteRow(index, 'customSegment')
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}

                  {this.state.customVertebra.map((item, index) => {
                    return (
                      <Row>
                        <Col flex='auto' key={index}>
                          <Space size='0'>
                            <Tag color='#2db7f5' style={{ width: tagWidth }}>
                              obratel
                            </Tag>
                            <Select
                              key={index}
                              value={item.vertebra}
                              size='small'
                              style={{ width: '90px' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'customVertebra',
                                  'vertebra',
                                  value
                                )
                              }
                            >
                              {aExamVertebrae.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                            <Divider type='vertical' />
                          </Space>
                        </Col>
                        <Col flex='auto' align='right'>
                          <Space size='0px'>
                            <TextArea
                              defaultValue={item.description}
                              size='small'
                              placeholder='popis...'
                              style={{ width: '475px' }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'customVertebra',
                                  'description',
                                  event.target.value
                                )
                              }
                            />
                            <Divider type='vertical' />
                            <TextArea
                              defaultValue={item.conclusion}
                              size='small'
                              placeholder='závěr...'
                              style={{ width: '475px' }}
                              autoSize={{ minRows: 1, maxRows: 5 }}
                              onBlur={event =>
                                this.updateRow(
                                  index,
                                  'customVertebra',
                                  'conclusion',
                                  formatCustomInput(event.target.value)
                                )
                              }
                            />
                          </Space>
                        </Col>
                        <Col flex='35px' align='right'>
                          <Button
                            type='primary'
                            size='small'
                            danger
                            onClick={() =>
                              this.deleteRow(index, 'customVertebra')
                            }
                          >
                            x
                          </Button>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <Row align='top'>
          <Col flex='auto'>
            <CustomCards content={spineSegments} />
            <CustomCards content={customEntry} />
            <CustomCards content={myContent1} />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default MR_spine_C;
