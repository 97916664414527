// Custom scripts
import { aEdema, aFattyMarrow, aErosions } from './MR_SIJ_arrays';

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  contentWidth,
  aSizemm,
  aSizecm,
} from '../scripts/GeneralArrays';
import {
  capitelizeFirstLetter,
  formatArrayToText,
} from '../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //

  const { edemaR, edemaL, fatR, fatL, erosionR, erosionL } = props.general;

  // edém kostní dřeně

  let edemaDescription = '';
  let edemaConclusion = '';
  if (edemaR + edemaL === 0) {
    edemaDescription = aEdema[edemaR].description + ' SI kloubů. ';
    edemaConclusion = 'Bez akutních zánětlivých změn SI kloubů. ';
  } else {
    if (edemaR === edemaL) {
      edemaDescription =
        aEdema[edemaR].description + ' pravého i levého SI kloubu. ';
      edemaConclusion = capitelizeFirstLetter(
        aEdema[edemaR].conclusion +
          ' akutní zánětlivé změny pravého i levého SI kloubu. '
      );
    } else {
      edemaDescription =
        aEdema[edemaR].description +
        ' pravého SI kloubu. ' +
        aEdema[edemaL].description +
        ' levého SI kloubu. ';
      if (edemaR > edemaL && edemaL !== 0) {
        edemaConclusion = capitelizeFirstLetter(
          aEdema[edemaR].conclusion +
            ' akutní zánětlivé změny pravého SI kloubu a ' +
            aEdema[edemaL].conclusion +
            ' levého SI kloubu. '
        );
      } else if (edemaL > edemaR && edemaR !== 0) {
        edemaConclusion = capitelizeFirstLetter(
          aEdema[edemaL].conclusion +
            ' akutní zánětlivé změny levého SI kloubu a ' +
            aEdema[edemaR].conclusion +
            ' pravého SI kloubu. '
        );
      } else if (edemaR > 0) {
        edemaConclusion = capitelizeFirstLetter(
          aEdema[edemaR].conclusion +
            ' akutní zánětlivé změny pravého SI kloubu. '
        );
      } else if (edemaL > 0) {
        edemaConclusion = capitelizeFirstLetter(
          aEdema[edemaL].conclusion +
            ' akutní zánětlivé změny levého SI kloubu. '
        );
      }
    }
  }

  // tuková degenerace kostní dřeně

  let fattyMarrowDescription = '';
  if (fatR + fatL === 0) {
    fattyMarrowDescription = aFattyMarrow[fatR].description + ' SI kloubů. ';
  } else {
    if (fatR === fatL) {
      fattyMarrowDescription =
        aFattyMarrow[fatR].description + ' pravého i levého SI kloubu. ';
    } else {
      fattyMarrowDescription =
        aFattyMarrow[fatR].description +
        ' pravého SI kloubu. ' +
        aFattyMarrow[fatL].description +
        ' levého SI kloubu. ';
    }
  }

  // tuková degenerace kostní dřeně

  let erosionsDescription = '';
  if (erosionR + erosionL === 0) {
    erosionsDescription = aErosions[erosionR].description + ' SI kloubů. ';
  } else {
    if (erosionR === erosionL) {
      erosionsDescription =
        aErosions[erosionR].description + ' pravého i levého SI kloubu. ';
    } else {
      erosionsDescription =
        aErosions[erosionR].description +
        ' pravého SI kloubu. ' +
        aErosions[erosionL].description +
        ' levého SI kloubu. ';
    }
  }

  // chronické zánětlivé změny SI kloubů
  let chronicChangesConclusion = '';
  if (fatR + erosionR > 0 && erosionL + erosionL > 0) {
    chronicChangesConclusion = 'Chronické zánětlivé změny obou SI kloubů. ';
  } else if (fatR + erosionR > 0) {
    chronicChangesConclusion = 'Chronické zánětlivé změny pravého SI kloubu. ';
  } else if (erosionL + erosionL > 0) {
    chronicChangesConclusion = 'Chronické zánětlivé změny levého SI kloubu. ';
  }

  importDescription1(
    '' + edemaDescription + fattyMarrowDescription + erosionsDescription
  );

  importConclusion1(edemaConclusion + chronicChangesConclusion);

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
