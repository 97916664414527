// Custom scripts
import {
  aExams,
  aInvasionWall,
  aInvasionVagina,
  aInvasionParametria,
  aInvasionPelvicWall,
  aHydronephrosis,
  aLNpelvic,
  aUrinaryBladder,
  aRectum,
  aAscites,
  aOvary,
  aLNcount,
} from "./MR_cervix_arrays";

// General scripts
import { indent, br } from "../scripts/GeneralArrays";
import {
  formatArrayToText,
  capitelizeFirstLetter,
} from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //

  const {
    invasionWall,
    tumorSize,
    invasionVagina,
    invasionPelvicWall,
    invasionParametria,
    hydronephrosis,
    LNparaaortic,
    LNiliacComR,
    LNiliacComL,
    LNiliacExtR,
    LNiliacExtL,
    LNiliacIntR,
    LNiliacIntL,
    LNinguinalR,
    LNinguinalL,
    LNparaaorticCount,
    LNiliacComRCount,
    LNiliacComLCount,
    LNiliacExtRCount,
    LNiliacExtLCount,
    LNiliacIntRCount,
    LNiliacIntLCount,
    LNinguinalRCount,
    LNinguinalLCount,
    LNcustomDescription,
    LNcustomConclusion,
    urinaryBladder,
    rectum,
    boneMeta,
    ascites,
    ovaryR,
    ovaryL,
    customDescription,
    customConclusion,
  } = props.general;

  let stageDescription = "";
  let stageConclusion = "";

  // lymfatické uzliny
  let LNDescription = "";
  let LNConclusion = "";
  let LNisPat = false;
  let aEnlargedLN = [];
  let aEnlargedLNcount = [];
  let aBorderlineLN = [];
  let aBorderlineLNcount = [];

  if (LNparaaortic > 1) {
    aEnlargedLNcount.push(LNparaaorticCount);
    aEnlargedLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  } else if (LNparaaortic === 1) {
    aBorderlineLNcount.push(LNparaaorticCount);
    aBorderlineLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  }
  if (LNiliacComR > 1) {
    aEnlargedLNcount.push(LNiliacComRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  } else if (LNiliacComR === 1) {
    aBorderlineLNcount.push(LNiliacComRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  }
  if (LNiliacComL > 1) {
    aEnlargedLNcount.push(LNiliacComLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  } else if (LNiliacComL === 1) {
    aBorderlineLNcount.push(LNiliacComLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  }
  if (LNiliacExtR > 1) {
    aEnlargedLNcount.push(LNiliacExtRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  } else if (LNiliacExtR === 1) {
    aBorderlineLNcount.push(LNiliacExtRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  }
  if (LNiliacExtL > 1) {
    aEnlargedLNcount.push(LNiliacExtLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  } else if (LNiliacExtL === 1) {
    aBorderlineLNcount.push(LNiliacExtLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  }
  if (LNiliacIntR > 1) {
    aEnlargedLNcount.push(LNiliacIntRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  } else if (LNiliacIntR === 1) {
    aBorderlineLNcount.push(LNiliacIntRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  }
  if (LNiliacIntL > 1) {
    aEnlargedLNcount.push(LNiliacIntLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  } else if (LNiliacIntL === 1) {
    aBorderlineLNcount.push(LNiliacIntLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  }
  if (LNinguinalR > 1) {
    aEnlargedLNcount.push(LNinguinalRCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? "pravostranné inguinální"
          : "pravostranná inguinální")
    );
  } else if (LNinguinalR === 1) {
    aBorderlineLNcount.push(LNinguinalRCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? "pravostranné inguinální"
          : "pravostranná inguinální")
    );
  }
  if (LNinguinalL > 1) {
    aEnlargedLNcount.push(LNinguinalLCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? "levostranné inguinální"
          : "levostranná inguinální")
    );
  } else if (LNinguinalL === 1) {
    aBorderlineLNcount.push(LNinguinalLCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? "levostranné inguinální"
          : "levostranná inguinální")
    );
  }

  if (aEnlargedLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aEnlargedLN) +
        (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose větší než 10mm. "
    );
    LNConclusion +=
      (aEnlargedLNcount[0] > 0 ? "Zvětšené " : "Zvětšená ") +
      formatArrayToText(aEnlargedLN) +
      (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (aBorderlineLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aBorderlineLN) +
        (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose přibližně 10mm. "
    );
    LNConclusion +=
      "Velikostí hraniční " +
      formatArrayToText(aBorderlineLN) +
      (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (
    aEnlargedLN.length > 0 ||
    aBorderlineLN.length > 0 ||
    LNcustomDescription !== ""
  ) {
    LNDescription +=
      LNcustomDescription +
      "Jinak v zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  } else {
    LNDescription +=
      "V zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  }

  if (LNcustomConclusion !== "") {
    LNConclusion += LNcustomConclusion;
  }

  let isLymphadenopathy = false;
  if (
    LNiliacComR +
      LNiliacComL +
      LNiliacExtR +
      LNiliacExtL +
      LNiliacIntR +
      LNiliacIntL +
      LNparaaortic >
    1
  ) {
    isLymphadenopathy = true;
  }

  // metastázy či invaze okolních orgánů
  let MDescription = "";
  MDescription +=
    aUrinaryBladder[urinaryBladder].description +
    (urinaryBladder > 0 ? "" : "Přiměřený nález na močovém měchýři. ");
  MDescription +=
    aRectum[rectum].description +
    (rectum > 0 ? "" : "Normální obraz stěny rekta. ");
  MDescription += boneMeta
    ? "Na STIR ložisková zvýšení SI kostní dřeně obrazu metastáz. "
    : "Na STIR bez přesvědčivého zvýšení signálu kostní dřeně obrazu metastáz. ";

  // Lokální staging
  let FIGOConclusion = "";
  let TConclusion = "Tx";
  let NConclusion = "Nx";
  let MConclusion = "Mx";

  // FIGO stage
  let isLocalLN =
    LNiliacComR +
      LNiliacComL +
      LNiliacExtR +
      LNiliacExtL +
      LNiliacIntR +
      LNiliacIntL >
    1
      ? true
      : false;
  let isDistantLN = LNparaaortic > 1 ? true : false;

  if (boneMeta) {
    FIGOConclusion = "IVB";
  } else if (rectum || urinaryBladder) {
    FIGOConclusion = "IVA";
  } else if (isDistantLN) {
    FIGOConclusion = "IIIC2";
  } else if (isLocalLN) {
    FIGOConclusion = "IIIC1";
  } else if (invasionPelvicWall || hydronephrosis) {
    FIGOConclusion = "IIIB";
  } else if (invasionVagina === 2) {
    FIGOConclusion = "IIIA";
  } else if (invasionParametria) {
    FIGOConclusion = "IIB";
  } else if (invasionVagina === 1) {
    if (invasionWall < 5) {
      FIGOConclusion = "IIA1";
    } else {
      FIGOConclusion = "IIA2";
    }
  } else {
    FIGOConclusion =
      invasionWall > 0 ? aInvasionWall[invasionWall].conclusion : "";
  }

  // T stage
  if (urinaryBladder || rectum) {
    TConclusion = "T4";
  } else if (invasionPelvicWall > 0 || hydronephrosis) {
    TConclusion = "T3b";
  } else if (invasionVagina === 2) {
    TConclusion = "T3a";
  } else if (invasionParametria > 0) {
    TConclusion = "T2b";
  } else if (invasionVagina === 1) {
    TConclusion = "T2a";
  } else {
    TConclusion = "T1b";
  }

  // N stage
  if (LNparaaortic > 1) {
    NConclusion = "N2";
  } else if (
    LNiliacComR +
      LNiliacComL +
      LNiliacExtR +
      LNiliacExtL +
      LNiliacIntR +
      LNiliacIntL >
    1
  ) {
    NConclusion = "N1";
  } else {
    NConclusion = "N0";
  }

  // M stage
  if (boneMeta) {
    MConclusion = "M1";
  } else {
    MConclusion = "M0";
  }

  // pokročilost nádoru v závěru
  let detailedStage = [];
  let detailedConclusion = "";
  if (invasionWall > 0) {
    detailedConclusion +=
      "Nádor děložního čípku " +
      (tumorSize != "" ? " (" + tumorSize + "mm) " : "");
    if (
      invasionVagina +
        invasionParametria +
        invasionPelvicWall +
        rectum +
        urinaryBladder >
      0
    ) {
      if (invasionParametria + invasionVagina > 0)
        detailedConclusion += "se šíří ";
      if (invasionParametria > 0) {
        detailedStage.push(
          invasionParametria === 1
            ? "do pravostranných parametrií"
            : invasionParametria === 2
            ? "do levostranných parametrií"
            : "bilat. do parametrií"
        );
      }
      if (invasionVagina > 0) {
        detailedStage.push(
          invasionVagina === 1
            ? "do max. horních 2/3 pochvy"
            : "do dolní třetiny pochvy"
        );
      }
      if (urinaryBladder > 0) {
        detailedStage.push(
          urinaryBladder === 1 ? "susp. prorůstá do močového měchýře" : ""
        );
      }
      if (rectum > 0) {
        detailedStage.push(rectum === 1 ? "susp. prorůstá do rekta" : "");
      }
      if (invasionPelvicWall > 0) {
        detailedStage.push(
          invasionPelvicWall === 1 ? "susp. prorůstá do pánevní stěny" : ""
        );
      }
      detailedConclusion += formatArrayToText(detailedStage) + ". ";
    } else {
      detailedConclusion += "bez šíření mimo dělohu. ";
    }
    detailedConclusion += boneMeta ? "Kostní metastázy. " : "";
    detailedConclusion +=
      hydronephrosis > 0
        ? "Hydronefróza " +
          (hydronephrosis === 1
            ? "vpravo. "
            : hydronephrosis === 2
            ? "vlevo. "
            : "bilat. ")
        : "";
  }

  stageDescription +=
    aInvasionWall[invasionWall].description +
    (invasionWall > 0
      ? tumorSize != ""
        ? ", celkové velikosti " + tumorSize + "mm. "
        : ". "
      : ". ") +
    aInvasionVagina[invasionVagina].description +
    aInvasionParametria[invasionParametria].description +
    aInvasionPelvicWall[invasionPelvicWall].description +
    aHydronephrosis[hydronephrosis].description;

  // vedlejší nálezy
  let ascitesDescription = "";
  let ascitesConclusion = "";

  ascitesDescription = aAscites[ascites].description;
  ascitesConclusion = aAscites[ascites].conclusion;

  let ovariesDescription =
    ovaryR === ovaryL
      ? "Pravé i levé ovarium " + aOvary[ovaryR].description + ". "
      : "Pravé ovarium " +
        aOvary[ovaryR].description +
        ", levé ovarium " +
        aOvary[ovaryL].description +
        ". ";

  let ovariesConclusion =
    (ovaryR > 1 ? aOvary[ovaryR].description + " pravého ovaria. " : "") +
    (ovaryL > 1 ? aOvary[ovaryL].description + " levého ovaria. " : "");

  importDescription1(stageDescription);
  importDescription1(LNDescription);
  importDescription1(MDescription);
  importDescription1(
    customDescription + ovariesDescription + ascitesDescription
  );

  importConclusion1(
    (detailedConclusion !== ""
      ? detailedConclusion
      : "Bez přesvědčivého nádoru děložního čípku. ") +
      customConclusion +
      LNConclusion +
      ascitesConclusion +
      ovariesConclusion
  );
  importConclusion1(
    invasionWall > 0
      ? "FIGO klasifikace stage " +
          FIGOConclusion +
          ", TNM klasifikace stage " +
          TConclusion +
          NConclusion +
          MConclusion +
          ". "
      : ""
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
