import { green, yellow, orange, red, grey } from "../scripts/GeneralArrays";

export const aSpineCor = [
  {
    value: 0,
    text: "v ose",
    description: "Bederní páteř v ose. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "mírná dextroskolióza",
    description: "Mírné pravostranné vybočení LS páteře. ",
    conclusion: "Mírná dextroskolióza LS páteře. ",
  },
  {
    value: 2,
    text: "mírná sinistroskolióza",
    description: "Mírné levostranné vybočení LS páteře. ",
    conclusion: "Mírná sinistroskolióza LS páteře. ",
  },
  {
    value: 3,
    text: "dextroskolióza",
    description: "Pravostranné vybočení LS páteře. ",
    conclusion: "Dextroskolióza LS páteře. ",
  },
  {
    value: 4,
    text: "sinistroskolióza",
    description: "Levostranné vybočení LS páteře. ",
    conclusion: "Sinistroskolióza LS páteře. ",
  },
  {
    value: 5,
    text: "výrazná dextroskolióza",
    description: "Výrazné pravostranné vybočení LS páteře. ",
    conclusion: "Výrazná dextroskolióza LS páteře. ",
  },
  {
    value: 6,
    text: "výrazná sinistroskolióza",
    description: "Výrazné levostranné vybočení LS páteře. ",
    conclusion: "Výrazná sinistroskolióza LS páteře. ",
  },
];

export const aSpineSag = [
  {
    value: 0,
    text: "přiměřená",
    description: "Plynulá bederní lordóza. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "plošší",
    description: "Plošší bederní lordóza. ",
    conclusion: "Plošší bederní lordóza. ",
  },
  {
    value: 2,
    text: "vyrovnaná",
    description: "Vyrovnaná bederní lordóza. ",
    conclusion: "Vyrovnaná bederní lordóza. ",
  },
  {
    value: 3,
    text: "paradoxní kyfotizace",
    description: "Mírná paradoxní kyfotizace bederní lordózy. ",
    conclusion: "Mírná paradoxní kyfotizace bederní lordózy. ",
  },
];

export const aSegments = [
  { value: 0, text: "Th10/11" },
  { value: 1, text: "Th11/12" },
  { value: 2, text: "Th12/L1" },
  { value: 3, text: "L1/2" },
  { value: 4, text: "L2/3" },
  { value: 5, text: "L3/4" },
  { value: 6, text: "L4/5" },
  { value: 7, text: "L5/S1" },
];

export const aVertebrae = [
  { value: 0, text: "Th10" },
  { value: 1, text: "Th11" },
  { value: 2, text: "Th12" },
  { value: 3, text: "L1" },
  { value: 4, text: "L2" },
  { value: 5, text: "L3" },
  { value: 6, text: "L4" },
  { value: 7, text: "L5" },
  { value: 8, text: "S1" },
  { value: 9, text: "S2" },
];

export const aSpine = [
  { value: 0, text: "C1/2" },
  { value: 1, text: "C2/3" },
  { value: 2, text: "C3/4" },
  { value: 3, text: "C4/5" },
  { value: 4, text: "C5/6" },
  { value: 5, text: "C6/7" },
  { value: 6, text: "C7/Th1" },
  { value: 7, text: "Th1/2" },
  { value: 8, text: "Th2/3" },
  { value: 9, text: "Th3/4" },
  { value: 10, text: "Th4/5" },
  { value: 11, text: "Th5/6" },
  { value: 12, text: "Th6/7" },
  { value: 13, text: "Th7/8" },
  { value: 14, text: "Th8/9" },
  { value: 15, text: "Th9/10" },
  { value: 16, text: "Th10/11" },
  { value: 17, text: "Th11/12" },
  { value: 18, text: "Th12/L1" },
  { value: 19, text: "L1/2" },
  { value: 20, text: "L2/3" },
  { value: 21, text: "L3/4" },
  { value: 22, text: "L4/5" },
  { value: 23, text: "L5/S1" },
];

export const aAddFinds = [
  {
    value: 0,
    text: "divertikly sigmatu",
    description: "Výchlipky stěny sigmatu. ",
    conclusion: "Divertikly sigmatu. ",
  },
  {
    value: 1,
    text: "zvětšená prostata",
    description: "Zvětšená prostata. ",
    conclusion: "Zvětšená prostata. ",
  },
  {
    value: 2,
    text: "myomy dělohy",
    description: "Na T2 hyposignální ložiska v myometriu dělohy. ",
    conclusion: "Myomy dělohy. ",
  },
  {
    value: 3,
    text: "cysty pravé ledviny",
    description:
      "Tenkou stěnou ohraničená kulovitá ložiska pravé ledviny signálu tekutiny. ",
    conclusion: "Cysty pravé ledviny. ",
  },
  {
    value: 4,
    text: "cysty levé ledviny",
    description:
      "Tenkou stěnou ohraničená kulovitá ložiska levé ledviny signálu tekutiny. ",
    conclusion: "Cysty levé ledviny. ",
  },

  {
    value: 5,
    text: "Tarlovova cysta",
    description:
      "Expanzivní struktura signálu tekutiny v sakrálním páteřním kanálu. ",
    conclusion: "Tarlovova cysta v sakrálním páteřním kanálu. ",
  },
  {
    value: 6,
    text: "Tarlovovy cysty",
    description:
      "Expanzivní struktury signálu tekutiny v sakrálním páteřním kanálu. ",
    conclusion: "Tarlovovy cysty v sakrálním páteřním kanálu. ",
  },
];

export const aDiskPat = [
  {
    value: 0,
    text: "normal",
    description: "",
    conclusion: "",
  },
  {
    value: 1,
    text: "hernie",
    description: "hernie disku ",
    conclusion: "hernie disku ",
  },
  {
    value: 2,
    text: "bulging",
    description: "bulging disku",
    conclusion: "bulgingu disku",
  },
  {
    value: 3,
    text: "osteofyty",
    description: "osteofyty krycích ploten ",
    conclusion: "osteofyty krycích ploten ",
  },
  {
    value: 4,
    text: "anul. fisura",
    description: "anulární fisura disku ",
    conclusion: "anulární fisura disku ",
  },
];

export const aSize = [
  { value: 0, text: "0mm", color: green },
  { value: 1, text: "1mm", color: yellow },
  { value: 2, text: "2mm", color: yellow },
  { value: 3, text: "3mm", color: yellow },
  { value: 4, text: "4mm", color: yellow },
  { value: 5, text: "5mm", color: yellow },
  { value: 6, text: "6mm", color: orange },
  { value: 7, text: "7mm", color: orange },
  { value: 8, text: "8mm", color: orange },
  { value: 9, text: "9mm", color: orange },
  { value: 10, text: "10mm", color: red },
  { value: 11, text: "11mm", color: red },
  { value: 12, text: "12mm", color: red },
  { value: 13, text: "13mm", color: red },
  { value: 14, text: "14mm", color: red },
  { value: 15, text: "15mm", color: red },
];

export const aCustom = [
  {
    value: 0,
    text: "komprese obratle",
    description: "",
  },
];

export const aFractureSite = [
  {
    value: 0,
    text: "ventrální snížení ",
    description: "ventrální klínovité snížení obratlového těla ",
    conclusion: "ventrální komprese obratlového těla ",
    color: orange,
  },
  {
    value: 1,
    text: "centrální snížení",
    description: "centrální snížení obratlového těla ",
    conclusion: "centrální komprese obratlového těla ",
    color: orange,
  },
  {
    value: 2,
    text: "dorzální snížení",
    description: "dorzální klínovité snížení obratlového těla ",
    conclusion: "dorzální komprese obratlového těla ",
    color: orange,
  },
];

export const aFractureEndplate = [
  {
    value: 0,
    text: "kompresí horní krycí plotny",
    description: " následkem prolomení horní krycí plotny ",
    conclusion: "horní krycí plotny ",
    color: orange,
  },
  {
    value: 1,
    text: "kompresí dolní krycí plotny",
    description: " následkem prolomení dolní krycí plotny ",
    conclusion: "dolní krycí plotny ",
    color: orange,
  },
  {
    value: 2,
    text: "kompresí obou krycích ploten",
    description: " následkem prolomení obou krycích ploten",
    conclusion: "obou krycích ploten",
    color: red,
  },
];

export const aFractureDepth = [
  {
    value: 0,
    text: "o <25%",
    description: " o méně než 25% původní výše, ",
    conclusion: "lehká ",
    color: yellow,
  },
  {
    value: 1,
    text: "o 25 až 40%",
    description: " o 25-40% původní výše, ",
    conclusion: "středně těžká ",
    color: orange,
  },
  {
    value: 2,
    text: "o >40%",
    description: " o více než 40% původní výše, ",
    conclusion: "těžká ",
    color: red,
  },
];

export const aFractureEdema = [
  {
    value: 0,
    text: "bez edému",
    description: "na STIR bez zvýšení SI kostní dřeně, ",
    conclusion: "Starší ",
    color: yellow,
  },
  {
    value: 1,
    text: "s edémem",
    description: "na STIR se zvýšením SI kostní dřeně, ",
    conclusion: "Nedávná ",
    color: red,
  },
];

export const aFractureProminence = [
  {
    value: 0,
    text: "bez prominence do páteřního kanálu",
    description: "bez prominence zadní hrany do páteřního kanálu, ",
    conclusion: ". ",
    color: green,
  },
  {
    value: 1,
    text: "s prominencí bez stenózy páteřního kanálu",
    description:
      "s diskrétní prominencí zadní hrany (bez stenózy páteřního kanálu), ",
    conclusion:
      ", s pouze diskrétní prominencí zadní hrany do páteřního kanálu. ",
    color: orange,
  },
  {
    value: 2,
    text: "s prominencí a lehkou stenózou",
    description:
      "způsobující lehkou stenózu páteřního kanálu prominencí zadní hrany obratlového těla, ",
    conclusion: ", způsobující lehkou stenózu páteřního kanálu. ",
    color: orange,
  },
  {
    value: 3,
    text: "s prominencí a středně těžkou stenózou",
    description:
      "způsobující středně těžkou stenózu páteřního kanálu prominencí zadní hrany obratlového těla, ",
    conclusion: ", způsobující středně těžkou stenózu páteřního kanálu. ",
    color: orange,
  },
  {
    value: 2,
    text: "s prominencí a těžkou stenózou",
    description:
      "způsobující těžkou stenózu páteřního kanálu prominencí zadní hrany obratlového těla, ",
    conclusion: ", způsobující těžkou stenózu páteřního kanálu. ",
    color: orange,
  },
];

export const aSignal = [
  {
    value: 0,
    text: "↓↓",
    description: "výrazně nižší SI na ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "↓",
    description: "nižší SI na ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "⇄",
    description: "střední SI na ",
    conclusion: "",
    color: green,
  },
  {
    value: 3,
    text: "↑",
    description: "vyšší SI na ",
    conclusion: "",
    color: green,
  },
  {
    value: 4,
    text: "↑↑",
    description: "výrazně vyšší SI na ",
    conclusion: "",
    color: green,
  },
];

/*export const aLaminectomy = [
  {
    value: 0,
    text: "bilaterální",
    description: "st.p. bilaterální laminektomii",
    conclusion: "St.p. bilaterální laminektomii",
    color: green,
  },
  {
    value: 1,
    text: "pravostranná",
    description: "st.p. pravostranné laminektomii",
    conclusion: "St.p. pravostranné laminektomii",
    color: green,
  },
  {
    value: 2,
    text: "levostranná",
    description: "st.p. levostranné laminektomii",
    conclusion: "st.p. levostranné laminektomii",
    color: green,
  },
];*/

export const aStabilisation = [
  {
    value: 0,
    text: "zadní stabilizace",
    description: "st.p. zadní stabilizaci ",
    conclusion: "St.p. zadní stabilizaci ",
    color: green,
  },
  {
    value: 1,
    text: "přední stabilizace",
    description: "st.p. přední stabilizaci ",
    conclusion: "St.p. přední stabilizaci ",
    color: green,
  },
  {
    value: 2,
    text: "přední i zadní",
    description: "st.p. přední i zadní stabilizaci ",
    conclusion: "St.p. přední i zadní stabilizaci ",
    color: green,
  },
];
