import React from "react";

// Custom scripts
import { aSelect } from "./MR_neck_arrays";
import { generateReport } from "./MR_neck_functions";

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import DropDown from "../components/DropDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";
import ButtonUpDown from "../components/ButtonUpDown";
import CustomCards from "../components/CustomCards";

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
} from "antd";
import { debounce } from "lodash";
import { CloseCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;

class MR_neck extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      // Glióza
      selection_1: 0,
      selection_2: 0,
      selection_3: false,
      selection_4: "",
    },
  };

  initialState = this.state;

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState(
      {
        [segment]: {
          ...this.state[segment],
          [name]: value,
        },
      },
      () =>
        this.props.askReport(
          generateReport(this.state, this.props.protocol, this.props.contrast)
        )
    );
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  render() {
    const blocks_1 = [
      {
        title: "část 1",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "volba 1",
              input: (
                <ButtonUpDown
                  name="selection_1"
                  segment="general"
                  value={this.state.general.selection_1}
                  options={aSelect}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "volba 2",
              input: (
                <DropDown
                  name="selection_2"
                  segment="general"
                  defaultValue={this.state.general.selection_2}
                  options={aSelect}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "část 2",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "volba 3",
              input: (
                <ButtonTrueFalse
                  name="selection_3"
                  segment="general"
                  value={this.state.general.selection_3}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "volba 4",
              input: (
                <Input
                  name="selection_4"
                  segment="report"
                  defaultValue={this.state.general.selection_4}
                  placeholder="indikace..."
                  onBlur={(event) =>
                    this.onChange({
                      name: "selection_4",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                  size="small"
                />
              ),
            },
          ],
        ],
      },
      {
        title: "část 3",
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
      {
        title: "část 4",
        color: aCardColors.purple,
        rows: [
          [
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={blocks_1} />
      </Form>
    );
  }
}

export default MR_neck;
