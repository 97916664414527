import { green, yellow, orange, red, neutral } from "../scripts/GeneralArrays";

// Custom

export const aSelect = [
  {
    value: 0,
    text: "select 1",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "select 2",
    description: "",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "select 3",
    description: "",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "select 4",
    description: "",
    conclusion: "",
    color: red,
  },
];

export const aPEsite = [
  {
    value: 0,
    text: "bez embolizace",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "subsegmentární",
    description: "subsegmentárních plicních tepen",
    conclusion: "subsegmentární",
    color: yellow,
  },
  {
    value: 2,
    text: "segmentární",
    description: "segmentárních plicních tepen",
    conclusion: "segmentární",
    color: orange,
  },
  {
    value: 3,
    text: "lobární",
    description: "lobárních plicních tepen",
    conclusion: "lobární",
    color: orange,
  },
  {
    value: 4,
    text: "kmenová",
    description: "kmene plicní tepny",
    conclusion: "kmenová",
    color: red,
  },
];
