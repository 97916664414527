// Custom scripts
import {
  aFluidothorax,
  aHeart,
  aHeartFailure,
  aCZKvsR,
  aCZKvjR,
  aCZKvsL,
  aCZKvjL,
  aSheatR,
  aSheatL,
  aPICCR,
  aPICCL,
  aSGcath,
  aECMO,
  aETK,
  aTSK,
  aHDR,
  aHDL,
  aPMR,
  aPML,
  aScoliosis,
  aSpondylosis,
  aScars,
  aConsolidation,
  aHypoventilation,
  aObscured,
  aPatientRotation,
  aCompareToLast,
  aAo,
  aMi,
  aTri,
  aPul,
} from './XRAY_chest_arrays';

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  aSizemm,
  aSizecm,
  contentWidth,
} from '../scripts/GeneralArrays';
import {
  capitelizeFirstLetter,
  formatArrayToText,
} from '../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  console.log(protocol);
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //

  const {
    consRU,
    consRM,
    consRL,
    consLU,
    consLM,
    consLL,
    hypoventRU,
    hypoventRM,
    hypoventRL,
    hypoventLU,
    hypoventLM,
    hypoventLL,
    pneumothoraxR,
    pneumothoraxL,
    heart,
    heartFailure,
    aortaAS,
    fluidothoraxR,
    fluidothoraxL,
    pulmEmphysema,
    CZKvsR,
    CZKvjR,
    CZKvsL,
    CZKvjL,
    sheatR,
    sheatL,
    portCathR,
    portCathL,
    SGcathR,
    SGcathL,
    PICCR,
    PICCL,
    ECMO,
    ETK,
    TSK,
    NGS,
    perikardEl,
    clippingLA,
    ao,
    mi,
    tri,
    pul,
    HDR1,
    HDR2,
    HDL1,
    HDL2,
    EKG,
    PMR,
    PML,
    scar,
    scoliosis,
    spondylosis,
    lobusAzygos,
    omarthrosisR,
    omarthrosisL,
    ribsFractureR,
    ribsFractureL,
    subcutEmphR,
    subcutEmphL,
    obscured,
    patientRotation,
    normalFinding,
    compareToLast,
    compareToLastConclusion,
  } = props.general;

  // Limitace
  let limitationsDescription = '';
  limitationsDescription +=
    (obscured > 0 ? aObscured[obscured].description : '') +
    (patientRotation > 0 ? aPatientRotation[patientRotation].description : '');

  // Konsolidace

  const consolidationsRight = [consRU, consRM, consRL];
  const consolidationsLeft = [consLU, consLM, consLL];

  const hypoventilationsRight = [hypoventRU, hypoventRM, hypoventRL];
  const hypoventilationsLeft = [hypoventLU, hypoventLM, hypoventLL];

  function getLungZones(str, value) {
    let string = str.map(item => {
      if (item === value) {
        return 1;
      } else {
        return 0;
      }
    });
    if (JSON.stringify(string) === JSON.stringify([0, 0, 0])) {
      return '';
    } else if (JSON.stringify(string) === JSON.stringify([1, 0, 0])) {
      return 'horním';
    } else if (JSON.stringify(string) === JSON.stringify([0, 1, 0])) {
      return 'středním';
    } else if (JSON.stringify(string) === JSON.stringify([0, 0, 1])) {
      return 'dolním';
    } else if (JSON.stringify(string) === JSON.stringify([1, 1, 0])) {
      return 'horním a středním';
    } else if (JSON.stringify(string) === JSON.stringify([0, 1, 1])) {
      return 'středním a dolním';
    } else if (JSON.stringify(string) === JSON.stringify([1, 0, 1])) {
      return 'horním a dolním';
    } else if (JSON.stringify(string) === JSON.stringify([1, 1, 1])) {
      return 'horním, středním a dolním';
    }
  }

  function getConsolidation(str1, str2, value, type, array) {
    let consRight = getLungZones(str1, value);
    let consLeft = getLungZones(str2, value);
    let consAll =
      (consRight !== '' || consLeft !== ''
        ? type === 'description'
          ? array[value].description
          : array[value].conclusion
        : '') +
      (consRight === '' ? '' : 'v pravém ' + consRight) +
      (consRight !== '' && consLeft !== '' ? ' a ' : '') +
      (consLeft === '' ? '' : 'v levém ' + consLeft) +
      (consRight !== '' || consLeft !== '' ? ' plicním poli. ' : '');

    return consAll;
  }

  // konsolidace

  let consolidationsDescription =
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      1,
      'description',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      2,
      'description',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      3,
      'description',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      4,
      'description',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      5,
      'description',
      aConsolidation
    );

  let consolidationsConclusion =
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      1,
      'conclusion',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      2,
      'conclusion',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      3,
      'conclusion',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      4,
      'conclusion',
      aConsolidation
    ) +
    getConsolidation(
      consolidationsRight,
      consolidationsLeft,
      5,
      'conclusion',
      aConsolidation
    );

  // hypoventilace

  let hypoventilationsDescription =
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      1,
      'description',
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      2,
      'description',
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      3,
      'description',
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      4,
      'description',
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      5,
      'description',
      aHypoventilation
    );

  let hypoventilationsConclusion = '';
  if (hypoventRL || hypoventLL) {
    if (hypoventRL && hypoventLL) {
      hypoventilationsConclusion = 'Hypoventilace bazálně bilat. ';
    } else if (hypoventRL) {
      hypoventilationsConclusion = 'Hypoventilace bazálně vpravo. ';
    } else if (hypoventLL) {
      hypoventilationsConclusion = 'Hypoventilace bazálně vlevo. ';
    }
  }

  /*let hypoventilationsConclusion =
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      1,
      "conclusion",
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      2,
      "conclusion",
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      3,
      "conclusion",
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      4,
      "conclusion",
      aHypoventilation
    ) +
    getConsolidation(
      hypoventilationsRight,
      hypoventilationsLeft,
      5,
      "conclusion",
      aHypoventilation
    );*/

  // pneumothorax
  let pneumothoraxDescription =
    pneumothoraxR === pneumothoraxL && pneumothoraxR === 0
      ? obscured > 0
        ? 'V zobrazeném rozsahu plicní křídla rozvinuta. '
        : 'Plicní křídla rozvinuta. '
      : (pneumothoraxR > 0
          ? 'Nedorozvinutí pravého plicního křídla na ' +
            aSizemm[pneumothoraxR].text +
            '. '
          : 'Pravé plicní křídlo rozvinuto. ') +
        (pneumothoraxL > 0
          ? 'Nedorozvinutí levého plicního křídla na ' +
            aSizemm[pneumothoraxL].text +
            '. '
          : 'Levé plicní křídlo rozvinuto. ');

  let pulmonaryEmphysemaDescription = pulmEmphysema
    ? 'Zvýšená transparence v horních a středních plicních polích s rozšířením mezižeberních prostor a oploštěním bránice. '
    : '';

  let lobusAzygosDescription = lobusAzygos ? 'Lobus v. azygos. ' : '';

  let heartFailureDescription = aHeartFailure[heartFailure].description;

  let fluidothoraxDescription =
    fluidothoraxR === fluidothoraxL && fluidothoraxR > 0
      ? 'Oboustranně ' +
        (protocol === 0
          ? aFluidothorax[fluidothoraxR].description1
          : aFluidothorax[fluidothoraxR].description2)
      : (fluidothoraxR > 0
          ? 'Vpravo ' +
            (protocol === 0
              ? aFluidothorax[fluidothoraxR].description1
              : aFluidothorax[fluidothoraxR].description2)
          : '') +
        (fluidothoraxL > 0
          ? 'Vlevo ' +
            (protocol === 0
              ? aFluidothorax[fluidothoraxL].description1
              : aFluidothorax[fluidothoraxL].description2)
          : '');

  let lungDescription =
    pneumothoraxDescription +
    consolidationsDescription +
    hypoventilationsDescription +
    pulmonaryEmphysemaDescription +
    heartFailureDescription +
    fluidothoraxDescription +
    lobusAzygosDescription +
    (consolidationsDescription +
      hypoventilationsDescription +
      pulmonaryEmphysemaDescription +
      heartFailureDescription +
      fluidothoraxDescription !==
    ''
      ? 'Jinak plicní křídla bez přesvědčivých ložiskových stínů či konsolidací. '
      : 'Oboustranně bez přesvědčivých ložiskových stínů či konsolidací. ');

  let mediastinumDescription =
    aHeart[heart].description +
    (aortaAS ? 'Stíny sytosti kalcifikací v úrovni oblouku aorty. ' : '') +
    (EKG > 0 ? 'EKG elektrody. ' : '') +
    (PMR > 0 ? aPMR[PMR].description : '') +
    (PML > 0 ? aPML[PML].description : '') +
    (scar > 0 ? aScars[scar].description : '') +
    (clippingLA ? 'Uzávěr ouška levé síně metalickým klipem. ' : '');

  // st.p. operaci srdečních chlopní
  let cardiacValvesDescription = '';
  let aValves = [];
  if (ao) aValves.push(aAo[ao].description);
  if (mi) aValves.push(aMi[mi].description);
  if (tri) aValves.push(aTri[tri].description);
  if (pul) aValves.push(aPul[pul].description);

  if (aValves.length > 0)
    cardiacValvesDescription =
      'St.p. náhradě ' + formatArrayToText(aValves) + ' chlopně. ';
  mediastinumDescription += cardiacValvesDescription;

  let thoraxWallDescription =
    subcutEmphR && subcutEmphL
      ? 'Podkožní emfyzém obou hemithoraxů. '
      : subcutEmphR
      ? 'Podkožní emfyzém pravého hemithoraxu. '
      : subcutEmphL
      ? 'Podkožní emfyzém levého hemithoraxu. '
      : '';

  // Vedlejší nálezy
  let addFindingsDescription = '';
  let addFindings = [];
  if (
    scoliosis +
      spondylosis +
      omarthrosisR +
      omarthrosisL +
      ribsFractureR +
      ribsFractureL >
    0
  ) {
    if (scoliosis > 0) addFindings.push(aScoliosis[scoliosis].description);
    if (spondylosis > 0)
      addFindings.push(aSpondylosis[spondylosis].description);
    if (omarthrosisR > 0 && omarthrosisL > 0)
      addFindings.push('Omartróza bilat. ');
    else if (omarthrosisR > 0) addFindings.push('Omartróza vpravo. ');
    else if (omarthrosisL > 0) addFindings.push('Omartróza vlevo. ');
    if (ribsFractureR > 0 && ribsFractureL > 0)
      addFindings.push('St.p. sériové fraktuře žeber bilat. ');
    else if (ribsFractureR > 0)
      addFindings.push('St.p. sériové fraktuře žeber vpravo. ');
    else if (ribsFractureL > 0)
      addFindings.push('St.p. sériové fraktuře žeber vlevo. ');
  }

  // Invaze
  let HDTextR = '';
  if (HDR1 + HDR2 > 0) {
    if (HDR1 === HDR2) {
      if (HDR1 === 1) HDTextR += 'Dva hrudní drény zprava. ';
      else if (HDR1 === 2) HDTextR += 'Nově dva hrudní drény zprava. ';
      else if (HDR1 === 3) HDTextR += 'Odstraněny dva hrudní drény zprava. ';
    } else if (HDR1 > 0 && HDR2 > 0) {
      if (HDR1 === 1)
        HDTextR += aHDR[HDR2].description + 'Stac. druhý hrudní drén zprava. ';
      else if (HDR1 === 2 && HDR2 === 1)
        HDTextR += aHDR[HDR1].description + 'Stac. druhý hrudní drén zprava. ';
      else if (HDR1 === 3 && HDR2 === 1)
        HDTextR += aHDR[HDR1].description + 'Stac. druhý hrudní drén zprava. ';
      else if ((HDR1 === 2 && HDR2 === 3) || (HDR1 === 3 && HDR2 === 2))
        HDTextR +=
          aHDR[HDR1].description +
          'Původní druhý hrudní drén zprava odstraněn. ';
    } else if (HDR1 > 0) HDTextR += aHDR[HDR1].description;
    else if (HDR2 > 0) HDTextR += aHDR[HDR2].description;
  }
  let HDTextL = '';
  if (HDL1 + HDL2 > 0) {
    if (HDL1 === HDL2) {
      if (HDL1 === 1) HDTextL += 'Dva hrudní drény zleva. ';
      else if (HDL1 === 2) HDTextL += 'Nově dva hrudní drény zleva. ';
      else if (HDL1 === 3) HDTextL += 'Odstraněny dva hrudní drény zleva. ';
    } else if (HDL1 > 0 && HDL2 > 0) {
      if (HDL1 === 1)
        HDTextL += aHDL[HDL2].description + 'Stac. druhý hrudní drén zleva. ';
      else if (HDL1 === 2 && HDL2 === 1)
        HDTextL += aHDL[HDL1].description + 'Stac. druhý hrudní drén zleva. ';
      else if (HDL1 === 3 && HDL2 === 1)
        HDTextL += aHDL[HDL1].description + 'Stac. druhý hrudní drén zleva. ';
      else if ((HDL1 === 2 && HDL2 === 3) || (HDL1 === 3 && HDL2 === 2))
        HDTextL +=
          aHDL[HDL1].description +
          'Původní druhý hrudní drén zleva odstraněn. ';
    } else if (HDL1 > 0) HDTextL += aHDL[HDL1].description;
    else if (HDL2 > 0) HDTextL += aHDL[HDL2].description;
  }
  if (addFindings.length > 0) {
    addFindingsDescription = 'Vedlejší nálezy: ' + addFindings.join('');
  }

  // Invaze
  let invasionsDescription =
    CZKvsR +
      CZKvjR +
      CZKvsL +
      CZKvjL +
      sheatR +
      sheatL +
      portCathR +
      portCathL +
      SGcathR +
      SGcathL +
      ECMO +
      ETK +
      TSK +
      HDR1 +
      HDR2 +
      HDL1 +
      HDL2 +
      perikardEl +
      PICCR +
      PICCL +
      NGS >
    0
      ? 'Invaze: ' +
        aCZKvsR[CZKvsR].description +
        aCZKvjR[CZKvjR].description +
        aCZKvjL[CZKvjL].description +
        aCZKvsL[CZKvsL].description +
        aSheatR[sheatR].description +
        aSheatL[sheatL].description +
        (portCathR
          ? 'Portkatétr zprava cestou v. subclavia, koncem v úrovni horní duté žíly. '
          : '') +
        (portCathL
          ? 'Portkatétr zleva cestou v. subclavia, koncem v úrovni horní duté žíly. '
          : '') +
        (SGcathR > 0
          ? 'Swan-Ganz katétr zprava cestou v. jugularis int.' +
            aSGcath[SGcathR].description
          : '') +
        (SGcathL > 0
          ? 'Swan-Ganz katétr zleva cestou v. jugularis int.' +
            aSGcath[SGcathL].description
          : '') +
        aECMO[ECMO].description +
        aETK[ETK].description +
        aTSK[TSK].description +
        aPICCR[PICCR].description +
        aPICCL[PICCL].description +
        HDTextR +
        HDTextL +
        (perikardEl > 0 ? 'Perikardiální elektrody. ' : '') +
        (NGS > 0 ? 'Nazogastrická sonda. ' : '')
      : '';

  importDescription1(
    limitationsDescription +
      lungDescription +
      mediastinumDescription +
      thoraxWallDescription
  );
  importDescription1(addFindingsDescription);
  importDescription1(invasionsDescription);

  // Závěr
  let aPneumothorax = [];
  let pneumothoraxConclusion = '';
  if (pneumothoraxR > 0)
    aPneumothorax.push(
      'pravého plicního křídla apikálně na ' + aSizemm[pneumothoraxR].text
    );

  if (pneumothoraxR + pneumothoraxL > 0) {
    if (pneumothoraxL > 0)
      aPneumothorax.push(
        'levého plicního křídla apikálně na ' + aSizemm[pneumothoraxL].text
      );
    pneumothoraxConclusion =
      'Nedorozvinutí ' + formatArrayToText(aPneumothorax) + '. ';
  }

  let lungEmphysemaConclusion = pulmEmphysema ? 'Susp. plicní emfyzém. ' : '';
  let lungConclusion =
    pneumothoraxConclusion + consolidationsConclusion + lungEmphysemaConclusion;

  let mediastinumConclusion =
    aHeart[heart].conclusion +
    aHeartFailure[heartFailure].conclusion +
    (aortaAS ? 'Ateroskleróza oblouku aorty. ' : '');

  let fluidothoraxConclusion =
    fluidothoraxR === fluidothoraxL && fluidothoraxR > 0
      ? 'Oboustranně ' + aFluidothorax[fluidothoraxR].conclusion
      : (fluidothoraxR > 0
          ? 'Vpravo ' + aFluidothorax[fluidothoraxR].conclusion
          : '') +
        (fluidothoraxL > 0
          ? 'Vlevo ' + aFluidothorax[fluidothoraxL].conclusion
          : '');

  fluidothoraxConclusion +=
    hypoventilationsConclusion != '' ? hypoventilationsConclusion : '';

  let noPathology = true;
  if (
    pneumothoraxR +
      pneumothoraxL +
      heart +
      heartFailure +
      fluidothoraxR +
      fluidothoraxL +
      aortaAS >
      0 ||
    pulmEmphysema ||
    consolidationsConclusion !== '' ||
    hypoventilationsConclusion !== ''
  ) {
    noPathology = false;
  }

  let normalFindingConclusion = normalFinding
    ? noPathology === false
      ? 'Jinak přiměřený nález na nitrohrudních orgánech. '
      : compareToLast === 1
      ? ''
      : compareToLast === 2
      ? 'Jinak přiměřený nález na nitrohrudních orgánech. '
      : 'Přiměřený nález na nitrohrudních orgánech. '
    : '';

  let compareConclusion = '';
  if (compareToLast === 2) {
    compareConclusion = compareToLastConclusion + 'Jinak nález stac. ';
  } else if (compareToLast === 1) {
    if (noPathology) {
      compareConclusion =
        'Oproti minule stac. přiměřený nález na nitrohrudních orgánech. ';
    } else {
      compareConclusion = 'Oproti minule nález bez přesvědčivých změn. ';
    }
  }

  importConclusion1(
    compareConclusion +
      lungConclusion +
      mediastinumConclusion +
      fluidothoraxConclusion +
      normalFindingConclusion
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
