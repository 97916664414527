import { green, yellow, orange, red } from "../scripts/GeneralArrays";

export const aGITSegments = [
  {
    value: 0,
    text: "termin. ileum",
    description: "terminálního ilea",
    conclusion: "terminálního ilea",
    color: green,
  },
  {
    value: 1,
    text: "střední ileum",
    description: "středního ilea",
    conclusion: "středního ilea",
    color: green,
  },
  {
    value: 2,
    text: "orální ileum",
    description: "orálního ilea",
    conclusion: "orálního ilea",
    color: green,
  },
  {
    value: 3,
    text: "jejunum",
    description: "jejuna",
    conclusion: "jejuna",
    color: green,
  },
  {
    value: 4,
    text: "cékum",
    description: "céka",
    conclusion: "céka",
    color: green,
  },
  {
    value: 5,
    text: "col. ascendens",
    description: "colon ascendens",
    conclusion: "colon ascendens",
    color: green,
  },
  {
    value: 6,
    text: "col. transversum",
    description: "colon transversum",
    conclusion: "colon transversum",
    color: green,
  },
  {
    value: 7,
    text: "col. descendens",
    description: "colon descendens",
    conclusion: "colon descendens",
    color: green,
  },
  {
    value: 8,
    text: "sigma",
    description: "sigmatu",
    conclusion: "sigmatu",
    color: green,
  },
  {
    value: 9,
    text: "rektum",
    description: "rekta",
    conclusion: "rekta",
    color: green,
  },
];

export const aLength = [
  {
    value: 0,
    text: "0cm",
    description: "0cm",
    color: yellow,
  },
  {
    value: 1,
    text: "1cm",
    description: "1cm",
    color: yellow,
  },
  {
    value: 2,
    text: "2cm",
    description: "2cm",
    color: yellow,
  },
  {
    value: 3,
    text: "3cm",
    description: "3cm",
    color: yellow,
  },
  {
    value: 4,
    text: "4cm",
    description: "4cm",
    color: yellow,
  },
  {
    value: 5,
    text: "5cm",
    description: "5cm",
    color: yellow,
  },
  {
    value: 6,
    text: "6cm",
    description: "6cm",
    color: orange,
  },
  {
    value: 7,
    text: "7cm",
    description: "7cm",
    color: orange,
  },
  {
    value: 8,
    text: "8cm",
    description: "8cm",
    color: orange,
  },
  {
    value: 9,
    text: "9cm",
    description: "9cm",
    color: orange,
  },
  {
    value: 10,
    text: "10cm",
    description: "10cm",
    color: orange,
  },
  {
    value: 11,
    text: "11cm",
    description: "11cm",
    color: orange,
  },
  {
    value: 12,
    text: "12cm",
    description: "12cm",
    color: orange,
  },
  {
    value: 13,
    text: "13cm",
    description: "13cm",
    color: orange,
  },
  {
    value: 14,
    text: "14cm",
    description: "14cm",
    color: orange,
  },
  {
    value: 15,
    text: "15cm",
    description: "15cm",
    color: orange,
  },
  {
    value: 16,
    text: ">15mm",
    description: "více než 15cm",
    color: red,
  },
];

export const aWallWidth = [
  {
    value: 0,
    text: ">3-5mm",
    description: "méně než 5mm",
    conclusion: "",
    color: yellow,
  },
  {
    value: 1,
    text: ">5-7mm",
    description: "max. 7mm",
    conclusion: "",
    color: orange,
  },
  {
    value: 2,
    text: "> 7mm",
    description: "více než 7mm",
    conclusion: "",
    color: red,
  },
];

export const aEnhancementType = [
  {
    value: 0,
    text: "homogenní",
    description: "homogenním",
    conclusion: "",
    color: yellow,
  },
  {
    value: 1,
    text: "mukózní",
    description: "mukózním",
    conclusion: "",
    color: orange,
  },
  {
    value: 2,
    text: "vrstevnaté",
    description: "vrstevnatým",
    conclusion: "",
    color: red,
  },
];

export const aEnhancementAmount = [
  {
    value: 0,
    text: "mírná",
    description: "mírným",
    conclusion: "",
    color: yellow,
  },
  {
    value: 1,
    text: "střední",
    description: "středně výrazným",
    conclusion: "",
    color: orange,
  },
  {
    value: 2,
    text: "výrazná",
    description: "výrazným",
    conclusion: "",
    color: red,
  },
];

export const aWallEdema = [
  {
    value: 0,
    text: "tmavě šedá",
    description: "tmavě šedá (mírný edém)",
    conclusion: "",
    color: yellow,
  },
  {
    value: 1,
    text: "světle šedá",
    description: "světle šedá (edém)",
    conclusion: "",
    color: orange,
  },
  {
    value: 2,
    text: "až SI vody",
    description: "výrazně světlá (výrazný edém)",
    conclusion: "",
    color: red,
  },
];

export const aProstateSize = [
  {
    value: 0,
    text: "normální",
    description: "Prostata normální velikosti. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvětšena",
    description: "Prostata mírně zvětšena zbytněním přechodové zóny. ",
    conclusion: "Prostata mírně zvětšena. ",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšena",
    description: "Prostata zvětšena zbytněním přechodové zóny. ",
    conclusion: "Prostata zvětšena. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvětšena",
    description: "Prostata výrazně zvětšena zbytněním přechodové zóny. ",
    conclusion: "Prostata výrazně zvětšena. ",
    color: red,
  },
];

export const aUterusGeneral = [
  {
    value: 0,
    text: "normální",
    description: "věku přiměřené velikosti. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "nedif.",
    description: "není přesvědčivě diferencovatelná. ",
    conclusion: "St.p. hysterektomii. ",
    color: yellow,
  },
  {
    value: 2,
    text: "myom",
    description: "obsahuje v myometriu na T2 hyposignální ložisko. ",
    conclusion: "Myom dělohy. ",
    color: orange,
  },
  {
    value: 3,
    text: "myomy",
    description: "obsahuje  myometriu na T2 hyposignální ložiska. ",
    conclusion: "Myomy dělohy. ",
    color: orange,
  },
];

export const aUterusPosition = [
  {
    value: 0,
    text: "anteverze",
    description: "v anteverzi, ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "retroverze",
    description: "v retroverzi, ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "dextroverze",
    description: "v dextroverzi, ",
    conclusion: "",
    color: green,
  },
  {
    value: 3,
    text: "sinistropozice",
    description: "v sinistropozici, ",
    conclusion: "",
    color: green,
  },
];

export const aOvarium = [
  {
    value: 0,
    text: "normální",
    description: "s přiměřeným nálezem. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "nedif.",
    description: "není přesvědčivě diferencovatelné. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "cysta",
    description:
      "obsahuje tenkou stěnou ohraničenou kolekci signálu tekutiny. ",
    conclusion: "s cystou. ",
    color: orange,
  },
  {
    value: 3,
    text: "cysty",
    description: "obsahuje tenkou stěnou ohraničené kolekce signálu tekutiny. ",
    conclusion: "s cystami. ",
    color: orange,
  },
];

export const aAscites = [
  {
    value: 0,
    text: "bez volné tekutiny",
    description: "V peritoneální dutině bez volné tekutiny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "malý ascites",
    description:
      "V peritoneální dutině na dně pánve malé množství volné tekutiny. ",
    conclusion: "Malý ascites. ",
    color: yellow,
  },
  {
    value: 2,
    text: "středně velký ascites",
    description:
      "V oblasti pánve v peritoneální dutině volná tekutina mezi kličkami střevními. ",
    conclusion: "Středně velký ascites. ",
    color: orange,
  },
  {
    value: 3,
    text: "velký ascites",
    description:
      "V oblasti pánve v peritoneální dutině velké množství volné tekutiny mezi kličkami střevními. ",
    conclusion: "Velký ascites. ",
    color: red,
  },
];

export const aLimitations = [
  {
    value: 0,
    text: "bez limitace",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně rozhýbané",
    description:
      "Hodnotitelnost některých sekvencí limitována pohybovými artefakty. ",
    conclusion: "",
    color: orange,
  },
  {
    value: 2,
    text: "výrazně rozhýbané",
    description:
      "Hodnotitelnost některých sekvencí výrazně limitována pohybovými artefakty. ",
    conclusion: "",
    color: red,
  },
];

export const aNormalFindings = [
  {
    value: 0,
    text: "ukázat",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "neukázat",
    description: "",
    conclusion: "",
    color: orange,
  },
];

export const aLN = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční",
    description: "hraniční velikosti",
    conclusion: "hraniční velikosti",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšené",
    description: "zvětšené",
    conclusion: "zvětšené",
    color: red,
  },
];

export const aLNsize = [
  {
    value: 0,
    text: "příčně <5mm",
    description: "Mezorektální lymfatická uzlina v krátké ose menší než 5mm",
  },
  {
    value: 1,
    text: "příčně 5-9mm",
    description:
      "Mezorektální lymfatická uzlina v krátké ose velikostí mezi 5 až 9mm",
  },
  {
    value: 2,
    text: "příčně >9mm",
    description: "Mezorektální lymfatická uzlina v krátké ose větší než 9mm",
  },
];

export const aLNcount = [
  {
    value: 0,
    text: "1",
    description: "1",
    conclusion: "jedna ",
    color: orange,
  },
  {
    value: 1,
    text: "2",
    description: "2",
    conclusion: "dvě ",
    color: orange,
  },
  {
    value: 2,
    text: "3",
    description: "3",
    conclusion: "tři ",
    color: orange,
  },
  {
    value: 3,
    text: ">3",
    description: ">3",
    conclusion: "více než 3 ",
    color: orange,
  },
];
