import { indent, br } from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

import { aSelect, aLNmediastinum } from "./CT_chest_general_arrays";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const {
    LNmediastinumEnlarged,
    LNmediastinumMarginal,
    descriptionCustomLungR,
    descriptionCustomLungL,
    conclusionCustomLungR,
    conclusionCustomLungL,
    descriptionCustomBones,
    conclusionCustomBones,
  } = props.general;

  // Plicní parenchym
  const descriptionPulmonaryParenchyma =
    descriptionCustomLungR === "" && descriptionCustomLungL === ""
      ? "Přiměřená denzita plicnícho parenchymu, bez ložiskových změn."
      : descriptionCustomLungR +
        descriptionCustomLungL +
        "Jinak přiměřený nález na plicním parenchymu. ";

  const conclusionPulmonaryParenchyma =
    conclusionCustomLungR === "" && conclusionCustomLungL === ""
      ? ""
      : conclusionCustomLungR + conclusionCustomLungL;

  // Skelet
  const descriptionBones =
    descriptionCustomBones === ""
      ? "Zobrazený skelet a měkké tkáně hrudní stěny bez přesvědčivého patologického nálezu."
      : descriptionCustomBones +
        "Jinak zobrazený skelet a měkké tkáně hrudní stěny bez přesvědčivého patologického nálezu.";

  const conclusionBones =
    conclusionCustomBones === "" ? "" : conclusionCustomBones;

  // Lymfatické uzliny
  const descriptionLN =
    (LNmediastinumEnlarged.length > 0
      ? "Zvětšené " +
        formatArrayToText(
          LNmediastinumEnlarged.map((LN) => aLNmediastinum[LN].description)
        ) +
        " lymfatické uzliny. "
      : "Bez zvětšených mediastinálních či hilových lymfatických uzlin. ") +
    (LNmediastinumMarginal.length > 0
      ? "Hraniční " +
        formatArrayToText(
          LNmediastinumMarginal.map((LN) => aLNmediastinum[LN].description)
        ) +
        " lymfatické uzliny. "
      : "");

  const conclusionLN =
    (LNmediastinumEnlarged.length > 0
      ? "Zvětšené lymfatické uzliny " +
        formatArrayToText(
          LNmediastinumEnlarged.map((LN) => aLNmediastinum[LN].description)
        ) +
        " (" +
        LNmediastinumEnlarged.map((LN) => aLNmediastinum[LN].conclusion).join(
          ", "
        ) +
        "). "
      : "") +
    (LNmediastinumMarginal.length > 0
      ? "Velikostí hraniční lymfatické uzliny " +
        formatArrayToText(
          LNmediastinumMarginal.map((LN) => aLNmediastinum[LN].description)
        ) +
        " (" +
        LNmediastinumMarginal.map((LN) => aLNmediastinum[LN].conclusion).join(
          ", "
        ) +
        "). "
      : "");

  importDescription1(
    "Plicní křídla rozvinuta. " + descriptionPulmonaryParenchyma
  );
  importDescription1(
    "Mediastinum přiměřené šíře. Přiměřená šíře cév v mediastinu i plicních hilech. Srdeční oddíly nezvětšeny. " +
      descriptionLN +
      descriptionBones
  );

  importConclusion1(
    conclusionPulmonaryParenchyma + conclusionLN + conclusionBones
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
