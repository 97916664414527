import React from "react";
import { Button } from "antd";

class ButtonUpDownRow extends React.Component {
  state = {
    value: this.props.options[this.props.value].value,
  };

  onClick = (event) => {
    if (event.nativeEvent.which === 1) {
      // Left Click
      if (this.state.value < this.props.options.length - 1) {
        this.setState((prevState) => {
          return { ...prevState, value: prevState.value + 1 };
        });
        this.props.updateRow(
          this.props.index,
          this.props.segment,
          this.props.name,
          this.props.options[this.state.value + 1].value
        );
      }
    } else if (event.nativeEvent.which === 3) {
      // Right Click
      event.preventDefault();
      if (this.state.value > 0) {
        this.setState((prevState) => {
          return { ...prevState, value: prevState.value - 1 };
        });
        this.props.updateRow(
          this.props.index,
          this.props.segment,
          this.props.name,
          this.props.options[this.state.value - 1].value
        );
      }
    }
  };

  render() {
    return (
      <Button
        key={this.props.name + this.props.index}
        type="text"
        shape="round"
        size="small"
        block
        onClick={this.onClick}
        onContextMenu={this.onClick}
        value={this.state.value}
        style={{
          backgroundColor: this.props.options[this.state.value].color,
          width: this.props.width,
        }}
        disabled={this.props.isDisabled}
      >
        {this.props.options[this.state.value].text}
      </Button>
    );
  }
}

export default ButtonUpDownRow;
