import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import {
  aExams,
  aSpineSag,
  aSpineCor,
  aListesis,
  aSegments,
  aVertebrae,
  aAddFinds,
  aDiskDegen,
  aDiskPat,
  aDiskDirection,
  aDiskMigration,
  aStenosis,
} from "./CT_spine_LS_arrays";
import { generateReport } from "./CT_spine_LS_functions";

// General scripts
import {
  contentWidth,
  aContrastCT,
  aContrastCTamount,
  aCardColors,
  aTrueFalse,
  aSpine,
  aSize,
} from "../scripts/GeneralArrays";
import {} from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";
import DropDown from "../components/DropDown";
import ButtonUpDownRow from "../components/ButtonUpDownRow";

// 3rd party components
import { Form, Row, Col, Select, Input, Button, Tag } from "antd";
import { debounce } from "lodash";
const { Option } = Select;
const { CheckableTag } = Tag;

// Default
let firstSegment = 16;
let lastSegment = 23;
let aExamSegments = aSpine.filter(
  (item) => item.value >= firstSegment && item.value <= lastSegment
);

class CT_spine_LS extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      firstSegment: firstSegment,
      lastSegment: lastSegment,
      spineCor: 0,
      spineSag: 0,
      hemangiomas: [],
      schmorls: [],
      addFinds: [],
      valueNum: 0,
      valueText: "",
      valueBoolean: 0,
    },
    segments: aExamSegments.map((item) => {
      return {
        name: item.text,
        listesis: 0,
        listesisSize: 0,
        diskDegen: 0,
        diskPat: 0,
        diskDirection: [],
        diskPatSize: 0,
        diskMigration: 0,
        diskMigrationSize: 0,
        facetArthrosis: 0,
        foramenR: 0,
        foramenL: 0,
        recessR: 0,
        recessL: 0,
        spinalCanal: 0,
      };
    }),
    compressions: [],
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value) => {
    let vertebra = value;

    let newRecord = {
      vertebra: vertebra,
    };

    this.setState((prevState) => ({
      compressions: [...prevState.compressions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  handleTags = (item, checked, index) => {
    let array = [...this.state.segments];
    let selectedTags = array[index].diskDirection;
    if (selectedTags.indexOf(item) === -1) {
      selectedTags.push(item);
    } else {
      selectedTags.splice(selectedTags.indexOf(item), 1);
    }
    selectedTags.sort(function (a, b) {
      return a.value - b.value;
    });
    array[index].diskDirection = selectedTags;
    this.setState({ segments: array });
  };

  render() {
    let showListesisSize =
      this.state.segments.filter((item) => item.listesis > 0).length > 0
        ? true
        : false;
    let showDiskPatSize =
      this.state.segments.filter(
        (item) =>
          item.diskPat === 1 ||
          item.diskPat === 2 ||
          item.diskPat === 3 ||
          item.diskPat === 4
      ).length > 0
        ? true
        : false;

    let showDiskDirection =
      this.state.segments.filter(
        (item) =>
          (showDiskPatSize && item.diskPatSize > 0 && item.diskPat != 2) ||
          item.diskPat === 5
      ).length > 0
        ? true
        : false;

    let showDiskMigration =
      this.state.segments.filter(
        (item) =>
          item.diskPat === 1 &&
          item.diskPatSize > 0 &&
          item.diskDirection.length > 0
      ).length > 0
        ? true
        : false;

    let showDiskMigrationSize =
      this.state.segments.filter(
        (item) => showDiskMigration && item.diskMigration > 0
      ).length > 0
        ? true
        : false;

    const labelEmpty = 65;
    const labelListesis = 95;
    const labelListesisSize = 65;

    const labelDiskDegen = 77;

    const labelDiskPat = 100;
    const labelDiskPatSize = 65;
    const labelDiskDirection = 113;
    const labelDiskMigration = 78;
    const labelDiskMigrationSize = 65;

    const labelFacetArthrosis = 61;
    const labelUncoArthrosis = 61;
    const labelYellowLigament = 50;

    const labelStenosis = 34;

    const spineSegments = [
      {
        title: "segmenty páteře",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {
                    <Row>
                      <Col flex={labelEmpty + "px"}></Col>
                      <Col
                        flex={
                          showListesisSize
                            ? labelListesis + labelListesisSize + "px"
                            : labelListesis + "px"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          posun obratle
                        </Tag>
                      </Col>
                      <Col
                        flex={labelDiskDegen + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          degen. disk
                        </Tag>
                      </Col>
                      <Col
                        flex={
                          labelDiskPat +
                          (showDiskPatSize ? labelDiskPatSize : 0) +
                          (showDiskDirection ? labelDiskDirection : 0) +
                          (showDiskMigration ? labelDiskMigration : 0) +
                          (showDiskMigrationSize ? labelDiskMigrationSize : 0) +
                          "px"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          segment pat.
                        </Tag>
                      </Col>
                      <Col
                        flex={labelFacetArthrosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          FA
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PF
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PR
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PK
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          LR
                        </Tag>
                      </Col>
                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          LF
                        </Tag>
                      </Col>
                    </Row>
                  }
                  {this.state.segments.map((item, index) => {
                    return (
                      <Row style={{ marginBottom: "1px" }}>
                        <Col
                          flex={labelEmpty + "px"}
                          style={{ textAlign: "right", paddingRight: "5px" }}
                        >
                          <Tag
                            style={{
                              width: "100%",
                              margin: 0,
                              backgroundColor: aCardColors.blue.header,
                            }}
                          >
                            {item.name}
                          </Tag>
                        </Col>
                        <Col flex={labelListesis + "px"}>
                          <Select
                            key={index}
                            value={item.listesis}
                            defaultValue={item.listesis}
                            size="small"
                            showArrow={false}
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "segments",
                                "listesis",
                                value
                              )
                            }
                          >
                            {aListesis.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        {showListesisSize ? (
                          <Col flex={labelListesisSize + "px"}>
                            {item.listesis > 0 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="listesisSize"
                                segment="segments"
                                value={item.listesisSize}
                                options={aSize}
                                updateRow={this.updateRow}
                                isDisabled={item.listesis === 0 ? true : false}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col flex={labelDiskDegen + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="diskDegen"
                            segment="segments"
                            value={item.diskDegen}
                            options={aDiskDegen}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelDiskPat + "px"}>
                          <Select
                            key={index}
                            value={item.diskPat}
                            defaultValue={item.diskPat}
                            size="small"
                            showArrow={false}
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "segments",
                                "diskPat",
                                value
                              )
                            }
                          >
                            {aDiskPat.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        {showDiskPatSize ? (
                          <Col flex={labelDiskPatSize + "px"}>
                            {item.diskPat !== 0 && item.diskPat !== 5 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="diskPatSize"
                                segment="segments"
                                value={item.diskPatSize}
                                options={aSize}
                                style={{ width: "100%" }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskPat === 0 || item.diskPat === 6
                                    ? true
                                    : false
                                }
                              />
                            ) : null}
                          </Col>
                        ) : null}
                        {showDiskDirection ? (
                          <Col flex={labelDiskDirection + "px"}>
                            <Row>
                              {item.diskPat === 0 ||
                              item.diskPat === 2 ||
                              (item.diskPatSize === 0 && item.diskPat !== 5)
                                ? null
                                : aDiskDirection.map((item) => (
                                    <Col>
                                      <CheckableTag
                                        key={item.value}
                                        checked={
                                          this.state.segments[
                                            index
                                          ].diskDirection.indexOf(item) > -1
                                        }
                                        onChange={(checked) =>
                                          this.handleTags(item, checked, index)
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        {item.text}
                                      </CheckableTag>
                                    </Col>
                                  ))}
                            </Row>
                          </Col>
                        ) : (
                          ""
                        )}
                        {showDiskMigration ? (
                          <Col flex={labelDiskMigration + "px"}>
                            {item.diskPat === 1 &&
                            item.diskDirection.length > 0 ? (
                              <Select
                                key={index}
                                value={item.diskMigration}
                                defaultValue={item.diskMigration}
                                size="small"
                                showArrow={false}
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                  this.updateRow(
                                    index,
                                    "segments",
                                    "diskMigration",
                                    value
                                  )
                                }
                              >
                                {aDiskMigration.map((item) => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                              </Select>
                            ) : null}
                          </Col>
                        ) : (
                          ""
                        )}
                        {showDiskMigrationSize ? (
                          <Col flex={labelDiskMigrationSize + "px"}>
                            {item.diskMigration > 0 && item.diskPat > 0 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="diskMigrationSize"
                                segment="segments"
                                value={item.diskMigrationSize}
                                options={aSize}
                                style={{ width: "100%" }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskMigration > 0 ? false : true
                                }
                              />
                            ) : null}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col flex={labelFacetArthrosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="facetArthrosis"
                            segment="segments"
                            value={item.facetArthrosis}
                            options={aTrueFalse}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="foramenR"
                            segment="segments"
                            value={item.foramenR}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="recessR"
                            segment="segments"
                            value={item.recessR}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="spinalCanal"
                            segment="segments"
                            value={item.spinalCanal}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="recessL"
                            segment="segments"
                            value={item.recessL}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="foramenL"
                            segment="segments"
                            value={item.foramenL}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    const myContent1 = [
      {
        title: "páteř obecně",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "LS páteř",
              input: (
                <DropDown
                  name="spineCor"
                  segment="general"
                  defaultValue={this.state.general.spineCor}
                  options={aSpineCor}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "lordóza",
              input: (
                <DropDown
                  name="spineSag"
                  segment="general"
                  defaultValue={this.state.general.spineSag}
                  options={aSpineSag}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "hemangiomy",
              input: (
                <DropDown
                  name="hemangiomas"
                  segment="general"
                  defaultValue={this.state.general.hemangiomas}
                  options={aVertebrae}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "Schmorl. uzly",
              input: (
                <DropDown
                  name="schmorls"
                  segment="general"
                  defaultValue={this.state.general.schmorls}
                  options={aSegments}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "vedlejší nálezy",
              input: (
                <DropDown
                  name="addFinds"
                  segment="general"
                  defaultValue={this.state.general.addFinds}
                  options={aAddFinds}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "kompresivní fraktura",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {this.state.compressions.map((item, index) => {
                    return (
                      <Col
                        span={24}
                        style={{ marginBottom: "10px" }}
                        key={index}
                      >
                        <Row>
                          <Col span={1}>{index + 1 + ")"}</Col>
                          <Col span={3}>
                            <Select
                              key={index}
                              value={item.vertebra}
                              //defaultValue={item.valueNum}
                              size="small"
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "compressions",
                                  "vertebra",
                                  value
                                )
                              }
                            >
                              {aVertebrae.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              ),
            },
          ],
          [
            {
              label: "",
              input: (
                <Row>
                  <Col span={4}>
                    <Select
                      value="nový nález"
                      size="small"
                      onChange={(value) => {
                        this.newRow(value);
                      }}
                    >
                      {aVertebrae.map((item) => (
                        <Option value={item.value}>{item.text}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col flex="auto"></Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={spineSegments} />
        <CustomCards content={myContent1} />
      </Form>
    );
  }
}

export default CT_spine_LS;
