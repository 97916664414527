import { indent, br } from "../scripts/GeneralArrays";
import {
  formatArrayToText,
  capitelizeFirstLetter,
} from "../scripts/GeneralFunctions";

import {
  aOrganSize,
  aOrganAtrophy,
  aLiverSize,
  aLiverParenchyma,
  aGBsize,
  aGBcontent,
  aGBwall,
  aBTextraSize,
  aBTintraSize,
  aApendix,
  aColon,
  aSpleenSize,
  aPancreasSize,
  aPancreasParenchyma,
  aKidneyParenchyma,
  aKidneyKPS,
  aSigma,
  aUrinaryBladder,
  aLNcount,
} from "./MR_abdomen_arrays";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const {
    liverSize,
    liverParenchyma,
    liverLesions,
    liverCustomDescription,
    liverCustomConclusion,
    GBCHCE,
    GBSize,
    GBcontent,
    GBwall,
    GBcustomDescription,
    GBcustomConclusion,
    BTextraSize,
    BTintraSize,
    BTaerobilia,
    BTcustomDescription,
    BTcustomConclusion,
    apendix,
    colon,
    GITcustomDescription,
    GITcustomConclusion,
    LNparaaortic,
    LNiliacComR,
    LNiliacComL,
    LNiliacExtR,
    LNiliacExtL,
    LNiliacIntR,
    LNiliacIntL,
    LNinguinalR,
    LNinguinalL,
    LNparaaorticCount,
    LNiliacComRCount,
    LNiliacComLCount,
    LNiliacExtRCount,
    LNiliacExtLCount,
    LNiliacIntRCount,
    LNiliacIntLCount,
    LNinguinalRCount,
    LNinguinalLCount,
    LNcustomDescription,
    LNcustomConclusion,
    spleenSize,
    spleenAcc,
    spleenCustomDescription,
    spleenCustomConclusion,
    kidneyParenchymaR,
    kidneyKPSR,
    kidneyCustomDescriptionR,
    kidneyCustomConclusionR,
    kidneyParenchymaL,
    kidneyKPSL,
    kidneyCustomDescriptionL,
    kidneyCustomConclusionL,
    pancreasSize,
    pancreasParenchyma,
    pancreasCustomDescription,
    pancreasCustomConclusion,
  } = props.general;

  // Játra
  let liverDescription = "";
  liverDescription += liverCustomDescription;
  liverDescription +=
    (liverCustomDescription === "" ? "Játra " : "Jinak játra ") +
    aLiverSize[liverSize].description +
    ", " +
    aLiverParenchyma[liverParenchyma].description +
    ". ";
  let liverPat = [];
  liverSize > 0 && liverPat.push(aLiverSize[liverSize].conclusion);
  liverParenchyma > 0 &&
    liverPat.push(aLiverParenchyma[liverParenchyma].conclusion);
  let liverConclusion = "";
  liverConclusion += liverCustomConclusion;
  liverConclusion +=
    liverPat.length > 0 ? "Játra " + formatArrayToText(liverPat) + ". " : "";
  let liverIsPat =
    liverSize + liverParenchyma > 0 || liverCustomConclusion !== ""
      ? true
      : false;

  // Žlučník
  let GBDescription = "";
  GBDescription += GBcustomDescription;
  GBDescription += GBCHCE
    ? "Žlučník chybí. "
    : (GBcustomDescription === "" ? "Žlučník " : "Jinak žlučník ") +
      aGBwall[GBwall].description +
      ", " +
      aGBsize[GBSize].description +
      ", " +
      aGBcontent[GBcontent].description +
      ". ";
  let GBPat = [];
  if (GBCHCE) {
    GBPat.push("St.p. cholecystektomii. ");
  } else {
    GBwall > 0 && GBPat.push(aGBwall[GBwall].conclusion);
    GBcontent > 0 && GBPat.push(aGBcontent[GBcontent].conclusion);
  }
  let GBConclusion = GBcustomConclusion;
  GBConclusion += GBPat.length > 0 ? GBPat.join("") : "";
  let GBisPat =
    GBwall + GBcontent > 0 || GBCHCE || GBcustomConclusion !== ""
      ? true
      : false;

  // Žlučové cesty
  let BTDescription = "";
  BTDescription +=
    (BTcustomDescription !== ""
      ? BTcustomDescription +
        (BTextraSize > 0 ? aBTextraSize[BTextraSize].description : "") +
        (BTintraSize > 0 ? aBTintraSize[BTintraSize].description : "")
      : aBTextraSize[BTextraSize].description +
        aBTintraSize[BTintraSize].description) +
    (BTaerobilia
      ? "V průběhu žlučových cest výpadky signálu z plynu - aerobilie. "
      : "");
  let BTConclusion =
    BTcustomConclusion +
    aBTextraSize[BTextraSize].conclusion +
    aBTintraSize[BTintraSize].conclusion;
  let BTisPat =
    BTextraSize + BTintraSize > 0 || BTcustomConclusion !== "" ? true : false;

  // GIT
  let GITisPat =
    apendix + colon > 0 || GITcustomConclusion !== "" ? true : false;
  let GITDescription = "";
  GITDescription += GITcustomDescription + aColon[colon].description;
  GITDescription += GITisPat
    ? "Jinak přiměřený nález na gastrointestinálním traktu. "
    : "Přiměřený nález na gastrointestinálním traktu. ";
  let GITConclusion = "";
  GITConclusion += aColon[colon].conclusion + GITcustomConclusion;

  // lymfatické uzliny
  let LNDescription = "";
  let LNConclusion = "";
  let LNisPat = false;
  let aEnlargedLN = [];
  let aEnlargedLNcount = [];
  let aBorderlineLN = [];
  let aBorderlineLNcount = [];

  if (LNparaaortic > 1) {
    aEnlargedLNcount.push(LNparaaorticCount);
    aEnlargedLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  } else if (LNparaaortic === 1) {
    aBorderlineLNcount.push(LNparaaorticCount);
    aBorderlineLN.push(aLNcount[LNparaaorticCount].conclusion + "paraaortální");
  }
  if (LNiliacComR > 1) {
    aEnlargedLNcount.push(LNiliacComRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  } else if (LNiliacComR === 1) {
    aBorderlineLNcount.push(LNiliacComRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComRCount].conclusion +
        (LNiliacComRCount > 0
          ? "pravostranné společné ilické"
          : "pravostranná společná ilická")
    );
  }
  if (LNiliacComL > 1) {
    aEnlargedLNcount.push(LNiliacComLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  } else if (LNiliacComL === 1) {
    aBorderlineLNcount.push(LNiliacComLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacComLCount].conclusion +
        (LNiliacComLCount > 0
          ? "levostranné společné ilické"
          : "levostranná společná ilická")
    );
  }
  if (LNiliacExtR > 1) {
    aEnlargedLNcount.push(LNiliacExtRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  } else if (LNiliacExtR === 1) {
    aBorderlineLNcount.push(LNiliacExtRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtRCount].conclusion +
        (LNiliacExtRCount > 0
          ? "pravostranné zevní ilické"
          : "pravostranná zevní ilická")
    );
  }
  if (LNiliacExtL > 1) {
    aEnlargedLNcount.push(LNiliacExtLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  } else if (LNiliacExtL === 1) {
    aBorderlineLNcount.push(LNiliacExtLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacExtLCount].conclusion +
        (LNiliacExtLCount > 0
          ? "levostranné zevní ilické"
          : "levostranná zevní ilická")
    );
  }
  if (LNiliacIntR > 1) {
    aEnlargedLNcount.push(LNiliacIntRCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  } else if (LNiliacIntR === 1) {
    aBorderlineLNcount.push(LNiliacIntRCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntRCount].conclusion +
        (LNiliacIntRCount > 0
          ? "pravostranné vnitřní ilické"
          : "pravostranná vnitřní ilická")
    );
  }
  if (LNiliacIntL > 1) {
    aEnlargedLNcount.push(LNiliacIntLCount);
    aEnlargedLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  } else if (LNiliacIntL === 1) {
    aBorderlineLNcount.push(LNiliacIntLCount);
    aBorderlineLN.push(
      aLNcount[LNiliacIntLCount].conclusion +
        (LNiliacIntLCount > 0
          ? "levostranné vnitřní ilické"
          : "levostranná vnitřní ilická")
    );
  }
  if (LNinguinalR > 1) {
    aEnlargedLNcount.push(LNinguinalRCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? "pravostranné inguinální"
          : "pravostranná inguinální")
    );
  } else if (LNinguinalR === 1) {
    aBorderlineLNcount.push(LNinguinalRCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalRCount].conclusion +
        (LNinguinalRCount > 0
          ? "pravostranné inguinální"
          : "pravostranná inguinální")
    );
  }
  if (LNinguinalL > 1) {
    aEnlargedLNcount.push(LNinguinalLCount);
    aEnlargedLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? "levostranné inguinální"
          : "levostranná inguinální")
    );
  } else if (LNinguinalL === 1) {
    aBorderlineLNcount.push(LNinguinalLCount);
    aBorderlineLN.push(
      aLNcount[LNinguinalLCount].conclusion +
        (LNinguinalLCount > 0
          ? "levostranné inguinální"
          : "levostranná inguinální")
    );
  }

  if (aEnlargedLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aEnlargedLN) +
        (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose větší než 10mm. "
    );
    LNConclusion +=
      (aEnlargedLNcount[0] > 0 ? "Zvětšené " : "Zvětšená ") +
      formatArrayToText(aEnlargedLN) +
      (aEnlargedLNcount[aEnlargedLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (aBorderlineLN.length > 0) {
    LNDescription += capitelizeFirstLetter(
      formatArrayToText(aBorderlineLN) +
        (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
          ? " lymfatické uzliny "
          : " lymfatická uzlina ") +
        "v krátké ose přibližně 10mm. "
    );
    LNConclusion +=
      "Velikostí hraniční " +
      formatArrayToText(aBorderlineLN) +
      (aBorderlineLNcount[aBorderlineLNcount.length - 1] > 0
        ? " lymfatické uzliny. "
        : " lymfatická uzlina. ");
    LNisPat = true;
  }

  if (
    aEnlargedLN.length > 0 ||
    aBorderlineLN.length > 0 ||
    LNcustomDescription !== ""
  ) {
    LNDescription +=
      LNcustomDescription +
      "Jinak v zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  } else {
    LNDescription +=
      "V zobrazeném rozsahu přiměřená velikost lymfatických uzlin. ";
  }

  if (LNcustomConclusion !== "") {
    LNConclusion += LNcustomConclusion;
  }

  // Slezina
  let spleenDescription =
    spleenCustomDescription +
    (spleenCustomDescription === "" ? "Slezina " : "Jinak slezina ") +
    aSpleenSize[spleenSize].description +
    ". " +
    (spleenAcc ? "Akcesorní slezina (splenunkulus). " : "");

  let spleenPat = [];
  spleenSize > 0 && spleenPat.push(aSpleenSize[spleenSize].conclusion);

  let spleenConclusion = "";
  spleenConclusion += spleenCustomConclusion;
  spleenConclusion +=
    spleenPat.length > 0
      ? "Slezina " + formatArrayToText(spleenPat) + ". "
      : "";
  let spleenIsPat =
    spleenSize > 0 || spleenCustomConclusion !== "" ? true : false;

  // Pankreas
  let pancreasDescription =
    (pancreasCustomDescription === ""
      ? "Pankreas "
      : pancreasCustomDescription + "Jinak pankreas ") +
    aPancreasSize[pancreasSize].description +
    ", " +
    aPancreasParenchyma[pancreasParenchyma].description +
    ", bez přesvědčivých ložiskových změn. ";
  let pancreasPat = [];
  pancreasSize > 0 && pancreasPat.push(aPancreasSize[pancreasSize].conclusion);
  pancreasParenchyma > 0 &&
    pancreasPat.push(aPancreasParenchyma[pancreasParenchyma].conclusion);
  let pancreasConclusion =
    pancreasCustomConclusion +
    (pancreasPat.length > 0
      ? "Pankreas " + formatArrayToText(pancreasPat) + ". "
      : "");
  let pancreasIsPat =
    pancreasSize + pancreasParenchyma > 0 || pancreasCustomConclusion !== ""
      ? true
      : false;

  // Ledvina pravá
  let kidneyDescriptionR =
    (kidneyCustomDescriptionR === ""
      ? "Pravá ledvina "
      : kidneyCustomDescriptionR + "Jinak pravá ledvina ") +
    aKidneyParenchyma[kidneyParenchymaR].description +
    ", " +
    aKidneyKPS[kidneyKPSR].description +
    ", bez přesvědčivých ložiskových změn. ";
  let kidneyPatR = [];
  kidneyKPSR > 0 && kidneyPatR.push(aKidneyKPS[kidneyKPSR].conclusion);
  kidneyParenchymaR > 0 &&
    kidneyPatR.push(aKidneyParenchyma[kidneyParenchymaR].conclusion);
  let kidneyConclusionR =
    kidneyCustomConclusionR +
    (kidneyPatR.length > 0
      ? "Pravá ledvina s " + formatArrayToText(kidneyPatR) + ". "
      : "");
  let kidneyIsPat =
    kidneyParenchymaR + kidneyParenchymaL + kidneyKPSR + kidneyKPSL > 0 ||
    kidneyCustomConclusionR !== "" ||
    kidneyCustomConclusionR !== ""
      ? true
      : false;

  // Ledvina levá
  let kidneyDescriptionL =
    (kidneyCustomDescriptionL === ""
      ? "Levá ledvina "
      : kidneyCustomDescriptionL + "Jinak levá ledvina ") +
    aKidneyParenchyma[kidneyParenchymaL].description +
    ", " +
    aKidneyKPS[kidneyKPSL].description +
    ", bez přesvědčivých ložiskových změn. ";
  let kidneyPatL = [];
  kidneyKPSL > 0 && kidneyPatL.push(aKidneyKPS[kidneyKPSL].conclusion);
  kidneyParenchymaL > 0 &&
    kidneyPatL.push(aKidneyParenchyma[kidneyParenchymaL].conclusion);
  let kidneyConclusionL =
    kidneyCustomConclusionL +
    (kidneyPatL.length > 0
      ? "Levá ledvina s " + formatArrayToText(kidneyPatL) + ". "
      : "");

  // Přiměřený nález nebo jinak přiměřený nález
  let normalFindingsConclusion =
    "Přiměřený nález na MR " +
    (protocol === 0 ? "břicha. " : protocol === 1 ? "ledvin. " : "pankreatu. ");
  if (
    kidneyIsPat ||
    liverIsPat ||
    GBisPat ||
    BTisPat ||
    spleenIsPat ||
    pancreasIsPat ||
    GITisPat ||
    LNisPat
  ) {
    normalFindingsConclusion =
      "Jinak přiměřený nález na MR " +
      (protocol === 0
        ? "břicha. "
        : protocol === 1
        ? "ledvin. "
        : "pankreatu. ");
  }

  importDescription1(
    liverDescription +
      GBDescription +
      BTDescription +
      spleenDescription +
      pancreasDescription +
      GITDescription
  );
  importDescription1(kidneyDescriptionR + kidneyDescriptionL);
  importDescription1(LNDescription);

  importConclusion1(
    liverConclusion +
      GBConclusion +
      BTConclusion +
      spleenConclusion +
      pancreasConclusion +
      GITConclusion +
      kidneyConclusionR +
      kidneyConclusionL +
      LNConclusion +
      normalFindingsConclusion
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
