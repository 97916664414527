import { indent, br } from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

import { aPEsite } from "./CT_CTA_pulmonary_arrays";
import { aSelect, aLNmediastinum } from "./CT_chest_general_arrays";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const {
    PEright,
    PEleft,
    LNmediastinumEnlarged,
    LNmediastinumMarginal,
    descriptionCustomLungR,
    descriptionCustomLungL,
    conclusionCustomLungR,
    conclusionCustomLungL,
    descriptionCustomMediastinum,
    conclusionCustomMediastinum,
    descriptionCustomBones,
    conclusionCustomBones,
  } = props.general;

  // Plicní parenchym
  const descriptionPulmonaryParenchyma =
    descriptionCustomLungR === "" && descriptionCustomLungL === ""
      ? "Přiměřená denzita plicnícho parenchymu, bez ložiskových změn."
      : descriptionCustomLungR +
        descriptionCustomLungL +
        "Jinak přiměřený nález na plicním parenchymu. ";

  const conclusionPulmonaryParenchyma =
    conclusionCustomLungR === "" && conclusionCustomLungL === ""
      ? ""
      : conclusionCustomLungR + conclusionCustomLungL;

  // Plicní embolie
  const descriptionPE =
    PEright + PEleft === 0
      ? "V hodnotitelném rozsahu bez přesvědčivých defektů v kontrastní náplni plicních tepen. "
      : PEright > 0 && PEleft > 0
      ? "Vpravo defekty v kontrastní náplni " +
        aPEsite[PEright].description +
        ". " +
        "Vlevo defekty v kontrastní náplni " +
        aPEsite[PEleft].description +
        ". "
      : PEright > 0
      ? "Vpravo defekty v kontrastní náplni " +
        aPEsite[PEright].description +
        ". Vlevo v hodnotitelném rozsahu plicních tepen bez přesvědčivých defektů v jejich kontrastní náplni. "
      : "Vpravo defekty v kontrastní náplni " +
        aPEsite[PEleft].description +
        ". Vpravo v hodnotitelném rozsahu plicních tepen bez přesvědčivých defektů v jejich kontrastní náplni. ";

  const conclusionPE =
    PEright + PEleft === 0
      ? "Bez průkazu plicní embolie. "
      : PEright > 0 && PEleft > 0
      ? "Bilat. plicní embolie (" +
        (PEright === PEleft
          ? aPEsite[PEright].conclusion + "). "
          : "vpravo " +
            aPEsite[PEright].conclusion +
            ", vlevo " +
            aPEsite[PEleft].conclusion +
            "). ")
      : PEright > 0
      ? "Pravostranná plicní embolie (" + aPEsite[PEright].conclusion + "). "
      : "Levostranná plicní embolie (" + aPEsite[PEleft].conclusion + "). ";

  // Lymfatické uzliny

  const descriptionLN =
    (LNmediastinumEnlarged.length > 0
      ? "Zvětšené " +
        formatArrayToText(
          LNmediastinumEnlarged.map((LN) => aLNmediastinum[LN].description)
        ) +
        " lymfatické uzliny. "
      : "Bez zvětšených mediastinálních či hilových lymfatických uzlin. ") +
    (LNmediastinumMarginal.length > 0
      ? "Hraniční " +
        formatArrayToText(
          LNmediastinumMarginal.map((LN) => aLNmediastinum[LN].description)
        ) +
        " lymfatické uzliny. "
      : "");

  const conclusionLN =
    (LNmediastinumEnlarged.length > 0
      ? "Zvětšené lymfatické uzliny " +
        formatArrayToText(
          LNmediastinumEnlarged.map((LN) => aLNmediastinum[LN].description)
        ) +
        " (" +
        LNmediastinumEnlarged.map((LN) => aLNmediastinum[LN].conclusion).join(
          ", "
        ) +
        "). "
      : "") +
    (LNmediastinumMarginal.length > 0
      ? "Velikostí hraniční lymfatické uzliny " +
        formatArrayToText(
          LNmediastinumMarginal.map((LN) => aLNmediastinum[LN].description)
        ) +
        " (regio " +
        LNmediastinumMarginal.map((LN) => aLNmediastinum[LN].conclusion).join(
          ", "
        ) +
        "). "
      : "");

  // Mediastinum
  const descriptionMediastinum =
    descriptionCustomMediastinum === ""
      ? "Přiměřená šíře cév v mediastinu i plicních hilech. Srdeční oddíly nezvětšeny. "
      : descriptionCustomMediastinum +
        "Jinak přiměřený nález na srdci a velkých cévách mediastina. ";

  const conclusionMediastinum =
    conclusionCustomMediastinum === "" ? "" : conclusionCustomMediastinum;

  // Skelet
  const descriptionBones =
    descriptionCustomBones === ""
      ? "Zobrazený skelet a měkké tkáně hrudní stěny bez přesvědčivého patologického nálezu."
      : descriptionCustomBones +
        "Jinak zobrazený skelet a měkké tkáně hrudní stěny bez přesvědčivého patologického nálezu.";

  const conclusionBones =
    conclusionCustomBones === "" ? "" : conclusionCustomBones;

  importDescription1(descriptionPE);
  importDescription1(
    "Plicní křídla rozvinuta. " + descriptionPulmonaryParenchyma
  );
  importDescription1(descriptionLN + descriptionMediastinum + descriptionBones);

  importConclusion1(
    conclusionPE +
      conclusionPulmonaryParenchyma +
      conclusionLN +
      conclusionMediastinum +
      conclusionBones
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
