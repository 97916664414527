export const aExams = [
  {
    value: 0,
    text: 'MR pravého kolenního kloubu',
  },
  {
    value: 1,
    text: 'MR levého kolenního kloubu',
  },
];

export const green = '#b7eb8f';
export const yellow = '#ffec3d';
export const orange = '#ffa940';
export const red = '#ff4d4f';
export const grey = '#8c8c8c';
export const black = '#000000';
export const darkred = '#a8071a';

export const aLCA = [
  {
    value: 0,
    text: 'normální',
    description:
      'Bez strukturálních či signálových změn předního zkříženého vazu, jehož průběh je paralelní s Blumensaatovou linií. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description:
      'Nehomogenní zvýšení SI s možným přerušením kontinuity méně než poloviny šíře předního zkříženného vazu. ',
    conclusion: 'Susp. low grade parciální ruptura LCA. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 2',
    description:
      'Nehomogenní zvýšení SI s přerušením kontinuity více než poloviny šíře předního zkříženného vazu. ',
    conclusion: 'Susp. high grade parciální ruptura LCA. ',
    color: orange,
  },
  {
    value: 3,
    text: 'grade 3',
    description: 'Kompletní přerušení kontinuity předního zkříženého vazu. ',
    conclusion: 'Kompletní ruptura LCA. ',
    color: red,
  },
  {
    value: 4,
    text: 'st.p. náhradě',
    description: 'St.p. náhradě předního zkříženého vazu',
    conclusion: 'St.p. náhradě LCA',
    color: red,
  },
];

export const aLCABoneContusion = [
  {
    value: 0,
    text: 'bez postkont. edému',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 's postkont. edémem',
    description:
      'Na PDFS zvýšení signální intenzity kostní dřeně ventrolaterálního okraje laterálního kondylu femuru a dorzálního okraje laterálního kondylu tibie. ',
    conclusion:
      'Kompletní ruptura LCA s doprovodným postkontuzním edémem laterálního kondylu femuru a tibie, tedy recentní. ',
    color: yellow,
  },
];

export const aLCP = [
  {
    value: 0,
    text: 'normální',
    description: 'Normální nález na zadním zkříženém vazu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description:
      'Nehomogenní zvýšení SI s možným přerušením kontinuity méně než poloviny šíře zadního zkříženného vazu. ',
    conclusion: 'Low grade parciální ruptura LCP. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 2',
    description:
      'Nehomogenní zvýšení SI s přerušením kontinuity více než poloviny šíře zadního zkříženného vazu. ',
    conclusion: 'High grade parciální ruptura LCP. ',
    color: orange,
  },
  {
    value: 3,
    text: 'grade 3',
    description: 'Kompletní přerušení kontinuity zadního zkříženého vazu. ',
    conclusion: 'Kompletní ruptura LCP. ',
    color: red,
  },
];

export const aLCM = [
  {
    value: 0,
    text: 'normální',
    description:
      'Bez strukturálních či signálových změn mediálního kolaterálního vazu a nebo jeho okolí. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description:
      'Na PDFS zvýšení SI tuku podél průběhu jinak přiměřeného mediálního kolaterálního vazu. ',
    conclusion: 'Natažení mediálního kolaterálního vazu. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 2',
    description:
      'Nehomogenní zvýšení SI s přerušením kontinuity části mediálního kolaterálního vazu, se zvýšením SI okolního tuku. ',
    conclusion: 'Parciální ruptura mediálního kolaterálního vazu. ',
    color: orange,
  },
  {
    value: 3,
    text: 'grade 3',
    description:
      'Kompletní přerušení kontinuity mediálního kolaterálního vazu s výrazným zvýšením SI okolního tuku. ',
    conclusion: 'Kompletní ruptura mediálního kolaterálního vazu. ',
    color: red,
  },
];

export const aLCL = [
  {
    value: 0,
    text: 'normální',
    description:
      'Bez strukturálních či signálových změn laterálního kolaterálního vazu a nebo jeho okolí. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'grade 1',
    description:
      'Na PDFS zvýšení SI tuku podél průběhu jinak přiměřeného laterálního kolaterálního vazu. ',
    conclusion: 'Natažení laterálního kolaterálního vazu. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'grade 2',
    description:
      'Nehomogenní zvýšení SI s přerušením kontinuity části laterálního kolaterálního vazu, se zvýšením SI okolního tuku. ',
    conclusion: 'Parciální ruptura laterálního kolaterálního vazu. ',
    color: orange,
  },
  {
    value: 3,
    text: 'grade 3',
    description:
      'Kompletní přerušení kontinuity laterálního kolaterálního vazu s výrazným zvýšením SI okolního tuku. ',
    conclusion: 'Kompletní ruptura laterálního kolaterálního vazu. ',
    color: red,
  },
];

export const aChondropathyCompartment = [
  {
    value: 0,
    label: 'femur - mediální kondyl',
    description: 'zátěžové zóny mediálního kondylu femuru',
    conclusion: 'zátěžové zóny mediálního kondylu femuru',
  },
  {
    value: 1,
    label: 'femur - laterální kondyl',
    description: 'zátěžové zóny laterálního kondylu femuru',
    conclusion: 'zátěžové zóny laterálního kondylu femuru',
  },
  {
    value: 2,
    label: 'tibie - mediální kondyl',
    description: 'zátěžové zóny mediálního kondylu tibie',
    conclusion: 'zátěžové zóny mediálního kondylu tibie',
  },
  {
    value: 3,
    label: 'tibie - laterální kondyl',
    description: 'zátěžové zóny laterálního kondylu tibie',
    conclusion: 'zátěžové zóny laterálního kondylu tibie',
  },
  {
    value: 4,
    label: 'patella - mediální faceta',
    description: 'mediální facety patelly',
    conclusion: 'mediální facety patelly',
  },
  {
    value: 5,
    label: 'patella - laterální faceta',
    description: 'laterální facety patelly',
    conclusion: 'laterální facety patelly',
  },
  {
    value: 6,
    label: 'patella - rozhraní facet',
    description: 'rozhraní obou facet patelly',
    conclusion: 'rozhraní obou facet patelly',
  },
];

export const aChondropathyArea = [
  {
    value: 0,
    label: 'centrální část',
    description: 'centrální části ',
    conclusion: 'centrální části ',
  },
  {
    value: 1,
    label: 'mediální část',
    description: 'mediální části ',
    conclusion: 'mediální části ',
  },
  {
    value: 2,
    label: 'laterální část',
    description: 'laterální části ',
    conclusion: 'laterální části ',
  },
  {
    value: 3,
    label: 'dorzální část',
    description: 'dorzální části ',
    conclusion: 'dorzální části ',
  },
  {
    value: 4,
    label: 'ventrální část',
    description: 'ventrální části ',
    conclusion: 'ventrální části ',
  },
];

export const aChondropathyAreaPatella = [
  {
    value: 0,
    label: 'centrální část',
    description: 'centrální části ',
    conclusion: 'centrální části ',
  },
  {
    value: 1,
    label: 'mediální část',
    description: 'mediální části ',
    conclusion: 'mediální části ',
  },
  {
    value: 2,
    label: 'laterální část',
    description: 'laterální části ',
    conclusion: 'laterální části ',
  },
  {
    value: 3,
    label: 'horní část',
    description: 'horní části ',
    conclusion: 'horní části ',
  },
  {
    value: 4,
    label: 'dolní část',
    description: 'dolní části ',
    conclusion: 'dolní části ',
  },
];

export const aChondropathyGrade = [
  {
    value: 0,
    label: 'grade 1',
    description: 'Mírné zvýšení SI chrupavky ',
    conclusion: 'Lehká ',
  },
  {
    value: 1,
    label: 'grade 2',
    description: 'Povrchové nepravidelnosti chrupavky ',
    conclusion: 'Lehká ',
  },
  {
    value: 2,
    label: 'grade 3',
    description: 'Defekt necelé šíře chrupavky ',
    conclusion: 'Středně těžká ',
  },
  {
    value: 3,
    label: 'grade 4',
    description:
      'Defekt celé šíře chrupavky a signálové zvýšení subchondrální kostní dřeně ',
    conclusion: 'Těžká ',
  },
];

export const aChondropathySize = [
  {
    value: 0,
    label: '5mm',
    description: '5',
    conclusion: '5',
  },
  {
    value: 1,
    label: '10mm',
    description: '10',
    conclusion: '10',
  },
  {
    value: 2,
    label: '15mm',
    description: '15',
    conclusion: '15',
  },
];

export const aMenisci = [
  {
    value: 0,
    label: 'mediální meniskus',
    description: 'mediálního menisku ',
    conclusion: 'mediálního menisku',
  },
  {
    value: 1,
    label: 'laterální meniskus',
    description: 'laterálního menisku ',
    conclusion: 'laterálního menisku',
  },
];

export const aMeniscusPart = [
  {
    value: 0,
    label: 'přední roh',
    description: 'předního rohu ',
    conclusion: 'předního rohu ',
  },
  {
    value: 1,
    label: 'rozhraní přední roh a tělo',
    description: 'na rozhraní předního rohu a těla ',
    conclusion: 'na rozhraní předního rohu a těla ',
  },
  {
    value: 2,
    label: 'přední roh až tělo',
    description: 'předního rohu až těla ',
    conclusion: 'předního rohu až těla ',
  },
  {
    value: 3,
    label: 'tělo',
    description: 'těla ',
    conclusion: 'těla ',
  },
  {
    value: 4,
    label: 'tělo až zadní roh',
    description: 'těla až zadního rohu ',
    conclusion: 'těla až zadního rohu ',
  },
  {
    value: 5,
    label: 'rozhraní tělo a zadní roh',
    description: 'na rozhraní těla a zadního rohu ',
    conclusion: 'na rozhraní těla a zadního rohu ',
  },
  {
    value: 6,
    label: 'zadní roh',
    description: 'zadního rohu ',
    conclusion: 'zadního rohu ',
  },
];

export const aMeniscusGrade = [
  {
    value: 0,
    label: 'grade 1',
    description:
      'centrální okrouhlé zvýšení signálu nedosahující k jeho okrajům - grade 1. ',
    conclusion: 'Mírné degenerativní změny ',
  },
  {
    value: 1,
    label: 'grade 2',
    description:
      'horizontální pruhovité zvýšení signálu dosahující na max. jednom řezu k jeho hornímu či dolnímu okraji - grade 2. ',
    conclusion: 'Pokročilé degenerativní změny ',
  },
  {
    value: 2,
    label: 'grade 3',
    description: 'pruhovité zvýšení signálu dosahující na min. 2 řezech jeho ',
    conclusion: 'ruptura ',
  },
];

export const aMeniscusSurface = [
  {
    value: 0,
    label: 'horní',
    description: 'horního (femorálního) okraje - grade 3. ',
    conclusion: ' z jeho horního (femorálního) okraje. ',
  },
  {
    value: 1,
    label: 'dolní',
    description: 'dolního (tibiálního) okraje - grade 3. ',
    conclusion: ' z jeho dolního (tibiálního) okraje. ',
  },
  {
    value: 2,
    label: 'napříč',
    description: 'horního i dolního okraje - grade 3. ',
    conclusion: '. ',
  },
];

export const aMeniscusTearType = [
  {
    value: 0,
    label: 'horizontální',
    description: 'longitudinálně a horizontálně probíhající ',
    conclusion: 'Horizontální longitudinální ',
  },
  {
    value: 1,
    label: 'vertikální',
    description: 'longitudinálně a vertikálně probíhající ',
    conclusion: 'Vertikální longitudinální ',
  },
  {
    value: 2,
    label: 'radiální',
    description:
      'z volného okraje příčně probíhající pruhovité zvýšení signálu - grade 3. ',
    conclusion: 'Radiální ',
  },
  {
    value: 3,
    label: 'komplexní',
    description:
      'výrazně nehomogenní struktura a různě orientovaná zvýšení signálu - grade 3. ',
    conclusion: 'Komplexní těžká degenerativní ',
  },
  {
    value: 4,
    label: 'bucket handle',
    description:
      ' s dislokací jeho vnitřní části mediálně (bucket handle) - grade 3. ',
    conclusion: " tvaru 'ucha od košíku' (bucket handle). ",
  },
  {
    value: 5,
    label: 'bucket handle - flipped',
    description:
      ' s dislokací jeho vnitřní části mediálně (bucket handle) a převrácením velké části jeho dorzální poloviny ventrálně (flipped meniscus) - grade 3. ',
    conclusion:
      " tvaru 'bucket handle' s převrácením velké části jeho dorzální poloviny ventrálně (flipped meniscus). ",
  },
  {
    value: 6,
    label: 'flap',
    description: 'flap description',
    conclusion: " tvaru 'flap meniscus.' ",
  },
  {
    value: 7,
    label: 'odtržení zadního rohu',
    description:
      ' v radiálním směru s odtržením jeho zadního rohu v místě úponu, sekundárně tak s extruzí části těla menisku a na PDFS se zvýšením SI tuku podél průběhu kolaterálního vazu. ',
    conclusion:
      ' v místě jeho úponu (meniscal root tear), sekundárně s reaktivním prosáknutím kolaterálního vazu z imprese extruzí těla menisku. ',
  },
];

export const aBursitisSuprapatellar = [
  {
    value: 0,
    text: 'normální nález',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná bursitida',
    description:
      'V oblasti suprapatelární podkožní burzy na PDFS zvýšení SI obrazu prosáknutí až menší tekutinové kolekce. ',
    conclusion: 'Mírná suprapatelární bursitida. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'pokročilá bursitida',
    description:
      'V oblasti suprapatelární podkožní burzy na PDFS kolekce tekutiny s prosáknutím okolního tuku. ',
    conclusion: 'Pokročilá suprapatelární bursitida. ',
    color: orange,
  },
];

export const aBursitisPrepatellar = [
  {
    value: 0,
    text: 'normální nález',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná bursitida',
    description:
      'V oblasti prepatelární podkožní burzy na PDFS zvýšení SI obrazu prosáknutí až menší tekutinové kolekce. ',
    conclusion: 'Mírná prepatelární bursitida. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'pokročilá bursitida',
    description:
      'V oblasti prepatelární podkožní burzy na PDFS kolekce tekutiny s prosáknutím okolního tuku. ',
    conclusion: 'Pokročilá prepatelární bursitida. ',
    color: red,
  },
];

export const aBursitisInfrapatellarSuperf = [
  {
    value: 0,
    text: 'normální nález',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná bursitida',
    description:
      'V oblasti infrapatelární povrchové burzy na PDFS zvýšení SI obrazu prosáknutí až menší tekutinové kolekce. ',
    conclusion: 'Mírná infrapatelární povrchová bursitida. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'pokročilá bursitida',
    description:
      'V oblasti povrchové infrapatelární burzy na PDFS kolekce tekutiny s prosáknutím okolního tuku. ',
    conclusion: 'Pokročilá povrchová infrapatelární bursitida. ',
    color: red,
  },
];

export const aBursitisInfrapatellarDeep = [
  {
    value: 0,
    text: 'normální nález',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná bursitida',
    description:
      'V oblasti hluboké infrapatelární burzy na PDFS zvýšení SI obrazu prosáknutí až menší tekutinové kolekce. ',
    conclusion: 'Mírná hluboká infrapatelární bursitida. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'pokročilá bursitida',
    description:
      'V oblasti hluboké infrapatelární burzy na PDFS kolekce tekutiny s prosáknutím okolního tuku. ',
    conclusion: 'Pokročilá hluboká infrapatellární bursitida. ',
    color: red,
  },
];

export const aContrast = [
  {
    key: 0,
    value: 0,
    text: 'bez kontrastní látky',
  },
  {
    key: 1,
    value: 1,
    text: 'Prohance 10ml',
  },
  {
    key: 2,
    value: 2,
    text: 'Clarsican 10ml',
  },
];

export const aSynovialFluid = [
  {
    value: 0,
    text: 'normální',
    description: 'Přiměřená náplň kolenního kloubu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírně zvýšená',
    description: 'Mírně vyšší náplň kolenního kloubu. ',
    conclusion: 'Mírně vyšší náplň kolenního kloubu. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'zvýšená',
    description: 'Zvýšená náplň kolenního kloubu. ',
    conclusion: 'Zvýšená náplň kolenního kloubu. ',
    color: orange,
  },
  {
    value: 3,
    text: 'výrazně zvýšená',
    description: 'Výrazně zvýšená náplň kolenního kloubu. ',
    conclusion: 'Výrazně zvýšená náplň kolenního kloubu. ',
    color: red,
  },
];

export const aBakerCyst = [
  {
    value: 0,
    text: '',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: '',
    description:
      'Výchlipka kloubního pouzdra defektem mezi mediální hlavou m. gastrocnemius a šlachou m. semimembranosus vyplněná tekutinou',
    conclusion: 'Bakerova cysta',
  },
];

export const aLCACyst = [
  {
    value: 0,
    text: '',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: '',
    description: 'Tekutinová kolekce podél průběhu předního zkříženého vazu',
    conclusion: 'Ganglionová cysta LCA. ',
  },
];

export const aLCPCyst = [
  {
    value: 0,
    text: '',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: '',
    description: 'Tekutinová kolekce podél průběhu zadního zkříženého vazu',
    conclusion: 'Ganglionová cysta LCP. ',
  },
];

export const aGCNMCyst = [
  {
    value: 0,
    text: '',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: '',
    description:
      'Tekutinová kolekce při femorálním origu mediální hlavy m. gastrocnemius',
    conclusion:
      'Ganglionová cysta femorálního origa mediální hlavy m. gastrocnemius. ',
  },
];

export const aGCNLCyst = [
  {
    value: 0,
    text: '',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: '',
    description:
      'Tekutinová kolekce při femorálním origu laterální hlavy m. gastrocnemius',
    conclusion:
      'Ganglionová cysta femorálního origa laterální hlavy m. gastrocnemius. ',
  },
];

export const aArthrosis = [
  {
    value: 0,
    text: 'bez artrózy',
    description: 'Bez degenerativních změn kolenního kloubu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná artróza',
    description: 'Mírné snížení vrstvy chrupavky kolenního kloubu. ',
    conclusion: 'Mírná gonartróza. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'artróza',
    description:
      'Snížení vrstvy chrupavky kolenního kloubu s přihrocením okrajů kloubních ploch. ',
    conclusion: 'Gonartróza. ',
    color: orange,
  },
  {
    value: 3,
    text: 'výrazná artróza',
    description:
      'Snížení vrstvy chrupavky kolenního kloubu s výraznými okrajovými osteofyty. ',
    conclusion: 'Pokročilá gonartróza. ',
    color: red,
  },
];

export const aArthrosisFP = [
  {
    value: 0,
    text: 'bez artrózy',
    description: 'Bez degenerativních změn femoropatellárního kloubu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná artróza',
    description: 'Mírné snížení vrstvy chrupavky femoropatellárního kloubu. ',
    conclusion: 'Mírná femoropatellární artróza. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'artróza',
    description:
      'Snížení vrstvy chrupavky femoropatellárního kloubu s přihrocením okrajů kloubních ploch. ',
    conclusion: 'Femoropatellární artróza. ',
    color: orange,
  },
  {
    value: 3,
    text: 'výrazná artróza',
    description:
      'Snížení vrstvy chrupavky femoropatellárního kloubu s výraznými okrajovými osteofyty. ',
    conclusion: 'Pokročilá femoropatellární artróza. ',
    color: red,
  },
];

export const patType = [
  { value: 0, text: 'normální' },
  { value: 1, text: 'tendinóza' },
  { value: 2, text: 'ruptura < 1/4 šíře (< 3mm)' },
  { value: 3, text: 'ruptura < 1/2 šíře (3-6mm)' },
  { value: 4, text: 'ruptura > 1/2 šíře (> 6mm)' },
  { value: 5, text: 'ruptura v celé šíři části šlachy' },
  { value: 6, text: 'ruptura celé šlachy' },
];

export const patSide = [
  {
    value: 0,
    text: 'normální nález',
    description: '',
  },
  {
    value: 1,
    text: 'burzální',
    description: 'z burzální plochy ',
  },
  {
    value: 2,
    text: 'intersticiální',
    description: 'intersticiální části ',
  },
  {
    value: 3,
    text: 'artikulační',
    description: 'z artikulační plochy ',
  },
];

export const aWiberg = [
  {
    value: 0,
    text: 'typ 1',
    description:
      'Přibližně symetrické a konkávní facety patelly - Wiberg typ 1. ',
    conclusion: 'Patella dle Wiberga typ 1. ',
    color: green,
  },
  {
    value: 1,
    text: 'typ 2',
    description:
      'Menší mediální faceta a zachovalá konkavita laterální facety patelly - Wiberg typ 2. ',
    conclusion: 'Patella dle Wiberga typ 2. ',
    color: green,
  },
  {
    value: 2,
    text: 'typ 3',
    description:
      'Výrazně zmenšená a vertikálněji orientovaná mediální faceta patelly - Wiberg typ 3. ',
    conclusion: 'Patella dle Wiberga typ 3. ',
    color: green,
  },
  {
    value: 3,
    text: 'typ 4',
    description:
      'Nevyvinutá mediální faceta patelly, její mediální okraje je tak výrazně vertikálně orientován - Wiberg typ 4. ',
    conclusion: 'Patella dle Wiberga typ 4. ',
    color: green,
  },
];
