import { green, yellow, orange, red, neutral } from "../scripts/GeneralArrays";

// Custom

export const aSelect = [
  {
    value: 0,
    text: "select 1",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "select 2",
    description: "",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "select 3",
    description: "",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "select 4",
    description: "",
    conclusion: "",
    color: red,
  },
];

export const aPEsite = [
  {
    value: 0,
    text: "bez embolizace",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "subsegmentární",
    description: "subsegmentárních plicních tepen",
    conclusion: "subsegmentární",
    color: yellow,
  },
  {
    value: 2,
    text: "segmentární",
    description: "segmentárních plicních tepen",
    conclusion: "segmentární",
    color: orange,
  },
  {
    value: 3,
    text: "lobární",
    description: "lobárních plicních tepen",
    conclusion: "lobární",
    color: orange,
  },
  {
    value: 4,
    text: "kmenová",
    description: "kmene plicní tepny",
    conclusion: "kmenová",
    color: red,
  },
];

export const aLNmediastinum = [
  {
    value: 0,
    text: "1 - supraklavikulární",
    description: "supraklavikulární",
    conclusion: "1",
    color: green,
  },
  {
    value: 1,
    text: "2R - horní paratracheální",
    description: "pravostranné horní paratracheální",
    conclusion: "2R",
    color: yellow,
  },
  {
    value: 2,
    text: "2L - horní paratracheální",
    description: "levostranné horní paratracheální",
    conclusion: "2L",
    color: orange,
  },
  {
    value: 3,
    text: "3A - prevaskulární",
    description: "prevaskulární",
    conclusion: "3A",
    color: red,
  },
  {
    value: 4,
    text: "3P - prevertebrální",
    description: "prevertebrální",
    conclusion: "3P",
    color: red,
  },
  {
    value: 5,
    text: "4R - dolní paratracheální",
    description: "pravostranné dolní paratracheální",
    conclusion: "4R",
    color: red,
  },
  {
    value: 6,
    text: "4L - dolní paratracheální",
    description: "levostranné dolní paratracheální",
    conclusion: "4L",
    color: red,
  },
  {
    value: 7,
    text: "5 - subaortální",
    description: "subaortální",
    conclusion: "5",
    color: red,
  },
  {
    value: 8,
    text: "6 - paraaortální",
    description: "paraaortální",
    conclusion: "6",
    color: red,
  },
  {
    value: 9,
    text: "7 - subkarinální",
    description: "subkarinální",
    conclusion: "7",
    color: red,
  },
  {
    value: 10,
    text: "8 - paraezofageální",
    description: "paraezofageální",
    conclusion: "8",
    color: red,
  },
  {
    value: 11,
    text: "9 - v lig. pulmonale",
    description: "v lig. pulmonale",
    conclusion: "9",
    color: red,
  },
  {
    value: 12,
    text: "10R - pravostranné hilové",
    description: "pravostranné hilové",
    conclusion: "10R",
    color: red,
  },
  {
    value: 13,
    text: "10L - levostranné hilové",
    description: "levostranné hilové",
    conclusion: "10L",
    color: red,
  },
];
