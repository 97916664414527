import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Custom scripts
import {
  aExams,
  aHeart,
  aHeartFailure,
  aFluidothorax,
  aCZKvsR,
  aCZKvjR,
  aCZKvsL,
  aCZKvjL,
  aSheatR,
  aSheatL,
  aPICCR,
  aPICCL,
  aSGcath,
  aETK,
  aTSK,
  aHDR,
  aHDL,
  aECMO,
  aPMR,
  aPML,
  aScoliosis,
  aSpondylosis,
  aScars,
  aConsolidation,
  aHypoventilation,
  aObscured,
  aPatientRotation,
  aCompareToLast,
  compareToLastConclusion,
  aAo,
  aMi,
  aTri,
  aPul,
} from './XRAY_chest_arrays';
import { generateReport } from '././XRAY_chest_functions';

// General scripts
import {
  aGeneral,
  contentWidth,
  aContrastMR,
  aContrastMRamount,
  aCardColors,
  aTrueFalse,
  aSizemm,
} from '../scripts/GeneralArrays';
import { formatCustomInput } from '../scripts/GeneralFunctions';

// Custom components
import CustomCards from '../components/CustomCards';
import DropDown from '../components/DropDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';
import ButtonUpDown from '../components/ButtonUpDown';

// 3rd party components
import { Form, Row, Col, Select, Input, Button, Tooltip } from 'antd';
import { debounce } from 'lodash';
const { Option } = Select;
const { TextArea } = Input;

class XRAY_chest extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      consRU: 0,
      consRM: 0,
      consRL: 0,
      consLU: 0,
      consLM: 0,
      consLL: 0,
      hypoventRU: 0,
      hypoventRM: 0,
      hypoventRL: false,
      hypoventLU: 0,
      hypoventLM: 0,
      hypoventLL: false,
      heart: 0,
      heartFailure: 0,
      aortaAS: 0,
      fluidothoraxR: 0,
      fluidothoraxL: 0,
      pneumothoraxR: 0,
      pneumothoraxL: 0,
      pulmEmphysema: false,
      CZKvsR: 0,
      CZKvjR: 0,
      CZKvsL: 0,
      CZKvjL: 0,
      sheatR: 0,
      sheatL: 0,
      portCathR: false,
      portCathL: false,
      SGcathR: 0,
      SGcathL: 0,
      PICCR: 0,
      PICCL: 0,
      trachea: 0,
      ETK: 0,
      TSK: 0,
      HDR1: 0,
      HDR2: 0,
      HDL1: 0,
      HDL2: 0,
      ECMO: 0,
      EKG: 0,
      PMR: 0,
      PML: 0,
      scar: 0,
      perikardEl: 0,
      clippingLA: false,
      ao: 0,
      mi: 0,
      tri: 0,
      pul: 0,
      NGS: 0,
      scoliosis: 0,
      spondylosis: 0,
      lobusAzygos: false,
      omarthrosisR: false,
      omarthrosisL: false,
      ribsFractureR: false,
      ribsFractureL: false,
      subcutEmphR: false,
      subcutEmphL: false,
      obscured: 0,
      patientRotation: 0,
      normalFinding: true,
      compareToLast: 0,
      compareToLastConclusion: 'Oproti minule nyní nově ',
      valueNum: 0,
      valueText: '',
      valueBoolean: 0,
    },
    consolidations: [],
    lesions: [],
  };

  initialState = cloneDeep(this.state);

  handleButtonGroup = value => {
    let array = [...this.state.consolidations];
    if (array.indexOf(value) === -1) {
      array.push(value);
      array.sort();
    } else {
      array.splice(array.indexOf(value), 1);
    }
    this.setState({ consolidations: array });
    console.log(array);
  };

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = value => {
    let valueNum = value;
    let valueText = '';
    let valueBoolean = false;

    let newRecord = {
      valueNum: valueNum,
      valueText: valueText,
      valueBoolean: valueBoolean,
    };

    this.setState(prevState => ({
      lesions: [...prevState.lesions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const { protocol } = this.props;
    const lungs = [
      {
        title: 'zánět',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'konsolidace',
              input: (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <ButtonUpDown
                          name='consRU'
                          segment='general'
                          value={this.state.general.consRU}
                          options={aConsolidation}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name='consLU'
                          segment='general'
                          value={this.state.general.consLU}
                          options={aConsolidation}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <ButtonUpDown
                          name='consRM'
                          segment='general'
                          value={this.state.general.consRM}
                          options={aConsolidation}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name='consLM'
                          segment='general'
                          value={this.state.general.consLM}
                          options={aConsolidation}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <ButtonUpDown
                          name='consRL'
                          segment='general'
                          value={this.state.general.consRL}
                          options={aConsolidation}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name='consLL'
                          segment='general'
                          value={this.state.general.consLL}
                          options={aConsolidation}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: 'hypoventilace',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'hypoventilace',
              input: (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <ButtonTrueFalse
                          name='hypoventRL'
                          segment='general'
                          value={this.state.general.hypoventRL}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonTrueFalse
                          name='hypoventLL'
                          segment='general'
                          value={this.state.general.hypoventLL}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const myContent1 = [
      {
        title: 'plíce a pleura',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'pneumothorax',
              input: (
                <Row>
                  <Tooltip placement='top' title={<span>vpravo</span>}>
                    <Col span={12}>
                      <DropDown
                        name='pneumothoraxR'
                        segment='general'
                        defaultValue={this.state.general.pneumothoraxR}
                        options={aSizemm}
                        onChange={this.onChange}
                      />
                    </Col>
                  </Tooltip>
                  <Tooltip placement='top' title={<span>vlevo</span>}>
                    <Col span={12}>
                      <DropDown
                        name='pneumothoraxL'
                        segment='general'
                        defaultValue={this.state.general.pneumothoraxL}
                        options={aSizemm}
                        onChange={this.onChange}
                      />
                    </Col>
                  </Tooltip>
                </Row>
              ),
            },
            {
              label: 'fluidothorax',
              input: (
                <Row>
                  <Tooltip placement='top' title={<span>vpravo</span>}>
                    <Col span={12}>
                      <ButtonUpDown
                        name='fluidothoraxR'
                        segment='general'
                        value={this.state.general.fluidothoraxR}
                        options={aFluidothorax}
                        onChange={this.onChange}
                      />
                    </Col>
                  </Tooltip>
                  <Tooltip placement='top' title={<span>vlevo</span>}>
                    <Col span={12}>
                      <ButtonUpDown
                        name='fluidothoraxL'
                        segment='general'
                        value={this.state.general.fluidothoraxL}
                        options={aFluidothorax}
                        onChange={this.onChange}
                      />
                    </Col>
                  </Tooltip>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'pl. emfyzém',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='pulmEmphysema'
                      segment='general'
                      value={this.state.general.pulmEmphysema}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
            {
              label: 'lob. v. azygos',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='lobusAzygos'
                      segment='general'
                      value={this.state.general.lobusAzygos}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: 'srdce, mediastinum',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'srdce',
              input: (
                <ButtonUpDown
                  name='heart'
                  segment='general'
                  value={this.state.general.heart}
                  options={aHeart}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'městnání',
              input: (
                <ButtonUpDown
                  name='heartFailure'
                  segment='general'
                  value={this.state.general.heartFailure}
                  options={aHeartFailure}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'AS aorty',
              input: (
                <ButtonTrueFalse
                  name='aortaAS'
                  segment='general'
                  value={this.state.general.aortaAS}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'náhrada chl.',
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonUpDown
                      name='ao'
                      segment='general'
                      value={this.state.general.HDR1}
                      options={aAo}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='mi'
                      segment='general'
                      value={this.state.general.HDR2}
                      options={aMi}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='tri'
                      segment='general'
                      value={this.state.general.HDL1}
                      options={aTri}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='pul'
                      segment='general'
                      value={this.state.general.HDL2}
                      options={aPul}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'klip. ouška LS',
              input: (
                <ButtonTrueFalse
                  name='clippingLA'
                  segment='general'
                  value={this.state.general.clippingLA}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: '',
              input: '',
            },
          ],
        ],
      },
    ];

    const myContent2 = [
      {
        title: 'invaze, implantáty a poop. změny',
        color: aCardColors.orange,
        rows: [
          // řádek 1
          [
            {
              label: 'CŽK',
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonUpDown
                      name='CZKvsR'
                      segment='general'
                      value={this.state.general.CZKvsR}
                      options={aCZKvsR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='CZKvjR'
                      segment='general'
                      value={this.state.general.CZKvjR}
                      options={aCZKvjR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='CZKvjL'
                      segment='general'
                      value={this.state.general.CZKvjL}
                      options={aCZKvjL}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='CZKvsL'
                      segment='general'
                      value={this.state.general.CZKvsL}
                      options={aCZKvsL}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: 'ETK',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='ETK'
                      segment='general'
                      value={this.state.general.ETK}
                      options={aETK}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
          ],
          // řádek 2
          [
            {
              label: 'sheat',
              input: (
                <Row>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='sheatR'
                      segment='general'
                      value={this.state.general.sheatR}
                      options={aSheatR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='sheatL'
                      segment='general'
                      value={this.state.general.sheatL}
                      options={aSheatL}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: 'TSK',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='TSK'
                      segment='general'
                      value={this.state.general.TSK}
                      options={aTSK}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
          ],
          // řádek 3
          [
            {
              label: 'PICC',
              input: (
                <Row wrap={false}>
                  <Col span={6}>
                    <ButtonUpDown
                      name='PICCR'
                      segment='general'
                      value={this.state.general.PICCR}
                      options={aPICCR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}></Col>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='PICCL'
                      segment='general'
                      value={this.state.general.PICCL}
                      options={aPICCL}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: 'NG sonda',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='NGS'
                      segment='general'
                      value={this.state.general.NGS}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
          ],
          // řádek 3
          [
            {
              label: 'portkatétr',
              input: (
                <Row wrap={false}>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name='portCathR'
                      segment='general'
                      value={this.state.general.portCathR}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name='portCathL'
                      segment='general'
                      value={this.state.general.portCathL}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: 'kardiostimul.',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name='PMR'
                      segment='general'
                      value={this.state.general.PMR}
                      options={aPMR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='PML'
                      segment='general'
                      value={this.state.general.PML}
                      options={aPML}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          // řádek 5
          [
            {
              label: 'SG katétr',
              input: (
                <Row wrap={false}>
                  <Col span={6}></Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='SGcathR'
                      segment='general'
                      value={this.state.general.SGcathR}
                      options={aSGcath}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='SGcathL'
                      segment='general'
                      value={this.state.general.SGcathL}
                      options={aSGcath}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}></Col>
                </Row>
              ),
            },
            {
              label: 'perikard. el.',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='perikardEl'
                      segment='general'
                      value={this.state.general.perikardEl}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
          ],
          // řádek 6
          [
            {
              label: 'hrudní drény',
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonUpDown
                      name='HDR1'
                      segment='general'
                      value={this.state.general.HDR1}
                      options={aHDR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='HDR2'
                      segment='general'
                      value={this.state.general.HDR2}
                      options={aHDR}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='HDL1'
                      segment='general'
                      value={this.state.general.HDL1}
                      options={aHDL}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={6}>
                    <ButtonUpDown
                      name='HDL2'
                      segment='general'
                      value={this.state.general.HDL2}
                      options={aHDL}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: 'EKG el.',
              input: (
                <ButtonTrueFalse
                  name='EKG'
                  segment='general'
                  value={this.state.general.EKG}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          // řádek 7
          [
            {
              label: 'ECMO',
              input: (
                <ButtonUpDown
                  name='ECMO'
                  segment='general'
                  value={this.state.general.ECMO}
                  options={aECMO}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'poop. rána',
              input: (
                <ButtonUpDown
                  name='scar'
                  segment='general'
                  value={this.state.general.scar}
                  options={aScars}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'vedlejší nálezy',
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 'skolióza',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='scoliosis'
                      segment='general'
                      value={this.state.general.scoliosis}
                      options={aScoliosis}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
            {
              label: 'omartróza',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='omarthrosisR'
                      segment='general'
                      value={this.state.general.omarthrosisR}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='omarthrosisL'
                      segment='general'
                      value={this.state.general.omarthrosisL}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'def. spondyl.',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='spondylosis'
                      segment='general'
                      value={this.state.general.spondylosis}
                      options={aSpondylosis}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}></Col>
                </Row>
              ),
            },
            {
              label: 'fraktura žeber',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='ribsFractureR'
                      segment='general'
                      value={this.state.general.ribsFractureR}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='ribsFractureL'
                      segment='general'
                      value={this.state.general.ribsFractureL}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: '',
              input: '',
            },
            {
              label: 'podkožní emf.',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='subcutEmphR'
                      segment='general'
                      value={this.state.general.subcutEmphR}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonTrueFalse
                      name='subcutEmphL'
                      segment='general'
                      value={this.state.general.subcutEmphL}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const blockLimitAndNormalFindigs = [
      {
        title: 'limitace vyšetření',
        color: aCardColors.green,
        rows: [
          // řádek 1
          [
            {
              label: 'rotace pac.',
              input: (
                <ButtonUpDown
                  name='patientRotation'
                  segment='general'
                  value={this.state.general.patientRotation}
                  options={aPatientRotation}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'nezobrazeno',
              input: (
                <ButtonUpDown
                  name='obscured'
                  segment='general'
                  value={this.state.general.obscured}
                  options={aObscured}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'doplnění nálezu',
        color: aCardColors.yellow,
        rows: [
          // řádek 1
          [
            {
              label: 'oproti minule',
              input: (
                <ButtonUpDown
                  name='compareToLast'
                  segment='general'
                  value={this.state.general.compareToLast}
                  options={aCompareToLast}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'normální nález',
              input: (
                <ButtonTrueFalse
                  name='normalFinding'
                  segment='general'
                  value={this.state.general.normalFinding}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.general.compareToLast === 2 ? '' : '',
              input:
                this.state.general.compareToLast === 2 ? (
                  <TextArea
                    name='compareToLastConclusion'
                    placeholder='Oproti minule nyní nově...'
                    defaultValue={this.state.general.compareToLastConclusion}
                    autoSize={{ minRows: 1, maxRows: 5 }}
                    onBlur={event =>
                      this.onChange({
                        name: 'compareToLastConclusion',
                        segment: 'general',
                        value: event.target.value,
                      })
                    }
                  />
                ) : (
                  ''
                ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={lungs} />
        <CustomCards content={myContent1} />
        <CustomCards content={myContent2} />
        <CustomCards content={blockLimitAndNormalFindigs} />
      </Form>
    );
  }
}

export default XRAY_chest;
