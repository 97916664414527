import React from "react";

// Custom scripts
import {
  aSpineSag,
  aSpineCor,
  aListesis,
  aAddFinds,
  aDiskDegen,
  aModic,
  aDiskPat,
  aDiskDirection,
  aDiskMigration,
  aStenosis,
  aFacetArthrosis,
  aUncoArthrosis,
  aADArthrosis,
  aFractureSite,
  aFractureEndplate,
  aFractureDepth,
  aFractureEdema,
  aFractureProminence,
} from "./CT_spine_C_arrays";
import { generateReport } from "./CT_spine_C_functions";

// General scripts
import {
  contentWidth,
  aCardColors,
  aTrueFalse,
  aSpine,
  aVertebrae,
  aSize,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";
import DropDown from "../components/DropDown";
import ButtonUpDownRow from "../components/ButtonUpDownRow";
import ButtonUpDown from "../components/ButtonUpDown";

import { Form, Row, Col, Select, Tag, Space, Button, Input } from "antd";
const { Option } = Select;
const { CheckableTag } = Tag;
const { TextArea } = Input;

// Default
let firstSegment = 0;
let lastSegment = 9;
let aExamSegments = aSpine.filter(
  (item) => item.value >= firstSegment && item.value <= lastSegment
);
let aExamVertebrae = aVertebrae.filter(
  (item) => item.value >= firstSegment && item.value <= lastSegment
);

class MR_spine_C extends React.Component {
  state = {
    report: {
      exam: this.props.exam,
      protocol: this.props.protocol,
      contrast: this.props.contrast,
    },
    general: {
      firstSegment: 0,
      lastSegment: 9,
      spineCor: 0,
      spineSag: 0,
      hemangiomas: [],
      schmorls: [],
      spineCustomDescription: "",
      spineCustomConclusion: "",
      addFinds: [],
      ADArthrosis: 0,
      valueNum: 0,
      valueText: "",
      valueBoolean: 0,
    },
    segments: aExamSegments.map((item) => {
      return {
        name: item.text,
        listesis: 0,
        listesisSize: 0,
        diskDegen: 0,
        Modic: 0,
        diskPat: 0,
        diskDirection: [],
        diskPatSize: 0,
        diskMigration: 0,
        diskMigrationSize: 0,
        yellowLigament: 0,
        foramenR: 0,
        foramenL: 0,
        facetArthrosisR: 0,
        facetArthrosisL: 0,
        uncoArthrosisR: 0,
        uncoArthrosisL: 0,
        spinalCanal: 0,
        active: false,
      };
    }),
    compressions: [],
    key: 0,
  };

  initialState = this.state;

  resetExamState = () => {
    this.initialState.key += 1;
    this.setState(this.initialState);
    console.log("Hello C spine");
  };

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState(
      {
        [segment]: {
          ...this.state[segment],
          [name]: value,
        },
      },
      () =>
        this.props.askReport(
          generateReport(this.state, this.props.protocol, this.props.contrast)
        )
    );
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  newRow = (value) => {
    let newRecord = {
      vertebra: value,
      endplate: 0,
      area: 0,
      compression: 0,
      edema: 0,
      prominence: 0,
    };

    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );

    let array = [...this.state.compressions];
    array.push(newRecord);
    array.sort((a, b) => a.vertebra - b.vertebra);

    this.setState({ compressions: array }, () => this.props.askReport(result));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];
    array.sort((a, b) => a.vertebra - b.vertebra);

    array[index][name] = value;

    this.setState({ [segment]: array }, () =>
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      )
    );
  };

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  handleTags = (item, checked, index) => {
    let array = [...this.state.segments];
    let selectedTags = array[index].diskDirection;
    if (selectedTags.indexOf(item) === -1) {
      selectedTags.push(item);
    } else {
      selectedTags.splice(selectedTags.indexOf(item), 1);
    }
    selectedTags.sort(function (a, b) {
      return a.value - b.value;
    });
    array[index].diskDirection = selectedTags;
    this.setState({ segments: array }, () =>
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      )
    );
  };

  render() {
    const initialState = Object.assign({}, this.state);
    let showListesisSize =
      this.state.segments.filter((item) => item.listesis > 0).length > 0
        ? true
        : false;
    let showDiskPatSize =
      this.state.segments.filter(
        (item) =>
          item.diskPat === 1 ||
          item.diskPat === 2 ||
          item.diskPat === 3 ||
          item.diskPat === 4 ||
          item.diskPat === 5
      ).length > 0
        ? true
        : false;

    let showDiskDirection =
      this.state.segments.filter(
        (item) =>
          (item.diskPat === 1 ||
            item.diskPat === 2 ||
            item.diskPat === 3 ||
            item.diskPat === 5 ||
            item.diskPat === 6) &&
          (item.diskPatSize > 0 || item.diskPat === 6)
      ).length > 0
        ? true
        : false;

    let showDiskMigration =
      this.state.segments.filter(
        (item) =>
          item.diskPat === 2 &&
          item.diskPatSize > 0 &&
          item.diskDirection.length > 0
      ).length > 0
        ? true
        : false;

    let showDiskMigrationSize =
      this.state.segments.filter(
        (item) =>
          item.diskPat === 2 &&
          item.diskPatSize > 0 &&
          item.diskDirection.length > 0 &&
          item.diskMigration > 0
      ).length > 0
        ? true
        : false;

    const labelEmpty = 65;
    const labelListesis = 95;
    const labelListesisSize = 65;

    const labelDiskDegen = 77;
    const labelModic = 77;

    const labelDiskPat = 100;
    const labelDiskPatSize = 65;
    const labelDiskDirection = 113;
    const labelDiskMigration = 78;
    const labelDiskMigrationSize = 65;

    const labelFacetArthrosis = 61;
    const labelUncoArthrosis = 61;
    const labelYellowLigament = 50;

    const labelStenosis = 34;

    const spineSegments = [
      {
        title: "segmenty C páteře",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {
                    <Row>
                      <Col flex={labelEmpty + "px"}></Col>
                      <Col
                        flex={
                          showListesisSize
                            ? labelListesis + labelListesisSize + "px"
                            : labelListesis + "px"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          posun obratle
                        </Tag>
                      </Col>
                      <Col
                        flex={labelDiskDegen + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          degen. disk
                        </Tag>
                      </Col>
                      <Col
                        flex={labelModic + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          Modic
                        </Tag>
                      </Col>
                      <Col
                        flex={
                          labelDiskPat +
                          (showDiskPatSize ? labelDiskPatSize : 0) +
                          (showDiskDirection ? labelDiskDirection : 0) +
                          (showDiskMigration ? labelDiskMigration : 0) +
                          (showDiskMigrationSize ? labelDiskMigrationSize : 0) +
                          "px"
                        }
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          segment pat.
                        </Tag>
                      </Col>

                      <Col
                        flex={labelFacetArthrosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          FA P
                        </Tag>
                      </Col>
                      <Col
                        flex={labelUncoArthrosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          UA P
                        </Tag>
                      </Col>
                      <Col
                        flex={labelYellowLigament + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          HŽV
                        </Tag>
                      </Col>
                      <Col
                        flex={labelUncoArthrosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          UA L
                        </Tag>
                      </Col>
                      <Col
                        flex={labelFacetArthrosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          FA L
                        </Tag>
                      </Col>

                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PF
                        </Tag>
                      </Col>

                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          PK
                        </Tag>
                      </Col>

                      <Col
                        flex={labelStenosis + "px"}
                        style={{ textAlign: "center" }}
                      >
                        <Tag
                          style={{
                            width: "100%",
                            margin: 0,
                            backgroundColor: aCardColors.blue.header,
                          }}
                        >
                          LF
                        </Tag>
                      </Col>
                    </Row>
                  }
                  {this.state.segments.map((item, index) => {
                    return (
                      <Row style={{ marginBottom: "1px" }}>
                        <Col
                          flex={labelEmpty + "px"}
                          style={{ textAlign: "right", paddingRight: "5px" }}
                        >
                          <Tag
                            style={{
                              width: "100%",
                              margin: 0,
                              backgroundColor: aCardColors.blue.header,
                            }}
                          >
                            {item.name}
                          </Tag>
                        </Col>
                        <Col flex={labelListesis + "px"}>
                          <Select
                            key={"listesis" + index + this.state.key}
                            value={item.listesis}
                            defaultValue={item.listesis}
                            size="small"
                            showArrow={false}
                            style={{
                              width: "100%",
                            }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "segments",
                                "listesis",
                                value
                              )
                            }
                          >
                            {aListesis.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>
                        {showListesisSize ? (
                          <Col flex={labelListesisSize + "px"}>
                            {item.listesis > 0 ? (
                              <ButtonUpDownRow
                                key={"listesisSize" + index + this.state.key}
                                index={index}
                                name="listesisSize"
                                segment="segments"
                                value={item.listesisSize}
                                options={aSize}
                                updateRow={this.updateRow}
                                isDisabled={item.listesis === 0 ? true : false}
                              />
                            ) : (
                              ""
                            )}
                          </Col>
                        ) : (
                          ""
                        )}
                        <Col flex={labelDiskDegen + "px"}>
                          <ButtonUpDownRow
                            key={"diskDegen" + index + this.state.key}
                            index={index}
                            name="diskDegen"
                            segment="segments"
                            value={item.diskDegen}
                            options={aDiskDegen}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelModic + "px"}>
                          <ButtonUpDownRow
                            key={"Modic" + index + this.state.key}
                            index={index}
                            name="Modic"
                            segment="segments"
                            value={item.Modic}
                            options={aModic}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelDiskPat + "px"}>
                          <Select
                            key={"diskPat" + index + this.state.key}
                            value={item.diskPat}
                            defaultValue={item.diskPat}
                            name="diskPat"
                            size="small"
                            showArrow={false}
                            style={{ width: "100%" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "segments",
                                "diskPat",
                                value
                              )
                            }
                          >
                            {aDiskPat.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                        </Col>

                        {showDiskPatSize ? (
                          <Col flex={labelDiskPatSize + "px"}>
                            {item.diskPat !== 0 && item.diskPat !== 6 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="diskPatSize"
                                segment="segments"
                                value={item.diskPatSize}
                                options={aSize}
                                style={{ width: "100%" }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskPat === 0 || item.diskPat === 6
                                    ? true
                                    : false
                                }
                              />
                            ) : null}
                          </Col>
                        ) : null}
                        {showDiskDirection ? (
                          <Col flex={labelDiskDirection + "px"}>
                            <Row>
                              {item.diskPat === 0 || item.diskPat === 4
                                ? null
                                : aDiskDirection.map((item) => (
                                    <Col>
                                      <CheckableTag
                                        key={item.value}
                                        checked={
                                          this.state.segments[
                                            index
                                          ].diskDirection.indexOf(item) > -1
                                        }
                                        onChange={(checked) =>
                                          this.handleTags(item, checked, index)
                                        }
                                        style={{ margin: 0 }}
                                      >
                                        {item.text}
                                      </CheckableTag>
                                    </Col>
                                  ))}
                            </Row>
                          </Col>
                        ) : (
                          ""
                        )}
                        {showDiskMigration ? (
                          <Col flex={labelDiskMigration + "px"}>
                            {item.diskPat > 0 &&
                            item.diskDirection.length > 0 ? (
                              <Select
                                key={index}
                                value={item.diskMigration}
                                defaultValue={item.diskMigration}
                                size="small"
                                showArrow={false}
                                style={{ width: "100%" }}
                                onChange={(value) =>
                                  this.updateRow(
                                    index,
                                    "segments",
                                    "diskMigration",
                                    value
                                  )
                                }
                                disabled={
                                  item.diskPat === 1 ||
                                  item.diskPat === 2 ||
                                  item.diskPat === 3
                                    ? false
                                    : true
                                }
                              >
                                {aDiskMigration.map((item) => {
                                  return (
                                    <Option key={item.value} value={item.value}>
                                      {item.text}
                                    </Option>
                                  );
                                })}
                              </Select>
                            ) : null}
                          </Col>
                        ) : (
                          ""
                        )}
                        {showDiskMigrationSize ? (
                          <Col flex={labelDiskMigrationSize + "px"}>
                            {item.diskMigration > 0 && item.diskPat > 0 ? (
                              <ButtonUpDownRow
                                index={index}
                                name="diskMigrationSize"
                                segment="segments"
                                value={item.diskMigrationSize}
                                options={aSize}
                                style={{ width: "100%" }}
                                updateRow={this.updateRow}
                                isDisabled={
                                  item.diskMigration > 0 ? false : true
                                }
                              />
                            ) : null}
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col flex={labelFacetArthrosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="facetArthrosisR"
                            segment="segments"
                            value={item.facetArthrosisR}
                            options={aFacetArthrosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelUncoArthrosis + "px"}>
                          {index > 0 && index < 6 ? (
                            <ButtonUpDownRow
                              index={index}
                              name="uncoArthrosisR"
                              segment="segments"
                              value={item.uncoArthrosisR}
                              options={aUncoArthrosis}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                          ) : null}
                        </Col>
                        <Col flex={labelYellowLigament + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="yellowLigament"
                            segment="segments"
                            value={item.yellowLigament}
                            options={aTrueFalse}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelUncoArthrosis + "px"}>
                          {index > 0 && index < 6 ? (
                            <ButtonUpDownRow
                              index={index}
                              name="uncoArthrosisL"
                              segment="segments"
                              value={item.uncoArthrosisL}
                              options={aUncoArthrosis}
                              style={{ width: "100%" }}
                              updateRow={this.updateRow}
                            />
                          ) : null}
                        </Col>
                        <Col flex={labelFacetArthrosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="facetArthrosisL"
                            segment="segments"
                            value={item.facetArthrosisL}
                            options={aFacetArthrosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>

                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="foramenR"
                            segment="segments"
                            value={item.foramenR}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="spinalCanal"
                            segment="segments"
                            value={item.spinalCanal}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>

                        <Col flex={labelStenosis + "px"}>
                          <ButtonUpDownRow
                            index={index}
                            name="foramenL"
                            segment="segments"
                            value={item.foramenL}
                            options={aStenosis}
                            style={{ width: "100%" }}
                            updateRow={this.updateRow}
                          />
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    const myContent1 = [
      {
        title: "páteř obecně",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "C páteř",
              input: (
                <DropDown
                  name="spineCor"
                  segment="general"
                  defaultValue={this.state.general.spineCor}
                  options={aSpineCor}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "lordóza",
              input: (
                <DropDown
                  name="spineSag"
                  segment="general"
                  defaultValue={this.state.general.spineSag}
                  options={aSpineSag}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "hemangiomy",
              input: (
                <DropDown
                  name="hemangiomas"
                  segment="general"
                  defaultValue={this.state.general.hemangiomas}
                  options={aVertebrae.filter(
                    (item) => item.value >= 0 && item.value <= 9
                  )}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "Schmorl. uzly",
              input: (
                <DropDown
                  name="schmorls"
                  segment="general"
                  defaultValue={this.state.general.schmorls}
                  options={aExamSegments}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "vedlejší nálezy",
              input: (
                <DropDown
                  name="addFinds"
                  segment="general"
                  defaultValue={this.state.general.addFinds}
                  options={aAddFinds}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "páteř - vlastní záznam",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "popis",
              input: (
                <TextArea
                  size="small"
                  name="spineCustomDescription"
                  segment="general"
                  defaultValue={this.state.general.spineCustomDescription}
                  placeholder="..."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "spineCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "závěr",
              input: (
                <TextArea
                  size="small"
                  name="spineCustomConclusion"
                  segment="general"
                  defaultValue={this.state.general.spineCustomConclusion}
                  placeholder="..."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "spineCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    const compressions = [
      {
        title: "páteř - komprese obratlů",
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {this.state.compressions.map((item, index) => {
                    return (
                      <Col
                        span={24}
                        style={{ marginBottom: "10px" }}
                        key={index}
                      >
                        <Space size="0">
                          {index + 1 + ") "}
                          <Select
                            key={index}
                            value={item.vertebra}
                            size="small"
                            style={{ width: "70px" }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "compressions",
                                "vertebra",
                                value
                              )
                            }
                          >
                            {aExamVertebrae.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          {" - "}
                          <Select
                            key={index}
                            value={item.area}
                            defaultValue={item.area}
                            size="small"
                            style={{ width: "138px" }}
                            bordered={false}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "compressions",
                                "area",
                                value
                              )
                            }
                          >
                            {aFractureSite.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          <Select
                            key={index}
                            value={item.compression}
                            defaultValue={item.compression}
                            size="small"
                            style={{ width: "120px" }}
                            bordered={false}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "compressions",
                                "compression",
                                value
                              )
                            }
                          >
                            {aFractureDepth.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          <Select
                            key={index}
                            value={item.endplate}
                            defaultValue={item.endplate}
                            size="small"
                            style={{ width: "230px" }}
                            bordered={false}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "compressions",
                                "endplate",
                                value
                              )
                            }
                          >
                            {aFractureEndplate.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          <Select
                            key={index}
                            value={item.edema}
                            defaultValue={item.edema}
                            size="small"
                            style={{ width: "110px" }}
                            bordered={false}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "compressions",
                                "edema",
                                value
                              )
                            }
                          >
                            {aFractureEdema.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          <Select
                            key={index}
                            value={item.prominence}
                            defaultValue={item.prominence}
                            size="small"
                            style={{ width: "200px" }}
                            bordered={false}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "compressions",
                                "prominence",
                                value
                              )
                            }
                          >
                            {aFractureProminence.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          <Button
                            type="primary"
                            size="small"
                            danger
                            onClick={() =>
                              this.deleteRow(index, "compressions")
                            }
                          >
                            x
                          </Button>
                        </Space>
                      </Col>
                    );
                  })}
                </>
              ),
            },
          ],
          [
            {
              label: "",
              input: (
                <Row>
                  <Col span={4}>
                    <Select
                      value="nový nález"
                      size="small"
                      onChange={(value) => {
                        this.newRow(value);
                      }}
                    >
                      {aExamVertebrae.map((item) => (
                        <Option value={item.value}>{item.text}</Option>
                      ))}
                    </Select>
                  </Col>
                  <Col flex="auto"></Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <Row align="top">
          <Col flex="auto">
            <CustomCards content={spineSegments} />
            <CustomCards content={myContent1} />
            <CustomCards content={compressions} />
          </Col>
        </Row>
      </Form>
    );
  }
}

export default MR_spine_C;
