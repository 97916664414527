import { green, yellow, orange, red, neutral } from "../scripts/GeneralArrays";

// Custom

export const aSelect = [
  {
    value: 0,
    text: "select 1",
    description1: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "select 2",
    description1: "",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "select 3",
    description1: "",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "select 4",
    description1: "",
    conclusion: "",
    color: red,
  },
];

export const aLigamentTear = [
  {
    value: 0,
    text: "bez poranění",
    description1: " je na PDFS normální šíře a nízké SI. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "low grade parciální ruptura",
    description1: " je na PDFS normální šíře a nízké SI",
    conclusion: "low grade parciální ruptura lig. talofibulare ant. ",
    color: yellow,
  },
  {
    value: 2,
    text: "high grade parciální ruptura",
    description1: " je na PDFS nehomogenně širší a vyšší SI",
    conclusion: "high grade parciální ruptura lig. talofibulare ant. ",
    color: orange,
  },
  {
    value: 3,
    text: "kompletní ruptura",
    description1: " je na PDFS kompletně přerušeno",
    conclusion: "kompletní ruptura lig. talofibulare ant. ",
    color: red,
  },
];

export const aLigamentAge = [
  {
    value: 0,
    text: "akutní",
    description1: ", s lemem vyšší SI okolního tuku. ",
    conclusion: "Akutní ",
    color: red,
  },
  {
    value: 1,
    text: "chronická",
    description1: ", bez přesvědčivého zvýšení SI okolního tuku. ",
    conclusion: "Chronická ",
    color: yellow,
  },
];

export const aTCdegen = [
  {
    value: 0,
    text: "bez artrózy",
    description1:
      "Přiměřená šíře chrupavky talokrurálního kloubu, bez osteofytů kloubních ploch. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehká artróza",
    description1:
      "Lehká redukce šíře chrupavky talokrurálního kloubu, ale bez osteofytů kloubních ploch. ",
    conclusion: "Lehká artróza talokrurálního kloubu. ",
    color: yellow,
  },
  {
    value: 2,
    text: "středně pokročilá artróza",
    description1:
      "Redukce šíře chrupavky talokrurálního kloubu, s nevelkými osteofyty okrajů kloubních ploch. ",
    conclusion: "Středně pokročilá artróza talokrurálního kloubu. ",
    color: orange,
  },
  {
    value: 3,
    text: "těžká artróza",
    description1:
      "Výrazná redukce šíře chrupavky talokrurálního kloubu, s nápadnými osteofyty okrajů kloubních ploch. ",
    conclusion: "Těžká artróza talokrurálního kloubu. ",
    color: red,
  },
];

export const aTCfluid = [
  {
    value: 0,
    text: "bez náplně",
    description1: "Normální náplň talokrurálního kloubu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehce zvýšená",
    description1: "Lehce zvýšená náplň talokrurálního kloubu. ",
    conclusion: "Lehce zvýšená náplň talokrurálního kloubu. ",
    color: yellow,
  },
  {
    value: 2,
    text: "středně zvýšená",
    description1: "Výrazněji zvýšená náplň talokrurálního kloubu. ",
    conclusion: "Výrazněji zvýšená náplň talokrurálního kloubu. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvýšená",
    description1: "Velmi výrazně zvýšená náplň talokrurálního kloubu. ",
    conclusion: "Velmi výrazně zvýšená náplň talokrurálního kloubu. ",
    color: red,
  },
];

export const aOCDgrade = [
  {
    value: 0,
    text: "bez osteochondrálního defektu",
    description1: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "stage I",
    description1:
      "Na PDFS okrsek zvýšení SI subchondrální kostní dřeně mediální části kloubní plochy trochlea tali, na T1 bez patrné linie nízké SI kostní dřeně z podminování",
    conclusion:
      "Susp. počínající osteochondrální defekt mediální části trochlea tali - OCD stage I",
    color: yellow,
  },
  {
    value: 2,
    text: "stage II",
    description1:
      "Na PDFS okrsek zvýšení SI subchondrální kostní dřeně mediální části kloubní plochy trochlea tali, na T1 inkompletní linie nízké SI kostní dřeně z podminování, bez dislokace fragmentu",
    conclusion:
      "Inkompletní osteochondrální defekt mediální části trochlea tali - OCD stage II",
    color: orange,
  },
  {
    value: 3,
    text: "stage III",
    description1:
      "Na PDFS okrsek zvýšení SI subchondrální kostní dřeně mediální části kloubní plochy trochlea tali, na T1 linie kompletně lemující fragment subchondrální kosti, ale bez jeho dislokace",
    conclusion:
      "Osteochondrální defekt mediální části trochlea tali - OCD stage III",
    color: orange,
  },
  {
    value: 3,
    text: "stage IV",
    description1:
      "Na PDFS okrsek zvýšení SI subchondrální kostní dřeně mediální části kloubní plochy trochlea tali, na T1 linie kompletně lemující fragment subchondrální kosti, který je dislokován",
    conclusion:
      "Osteochondrální defekt mediální části trochlea tali - OCD stage IV",
    color: red,
  },
];

export const aATtendinosis = [
  {
    value: 0,
    text: "bez tendinózy",
    description1: "přiměřené SI",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehká",
    description1: "lehce vyšší SI",
    conclusion: "lehká tendinóza",
    color: yellow,
  },
  {
    value: 2,
    text: "středně těžká",
    description1: "vyšší SI",
    conclusion: "středně pokročilá tendinóza",
    color: orange,
  },
  {
    value: 3,
    text: "těžká",
    description1: "výrazně vyšší SI",
    conclusion: "pokročilá tendinóza",
    color: red,
  },
];

export const aATperitendonitis = [
  {
    value: 0,
    text: "bez peritendonitidy",
    description1: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehce vyšší",
    description1: ", s lehce vyšší SI okolního tuku. ",
    conclusion: "lehká peritendonitida",
    color: yellow,
  },
  {
    value: 2,
    text: "vyšší",
    description1: ", s vyšší SI okolního tuku. ",
    conclusion: "středně pokročilá peritendonitida",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně vyšší",
    description1: ", s výrazně vyšší SI okolního tuku. ",
    conclusion: "pokročilá peritendonitida",
    color: red,
  },
];
