import { green, yellow, orange, red } from "../scripts/GeneralArrays";

export const aSynovialFluidHS = [
  {
    value: 0,
    text: "normální",
    description: "Přiměřená náplň ramenního kloubu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvýšená",
    description: "Mírně zvýšená náplň ramenního kloubu. ",
    conclusion: "Mírně zvýšená náplň ramenního kloubu. ",
    color: yellow,
  },
  {
    value: 2,
    text: "zvýšená",
    description: "Zvýšená náplň ramenního kloubu. ",
    conclusion: "Zvýšená náplň ramenního kloubu. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvýšená",
    description: "Výrazně zvýšená náplň ramenního kloubu. ",
    conclusion: "Výrazně zvýšená náplň ramenního kloubu. ",
    color: red,
  },
];

export const aSynovialFluidAC = [
  {
    value: 0,
    text: "normální",
    description: "Přiměřená náplň AC kloubu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvýšená",
    description: "Mírně zvýšená náplň AC kloubu. ",
    conclusion: "Mírně zvýšená náplň AC kloubu. ",
    color: yellow,
  },
  {
    value: 2,
    text: "zvýšená",
    description: "Zvýšená náplň AC kloubu. ",
    conclusion: "Zvýšená náplň AC kloubu. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvýšená",
    description: "Výrazně zvýšená náplň AC kloubu. ",
    conclusion: "Výrazně zvýšená náplň AC kloubu. ",
    color: red,
  },
];

export const aArthrosisAC = [
  {
    value: 0,
    text: "bez artrózy",
    description: "Bez degenerativních změn akromioklavikulárního kloubu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná artróza",
    description: "Mírné snížení vrstvy chrupavky akromioklavikulárního kloubu",
    conclusion: "Mírná artróza AC kloubu",
    color: yellow,
  },
  {
    value: 2,
    text: "artróza",
    description:
      "Snížení vrstvy chrupavky akromioklavikulárního kloubu s přihrocením okrajů kloubních ploch",
    conclusion: "Středně pokročilá artróza AC kloubu",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná artróza",
    description:
      "Snížení vrstvy chrupavky akromioklavikulárního kloubu s výraznými okrajovými osteofyty",
    conclusion: "Pokročilá artróza AC kloubu",
    color: red,
  },
];

export const aArthrosisHS = [
  {
    value: 0,
    text: "bez artrózy",
    description: "Bez degenerativních změn humeroskapulárního kloubu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná artróza",
    description: "Mírné snížení vrstvy chrupavky humeroskapulárního kloubu. ",
    conclusion: "Mírná omartróza. ",
    color: yellow,
  },
  {
    value: 2,
    text: "artróza",
    description:
      "Snížení vrstvy chrupavky humeroskapulárního kloubu s přihrocením okrajů kloubních ploch. ",
    conclusion: "Středně pokročilá omartróza. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná artróza",
    description:
      "Snížení vrstvy chrupavky humeroskapulárního kloubu s výraznými okrajovými osteofyty. ",
    conclusion: "Pokročilá omartróza. ",
    color: red,
  },
];

export const aSubchondralEdema = [
  {
    value: 0,
    text: "ne",
    description: ". ",
    conclusion: ". ",
    color: "green",
  },
  {
    value: 1,
    text: "ano",
    description: ", na PDFS vyšší SI subchondrální kostní dřeně AC kloubu. ",
    conclusion: ", s akutní dekompenzací degenerativních změn. ",
    color: "red",
  },
];

export const aAcromion = [
  {
    value: 0,
    text: "typ 1 - rovný",
    description: "Akromion rovné dolní kontury - typ 1. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "typ 2 - konkávní",
    description: "Akromion konkávní dolní kontury - typ 2. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "typ 3 - ventrálně zahnutý",
    description:
      "Akromion s kaudálním hákovitým ohnutím ventrálního okraje - typ 3. ",
    conclusion:
      "Hákovitý tvar akromia (typ 3), spojený s vyšším rizikem impingementu. ",
    color: orange,
  },
  {
    value: 3,
    text: "typ 4 - konvexní",
    description: "Akromion konvexní dolní kontury - typ 4. ",
    conclusion: "",
    color: yellow,
  },
];

export const aBursa = [
  {
    value: 0,
    text: "normální",
    description: "Normální náplň subakromiální-subdeltoidní burzy. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná náplň",
    description: "Mírně zvýšená náplň subakromiální-subdeltoidní burzy. ",
    conclusion: "Obraz mírné subakromiální-subdeltoidní bursitidy. ",
    color: yellow,
  },
  {
    value: 2,
    text: "výrazná náplň",
    description: "Výrazně zvýšená náplň subakromiální-subdeltoidní burzy. ",
    conclusion: "Obraz subakromiální-subdeltoidní bursitidy. ",
    color: red,
  },
];

export const aLabrum = [
  {
    value: 0,
    text: "normální",
    description:
      "Při pouze minimální náplni humeroskapulárního kloubu je labrum pouze omezeně hodnotitelné, bez přesvědčivých defektů. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "SLAP",
    description:
      "Na PDFS defekt SI tekutiny v horní části labra při čísle 11-1. ",
    conclusion: "Podezření na SLAP lézi labra. ",
    color: red,
  },
  {
    value: 2,
    text: "Bankart",
    description:
      "Na PDFS defekt SI tekutiny ventrokaudální části labra při čísle 4-5. ",
    conclusion: "Podezření na Bankart lézi labra. ",
    color: red,
  },
  {
    value: 3,
    text: "Sublabrální foramen",
    description:
      "Na PDFS defekt SI tekutiny ventrokraniální části labra, který ale nezasahuje dorzálně za úpon šlachy dlouhé hlavy m. biceps brachii a kaudálně nepřekračuje přes úroveň čísla 3 (susp. varietní sublabrální foramen). ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 4,
    text: "Bufordův komplex",
    description:
      "Chybí ventrokraniální část labra, sekundárně je zesílené střední glenohumerální ligamentum (Bufordův komplex). ",
    conclusion: "",
    color: yellow,
  },
];

export const aRotatorCuff = [
  {
    value: 0,
    text: "supraspinatus",
    description: "šlachy m. supraspinatus",
    conclusion: "šlachy m. supraspinatus",
    color: green,
  },
  {
    value: 1,
    text: "infraspinatus",
    description: "šlachy m. infraspinatus",
    conclusion: "šlachy m. infraspinatus",
    color: green,
  },
  {
    value: 2,
    text: "subscapularis",
    description: "šlachy m. subscapularis",
    conclusion: "šlachy m. subscapularis",
    color: green,
  },
  {
    value: 3,
    text: "teres minor",
    description: "šlachy m. teres minor",
    conclusion: "šlachy m. teres minor",
    color: green,
  },
];

export const aPatType = [
  {
    value: 0,
    text: "tendinóza",
    description: "Na PDFS a T2FS zvýšení SI ",
    conclusion: "Tendinóza ",
  },
  {
    value: 1,
    text: "ruptura < 1/2 šíře",
    description: "Defekt signálu tekutiny méně než poloviny šíře ",
    conclusion: "Low grade parciální ruptura ",
  },
  {
    value: 2,
    text: "ruptura > 1/2 šíře",
    description: "Defekt signálu tekutiny více než poloviny šíře ",
    conclusion: "High grade parciální ruptura ",
  },
  {
    value: 3,
    text: "ruptura v celé šíři části šlachy",
    description: "Defekt signálu tekutiny celé šíře ",
    conclusion: "Ruptura celé šíře ",
  },
  {
    value: 4,
    text: "ruptura celé šlachy",
    description: "Defekt signálu tekutiny celé šíře ",
    conclusion: "Kompletní ruptura ",
  },
];

export const aPatSide = [
  {
    value: 0,
    text: "artikulační",
    description: " z její artikulační plochy",
    conclusion: " z její artikulační plochy",
  },
  {
    value: 1,
    text: "burzální",
    description: " z její burzální plochy",
    conclusion: " z její burzální plochy",
  },
  {
    value: 2,
    text: "intersticiální",
    description: " v její intersticiální části",
    conclusion: " v její intersticiální části",
  },
];

export const aPatSiteHorizontal = [
  {
    value: 0,
    text: "ventrální",
    description: "ventrální",
    conclusion: "ventrální",
  },
  {
    value: 1,
    text: "střední",
    description: "střední",
    conclusion: "střední",
  },
  {
    value: 2,
    text: "dorzální",
    description: "dorzální",
    conclusion: "dorzální",
  },
];

export const aPatSiteVertical = [
  {
    value: 0,
    text: "horní",
    description: "horní",
    conclusion: "horní",
  },
  {
    value: 1,
    text: "střední",
    description: "střední",
    conclusion: "střední",
  },
  {
    value: 2,
    text: "dolní",
    description: "dolní",
    conclusion: "dolní",
  },
];

export const patShape = [
  {
    value: 0,
    text: "normální nález",
  },
  {
    value: 1,
    text: "tvar srpku měsíce",
  },
  {
    value: 2,
    text: "tvar 'L'",
  },
  {
    value: 3,
    text: "tvar obráceného 'L'",
  },
  {
    value: 4,
    text: "trapezoidní",
  },
  {
    value: 5,
    text: "masivní",
  },
];

export const aBicepsTendonDislocation = [
  {
    value: 0,
    text: "bez dislokace",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "med. subluxace",
    description: "Mediální subluxace šlachy dlouhé hlavy m. biceps brachii. ",
    conclusion: "Mediální subluxace šlachy dlouhé hlavy m. biceps brachii. ",
    color: orange,
  },
  {
    value: 2,
    text: "med. luxace",
    description: "Mediální luxace šlachy dlouhé hlavy m. biceps brachii. ",
    conclusion: "Mediální luxace šlachy dlouhé hlavy m. biceps brachii. ",
    color: red,
  },
];

export const aBicepsTendonRupture = [
  {
    value: 0,
    text: "bez ruptury",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "low grade ruptura",
    description:
      "Defekt šlachy intraartikulární porce dlouhé hlavy m. biceps brachii ztenčující šlachu o méně než 50% původní šíře. ",
    conclusion:
      "Low grade parciální ruptura intraartikulární porce šlachy dlouhé hlavy m. biceps brachii. ",
    color: yellow,
  },
  {
    value: 2,
    text: "high grade ruptura",
    description:
      "Defekt šlachy intraartikulární porce dlouhé hlavy m. biceps brachii ztenčující šlachu o více než 50% původní šíře. ",
    conclusion:
      "High grade parciální ruptura intraartikulární porce šlachy dlouhé hlavy m. biceps brachii. ",
    color: orange,
  },
  {
    value: 3,
    text: "kompletní ruptura",
    description:
      "Defekt celé šlachy intraartikulární porce dlouhé hlavy m. biceps brachii. ",
    conclusion:
      "Kompletní ruptura intraartikulární porce šlachy dlouhé hlavy m. biceps brachii. ",
    color: red,
  },
];

export const aBicepsTendinosis = [
  {
    value: 0,
    text: "bez tendinózy",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehká tendinóza",
    description:
      "Na T2FS vyšší SI intraartikulární porce šlachy dlouhé hlavy m. biceps brachii, bez jejího výraznějšího rozšíření. ",
    conclusion: "Lehká tendinóza dlouhé hlavy m. biceps brachii. ",
    color: orange,
  },
  {
    value: 2,
    text: "tendinóza",
    description:
      "Na T2FS vyšší SI a vřetenovité rozšíření intraartikulární porce šlachy dlouhé hlavy m. biceps brachii. ",
    conclusion: "Tendinóza dlouhé hlavy m. biceps brachii. ",
    color: red,
  },
];

export const aMuscleAtrophy = [
  {
    value: 0,
    text: "bez atrofie",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehká atrofie",
    description:
      "Na T2FS vyšší SI intraartikulární porce šlachy dlouhé hlavy m. biceps brachii, bez jejího výraznějšího rozšíření. ",
    conclusion: "Lehká tendinóza dlouhé hlavy m. biceps brachii. ",
    color: yellow,
  },
  {
    value: 2,
    text: "středně těžká atrofie",
    description:
      "Na T2FS vyšší SI a vřetenovité rozšíření intraartikulární porce šlachy dlouhé hlavy m. biceps brachii. ",
    conclusion: "Tendinóza dlouhé hlavy m. biceps brachii. ",
    color: orange,
  },
  {
    value: 3,
    text: "těžká atrofie",
    description:
      "Na T2FS vyšší SI a vřetenovité rozšíření intraartikulární porce šlachy dlouhé hlavy m. biceps brachii. ",
    conclusion: "Tendinóza dlouhé hlavy m. biceps brachii. ",
    color: red,
  },
];
