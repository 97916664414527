// Custom scripts
import {} from "./MR_hip_arrays";

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  contentWidth,
  aSizemm,
  aSizecm,
} from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //

  const {} = props.general;

  importDescription1(
    "Postavení v " +
      (protocol === 0
        ? "pravém kyčelním kloubu"
        : protocol === 1
        ? "levém kyčelním kloubu"
        : "obou kyčelních kloubech") +
      " není porušeno, bez zmnožení nitrokloubní tekutiny. Kostní dřeň bez infiltrativního či ložiskového zvýšení SI na STIR. Bez strukturálních či signálových změn okolních měkkých tkáních. V zobrazeném rozsahu pánve bez volné peritoneální tekutiny. V tříslech bez zvětšených lymfatických uzlin. "
  );
  importConclusion1(
    "Přiměřený nález na MR " +
      (protocol === 0
        ? "pravého kyčelního kloubu. "
        : protocol === 1
        ? "levého kyčelního kloubu. "
        : "obou kyčelních kloubů. ")
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
