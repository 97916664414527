import { green, yellow, orange, red, grey } from "../scripts/GeneralArrays";

export const aSpineCor = [
  {
    value: 0,
    text: "v ose",
    description: "Hrudní páteř v ose. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "mírná dextroskolióza",
    description: "Mírné pravostranné vybočení Th páteře. ",
    conclusion: "Mírná dextroskolióza Th páteře. ",
  },
  {
    value: 2,
    text: "mírná sinistroskolióza",
    description: "Mírné levostranné vybočení Th páteře. ",
    conclusion: "Mírná sinistroskolióza Th páteře. ",
  },
  {
    value: 3,
    text: "dextroskolióza",
    description: "Pravostranné vybočení Th páteře. ",
    conclusion: "Dextroskolióza Th páteře. ",
  },
  {
    value: 4,
    text: "sinistroskolióza",
    description: "Levostranné vybočení Th páteře. ",
    conclusion: "Sinistroskolióza Th páteře. ",
  },
  {
    value: 5,
    text: "výrazná dextroskolióza",
    description: "Výrazné pravostranné vybočení Th páteře. ",
    conclusion: "Výrazná dextroskolióza Th páteře. ",
  },
  {
    value: 6,
    text: "výrazná sinistroskolióza",
    description: "Výrazné levostranné vybočení Th páteře. ",
    conclusion: "Výrazná sinistroskolióza Th páteře. ",
  },
];

export const aSpineSag = [
  {
    value: 0,
    text: "přiměřená",
    description: "Plynulá hrudní kyfóza. ",
    conclusion: "",
  },
  {
    value: 1,
    text: "plošší",
    description: "Plošší hrudní kyfóza. ",
    conclusion: "Plošší hrudní kyfóza. ",
  },
  {
    value: 2,
    text: "vyrovnaná",
    description: "Vyrovnaná hrudní kyfóza. ",
    conclusion: "Vyrovnaná hrudní kyfóza. ",
  },
  {
    value: 3,
    text: "paradoxní lordotizace",
    description: "Paradoxní lordotizace hrudní kyfózy. ",
    conclusion: "Paradoxní lordotizace hrudní kyfózy. ",
  },
];

export const aSegments = [
  { value: 0, text: "Th10/11" },
  { value: 1, text: "Th11/12" },
  { value: 2, text: "Th12/L1" },
  { value: 3, text: "L1/2" },
  { value: 4, text: "L2/3" },
  { value: 5, text: "L3/4" },
  { value: 6, text: "L4/5" },
  { value: 7, text: "L5/S1" },
];

export const aVertebrae = [
  { value: 0, text: "Th10" },
  { value: 1, text: "Th11" },
  { value: 2, text: "Th12" },
  { value: 3, text: "L1" },
  { value: 4, text: "L2" },
  { value: 5, text: "L3" },
  { value: 6, text: "L4" },
  { value: 7, text: "L5" },
  { value: 8, text: "S1" },
  { value: 9, text: "S2" },
];

export const aAddFinds = [
  {
    value: 0,
    text: "divertikly sigmatu",
    description: "Výchlipky stěny sigmatu. ",
    conclusion: "divertikly sigmatu",
  },
  {
    value: 1,
    text: "zvětšená prostata",
    description: "Zvětšená prostata. ",
    conclusion: "zvětšená prostata",
  },
  {
    value: 2,
    text: "myomy dělohy",
    description: "Na T2 hyposignální ložiska v myometriu dělohy. ",
    conclusion: "myomy dělohy",
  },
  {
    value: 3,
    text: "cysty pravé ledviny",
    description:
      "Tenkou stěnou ohraničená kulovitá ložiska pravé ledviny signálu tekutiny. ",
    conclusion: "cysty pravé ledviny",
  },
  {
    value: 4,
    text: "cysty levé ledviny",
    description:
      "Tenkou stěnou ohraničená kulovitá ložiska levé ledviny signálu tekutiny. ",
    conclusion: "cysty levé ledviny",
  },
];

export const aListesis = [
  {
    value: 0,
    text: "bez posunu",
    description: "",
    conclusion: "",
  },
  {
    value: 1,
    text: "ventrolistéza",
    description: "ventrální posun obratlového těla ",
    conclusion: "ventrolistéza ",
  },
  {
    value: 2,
    text: "dorzolistéza",
    description: "dorzální posun obratlového těla ",
    conclusion: "dorzolistéza ",
  },
];

export const aDiskDegen = [
  {
    value: 0,
    text: "normal",
    color: green,
    description: "zachovalý signál a výše disku, ",
  },
  {
    value: 1,
    text: "grade 2",
    color: yellow,
    description: "disk na T2 nižší SI bez snížení jeho výše (grade 2), ",
  },
  {
    value: 2,
    text: "grade 3",
    color: yellow,
    description:
      "disk na T2 nižší SI snížen o méně než 1/2 původní výše (grade 3), ",
  },
  {
    value: 3,
    text: "grade 4",
    color: orange,
    description:
      "disk na T2 nižší SI snížen o více než 1/2 původní výše (grade 4), ",
  },
  {
    value: 4,
    text: "grade 5",
    color: orange,
    description: "disk je snížením téměř či úplně zaniklý (grade 5), ",
  },
];

export const aModic = [
  {
    value: 0,
    text: "normal",
    color: green,
    description: "",
  },
  {
    value: 1,
    text: "Modic 1",
    color: red,
    description: "na STIR vyšší signál kostní dřeně krycích ploten (Modic 1), ",
  },
  {
    value: 2,
    text: "Modic 2",
    color: yellow,
    description: "na T1 vyšší signál kostní dřeně krycích ploten (Modic 2), ",
  },
  {
    value: 3,
    text: "Modic 3",
    color: grey,
    description:
      "na všech sekvencích nízký signál kostní dřeně krycích ploten (Modic 3), ",
  },
];

export const aDiskPat = [
  {
    value: 0,
    text: "normal",
    description: "",
    conclusion: "",
  },
  {
    value: 1,
    text: "OD komplex",
    description: "osteofyt-diskogenní komplex ",
    conclusion: "osteofyt-diskogenního komplexu ",
  },
  {
    value: 2,
    text: "hernie",
    description: "hernie disku ",
    conclusion: "hernie disku ",
  },
  {
    value: 3,
    text: "osteofyty",
    description: "osteofyty krycích ploten ",
    conclusion: "osteofytů krycích ploten ",
  },
  {
    value: 4,
    text: "bulging",
    description: "bulging disku",
    conclusion: "bulgingu disku",
  },
  {
    value: 5,
    text: "anul. fisura",
    description: "anulární fisura disku ",
    conclusion: "anulární fisura disku ",
  },
];

export const aDiskDirection = [
  {
    value: 0,
    text: "F",
    symbol: "FR",
    description: "vpravo foraminálně",
  },
  {
    value: 1,
    text: "P",
    symbol: "PR",
    description: "vpravo paracentrálně",
  },
  {
    value: 2,
    text: "C",
    symbol: "C",
    description: "centrálně",
  },
  {
    value: 3,
    text: "P",
    symbol: "PL",
    description: "vlevo paracentrálně",
  },
  {
    value: 4,
    text: "F",
    symbol: "FL",
    description: "vlevo foraminálně",
  },
];

export const aDiskMigration = [
  {
    value: 0,
    text: "nemigruje",
    description: "",
  },
  {
    value: 1,
    text: "kraniálně",
    description: "migrující kraniálně",
  },
  {
    value: 2,
    text: "kaudálně",
    description: "migrující kaudálně",
  },
];

export const aStenosis = [
  {
    value: 0,
    text: "0",
    foramenLS: "",
    recessLS: "",
    canalLS: "",
    canalLSConclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "1",
    foramenLS: "v jednom rozměru zaniklý perineurální tuk (grade 1), ",
    foramenC:
      "zúžení s ještě patrným likvorem v durální pochvě míšního kořene (grade 1), ",
    foramenLSConclusion: "lehká ",
    recessLS: "bez odtlačení míšního kořene (grade 1), ",
    recessLSConclusion: "lehká ",
    canalLS:
      "zúžení páteřního kanálu bez shlukování míšních kořenů durálního vaku (grade 1), ",
    canalC: "zúžení páteřního kanálu, ale bez imprese míchy (grade 1), ",
    canalLSConclusion: "lehká stenóza páteřního kanálu z důvodu ",
    color: yellow,
  },
  {
    value: 2,
    text: "2",
    foramenLS: "téměř zaniklý perineurální tuk (grade 2), ",
    foramenC:
      "zúžení již bez patrného likvoru v durální pochvě míšního kořene, který ale není přesvědčivě komprimován (grade 2), ",
    foramenLSConclusion: "středně těžká ",
    recessLS: "s odtlačením nedeformovaného míšního kořene (grade 2), ",
    recessLSConclusion: "středně těžká ",
    canalLS:
      "zúžení páteřního kanálu způsobující shlukování míšních kořenů durálního vaku, mezi kterými je ale stále patrný likvor (grade 2), ",
    canalC:
      "zúžení páteřního kanálu s impresí míchy, ale bez přesvědčivých známek myelopatie (grade 2), ",
    canalLSConclusion: "středně těžká stenóza páteřního kanálu z důvodu ",
    color: orange,
  },
  {
    value: 3,
    text: "3",
    foramenLS: "z útlaku deformita durální pochvy míšního kořene (grade 3), ",
    foramenC:
      "zúžení již bez patrného likvoru v durální pochvě míšního kořene, který je susp. komprimován (grade 3), ",
    foramenLSConclusion: "těžká ",
    recessLS: "s odtlačením a deformací míšního kořene (grade 3), ",
    recessLSConclusion: "těžká ",
    canalLS:
      "zúžení páteřního kanálu způsobující způsobující shlukování míšních kořenů durální vaku, mezi kterými již není patrný likvor (grade 3), ",
    canalC:
      "zúžení páteřního kanálu se známkami myelopatie v této úrovni (grade 3), ",
    canalLSConclusion: "těžká stenóza páteřního kanálu z důvodu ",
    color: red,
  },
];

export const aCustom = [
  {
    value: 0,
    text: "ložisko",
  },
  {
    value: 1,
    text: "komprese",
  },
  {
    value: 2,
    text: "operace",
  },
];

export const aSurgery = [
  {
    value: 0,
    text: "přední stabilizace",
  },
  {
    value: 1,
    text: "zadní stabilizace",
  },
  {
    value: 2,
    text: "hemilaminektomie",
  },
];

export const aFacetArthrosis = [
  {
    value: 0,
    text: "ne",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "ano",
    description: "osteofyty okrajů kloubních ploch, ",
    conclusion: "facetovou",
    color: orange,
  },
  {
    value: 2,
    text: "edém",
    description:
      "osteofyty okrajů kloubních ploch a zvýšením signálu kostní dřeně na STIR, ",
    conclusion: "facetovou",
    color: red,
  },
];

export const aUncoArthrosis = [
  {
    value: 0,
    text: "ne",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "ano",
    description: "osteofyty okrajů kloubních ploch, ",
    conclusion: "unkovertebrální",
    color: orange,
  },
  {
    value: 2,
    text: "edém",
    description:
      "osteofyty okrajů kloubních ploch a zvýšením signálu kostní dřeně na STIR, ",
    conclusion: "unkovertebrální",
    color: red,
  },
];

export const aFractureSite = [
  {
    value: 0,
    text: "ventrální snížení",
    description: "Ventrální klínovité snížení obratlového těla ",
    conclusion: "ventrální komprese obratlového těla ",
    color: green,
  },
  {
    value: 1,
    text: "centrální snížení",
    description: "Centrální snížení obratlového těla ",
    conclusion: "centrální komprese obratlového těla ",
    color: orange,
  },
  {
    value: 2,
    text: "dorzální snížení",
    description: "Dorzální klínovité snížení obratlového těla ",
    conclusion: "dorzální komprese obratlového těla ",
    color: red,
  },
];

export const aFractureEndplate = [
  {
    value: 0,
    text: "kompresí horní krycí plotny",
    description: " následkem prolomení horní krycí plotny ",
    conclusion: "horní krycí plotny ",
    color: green,
  },
  {
    value: 1,
    text: "kompresí dolní krycí plotny",
    description: " následkem prolomení dolní krycí plotny ",
    conclusion: "dolní krycí plotny ",
    color: orange,
  },
  {
    value: 2,
    text: "kompresí obou krycích ploten",
    description: " následkem prolomení obou krycích ploten",
    conclusion: "obou krycích ploten",
    color: red,
  },
];

export const aFractureDepth = [
  {
    value: 0,
    text: "o <25%",
    description: " o méně než 25% jeho původní výše, ",
    conclusion: "lehká ",
    color: green,
  },
  {
    value: 1,
    text: "o 25 až 40%",
    description: " o 25-40% jeho původní výše, ",
    conclusion: "středně těžká ",
    color: orange,
  },
  {
    value: 2,
    text: "o >40%",
    description: " o více než 40% jeho původní výše, ",
    conclusion: "těžká ",
    color: red,
  },
];

export const aFractureEdema = [
  {
    value: 0,
    text: "bez edému",
    description: "na STIR bez zvýšení SI kostní dřeně, ",
    conclusion: "Starší ",
    color: green,
  },
  {
    value: 1,
    text: "s edémem",
    description: "na STIR se zvýšením SI kostní dřeně, ",
    conclusion: "Nedávná ",
    color: orange,
  },
];

export const aFractureProminence = [
  {
    value: 0,
    text: "bez prominence do páteřního kanálu",
    description: "bez prominence zadní hrany do páteřního kanálu. ",
    conclusion: ". ",
    color: green,
  },
  {
    value: 1,
    text: "s prominencí bez stenózy páteřního kanálu",
    description:
      "s diskrétní prominencí zadní hrany, ale bez stenózy páteřního kanálu, ",
    conclusion:
      ", s pouze diskrétní prominencí zadní hrany do páteřního kanálu. ",
    color: orange,
  },
  {
    value: 2,
    text: "s prominencí a lehkou stenózou",
    description:
      "s lehkou stenózou páteřního kanálu prominencí zadní hrany obratlového těla. ",
    conclusion: ", způsobující lehkou stenózu páteřního kanálu. ",
    color: orange,
  },
  {
    value: 3,
    text: "s prominencí a středně těžkou stenózou",
    description:
      "se středně těžkou stenózou páteřního kanálu prominencí zadní hrany obratlového těla. ",
    conclusion: ", způsobující středně těžkou stenózu páteřního kanálu. ",
    color: orange,
  },
  {
    value: 2,
    text: "s prominencí a těžkou stenózou",
    description:
      "s těžkou stenózou páteřního kanálu prominencí zadní hrany obratlového těla. ",
    conclusion: ", způsobující těžkou stenózu páteřního kanálu. ",
    color: orange,
  },
];
