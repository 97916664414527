import {
  aSpineSag,
  aSpineCor,
  aAddFinds,
  aListesis,
  aDiskDegen,
  aModic,
  aDiskPat,
  aDiskDirection,
  aDiskMigration,
  aStenosis,
  aFacetArthrosis,
  aUncoArthrosis,
  aADArthrosis,
  aFractureSite,
  aFractureDepth,
  aFractureEdema,
  aFractureProminence,
  aFractureEndplate,
} from "./CT_spine_C_arrays";

import { indent, br, aSize, aVertebrae } from "../scripts/GeneralArrays";
import {
  formatArrayToText,
  capitelizeFirstLetter,
  getFirstVertebraFromSegment,
} from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const {
    spineSag,
    spineCor,
    hemangiomas,
    schmorls,
    addFinds,
    ADArthrosis,
    spineCustomDescription,
    spineCustomConclusion,
  } = props.general;

  // Normální nález
  let normalFindings = "";

  if (
    spineSag +
      spineCor +
      hemangiomas.length +
      schmorls.length +
      addFinds.length ===
      0 &&
    spineCustomDescription === "" &&
    spineCustomConclusion === "" &&
    protocol === 0
  ) {
    normalFindings = "Přiměřený nález na CT C páteře. ";
  }
  if (spineCustomConclusion === "") {
    if (protocol === 1) {
      normalFindings =
        "Bez nitrolebního krvácení či přesvědčivých známek rozvinuté akutní ischemie mozkové. ";
    } else if (protocol === 2) {
      normalFindings =
        "Bez nitrolebního krvácení či čerstvých traumatických změn na zobrazeném skeletu lebky. ";
    }
  }

  importConclusion1(normalFindings);

  // Komprese

  let compressions = props.compressions;
  let compressionsDescription = "";
  let compressionsConclusion = "";
  if (compressions.length > 0) {
    compressions.map((item) => {
      return (compressionsDescription +=
        aFractureSite[item.area].description +
        aVertebrae[item.vertebra].text +
        aFractureEndplate[item.endplate].description +
        aFractureDepth[item.compression].description +
        aFractureEdema[item.edema].description +
        aFractureProminence[item.prominence].description);
    });
    compressions.map((item) => {
      return (compressionsConclusion +=
        aFractureEdema[item.edema].conclusion +
        aFractureDepth[item.compression].conclusion +
        aFractureSite[item.area].conclusion +
        aVertebrae[item.vertebra].text +
        aFractureProminence[item.prominence].conclusion);
    });
  }

  // Páteř segmenty

  let segments = props.segments.filter(
    (item) =>
      item.listesis > 0 ||
      item.diskDegen > 0 ||
      item.Modic > 0 ||
      item.diskPat > 0 ||
      item.yellowLigament > 0 ||
      item.foramenR + item.foramenL > 0 ||
      item.recessR + item.recessL > 0 ||
      item.facetArthrosisR + item.facetArthrosisL > 0 ||
      item.uncoArthrosisR + item.uncoArthrosisL > 0 ||
      item.spinalCanal > 0
  );

  let facetUncoEdemaConclusion = [];

  segments.map((item) => {
    let directionsText = "";
    let directions = item.diskDirection.map((direction) => direction.symbol);
    if (directions.length > 0) {
      if (directions.length === 3) {
        let directions = item.diskDirection.map(
          (direction) => direction.symbol
        );
        if (
          directions.includes("PR") &&
          directions.includes("C") &&
          directions.includes("PL")
        ) {
          directionsText = "centrálně až bilat. paracentrálně";
        } else if (
          directions.includes("C") &&
          directions.includes("PR") &&
          directions.includes("FR")
        ) {
          directionsText = "centrálně až vpravo foraminálně";
        } else if (
          directions.includes("C") &&
          directions.includes("PL") &&
          directions.includes("FL")
        ) {
          directionsText = "centrálně až vlevo foraminálně";
        } else {
          directionsText = formatArrayToText(
            item.diskDirection.map((direction) => direction.description)
          );
        }
      } else if (directions.length === 2) {
        if (directions.includes("C") && directions.includes("PR")) {
          directionsText = "centrálně až vpravo paracentrálně";
        } else if (directions.includes("C") && directions.includes("PL")) {
          directionsText = "centrálně až vlevo paracentrálně";
        } else if (directions.includes("PR") && directions.includes("FR")) {
          directionsText = "vpravo paracentrálně až foraminálně";
        } else if (directions.includes("PL") && directions.includes("FL")) {
          directionsText = "vlevo paracentrálně až foraminálně";
        } else if (directions.includes("FR") && directions.includes("FL")) {
          directionsText = "bilat. foraminálně";
        } else {
          directionsText = formatArrayToText(
            item.diskDirection.map((direction) => direction.description)
          );
        }
      } else {
        directionsText = formatArrayToText(
          item.diskDirection.map((direction) => direction.description)
        );
      }
    }

    let facetUncoArthrosisDescription = "";
    if (
      item.facetArthrosisR +
        item.facetArthrosisL +
        item.uncoArthrosisR +
        item.uncoArthrosisL >
      0
    ) {
      let arthrosis = [];
      let oedema = [];

      if (item.facetArthrosisR > 0 && item.facetArthrosisL > 0) {
        arthrosis.push("oba facetové klouby");
      } else if (item.facetArthrosisR > 0) {
        arthrosis.push("pravý facetový kloub");
      } else if (item.facetArthrosisL > 0) {
        arthrosis.push("levý facetový kloub");
      }
      if (item.uncoArthrosisR > 0 && item.uncoArthrosisL > 0) {
        arthrosis.push("oba unkovertebrální klouby");
      } else if (item.uncoArthrosisR > 0) {
        arthrosis.push("pravý unkovertebrální kloub");
      } else if (item.uncoArthrosisL > 0) {
        arthrosis.push("levý unkovertebrální kloub");
      }

      if (item.facetArthrosisR === 2 && item.facetArthrosisL === 2) {
        oedema.push("oba facetové klouby");
      } else if (item.facetArthrosisR === 2) {
        oedema.push("pravý facetový kloub");
      } else if (item.facetArthrosisL === 2) {
        oedema.push("levý facetový kloub");
      }
      if (item.uncoArthrosisR === 2 && item.uncoArthrosisL === 2) {
        oedema.push("oba unkovertebrální klouby");
      } else if (item.uncoArthrosisR === 2) {
        oedema.push("pravý unkovertebrální kloub");
      } else if (item.uncoArthrosisL === 2) {
        oedema.push("levý unkovertebrální kloub");
      }

      if (arthrosis.length > 0) {
        facetUncoArthrosisDescription +=
          formatArrayToText(arthrosis) + aFacetArthrosis[1].description;
      }
      if (oedema.length > 0) {
        facetUncoArthrosisDescription +=
          formatArrayToText(oedema) + aFacetArthrosis[2].description;
        facetUncoEdemaConclusion.push(
          formatArrayToText(oedema) + " " + item.name
        );
      }
    }
    let segmentDescription =
      item.name +
      " - " +
      (item.listesis > 0
        ? aListesis[item.listesis].description +
          getFirstVertebraFromSegment(item.name)
        : "") +
      (item.listesisSize > 0
        ? " o " + aSize[item.listesisSize].text + ", "
        : "") +
      (item.diskDegen > 0 ? aDiskDegen[item.diskDegen].description : "") +
      (item.Modic > 0 ? aModic[item.Modic].description : "") +
      (item.diskPat > 0 ? aDiskPat[item.diskPat].description : "") +
      (item.diskDirection.length > 0 && item.diskPat !== 4
        ? formatArrayToText(
            item.diskDirection.map(
              (direction) => aDiskDirection[direction.value].description
            )
          )
        : "") +
      (item.diskPatSize > 0
        ? " max. " + aSize[item.diskPatSize].text + ", "
        : "") +
      (item.diskMigration > 0
        ? aDiskMigration[item.diskMigration].description
        : "") +
      (item.diskMigrationSize > 0 && item.diskMigration > 0
        ? " max. " + aSize[item.diskMigrationSize].text + ", "
        : "") +
      (item.yellowLigament > 0 ? "hypertrofie žlutých vazů, " : "") +
      facetUncoArthrosisDescription +
      (item.foramenR > 0 || item.foramenL > 0
        ? item.foramenR === item.foramenL
          ? "v obou foraminech " + aStenosis[item.foramenR].foramenC
          : item.foramenR > 0 && item.foramenL > 0
          ? "v pravém foramen " +
            aStenosis[item.foramenR].foramenC +
            "v levém foramen " +
            aStenosis[item.foramenL].foramenC
          : item.foramenR > 0
          ? "v pravém foramen " + aStenosis[item.foramenR].foramenC
          : "v levém foramen " + aStenosis[item.foramenL].foramenC
        : "") +
      (item.spinalCanal > 0 ? aStenosis[item.spinalCanal].canalC : "");

    let causeOfForaminalStenosis = [];
    if (item.uncoArthrosisR + item.uncoArthrosisL > 0)
      causeOfForaminalStenosis.push("unkovertebrální");
    if (item.facetArthrosisR + item.facetArthrosisL > 0)
      causeOfForaminalStenosis.push("facetovou");

    if (causeOfForaminalStenosis.length > 0)
      causeOfForaminalStenosis =
        " " + formatArrayToText(causeOfForaminalStenosis) + " artrózou. ";

    let segmentConclusion =
      item.listesis +
        item.diskPat +
        item.foramenR +
        item.foramenL +
        item.spinalCanal >
      0
        ? item.name +
          " - " +
          (item.spinalCanal > 0
            ? aStenosis[item.spinalCanal].canalLSConclusion +
              (item.diskPat > 0 && item.diskPat !== 6
                ? aDiskPat[item.diskPat].conclusion
                : "") +
              directionsText +
              (item.diskPatSize > 0
                ? " (" + aSize[item.diskPatSize].text + ")"
                : "") +
              (item.diskMigration > 0
                ? " " + aDiskMigration[item.diskMigration].description
                : "") +
              (item.diskMigrationSize > 0 && item.diskMigration > 0
                ? " (" + aSize[item.diskMigrationSize].text + ")"
                : "") +
              (item.diskPat > 0 &&
              (item.listesis > 0 || item.yellowLigament > 0)
                ? " a "
                : "") +
              (item.listesis > 0 || item.yellowLigament > 0
                ? "degenerativních změn (" +
                  (item.listesis > 0
                    ? aListesis[item.listesis].conclusion +
                      getFirstVertebraFromSegment(item.name) +
                      (item.listesisSize > 0
                        ? " o " + aSize[item.listesisSize].text
                        : "")
                    : "") +
                  (item.listesis > 0 && item.yellowLigament > 0 ? ", " : "") +
                  (item.yellowLigament > 0 ? "hypertrofie žlutých vazů" : "") +
                  ")"
                : "") +
              ", "
            : (item.diskPat > 0 && item.diskPat !== 6
                ? aDiskPat[item.diskPat].description +
                  directionsText +
                  (item.diskPatSize > 0
                    ? " (" + aSize[item.diskPatSize].text + ")"
                    : "") +
                  (item.diskMigration > 0 ? " " : ", ") +
                  (item.diskMigration > 0
                    ? aDiskMigration[item.diskMigration].description
                    : "") +
                  (item.diskMigrationSize > 0 && item.diskMigration > 0
                    ? " (" + aSize[item.diskMigrationSize].text + "), "
                    : "")
                : "") +
              (item.diskPat === 6
                ? "anulární fisura disku " + directionsText + ", "
                : "") +
              (item.listesis > 0
                ? aListesis[item.listesis].conclusion +
                  getFirstVertebraFromSegment(item.name) +
                  (item.listesisSize > 0
                    ? " (" + aSize[item.listesisSize].text + "), "
                    : "")
                : "")) +
          (item.foramenR > 0 || item.foramenL > 0
            ? item.foramenR === item.foramenL
              ? "stenóza obou foramin " +
                aStenosis[item.foramenR].foramenLSConclusion
              : item.foramenR > 0 && item.foramenL > 0
              ? "stenóza pravého foramina " +
                aStenosis[item.foramenR].foramenLSConclusion +
                " a levého foramina " +
                aStenosis[item.foramenL].foramenLSConclusion
              : item.foramenR > 0
              ? "stenóza pravého foramina " +
                aStenosis[item.foramenR].foramenLSConclusion
              : "stenóza levého foramina " +
                aStenosis[item.foramenL].foramenLSConclusion
            : "") +
          (item.foramenR > 0 || item.foramenL > 0
            ? causeOfForaminalStenosis !== ""
              ? causeOfForaminalStenosis
              : ", "
            : "")
        : "";
    importDescription1(segmentDescription);
    importConclusion1(segmentConclusion);
  });

  if (facetUncoEdemaConclusion.length > 0) {
    facetUncoEdemaConclusion = capitelizeFirstLetter(
      formatArrayToText(facetUncoEdemaConclusion) +
        " s obrazem akutní dekompenzace degenerativních změn. "
    );
  }

  // Páteř obecně

  let hemangiomasFinal = hemangiomas
    .sort()
    .map((item) => aVertebrae[item].text);
  hemangiomasFinal = formatArrayToText(hemangiomasFinal);

  let spineGeneralDescription =
    spineCustomDescription +
    aSpineCor[spineCor].description +
    (segments.filter((item) => item.listesis > 0).length > 0
      ? "Jinak plynulá krční lordóza. "
      : aSpineSag[spineSag].description) +
    (compressions.length > 0
      ? compressionsDescription + "Jinak přiměřená výše obratlových těl. "
      : "Přiměřená výše obratlových těl. ") +
    aADArthrosis[ADArthrosis].description +
    (hemangiomas.length > 0
      ? "Ložisko nižší denzity s rozšířenou trámčinou v kostní dřeni obratlového těla " +
        hemangiomasFinal +
        ". Jinak bez přesvědčivých ložisek v kostní dřeni. "
      : "Bez přesvědčivých ložiskových změn skeletu. ");

  let spineGeneralConclusion =
    spineCustomConclusion +
    aSpineCor[spineCor].conclusion +
    aSpineSag[spineSag].conclusion +
    (segments.filter((item) => item.diskDegen > 2).length > 0
      ? "Těžká degenerace disku " +
        formatArrayToText(
          segments.filter((item) => item.diskDegen > 2).map((item) => item.name)
        ) +
        ". "
      : "") +
    (segments.filter((item) => item.diskDegen > 0 && item.diskDegen < 3)
      .length > 0
      ? "Lehká degenerace disku " +
        formatArrayToText(
          segments
            .filter((item) => item.diskDegen > 0 && item.diskDegen < 3)
            .map((item) => item.name)
        ) +
        ". "
      : "") +
    (segments.filter((item) => item.Modic === 1).length > 0
      ? "Obraz akutní dekompenzace degenerativních změn krycích ploten " +
        formatArrayToText(
          segments.filter((item) => item.Modic === 1).map((item) => item.name)
        ) +
        ". "
      : "") +
    facetUncoEdemaConclusion +
    aADArthrosis[ADArthrosis].conclusion +
    (hemangiomas.length > 0
      ? "Hemangiom obratle " + hemangiomasFinal + ". "
      : "");

  // Páteř ostatní
  const aAllSegments = props.segments;
  schmorls.sort((a, b) => a - b);
  let schmorlsFinal = schmorls.map((item) => aAllSegments[item].name);
  schmorlsFinal = formatArrayToText(schmorlsFinal);

  let spineOtherDescription =
    (schmorls.length > 0
      ? "Povrchové defekty centrální části krycích ploten segmentu " +
        schmorlsFinal +
        " obrazu Schmorlových uzlů. "
      : "") +
    (addFinds.length > 0
      ? addFinds
          .sort()
          .map((item) => aAddFinds[item].description)
          .join("")
      : "");
  let spineOtherConclusion =
    addFinds.length > 0
      ? "Vedlejší nález: " +
        formatArrayToText(
          addFinds.sort().map((item) => aAddFinds[item].conclusion)
        )
      : "";

  importDescription2(spineGeneralDescription + spineOtherDescription);
  importConclusion2(
    spineGeneralConclusion + compressionsConclusion + spineOtherConclusion
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
