import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import { aExams } from "./XRAY_abdomen_arrays";
import { generateReport } from "./XRAY_abdomen_functions";

// General scripts
import {
  aGeneral,
  contentWidth,
  aContrastMR,
  aContrastMRamount,
  aCardColors,
  aTrueFalse,
} from "../scripts/GeneralArrays";
import {} from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";
import DropDown from "../components/DropDown";
import ButtonUpDown from "../components/ButtonUpDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";

// 3rd party components
import { Form, Row, Col, Select, Input, Button } from "antd";
import { debounce } from "lodash";
const { Option } = Select;

class XRAY_abdomen extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      valueNum: 0,
      valueText: "",
      valueBoolean: 0,
    },
    lesions: [],
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value) => {
    let valueNum = value;
    let valueText = "";
    let valueBoolean = false;

    let newRecord = {
      valueNum: valueNum,
      valueText: valueText,
      valueBoolean: valueBoolean,
    };

    this.setState((prevState) => ({
      lesions: [...prevState.lesions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const { protocol } = this.props;
    const myContent1 = [
      {
        title: "karta 1",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "label 1",
              input: (
                <ButtonUpDown
                  name="valueNum"
                  segment="general"
                  value={this.state.general.valueNum}
                  options={aGeneral}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "label 2",
              input: (
                <Row>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "label 3",
              input: (
                <Input
                  name="valueText"
                  size="small"
                  placeholder="velikost"
                  suffix="mm"
                  onChange={(event) =>
                    this.onChange({
                      segment: "general",
                      name: "valueText",
                      value: event.target.value,
                    })
                  }
                />
              ),
            },
            {
              label: "label 4",
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name="valueBoolean"
                      segment="general"
                      value={this.state.general.valueBoolean}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col>
                    {this.state.general.valueBoolean ? (
                      <Input
                        name="valueText"
                        value={this.state.general.valueText}
                        size="small"
                        placeholder="velikost..."
                        suffix="mm"
                        onChange={(event) =>
                          this.onChange({
                            segment: "general",
                            name: "valueText",
                            value: event,
                          })
                        }
                        style={{ width: 145 }}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: "karta 2",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "label 1",
              input: (
                <DropDown
                  name="valueNum"
                  segment="general"
                  defaultValue={this.state.general.valueNum}
                  options={aGeneral}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "label 2",
              input: (
                <Row>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "label 3",
              input: (
                <Input
                  name="valueText"
                  size="small"
                  placeholder="velikost"
                  suffix="mm"
                  onChange={(event) =>
                    this.onChange({
                      segment: "general",
                      name: "valueText",
                      value: event.target.value,
                    })
                  }
                />
              ),
            },
            {
              label: "label 4",
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name="valueBoolean"
                      segment="general"
                      value={this.state.general.valueBoolean}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={18}>
                    {this.state.general.valueBoolean ? (
                      <Input
                        value={this.state.general.valueText}
                        size="small"
                        placeholder="velikost"
                        onChange={(event) =>
                          this.onChange({
                            segment: "general",
                            name: "valueText",
                            value: event.target.value,
                          })
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const myContent2 = [
      {
        title: "karta 3",
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "label 1",
              input: (
                <DropDown
                  name="valueNum"
                  segment="general"
                  defaultValue={this.state.general.valueNum}
                  options={aGeneral}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "label 2",
              input: (
                <Row>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "label 3",
              input: (
                <Input
                  name="valueText"
                  size="small"
                  placeholder="velikost"
                  suffix="mm"
                  onChange={(event) =>
                    this.onChange({
                      segment: "general",
                      name: "valueText",
                      value: event.target.value,
                    })
                  }
                />
              ),
            },
            {
              label: "label 4",
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name="valueBoolean"
                      segment="general"
                      value={this.state.general.valueBoolean}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={18}>
                    {this.state.general.valueBoolean ? (
                      <Input
                        value={this.state.general.valueText}
                        size="small"
                        placeholder="velikost"
                        onChange={(event) =>
                          this.onChange({
                            segment: "general",
                            name: "valueText",
                            value: event.target.value,
                          })
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: "karta 4",
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "label 1",
              input: (
                <DropDown
                  name="valueNum"
                  segment="general"
                  defaultValue={this.state.general.valueNum}
                  options={aGeneral}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "label 2",
              input: (
                <Row>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <DropDown
                      name="valueNum"
                      segment="general"
                      defaultValue={this.state.general.valueNum}
                      options={aGeneral}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "label 3",
              input: (
                <Input
                  name="valueText"
                  size="small"
                  placeholder="velikost"
                  suffix="mm"
                  onChange={(event) =>
                    this.onChange({
                      segment: "general",
                      name: "valueText",
                      value: event.target.value,
                    })
                  }
                />
              ),
            },
            {
              label: "label 4",
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name="valueBoolean"
                      segment="general"
                      value={this.state.general.valueBoolean}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={18}>
                    {this.state.general.valueBoolean ? (
                      <Input
                        value={this.state.general.valueText}
                        size="small"
                        placeholder="velikost"
                        onChange={(event) =>
                          this.onChange({
                            segment: "general",
                            name: "valueText",
                            value: event.target.value,
                          })
                        }
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={myContent1} />
        <CustomCards content={myContent2} />
      </Form>
    );
  }
}

export default XRAY_abdomen;
