import React from "react";

// Custom scripts
import {
  aSelect,
  aLigamentTear,
  aLigamentAge,
  aTCdegen,
  aTCfluid,
  aOCDgrade,
  aATtendinosis,
  aATperitendonitis,
} from "./MR_ankle_arrays";
import { generateReport } from "./MR_ankle_functions";

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import DropDown from "../components/DropDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";
import ButtonUpDown from "../components/ButtonUpDown";
import CustomCards from "../components/CustomCards";

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
} from "antd";
import { debounce } from "lodash";
import { CloseCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;

class MR_ankle extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    ligaments: {
      ATFLage: 0,
      ATFLtear: 0,
      CFLage: 0,
      CFLtear: 0,
      PTFLage: 0,
      PTFLtear: 0,
      deltoidtear: 0,
      deltoidage: 0,
    },
    general: {
      TCfluid: 0,
      TCdegen: 0,
      PBTtear: 0,
      PBTage: 1,
      OCDgrade: 0,
      OCDsize: "",
      calcar: false,
      plantFasc: false,
      retrokalkBursitis: false,
      ATwidth: "",
      ATtendinosis: 0,
      ATperitendonitis: 0,
      tsFHL: false,
    },
  };

  initialState = this.state;

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState(
      {
        [segment]: {
          ...this.state[segment],
          [name]: value,
        },
      },
      () =>
        this.props.askReport(
          generateReport(this.state, this.props.protocol, this.props.contrast)
        )
    );
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  render() {
    const blocks_1 = [
      {
        title: "mediální lig. komplex (deltoidní)",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "deltoidní lig.",
              input: (
                <Row>
                  {this.state.ligaments.deltoidtear != 0 ? (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name="deltoidtear"
                          segment="ligaments"
                          value={this.state.ligaments.deltoidtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name="deltoidage"
                          segment="ligaments"
                          value={this.state.ligaments.deltoidage}
                          options={aLigamentAge}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <ButtonUpDown
                          name="deltoidtear"
                          segment="ligaments"
                          value={this.state.ligaments.deltoidtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: "laterální lig. komplex",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "ATFL",
              input: (
                <Row>
                  {this.state.ligaments.ATFLtear != 0 ? (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name="ATFLtear"
                          segment="ligaments"
                          value={this.state.ligaments.ATFLtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name="ATFLage"
                          segment="ligaments"
                          value={this.state.ligaments.ATFLage}
                          options={aLigamentAge}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <ButtonUpDown
                          name="ATFLtear"
                          segment="ligaments"
                          value={this.state.ligaments.ATFLtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: "CFL",
              input: (
                <Row>
                  {this.state.ligaments.CFLtear != 0 ? (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name="CFLtear"
                          segment="ligaments"
                          value={this.state.ligaments.CFLtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name="CFLage"
                          segment="ligaments"
                          value={this.state.ligaments.CFLage}
                          options={aLigamentAge}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <ButtonUpDown
                          name="CFLtear"
                          segment="ligaments"
                          value={this.state.ligaments.CFLtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: "PTFL",
              input: (
                <Row>
                  {this.state.ligaments.PTFLtear != 0 ? (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name="PTFLtear"
                          segment="ligaments"
                          value={this.state.ligaments.PTFLtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name="PTFLage"
                          segment="ligaments"
                          value={this.state.ligaments.PTFLage}
                          options={aLigamentAge}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <ButtonUpDown
                          name="PTFLtear"
                          segment="ligaments"
                          value={this.state.ligaments.PTFLtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
        ],
      },
    ];
    const blocks_2 = [
      {
        title: "talokrurální kloub",
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "náplň",
              input: (
                <ButtonUpDown
                  name="TCfluid"
                  segment="general"
                  value={this.state.general.TCfluid}
                  options={aTCfluid}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "degenerace",
              input: (
                <ButtonUpDown
                  name="TCdegen"
                  segment="general"
                  value={this.state.general.TCdegen}
                  options={aTCdegen}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "OCD talu",
              input: (
                <Row>
                  {this.state.general.OCDgrade != 0 ? (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name="OCDgrade"
                          segment="general"
                          value={this.state.general.OCDgrade}
                          options={aOCDgrade}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          name="OCDsize"
                          value={this.state.general.OCDsize}
                          size="small"
                          suffix="mm"
                          onChange={(event) =>
                            this.onChange({
                              segment: "general",
                              name: "OCDsize",
                              value: event.target.value,
                            })
                          }
                          //style={{ width: 97 }}
                          autoFocus
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <ButtonUpDown
                          name="OCDgrade"
                          segment="general"
                          value={this.state.general.OCDgrade}
                          options={aOCDgrade}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: "tendinopatie a tenosynovitidy (TS)",
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: "lig.m.per.brevis",
              input: (
                <Row>
                  {this.state.general.PBTtear != 0 ? (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name="PBTtear"
                          segment="general"
                          value={this.state.general.PBTtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <ButtonUpDown
                          name="PBTage"
                          segment="general"
                          value={this.state.general.PBTage}
                          options={aLigamentAge}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <ButtonUpDown
                          name="PBTtear"
                          segment="general"
                          value={this.state.general.PBTtear}
                          options={aLigamentTear}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: "calcar calc. pl.",
              input: (
                <ButtonTrueFalse
                  name="calcar"
                  segment="general"
                  value={this.state.general.calcar}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "plant. fasc.",
              input: (
                <ButtonTrueFalse
                  name="plantFasc"
                  segment="general"
                  value={this.state.general.plantFasc}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "TS fl.hall.long.",
              input: (
                <ButtonTrueFalse
                  name="tsFHL"
                  segment="general"
                  value={this.state.general.tsFHL}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
    ];
    const blocks_3 = [
      {
        title: "",
        color: aCardColors.purple,
        rows: [
          [
            {
              label: "náplň",
              input: (
                <ButtonUpDown
                  name="TCfluid"
                  segment="general"
                  value={this.state.general.TCfluid}
                  options={aTCfluid}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "degenerace",
              input: (
                <ButtonUpDown
                  name="TCdegen"
                  segment="general"
                  value={this.state.general.TCdegen}
                  options={aTCdegen}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "OCD talu",
              input: (
                <Row>
                  {this.state.general.OCDgrade != 0 ? (
                    <>
                      <Col span={12}>
                        <ButtonUpDown
                          name="OCDgrade"
                          segment="general"
                          value={this.state.general.OCDgrade}
                          options={aOCDgrade}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        <Input
                          name="OCDsize"
                          value={this.state.general.OCDsize}
                          size="small"
                          suffix="mm"
                          onChange={(event) =>
                            this.onChange({
                              segment: "general",
                              name: "OCDsize",
                              value: event.target.value,
                            })
                          }
                          //style={{ width: 97 }}
                          autoFocus
                        />
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col span={24}>
                        <ButtonUpDown
                          name="OCDgrade"
                          segment="general"
                          value={this.state.general.OCDgrade}
                          options={aOCDgrade}
                          onChange={this.onChange}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: "Achillova šlacha",
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "tendinóza",
              input: (
                <ButtonUpDown
                  name="ATtendinosis"
                  segment="general"
                  value={this.state.general.ATtendinosis}
                  options={aATtendinosis}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "peritendonit.",
              input: (
                <ButtonUpDown
                  name="ATperitendonitis"
                  segment="general"
                  value={this.state.general.ATperitendonitis}
                  options={aATperitendonitis}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "AP rozměr",
              input: (
                <Input
                  name="ATwidth"
                  value={this.state.general.ATwidth}
                  size="small"
                  suffix="mm"
                  onChange={(event) =>
                    this.onChange({
                      segment: "general",
                      name: "ATwidth",
                      value: event.target.value,
                    })
                  }
                  style={{ width: 195 }}
                  autoFocus
                />
              ),
            },
            {
              label: "retrokalk. burs.",
              input: (
                <ButtonTrueFalse
                  name="retrokalkBursitis"
                  segment="general"
                  value={this.state.general.retrokalkBursitis}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={blocks_1} />
        <CustomCards content={blocks_2} />
        <CustomCards content={blocks_3} />
      </Form>
    );
  }
}

export default MR_ankle;
