import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import {
  aInvasionWall,
  aInvasionVagina,
  aInvasionPelvicWall,
  aInvasionParametria,
  aHydronephrosis,
  aLNpelvic,
  aUrinaryBladder,
  aRectum,
  aAscites,
  aOvary,
  aLN,
  aLNcount,
} from "./MR_cervix_arrays";
import { generateReport } from "./MR_cervix_functions";

// General scripts
import {
  contentWidth,
  aContrastMR,
  aContrastMRamount,
  aCardColors,
  aTrueFalse,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";
import ButtonTrueFalse from "../components/ButtonTrueFalse";
import ButtonUpDown from "../components/ButtonUpDown";

// 3rd party components
import { Form, Row, Col, Input, Switch } from "antd";
import { debounce } from "lodash";
const { TextArea } = Input;

class MR_cervix extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      invasionWall: 0,
      tumorSize: "",
      invasionVagina: 0,
      invasionPelvicWall: 0,
      invasionParametria: 0,
      hydronephrosis: 0,
      LNparaaortic: 0,
      LNiliacComR: 0,
      LNiliacComL: 0,
      LNiliacExtR: 0,
      LNiliacExtL: 0,
      LNiliacIntR: 0,
      LNiliacIntL: 0,
      LNinguinalR: 0,
      LNinguinalL: 0,
      LNparaaorticCount: 0,
      LNiliacComRCount: 0,
      LNiliacComLCount: 0,
      LNiliacExtRCount: 0,
      LNiliacExtLCount: 0,
      LNiliacIntRCount: 0,
      LNiliacIntLCount: 0,
      LNinguinalRCount: 0,
      LNinguinalLCount: 0,
      LNcustomDescription: "",
      LNcustomConclusion: "",
      urinaryBladder: 0,
      rectum: 0,
      boneMeta: false,
      ascites: 0,
      ovaryR: 0,
      ovaryL: 0,
      customDescription: "",
      customConclusion: "",
    },
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value) => {
    let valueNum = value;
    let valueText = "";
    let valueBoolean = false;

    let newRecord = {
      valueNum: valueNum,
      valueText: valueText,
      valueBoolean: valueBoolean,
    };

    this.setState((prevState) => ({
      lesions: [...prevState.lesions, newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const myContent1 = [
      {
        title: "tumor děložního krčku",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "invaze stěny",
              input: (
                <ButtonUpDown
                  name="invasionWall"
                  segment="general"
                  value={this.state.general.invasionWall}
                  options={aInvasionWall}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "velikost tu",
              input: (
                <Input
                  size="small"
                  name="tumorSize"
                  segment="general"
                  defaultValue={this.state.general.tumorSize}
                  suffix="mm"
                  onBlur={(event) =>
                    this.onChange({
                      name: "tumorSize",
                      segment: "general",
                      value: event.target.value,
                    })
                  }
                  style={{ width: "195px" }}
                />
              ),
            },
          ],
          [
            {
              label: "parametria",
              input: (
                <ButtonUpDown
                  name="invasionParametria"
                  segment="general"
                  value={this.state.general.invasionParametria}
                  options={aInvasionParametria}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "pochva",
              input: (
                <ButtonUpDown
                  name="invasionVagina"
                  segment="general"
                  value={this.state.general.invasionVagina}
                  options={aInvasionVagina}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "pánevní stěna",
              input: (
                <ButtonUpDown
                  name="invasionPelvicWall"
                  segment="general"
                  value={this.state.general.invasionPelvicWall}
                  options={aInvasionPelvicWall}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "hydronefróza",
              input: (
                <ButtonUpDown
                  name="hydronephrosis"
                  segment="general"
                  value={this.state.general.hydronephrosis}
                  options={aHydronephrosis}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>lymfatické uzliny</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("LNcustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "paraaortální",
              input: (
                <Row>
                  <Col span={7}></Col>
                  <Col span={this.state.general.LNparaaortic > 0 ? 8 : 10}>
                    <ButtonUpDown
                      name="LNparaaortic"
                      segment="general"
                      value={this.state.general.LNparaaortic}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNparaaortic > 0 ? (
                    <Col span={2}>
                      <ButtonUpDown
                        name="LNparaaorticCount"
                        segment="general"
                        value={this.state.general.LNparaaorticCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                  <Col span={7}></Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "ilické spol.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacComR"
                      segment="general"
                      value={this.state.general.LNiliacComR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacComRCount"
                        segment="general"
                        value={this.state.general.LNiliacComRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: "ilické spol.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacComL"
                      segment="general"
                      value={this.state.general.LNiliacComL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacComLCount"
                        segment="general"
                        value={this.state.general.LNiliacComLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: "ilické ext.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacExtR"
                      segment="general"
                      value={this.state.general.LNiliacExtR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacExtRCount"
                        segment="general"
                        value={this.state.general.LNiliacExtRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: "ilické ext.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacExtL"
                      segment="general"
                      value={this.state.general.LNiliacExtL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacExtLCount"
                        segment="general"
                        value={this.state.general.LNiliacExtLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: "ilické int.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacIntR"
                      segment="general"
                      value={this.state.general.LNiliacIntR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacIntRCount"
                        segment="general"
                        value={this.state.general.LNiliacIntRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: "ilické int.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacIntL"
                      segment="general"
                      value={this.state.general.LNiliacIntL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacIntLCount"
                        segment="general"
                        value={this.state.general.LNiliacIntLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: "inguinální",
              input: (
                <Row>
                  <Col span={this.state.general.LNinguinalR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNinguinalR"
                      segment="general"
                      value={this.state.general.LNinguinalR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNinguinalR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNinguinalRCount"
                        segment="general"
                        value={this.state.general.LNinguinalRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: "inguinální",
              input: (
                <Row>
                  <Col span={this.state.general.LNinguinalL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNinguinalL"
                      segment="general"
                      value={this.state.general.LNinguinalL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNinguinalL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNinguinalLCount"
                        segment="general"
                        value={this.state.general.LNinguinalLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? "vlastní popis" : "",
              input: this.state.LNcustomShow ? (
                <TextArea
                  name="LNcustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.LNcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "LNcustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? "vlastní závěr" : "",
              input: this.state.LNcustomShow ? (
                <TextArea
                  name="LNcustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.LNcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "LNcustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const myContent2 = [
      {
        title: "invaze okolních orgánů, metastázy, ascites",
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "moč. měchýř",
              input: (
                <ButtonUpDown
                  name="urinaryBladder"
                  segment="general"
                  value={this.state.general.urinaryBladder}
                  options={aUrinaryBladder}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "rektum",
              input: (
                <ButtonUpDown
                  name="rectum"
                  segment="general"
                  value={this.state.general.rectum}
                  options={aRectum}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "kostní meta",
              input: (
                <ButtonTrueFalse
                  name="boneMeta"
                  segment="general"
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "volná tek.",
              input: (
                <ButtonUpDown
                  name="ascites"
                  segment="general"
                  value={this.state.general.ascites}
                  options={aAscites}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "vedlejší nálezy",
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "ovaria",
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      name="ovaryR"
                      segment="general"
                      value={this.state.general.ovaryR}
                      options={aOvary}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name="ovaryL"
                      segment="general"
                      value={this.state.general.ovaryL}
                      options={aOvary}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: "vlastní popis",
              input: (
                <TextArea
                  size="small"
                  name="customDescription"
                  segment="general"
                  defaultValue={this.state.general.customDescription}
                  placeholder="..."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "customDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "vlastní závěr",
              input: (
                <TextArea
                  size="small"
                  name="customConclusion"
                  segment="general"
                  defaultValue={this.state.general.customConclusion}
                  placeholder="..."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "customConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={myContent1} />
        <CustomCards content={myContent2} />
      </Form>
    );
  }
}

export default MR_cervix;
