// Custom scripts
import {
  aHabitus,
  aGas,
  aExams,
  aLesions,
  aLiverParenchyma,
  aLiverSize,
  aGBwall,
  aGBcontent,
  aGBsize,
  aBiliaryEHSize,
  aBiliaryIHSize,
  aSpleenSize,
  aKidneyParenchyma,
  aKidneyKPS,
  aPancreasParenchyma,
  aPancreasDuctus,
  aPancreasLimitation,
  aAppendix,
  aAppendixPos,
  aPelvisOther,
  aSigmoid,
  aUrinaryBladder,
  aIntraabdominalFluid,
  aLiverDoppler,
} from './US_abdomen_arrays';

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  contentWidth,
  aSizemm,
} from '../scripts/GeneralArrays';
import { formatArrayToText } from '../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // Játra, žlučové cesty a slezina

  const {
    limitationHabitus,
    limitationGas,
    liverParenchyma,
    liverSize,
    liverDoppler,
    GBsurgery,
    GBcontent,
    GBconcrementSize,
    GBwall,
    GBsize,
    biliaryEHSize,
    biliaryIHSize,
    biliaryAir,
    spleenSize,
    kidneyRparenchyma,
    kidneyRKPS,
    kidneyLparenchyma,
    kidneyLKPS,
    pancreasParenchyma,
    pancreasDuctus,
    pancreasLimitation,
    appendix,
    appendixSize,
    appendixPos,
    sigmoid,
    sigmoidWall,
    sigmoidDiverticles,
    urinaryBladder,
    intraabdominalFluid,
    pelvisOther,
  } = props.general;

  // Limitace vyšetření
  let limitationDescription = '';
  let aLimitations = [];
  if (limitationHabitus)
    aLimitations.push(aHabitus[limitationHabitus].description);
  if (limitationGas) aLimitations.push(aGas[limitationGas].description);
  if (aLimitations.length)
    limitationDescription =
      'Limitace hodnotitelnosti vyšetření pacienta ' +
      formatArrayToText(aLimitations) +
      '. ';

  // Játra
  let liverDescription =
    aLiverSize[liverSize].description +
    aLiverParenchyma[liverParenchyma].description +
    'bez ložiskových změn. ';
  let liverConclusion = '';
  if (liverSize + liverParenchyma > 0) {
    if (liverSize > 0 && liverParenchyma > 0) {
      liverConclusion =
        aLiverParenchyma[liverParenchyma].conclusion +
        aLiverSize[liverSize].conclusion +
        'jater. ';
    } else if (liverSize > 0) {
      if (liverSize === 1) {
        liverConclusion = 'Játra mírně zvětšena. ';
      } else if (liverSize === 2) {
        liverConclusion = 'Játra zvětšena. ';
      } else if (liverSize === 3) {
        liverConclusion = 'Játra výrazně zvětšena. ';
      }
    } else {
      liverConclusion =
        aLiverParenchyma[liverParenchyma].conclusion + 'jater. ';
    }
  }
  if (liverDoppler)
    liverDescription +=
      'Normální rychlosti a charakter toku v lumen v. portae a jaterních žil. ';

  // Žlučník
  let biliaryDescription = '';
  let biliaryConclusion = '';

  if (!GBsurgery) {
    biliaryDescription += 'Žlučník ' + aGBsize[GBsize].description;
    biliaryDescription += aGBwall[GBwall].description;
    biliaryDescription += aGBcontent[GBcontent].description;
    if (GBcontent >= 2 && GBconcrementSize !== '') {
      biliaryDescription += ' velikosti max. ' + GBconcrementSize + 'mm. ';
    } else {
      biliaryDescription += '. ';
    }
    if (GBcontent > 0 || GBwall > 0) {
      biliaryConclusion += aGBwall[GBwall].conclusion;
      biliaryConclusion += aGBcontent[GBcontent].conclusion;
    }
  } else {
    biliaryDescription = 'Žlučník chybí. ';
    biliaryConclusion = 'St.p. CHCE. ';
  }

  // Žlučové cesty
  biliaryDescription +=
    aBiliaryEHSize[biliaryEHSize].description +
    aBiliaryIHSize[biliaryIHSize].description +
    (biliaryAir
      ? 'Kometkovité artefakty plynu v průběhu žlučových cest (aerobilie). '
      : '') +
    (biliaryEHSize >= 1 ? aBiliaryEHSize[biliaryEHSize].description : '') +
    (biliaryIHSize >= 1 || biliaryEHSize >= 1
      ? aBiliaryIHSize[biliaryIHSize].description
      : '');

  biliaryConclusion +=
    (biliaryEHSize >= 1 ? aBiliaryEHSize[biliaryEHSize].description : '') +
    (biliaryIHSize >= 1 || biliaryEHSize >= 1
      ? aBiliaryIHSize[biliaryIHSize].description
      : '');

  // Slezina
  let spleenDescription = aSpleenSize[spleenSize].description;
  let spleenConclusion = aSpleenSize[spleenSize].conclusion;

  // Pankreas
  let pancreasDescription =
    aPancreasLimitation[pancreasLimitation].description +
    (pancreasLimitation < 2
      ? aPancreasParenchyma[pancreasParenchyma].description +
        aPancreasDuctus[pancreasDuctus].description
      : '');
  let pancreasConclusion =
    pancreasLimitation < 2
      ? aPancreasParenchyma[pancreasParenchyma].conclusion +
        aPancreasDuctus[pancreasDuctus].conclusion
      : '';
  // Ledviny
  let kidneysDescription = '';
  let kidneysConclusion = '';

  // Description
  if (kidneyRparenchyma + kidneyLparenchyma + kidneyRKPS + kidneyLKPS === 0) {
    kidneysDescription +=
      'Obě ledviny ' +
      aKidneyParenchyma[kidneyRparenchyma].description +
      ' a ' +
      aKidneyKPS[kidneyRKPS].description +
      '. ';
  } else {
    kidneysDescription +=
      'Pravá ledvina ' +
      aKidneyParenchyma[kidneyRparenchyma].description +
      ' a ' +
      aKidneyKPS[kidneyRKPS].description +
      '. ';

    kidneysDescription +=
      'Levá ledvina ' +
      aKidneyParenchyma[kidneyLparenchyma].description +
      ' a ' +
      aKidneyKPS[kidneyLKPS].description +
      '. ';
  }

  // Conclusion
  if (kidneyRparenchyma + kidneyLparenchyma + kidneyRKPS + kidneyLKPS > 0) {
    if (
      kidneyRparenchyma === kidneyLparenchyma &&
      kidneyRKPS === kidneyLKPS &&
      kidneyRparenchyma > 0 &&
      kidneyRKPS > 0
    ) {
      kidneysConclusion +=
        'Obě ledviny s ' +
        aKidneyParenchyma[kidneyRparenchyma].conclusion +
        ' a ' +
        aKidneyKPS[kidneyRKPS].conclusion +
        '. ';
    } else {
      if (kidneyRparenchyma === kidneyLparenchyma && kidneyRparenchyma > 0) {
        kidneysConclusion +=
          'Obě ledviny s ' +
          aKidneyParenchyma[kidneyRparenchyma].conclusion +
          '. ';
        if (kidneyRKPS > 0) {
          kidneysConclusion +=
            'Pravá ledvina s ' + aKidneyKPS[kidneyRKPS].conclusion + '. ';
        }
        if (kidneyLKPS > 0) {
          kidneysConclusion +=
            'Levá ledvina s ' + aKidneyKPS[kidneyLKPS].conclusion + '. ';
        }
      } else if (kidneyRKPS === kidneyLKPS && kidneyRKPS > 0) {
        kidneysConclusion +=
          'Obě ledviny s ' + aKidneyKPS[kidneyRKPS].conclusion + '. ';
        if (kidneyRparenchyma > 0) {
          kidneysConclusion +=
            'Pravá ledvina s ' +
            aKidneyParenchyma[kidneyRparenchyma].conclusion +
            '. ';
        }
        if (kidneyLparenchyma > 0) {
          kidneysConclusion +=
            'Levá ledvina s ' +
            aKidneyParenchyma[kidneyLparenchyma].conclusion +
            '. ';
        }
      } else {
        if (kidneyRparenchyma > 0 || kidneyRKPS > 0) {
          if (kidneyRparenchyma > 0 && kidneyRKPS > 0) {
            kidneysConclusion +=
              'Pravá ledvina s ' +
              aKidneyKPS[kidneyRKPS].conclusion +
              ' a ' +
              aKidneyParenchyma[kidneyRparenchyma].conclusion +
              '. ';
          } else {
            if (kidneyRparenchyma > 0) {
              kidneysConclusion +=
                'Pravá ledvina s ' +
                aKidneyParenchyma[kidneyRparenchyma].conclusion +
                '. ';
            } else {
              kidneysConclusion +=
                'Pravá ledvina s ' + aKidneyKPS[kidneyRKPS].conclusion + '. ';
            }
          }
        }
        if (kidneyLparenchyma > 0 || kidneyLKPS > 0) {
          if (kidneyLparenchyma > 0 && kidneyLKPS > 0) {
            kidneysConclusion +=
              'Levá ledvina s ' +
              aKidneyKPS[kidneyLKPS].conclusion +
              ' a ' +
              aKidneyParenchyma[kidneyLparenchyma].conclusion +
              '. ';
          } else {
            if (kidneyLparenchyma > 0) {
              kidneysConclusion +=
                'Levá ledvina s ' +
                aKidneyParenchyma[kidneyLparenchyma].conclusion +
                '. ';
            } else {
              kidneysConclusion +=
                'Levá ledvina s ' + aKidneyKPS[kidneyLKPS].conclusion + '. ';
            }
          }
        }
      }
    }
  }

  // Appendix
  let appendixDescription = '';
  let appendixConclusion = '';

  if (appendix > 0) {
    appendixDescription += aAppendix[appendix].description;
    if (appendix === 2 || appendix === 3) {
      appendixDescription += aAppendixPos[appendixPos].description;
      if (appendix === 3) {
        appendixDescription +=
          ', šíře max. ' + aSizemm[appendixSize].text + '. ';
      } else {
        appendixDescription += '. ';
      }
    }
    appendixConclusion += aAppendix[appendix].conclusion;
    if (appendix === 3) {
      appendixDescription += aAppendixPos[appendixPos].conclusion;
    }
  }

  // Močový měchýř
  let urinaryBladderDescription = aUrinaryBladder[urinaryBladder].description;
  let urinaryBladderConclusion =
    urinaryBladder > 3 ? aUrinaryBladder[urinaryBladder].conclusion : '';

  // Intraabdominální tekutina
  let intraabdominalFluidDescription =
    aIntraabdominalFluid[intraabdominalFluid].description;
  let intraabdominalFluidConclusion =
    aIntraabdominalFluid[intraabdominalFluid].conclusion;

  // Ostatní pánev

  let pelvisOtherDescription = pelvisOther
    .map(item => {
      return aPelvisOther[item].description;
    })
    .join('');

  let pelvisOtherConclusion = pelvisOther
    .map(item => {
      return aPelvisOther[item].conclusion;
    })
    .join('');

  // Sigma

  let sigmoidDescription = aSigmoid[sigmoid].description;
  let sigmoidConclusion = aSigmoid[sigmoid].conclusion;

  if (sigmoid > 1) {
    sigmoidDescription += 'na max. ' + aSizemm[sigmoidWall].text + '. ';
    if (sigmoidDiverticles) {
      sigmoidDescription += 'Divertikly sigmatu. ';
      sigmoidConclusion += ' v terénu divertikulózy. ';
    } else {
      sigmoidConclusion += '. ';
    }
  }

  // Normální nebo jinak normální nález

  let isNormalConclusion = '';
  if (
    liverParenchyma +
      liverSize +
      GBwall +
      GBcontent +
      GBsize +
      biliaryEHSize +
      biliaryIHSize +
      spleenSize +
      kidneyRparenchyma +
      kidneyRKPS +
      kidneyLparenchyma +
      kidneyLKPS +
      (pancreasLimitation < 2 ? pancreasParenchyma + pancreasDuctus : 0) +
      appendix +
      sigmoid +
      (urinaryBladder > 3) +
      intraabdominalFluid +
      pelvisOther.length >
    0
  ) {
    if (exam === 0)
      isNormalConclusion = 'Jinak přiměřený nález na nitrobřišních orgánech. ';
    else if (exam === 1)
      isNormalConclusion =
        'Jinak přiměřený nález na játrech a žlučových cestách. ';
    else if (exam === 2)
      isNormalConclusion =
        'Jinak přiměřený nález na ledvinách a močovém měchýři. ';
  } else {
    if (exam === 0)
      isNormalConclusion = 'Přiměřený nález na nitrobřišních orgánech. ';
    else if (exam === 1)
      isNormalConclusion = 'Přiměřený nález na játrech a žlučových cestách. ';
    else if (exam === 2)
      isNormalConclusion = 'Přiměřený nález na ledvinách a močovém měchýři. ';
  }

  // Final
  if (exam === 0) {
    // UZ břicha
    // Description
    importDescription1(limitationDescription);
    importDescription1(
      liverDescription + biliaryDescription + spleenDescription
    );
    importDescription1(pancreasDescription);
    importDescription1(kidneysDescription);
    importDescription1(
      appendixDescription +
        sigmoidDescription +
        urinaryBladderDescription +
        pelvisOtherDescription +
        intraabdominalFluidDescription
    );
    // Conclusion
    importConclusion1(
      appendixConclusion +
        sigmoidConclusion +
        biliaryConclusion +
        liverConclusion +
        pancreasConclusion +
        kidneysConclusion +
        spleenConclusion +
        urinaryBladderConclusion +
        intraabdominalFluidConclusion +
        pelvisOtherConclusion +
        isNormalConclusion
    );
  } else if (exam === 1) {
    // UZ jater a žlučových cest
    // Description
    importDescription1(liverDescription + biliaryDescription);
    // Conclusion
    importConclusion1(biliaryConclusion + liverConclusion + isNormalConclusion);
  } else if (exam === 2) {
    // UZ ledvin a močového měchýře
    // Description
    importDescription1(
      kidneysDescription +
        urinaryBladderDescription +
        intraabdominalFluidDescription
    );
    // Conclusion
    importConclusion1(
      kidneysConclusion +
        urinaryBladderConclusion +
        intraabdominalFluidConclusion +
        isNormalConclusion
    );
  }

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
