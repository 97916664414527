import { green, yellow, orange, red, grey } from "../scripts/GeneralArrays";

export const black = "#000000";
export const darkred = "#a8071a";
export const white = "#ffffff";

export const aArteries = [
  {
    value: 0,
    text: "R_AIC",
    abb: "AIC",
    description: "a. iliaca comm. dx.",
    path: "M87.952 1.282c-1.292-.047-4.442.085-10.048.138-3.66 3.916-16.13 11.084-23.548 16.73-1.771 1.51-3.416 3.195-4.906 4.877l3.988 2.53 2.742-1.928C57 21.091 79.705 7.745 87.858 5.872zM103.27 33.088c-.572-.09-1.075-.25-1.492-.486.37.171.92.33 1.493.486z",
  },
  {
    value: 1,
    text: "R_AIE",
    abb: "AIE",
    description: "a. iliaca ext. dx.",
    path: "M49.45 23.027c-4.728 5.338-7.804 10.658-7.655 10.373-1.02 1.285-1.824 2.446-2.66 3.694l3.091 1.471c2.954-4.04 7.245-8.514 11.212-13.009z",
  },
  {
    value: 2,
    text: "R_AII",
    abb: "AII",
    description: "a. iliaca int. dx.",
    path: "M56.18 23.63l-2.742 1.926c4.166 3.362 11.92 8.426 14.036 13.389-.803-1.049-.427-2.586 0-3.969.447-.86 3.04-1.36 4.971-1.888-2.23.352-5.29-.27-6.526-1.003-4.952-2.933-8.987-7.632-9.702-8.116-.098-.07-.083-.198-.037-.34z",
  },
  {
    value: 3,
    text: "R_AFC",
    abb: "AFC",
    description: "a. fem. comm. dx.",
    path: "M39.135 37.094c-1.212 1.808-2.456 3.834-4.228 6.983v2.646h3.706c-.083-2.339 1.4-5.13 3.613-8.158z",
  },
  {
    value: 4,
    text: "R_AFSprox",
    abb: "prox. AFS",
    description: "a. fem. superf. dx. (prox. úsek)",
    path: "M34.907 46.723c-.918 7.747-.613 15.43.55 22.96l3.301-.38c-1.335-7.295-1.74-14.754-.145-22.58z",
  },
  {
    value: 5,
    text: "R_AFSmid",
    abb: "mid. AFS",
    description: "a. fem. superf. dx. (střední úsek)",
    path: "M38.758 69.303l-3.3.38c.736 4.763 1.81 9.466 3.155 14.081.488 3.017.887 5.986 1.222 8.92l3.887-.48a169.861 169.861 0 00-1.352-8.48c-1.43-4.77-2.722-9.56-3.612-14.421z",
  },
  {
    value: 6,
    text: "R_AFSdist",
    abb: "dist. AFS",
    description: "a. fem. superf. dx. (dist. úsek)",
    path: "M43.722 92.204l-3.887.48c.533 4.683.859 9.254.781 13.61.129 3.058.282 6.153.422 9.228l4.077-.117c-.11-3.03-.21-6.043-.4-9.19.06-4.504-.344-9.205-.993-14.01z",
  },
  {
    value: 7,
    text: "R_APF",
    abb: "APF",
    description: "a. prof. fem. dx.",
    path: "M34.907 46.723v-2.646c-5.514 2.693-7.98 4.661-11.97 7.937-4.706 5.292-1.609 15.595-1.609 15.595 1.975 5.495 4.8 11.51 5.69 16.343.64 4.54.734 9.026.15 13.443-1.549 4.23-3.03 8.75-3.379 18.12 1.239-5.155 2.249-10.432 4.14-15.236l3.144 8.694c-.053-3.97-1.725-8.01-1.809-10.496l.084-8.098c-.162-3.762-.485-7.639-1.684-12.14-3.966-9.735-5.02-14.227-4.988-16.764-.3-6.787 5.141-11.122 12.231-14.752z",
  },
  {
    value: 8,
    text: "R_APprox",
    abb: "prox. AP",
    description: "a. poplitea dx. (prox. úsek)",
    path: "M45.115 115.405l-4.077.117c.18 3.936.3 7.787.327 11.507l4.341-.19a360.327 360.327 0 01-.591-11.434z",
  },
  {
    value: 9,
    text: "R_APdist",
    abb: "dist. AP",
    description: "a. poplitea dx. (dist. úsek)",
    path: "M45.706 126.838l-4.341.191c.003.433.038.912.037 1.34l.524 8.032.787 3.3 4.332-.44a278.845 278.845 0 01-1.244-10.585c-.05-.641-.052-1.207-.095-1.838z",
  },
  {
    value: 10,
    text: "R_TTF",
    abb: "TTF",
    description: "truncus tibiofibularis dx.",
    path: "M47.045 139.26l-4.332.441c1.763 5.792 3.228 11.504 3.313 18.147l1.427 2.59 3.045-.624c-.932-6.223-2.116-10.567-3.453-20.554z",
  },
  {
    value: 11,
    text: "R_AF",
    abb: "AF",
    description: "a. fibularis dx.",
    path: "M47.453 160.438l-1.427-2.59c-6.012 5.778-9.319 10.966-11.418 15.995-3.95 12.102-1.57 20.814 1.348 29.238 1.39.496 9.786 36.473 9.284 35.825-.074-6.125-.993-13.305-2.746-21.496-.976-4.559-1.758-9.496-3.88-14.583-3.99-9.657-5.602-20.304-1.049-28.824 2.283-4.467 3.209-6.643 5.84-9.367z",
  },
  {
    value: 12,
    text: "R_ATP",
    abb: "ATP",
    description: "a. tibialis post. dx.",
    path: "M50.498 159.814l-3.045.624c1.928 3.783 1.272 20.916 1.344 28.622.131 1.965.786 28.333.786 28.333.155 10.299-.574 22.408-1.297 28.378-.192 1.583.188 3.21.717 5.129l1.84-.369c-.299-1.32-.55-2.35-.989-4.6.945-7.105 2.105-17.1 1.809-28.53l-.19-28.177c.396.141.184-25.51-.509-26.179-.141-1.204-.308-2.18-.466-3.231z",
  },
  {
    value: 13,
    text: "R_ATA",
    abb: "ATA",
    description: "a. tibialis ant. dx.",
    path: "M41.926 136.4c-6.597 7.09-14.537 17.13-16.433 22.878-3.679 11.151-1.035 34.94 2.658 49.121 3.307 12.456 6.757 24.855 8.722 36.855.257.888.861 2.859 1.4 4.64l1.496-.287c-.453-1.676-.846-3.247-.894-4.447l-3.706-18.52c-2.698-12.653-6.662-25.02-7.767-37.697-.836-9.592-2.792-19.578.88-28.824 2.454-6.18 6.651-12.303 14.43-20.418z",
  },
  {
    value: 14,
    text: "R_ADP",
    abb: "ADP",
    description: "a. dorsalis pedis dx.",
    path: "M39.77 249.607l-1.497.287c1.035 3.421 2.103 7.382 1.93 10.327-1.611 5.205-14.58 11.757-15.477 14.778 1.474-3.077 13.888-7.35 16.68-14.319.62-3.35-.678-7.532-1.637-11.073z",
  },
  {
    value: 15,
    text: "R_APP",
    abb: "APP",
    description: "a. plantaris pedis dx.",
    path: "M50.843 250.531l-1.84.369c.592 2.15 1.376 4.672 1.844 8.03.643 4.617-1.803 7.977-1.803 7.977-2.401 5.3-4.871 6.034-10.636 9.843 7.618-2.486 10.745-7.531 11.67-9.5 1.911-4.07 2.519-4.934 2.071-9.717-.213-2.278-.674-4.201-1.306-7.002z",
  },
  {
    value: 16,
    text: "L_AIC",
    abb: "AIC",
    description: "a. iliaca comm. sin.",
    path: "M87.764 1.282c1.291-.047 4.442.085 10.047.138 3.66 3.916 16.13 11.084 23.55 16.73 1.77 1.51 3.415 3.195 4.905 4.877l-3.988 2.53-2.742-1.928c-.82-2.538-23.525-15.884-31.678-17.757z",
  },
  {
    value: 17,
    text: "L_AIE",
    abb: "AIE",
    description: "a. iliaca ext. sin.",
    path: "M126.266 23.027c4.728 5.338 7.804 10.658 7.654 10.373 1.02 1.285 1.825 2.446 2.661 3.694l-3.091 1.471c-2.954-4.04-7.245-8.514-11.212-13.009z",
  },
  {
    value: 18,
    text: "L_AII",
    abb: "AII",
    description: "a. iliaca int. sin.",
    path: "M119.536 23.63l2.742 1.926c-4.167 3.362-11.92 8.426-14.037 13.389.804-1.049.428-2.586 0-3.969-.446-.86-3.039-1.36-4.97-1.888 2.23.352 5.29-.27 6.526-1.003 4.951-2.933 8.987-7.632 9.701-8.116.099-.07.084-.198.038-.34z",
  },
  {
    value: 19,
    text: "L_AFC",
    abb: "AFC",
    description: "a. fem. comm. sin.",
    path: "M136.581 37.094c1.212 1.808 2.456 3.834 4.227 6.983v2.646h-3.706c.084-2.339-1.4-5.13-3.612-8.158z",
  },
  {
    value: 20,
    text: "L_AFSprox",
    abb: "prox. AFS",
    description: "a. fem. superf. sin. (prox. úsek)",
    path: "M140.808 46.723c.918 7.747.614 15.43-.55 22.96l-3.3-.38c1.335-7.295 1.74-14.754.144-22.58z",
  },
  {
    value: 21,
    text: "L_AFSmid",
    abb: "mid. AFS",
    description: "a. fem. superf. sin. (střední úsek)",
    path: "M136.958 69.303l3.3.38c-.736 4.763-1.81 9.466-3.156 14.081a192.092 192.092 0 00-1.22 8.92l-3.888-.48c.378-2.796.817-5.618 1.352-8.48 1.43-4.77 2.722-9.56 3.612-14.421z",
  },
  {
    value: 22,
    text: "L_AFSdist",
    abb: "dist. AFS",
    description: "a. fem. superf. sin. (dist. úsek)",
    path: "M131.994 92.204l3.887.48c-.534 4.683-.859 9.254-.782 13.61-.128 3.058-.282 6.153-.422 9.228l-4.076-.117c.11-3.03.21-6.043.4-9.19-.06-4.504.344-9.205.993-14.01z",
  },
  {
    value: 23,
    text: "L_APF",
    abb: "APF",
    description: "a. prof. fem. sin.",
    path: "M140.808 46.723v-2.646c5.515 2.693 7.98 4.661 11.97 7.937 4.707 5.292 1.61 15.595 1.61 15.595-1.975 5.495-4.8 11.51-5.69 16.343-.64 4.54-.734 9.026-.15 13.443 1.549 4.23 3.03 8.75 3.379 18.12-1.239-5.155-2.249-10.432-4.14-15.236l-3.144 8.694c.053-3.97 1.724-8.01 1.808-10.496l-.083-8.098c.162-3.762.485-7.639 1.684-12.14 3.966-9.735 5.02-14.227 4.988-16.764.3-6.787-5.141-11.122-12.232-14.752z",
  },
  {
    value: 24,
    text: "L_APprox",
    abb: "prox. AP",
    description: "a. poplitea sin. (prox. úsek)",
    path: "M130.6 115.405l4.077.117a303.68 303.68 0 00-.326 11.507l-4.342-.19c.268-3.922.456-7.719.592-11.434z",
  },
  {
    value: 25,
    text: "L_APdist",
    abb: "dist. AP",
    description: "a. poplitea sin. (dist. úsek)",
    path: "M130.01 126.838l4.341.191c-.004.433-.038.912-.038 1.34l-.524 8.032-.786 3.3-4.332-.44c.405-3.027.816-6.214 1.244-10.585.05-.641.051-1.207.094-1.838z",
  },
  {
    value: 26,
    text: "L_TTF",
    abb: "TTF",
    description: "truncus tibiofibularis sin.",
    path: "M128.671 139.26l4.332.441c-1.763 5.792-3.229 11.504-3.313 18.147l-1.427 2.59-3.045-.624c.932-6.223 2.116-10.567 3.453-20.554z",
  },
  {
    value: 27,
    text: "L_AF",
    abb: "AF",
    description: "a. fibularis sin.",
    path: "M128.263 160.438l1.427-2.59c6.011 5.778 9.319 10.966 11.417 15.995 3.951 12.102 1.57 20.814-1.347 29.238-1.39.496-9.786 36.473-9.284 35.825.074-6.125.993-13.305 2.746-21.496.976-4.559 1.758-9.496 3.88-14.583 3.99-9.657 5.602-20.304 1.048-28.824-2.282-4.467-3.208-6.643-5.84-9.367z",
  },
  {
    value: 28,
    text: "L_ATP",
    abb: "ATP",
    description: "a. tibialis post. sin.",
    path: "M125.218 159.814l3.045.624c-1.928 3.783-1.272 20.916-1.344 28.622-.131 1.965-.786 28.333-.786 28.333-.156 10.299.573 22.408 1.297 28.378.192 1.583-.188 3.21-.717 5.129l-1.84-.369c.298-1.32.55-2.35.989-4.6-.945-7.105-2.105-17.1-1.81-28.53l.191-28.177c-.396.141-.184-25.51.508-26.179.142-1.204.31-2.18.467-3.231z",
  },
  {
    value: 29,
    text: "L_ATA",
    abb: "ATA",
    description: "a. tibialis ant. sin.",
    path: "M133.79 136.4c6.596 7.09 14.536 17.13 16.433 22.878 3.679 11.151 1.035 34.94-2.658 49.121-3.307 12.456-6.757 24.855-8.723 36.855-.256.887-.86 2.859-1.4 4.64l-1.496-.287c.454-1.676.846-3.247.894-4.447l3.706-18.52c2.698-12.653 6.663-25.02 7.768-37.697.836-9.592 2.791-19.578-.88-28.824-2.455-6.18-6.651-12.303-14.43-20.418z",
  },
  {
    value: 30,
    text: "L_ADP",
    abb: "ADP",
    description: "a. dorsalis pedis sin.",
    path: "M135.946 249.607l1.497.287c-1.035 3.421-2.104 7.382-1.93 10.327 1.611 5.205 14.58 11.757 15.477 14.778-1.475-3.077-13.888-7.35-16.681-14.319-.62-3.35.68-7.532 1.637-11.073z",
  },
  {
    value: 31,
    text: "L_APP",
    abb: "APP",
    description: "a. plantaris pedis sin.",
    path: "M124.873 250.531l1.84.369c-.592 2.15-1.376 4.672-1.844 8.03-.643 4.617 1.803 7.977 1.803 7.977 2.401 5.3 4.871 6.034 10.636 9.843-7.618-2.486-10.745-7.531-11.67-9.5-1.912-4.07-2.52-4.934-2.072-9.717.214-2.278.674-4.201 1.307-7.002z",
  },
];

export const aArteriesBackup = [
  {
    value: 0,
    text: "R_AIC",
    abb: "AIC",
    description: "a. iliaca comm. dx.",
    path: "m186.43-168.3c-0.50587 2e-3 -1.0086 5e-3 -1.5255 0.0124-4.8835 6.8458-12.444 10.992-17.342 17.042l3.3109 2.7647c4.3397-4.6544 15.842-14.292 20.645-15.838v-3.9688c-2.0271-1e-3 -3.5715-0.0168-5.0891-0.0124z",
  },
  {
    value: 1,
    text: "R_AIE",
    abb: "AIE",
    description: "a. iliaca ext. dx.",
    path: "m165.97-149.13c-2.9053 5.4127-6.1452 9.5163-8.7064 15.08l2.5265 1.142c2.4524-5.3191 5.6638-8.7459 9.1493-13.743z",
  },
  {
    value: 2,
    text: "R_AII",
    abb: "AII",
    description: "a. iliaca int. dx.",
    path: "m171.67-147.12-1.3844 1.5984c4.2656 3.0873 6.5564 7.0367 8 10.807-0.18947-0.96178-0.30345-2.9295-0.0651-4.0085 0.31856-0.86012 2.5335-0.56246 3.9119-1.0888-1.5948 0.36624-3.8068-0.26942-4.6953-1.0067-0.80762-0.73184-4.0003-3.9679-5.7671-6.3014z",
  },
  {
    value: 3,
    text: "R_AFC",
    abb: "AFC",
    description: "a. fem. comm. dx.",
    path: "m156.13-131.64c-0.96385 2.2844-1.6904 4.6182-2.0278 7.028l2.7983 0.37724c0.37141-2.3363 1.0065-4.4651 1.8278-6.4596z",
  },
  {
    value: 4,
    text: "R_AFSprox",
    abb: "prox. AFS",
    description: "a. fem. comm. dx. (prox. úsek)",
    path: "m153.88-121.98c-0.36753 6.3502-0.17422 12.799 0.4005 19.048l2.3652-0.20671c-0.60087-5.9659-0.72378-12.072-0.0651-18.477z",
  },
  {
    value: 5,
    text: "R_AFSmid",
    abb: "mid. AFS",
    description: "a. fem. comm. dx. (střední úsek)",
    path: "m156.93-100.67-2.3756 0.20826c0.76808 7.6853 2.5892 15.484 3.268 22.511l2.7502-0.24081c-0.73913-7.2388-2.9158-15.958-3.6427-22.479z",
  },
  {
    value: 6,
    text: "R_AFSdist",
    abb: "dist. AFS",
    description: "a. fem. comm. dx. (dist. úsek)",
    path: "m161.09-75.686-2.7652 0.24184c0.73134 7.585 0.36002 20.241 0.59428 27.171l2.9362-0.0698c-0.2019-7.1493-0.0896-20.978-0.76532-27.343z",
  },
  {
    value: 7,
    text: "R_APF",
    abb: "APF",
    description: "a. prof. fem.",
    path: "m151.34-123.83c-0.90611 0.16715-10.049 11.261-6.9277 21.506 3.2112 10.409 5.0834 19.639 4.1687 29.786-1.1057 4.2296-2.1626 8.7504-2.4117 18.12 0.88407-5.1542 1.6046-10.431 2.9549-15.235l2.2448 8.6935c-0.0381-3.9695-1.2308-8.0102-1.2909-10.496l0.0599-8.0977c0.15993-8.1394-2.5694-16.951-4.2028-23.338-2.096-8.0994 2.7889-15.872 5.9438-18.679l-0.5023-2.2593c-0.0102-3e-3 -0.0223-3e-3 -0.0367-5.3e-4z",
  },
  {
    value: 8,
    text: "R_APprox",
    abb: "prox. AP",
    description: "a. poplitea (prox. úsek)",
    path: "m159.11-46.048c0.01 1.4544 0.0193 8.0593 0.0377 13.631h2.8846c-0.0212-5.4635-0.0873-13.626-0.0873-13.626l-2.835-5e-3z",
  },
  {
    value: 9,
    text: "R_APdist",
    abb: "dist. AP",
    description: "a. poplitea (dist. úsek)",
    path: "m159.16-30.707c0.0246 5.919 0.53069 13.065 0.60307 14.618l3.0174-0.13126c-0.17605-2.0481-0.70384-8.8708-0.73381-14.486z",
  },
  {
    value: 10,
    text: "R_TTF",
    abb: "TTF",
    description: "truncus tibiofibularis",
    path: "m163.16-13.803-2.8505 0.28991c0.97771 6.9494 0.58308 3.7103 1.8386 13.64l2.095-0.23357c-0.5085-6.7877-0.5085-8.1106-1.0831-13.696z",
  },
  {
    value: 11,
    text: "R_AF",
    abb: "AF",
    description: "a. fibularis",
    path: "m160.09 0.50495c-15.364 18.451-1.2013 47.034 1.3896 68.189 0.84798-14.895-15.456-48.532-0.81339-64.921z",
  },
  {
    value: 12,
    text: "R_ATP",
    abb: "ATP",
    description: "a. tibialis post.",
    path: "m164.49 2.5276-1.9828 0.17363c1.3316 26.874 3.3838 46.189 1.1508 73.141-0.0181 0.20956-0.0292 0.41952-0.0341 0.63045l1.2056-0.10542c-0.0183-0.13049-0.0335-0.23013-0.0522-0.36483 1.5814-18.337 1.8173-45.324-0.2651-73.228-7e-3 -0.0854-0.015-0.16212-0.0222-0.2465z",
  },
  {
    value: 13,
    text: "R_ATA",
    abb: "ATA",
    description: "a. tibialis ant.",
    path: "m157.5-10.005c-19.266 29.285-5.2842 57.345-1.9839 85.33 0.0863 0.41776 0.21909 1.0094 0.36743 1.666l1.2144-0.10646c-0.0817-0.59744-0.1377-1.1546-0.15193-1.6531-5.3074-31.159-14.531-59.598 1.727-82.991-0.40256-0.74245-0.78741-1.4941-1.1731-2.2454z",
  },
  {
    value: 14,
    text: "R_ADP",
    abb: "ADP",
    description: "a. dorsalis pedis",
    path: "m157.55 79.502-1.0883 0.0951c1.9849 16.697-8.5984 23.311-9.6165 25.474 7.6326-6.1319 12.263-13.54 10.705-25.569z",
  },
  {
    value: 15,
    text: "R_APP",
    abb: "APP",
    description: "a. plantaris pedis",
    path: "m165.22 78.99-1.3849 0.12092c2.5497 11.891 1.2268 21.152-7.2213 27.711 11.228-6.0137 10.08-18.347 8.6062-27.832z",
  },
];

export const aStenosis = [
  {
    value: 0,
    text: "bez stenózy",
    description: "bez stenózy",
    color: green,
  },
  {
    value: 1,
    text: "významná",
    description: "významná stenóza",
    color: red,
  },
  {
    value: 2,
    text: "uzávěr",
    description: "uzávěr",
    color: black,
  },
];
