import React from 'react';

// Custom scripts
import {
  aCustom,
  aFazekas,
  aGliosisInfratentorial,
  aPerivascSpaces,
  aSeptumPellucidum,
  aGCA,
  aMTA,
  aKoedam,
  aFocalAtrophy,
  aCalvarium,
  aPituitary,
  aMastoid,
  aSeptumNasi,
  aConchaBullosa,
  aLymbic,
  aIntraaxial,
  aArteriesVariety,
  aDemyelinSupra,
  aDemyelinSupraFilled,
  aDemyelinInfra,
  aDemyelinInfraFilled,
  aDemyelinIntramed,
  aDemyelinIntramedFilled,
  aLatVentAsymetry,
} from './CT_brain_arrays';
import { generateReport } from './CT_brain_functions';

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from '../scripts/GeneralArrays';
import { formatCustomInput } from '../scripts/GeneralFunctions';

// Custom components
import DropDown from '../components/DropDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';
import ButtonUpDown from '../components/ButtonUpDown';
import CustomCards from '../components/CustomCards';

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
} from 'antd';
import { debounce } from 'lodash';
import { CloseCircleOutlined } from '@ant-design/icons';
const { TextArea } = Input;

class CT_brain extends React.Component {
  state = {
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      protocol: this.props.protocol,
      contrast: this.props.contrast,
    },
    general: {
      // Glióza
      Fazekas: 0,
      gliosisInfratentorial: 0,
      // Demyelinizace
      demyelinSupraDescription: '',
      demyelinSupraConclusion: '',
      demyelinSupraButton: 0,
      demyelinInfraDescription: '',
      demyelinInfraConclusion: '',
      demyelinInfraButton: 0,
      demyelinIntramedDescription: '',
      demyelinIntramedConclusion: '',
      demyelinIntramedButton: 0,
      demyelinInfra: '',
      demyelinIntramed: '',
      // Atrofie
      GCA: 0,
      MTAright: 0,
      MTAleft: 0,
      Koedam: 0,
      focalAtrophy: 0,
      // Ostatní
      pituitary: 0,
      RathkeCystSize: 0,
      pinealCyst: false,
      pinealCystSize: '',
      cavernoma: 0,
      DVA: 0,
      atherosclerosis: false,
      xanthogranulomas: false,
      lensRight: false,
      lensLeft: false,
      septumNasi: 0,
      concha: 0,
      mastoidRight: 0,
      mastoidLeft: 0,
      // Variety
      perivascSpaces: 0,
      septumPellucidum: 0,
      calvarium: 0,
      ependymitisGranularis: false,
      lymbic: 0,
      megacisterna: false,
      falxCalc: false,
      latVentAsymetry: 0,
      // VDN
      sinusFR: false,
      sinusFL: false,
      sinusMR: false,
      sinusML: false,
      sinusER: false,
      sinusEL: false,
      sinusSR: false,
      sinusSL: false,
      // Nosní dutina
      septumDeviation: 0,
      conchaBulosa: 0,
      // Mozkové tepny
      brainArtDescription: '',
      brainArtConclusion: '',
      A1R: 0,
      A1L: 0,
      P1R: 0,
      P1L: 0,
      VR: 0,
      VL: 0,
    },
    custom: [],
    key: 0,
  };

  initialState = this.state;

  resetExamState = () => {
    this.initialState.key += 1;
    this.setState(this.initialState);
  };

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = event => {
    let patType = event.key;
    let description = '';
    let conclusion = '';

    let newRecord = {
      patType: patType,
      description: description,
      conclusion: conclusion,
    };

    this.setState(prevState => ({
      custom: [...prevState.custom, newRecord],
    }));
  };

  updateRow = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;

    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    {
      //console.log({ ...this.state });
    }
    const protocol = this.props.protocol;
    const myContent = [
      {
        title: 'glióza',
        color: aCardColors.blue,
        rows: [
          [
            {
              label: 'Fazekas',
              input:
                this.state.report.exam !== 5 ? (
                  <ButtonUpDown
                    key={'Fazekas' + this.state.key}
                    name='Fazekas'
                    segment='general'
                    value={this.state.general.Fazekas}
                    options={aFazekas}
                    onChange={this.onChange}
                  />
                ) : null,
            },
          ],
        ],
      },
      {
        title: 'atrofie',
        color: aCardColors.green,
        rows: [
          [
            {
              label: 'GCA',
              input: (
                <ButtonUpDown
                  key={'GCA' + this.state.key}
                  name='GCA'
                  segment='general'
                  value={this.state.general.GCA}
                  options={aGCA}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'Koedam',
              input: (
                <ButtonUpDown
                  key={'Koedam' + this.state.key}
                  name='Koedam'
                  segment='general'
                  value={this.state.general.Koedam}
                  options={aKoedam}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'MTA dx/sin',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonUpDown
                      key={'MTAright' + this.state.key}
                      name='MTAright'
                      segment='general'
                      value={this.state.general.MTAright}
                      options={aMTA}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      key={'MTAleft' + this.state.key}
                      name='MTAleft'
                      segment='general'
                      value={this.state.general.MTAleft}
                      options={aMTA}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'fokální',
              input: (
                <DropDown
                  key={'focalAtrophy' + this.state.key}
                  name='focalAtrophy'
                  segment='general'
                  defaultValue={this.state.general.focalAtrophy}
                  options={aFocalAtrophy}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'ostatní',
        color: aCardColors.orange,
        rows: [
          [
            {
              label: 'hypofýza',
              input: (
                <Row wrap={false}>
                  <Col span={this.state.general.pituitary === 3 ? 8 : 24}>
                    <DropDown
                      key={'pituitary' + this.state.key}
                      name='pituitary'
                      segment='general'
                      defaultValue={this.state.general.pituitary}
                      options={aPituitary}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.pituitary === 3 ? (
                    <Col span={16}>
                      <Input
                        key={'RathkeCystSize' + this.state.key}
                        name='RathkeCystSize'
                        value={this.state.general.RathkeCystSize}
                        size='small'
                        placeholder='velikost v mm'
                        onChange={event =>
                          this.onChange({
                            segment: 'general',
                            name: 'RathkeCystSize',
                            value: event.target.value,
                          })
                        }
                      />
                    </Col>
                  ) : (
                    <Col span={0}></Col>
                  )}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'pineální cysta',
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name='pinealCyst'
                      segment='general'
                      value={this.state.general.pinealCyst}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col>
                    {this.state.general.pinealCyst ? (
                      <Input
                        name='pinealCystSize'
                        value={this.state.general.pinealCystSize}
                        size='small'
                        placeholder='velikost...'
                        suffix='mm'
                        onChange={event =>
                          this.onChange({
                            segment: 'general',
                            name: 'pinealCystSize',
                            value: event.target.value,
                          })
                        }
                        style={{ width: 130 }}
                      />
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'AS tepen',
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonTrueFalse
                      name='atherosclerosis'
                      segment='general'
                      value={this.state.general.atherosclerosis}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'náhrada čoček',
              input: (
                <Row>
                  <Col span={12}>
                    <ButtonTrueFalse
                      key={'lensRight' + this.state.key}
                      name='lensRight'
                      segment='general'
                      value={this.state.general.lensRight}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonTrueFalse
                      key={'lensLeft' + this.state.key}
                      name='lensLeft'
                      segment='general'
                      value={this.state.general.lensLeft}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'VDN',
              input: (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={6}></Col>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusFR' + this.state.key}
                          name='sinusFR'
                          segment='general'
                          value={this.state.general.sinusFR}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusFL' + this.state.key}
                          name='sinusFL'
                          segment='general'
                          value={this.state.general.sinusFL}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={6}></Col>
                    </Row>
                    <Row>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusMR' + this.state.key}
                          name='sinusMR'
                          segment='general'
                          value={this.state.general.sinusMR}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusER' + this.state.key}
                          name='sinusER'
                          segment='general'
                          value={this.state.general.sinusER}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusEL' + this.state.key}
                          name='sinusEL'
                          segment='general'
                          value={this.state.general.sinusEL}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusML' + this.state.key}
                          name='sinusML'
                          segment='general'
                          value={this.state.general.sinusML}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={6}></Col>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusSR' + this.state.key}
                          name='sinusSR'
                          segment='general'
                          value={this.state.general.sinusSR}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={6}>
                        <ButtonTrueFalse
                          key={'sinusSL' + this.state.key}
                          name='sinusSL'
                          segment='general'
                          value={this.state.general.sinusSL}
                          options={aTrueFalse}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={6}></Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'mastoidy',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      key={'mastoidRight' + this.state.key}
                      name='mastoidRight'
                      segment='general'
                      value={this.state.general.mastoidRight}
                      options={aMastoid}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      key={'mastoidLeft' + this.state.key}
                      name='mastoidLeft'
                      segment='general'
                      value={this.state.general.mastoidLeft}
                      options={aMastoid}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: 'variety',
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 's. pellucidum',
              input: (
                <DropDown
                  key={'septumPellucidum' + this.state.key}
                  name='septumPellucidum'
                  segment='general'
                  defaultValue={this.state.general.septumPellucidum}
                  options={aSeptumPellucidum}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'kalva',
              input: (
                <ButtonUpDown
                  key={'calvarium' + this.state.key}
                  name='calvarium'
                  segment='general'
                  value={this.state.general.calvarium}
                  options={aCalvarium}
                  onChange={this.onChange}
                />
              ),
            },
          ],

          [
            {
              label: 'mesial. oblast',
              input: (
                <DropDown
                  key={'lymbic' + this.state.key}
                  name='lymbic'
                  segment='general'
                  defaultValue={this.state.general.lymbic}
                  options={aLymbic}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'megacisterna',
              input: (
                <ButtonTrueFalse
                  key={'megacisterna' + this.state.key}
                  name='megacisterna'
                  segment='general'
                  value={this.state.general.megacisterna}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'kalc. falx',
              input: (
                <ButtonTrueFalse
                  key={'falxCalc' + this.state.key}
                  name='falxCalc'
                  segment='general'
                  value={this.state.general.falxCalc}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'septum nasi',
              input: (
                <ButtonUpDown
                  name='septumNasi'
                  segment='general'
                  value={this.state.general.septumNasi}
                  options={aSeptumNasi}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'con. bullosa',
              input: (
                <ButtonUpDown
                  name='concha'
                  segment='general'
                  value={this.state.general.concha}
                  options={aConchaBullosa}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'asym. komor',
              input: (
                <ButtonUpDown
                  name='latVentAsymetry'
                  segment='general'
                  value={this.state.general.latVentAsymetry}
                  options={aLatVentAsymetry}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];

    const brainArteries = [
      {
        title: 'mozkové tepny - vlastní popis',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: '',
              input: (
                <TextArea
                  name='brainArtDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.brainArtDescription}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'brainArtDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: '',
              input: (
                <TextArea
                  name='brainArtConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.brainArtConclusion}
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'brainArtConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
      {
        title: 'mozkové tepny - variety',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'A1 segmenty',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='A1R'
                      segment='general'
                      value={this.state.general.A1R}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='A1L'
                      segment='general'
                      value={this.state.general.A1L}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'P1 segmenty',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='P1R'
                      segment='general'
                      value={this.state.general.P1R}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='P1L'
                      segment='general'
                      value={this.state.general.P1L}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'a. vertebrales',
              input: (
                <Row wrap={false}>
                  <Col span={12}>
                    <ButtonUpDown
                      name='VR'
                      segment='general'
                      value={this.state.general.VR}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={12}>
                    <ButtonUpDown
                      name='VL'
                      segment='general'
                      value={this.state.general.VL}
                      options={aArteriesVariety}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const Block2 = [
      {
        title: 'CT mozku - vlastní popis',
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: '',
              input: (
                <>
                  {this.state.custom.map((item, index) => {
                    return (
                      <Col
                        span={24}
                        style={{ marginBottom: '10px' }}
                        key={index}
                      >
                        <Row align='middle'>
                          <Col flex='100px'>
                            <Divider
                              plain
                              orientation='center'
                              style={{ margin: '0px' }}
                            >
                              <Tag
                                color={aCustom[item.patType].color}
                                style={{ color: 'black' }}
                              >
                                {aCustom[item.patType].label}
                              </Tag>
                            </Divider>
                          </Col>
                          <Col flex='auto'>
                            <Divider style={{ margin: '0' }}></Divider>
                          </Col>
                          <Col flex='25px' align='middle'>
                            <CloseCircleOutlined
                              style={{ fontSize: '17px', color: 'red' }}
                              onClick={() => this.deleteRow(index, 'custom')}
                            />
                          </Col>
                        </Row>
                        <Row align='middle'>
                          <Col
                            flex='100px'
                            style={{
                              textAlign: 'right',
                              paddingRight: '5px',
                            }}
                          >
                            popis:
                          </Col>
                          <Col flex='auto'>
                            <TextArea
                              name='description'
                              placeholder='popis nálezu...'
                              defaultValue={
                                this.state.custom[index].description
                              }
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onChange={event =>
                                this.updateRow(
                                  index,
                                  'custom',
                                  'description',
                                  formatCustomInput(event.target.value)
                                )
                              }
                            />
                          </Col>
                        </Row>
                        <Row align='middle'>
                          <Col
                            flex='100px'
                            style={{
                              textAlign: 'right',
                              paddingRight: '5px',
                            }}
                          >
                            závěr:
                          </Col>
                          <Col flex='auto'>
                            <TextArea
                              name='conclusion'
                              placeholder='závěr nálezu...'
                              defaultValue={this.state.custom[index].conclusion}
                              autoSize={{ minRows: 1, maxRows: 3 }}
                              onChange={event =>
                                this.updateRow(
                                  index,
                                  'custom',
                                  'conclusion',
                                  formatCustomInput(event.target.value)
                                )
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              ),
            },
          ],
          [
            {
              label: '',
              input: (
                <Row>
                  <Col flex='100px'>
                    <Dropdown
                      placement='bottomLeft'
                      trigger={['click']}
                      style={{ width: '100%' }}
                      overlay={
                        <Menu onClick={this.newRow}>
                          {aCustom.map(item => (
                            <Menu.Item key={item.value} value={item.value}>
                              {item.label}
                            </Menu.Item>
                          ))}
                        </Menu>
                      }
                    >
                      <Button
                        size='small'
                        type='dashed'
                        style={{ width: '100%' }}
                      >
                        nový nález...
                      </Button>
                    </Dropdown>
                  </Col>
                  <Col flex='auto'></Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const blockMS = [
      {
        title: 'demyelinizace',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'supratentoriální',
              input: (
                <Row>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinSupraDescription'
                      segment='general'
                      placeholder='popis...'
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinSupraDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinSupraConclusion'
                      segment='general'
                      placeholder='závěr...'
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinSupraConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col flex='auto'>
                    <ButtonUpDown
                      name='demyelinSupraButton'
                      segment='general'
                      value={this.state.general.demyelinSupraButton}
                      options={
                        this.state.general.demyelinSupraDescription != '' ||
                        this.state.general.demyelinSupraConclusion != ''
                          ? aDemyelinSupraFilled
                          : aDemyelinSupra
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'infratentoriální',
              input: (
                <Row>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinInfraDescription'
                      segment='general'
                      placeholder='popis...'
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinInfraDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinInfraConclusion'
                      segment='general'
                      placeholder='závěr...'
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinInfraConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col flex='auto'>
                    <ButtonUpDown
                      name='demyelinInfraButton'
                      segment='general'
                      value={this.state.general.demyelinInfraButton}
                      options={
                        this.state.general.demyelinInfraDescription != '' ||
                        this.state.general.demyelinInfraConclusion != ''
                          ? aDemyelinInfraFilled
                          : aDemyelinInfra
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'intramedulární',
              input: (
                <Row>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinIntramedDescription'
                      segment='general'
                      placeholder='popis...'
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinIntramedDescription',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={10}>
                    <TextArea
                      size='small'
                      name='demyelinIntramedConclusion'
                      segment='general'
                      placeholder='závěr...'
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          name: 'demyelinIntramedConclusion',
                          segment: 'general',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col flex='auto'>
                    <ButtonUpDown
                      name='demyelinIntramedButton'
                      segment='general'
                      value={this.state.general.demyelinIntramedButton}
                      options={
                        this.state.general.demyelinIntramedDescription != '' ||
                        this.state.general.demyelinIntramedConclusion != ''
                          ? aDemyelinIntramedFilled
                          : aDemyelinIntramed
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        {protocol === 5 ? <CustomCards content={blockMS} /> : null}
        <CustomCards content={myContent} />
        {protocol === 2 ? <CustomCards content={brainArteries} /> : null}
        <CustomCards content={Block2} />
      </Form>
    );
  }
}

export default CT_brain;
