import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from "antd";

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <Button
      size="small"
      className="btn btn-primary btn-block"
      onClick={() => loginWithRedirect()}
    >
      přihlásit
    </Button>
  );
};

export default LoginButton;
