import { green, yellow, orange, red, grey } from '../scripts/GeneralArrays';

export const aExams = [
  {
    value: 0,
    text: 'MR prostaty',
  },
];

export const aHyperplasia = [
  {
    value: 0,
    text: 'žádná',
    description: 'Přiměřený poměr přechodové a periferní zóny. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná',
    description:
      'Mírné zbytnění nodulárně prostavěné přechodové zóny prostaty. ',
    conclusion: 'Mírná benigní hyperplázie prostaty. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'středně pokročilá',
    description:
      'Zbytnění nodulárně prostavěné přechodové zóny a tak útlak periferní zóny prostaty. ',
    conclusion: 'Středně pokročilá hyperplázie prostaty. ',
    color: orange,
  },
  {
    value: 3,
    text: 'pokročilá',
    description:
      'Pokročilé zbytnění nodulárně prostavěné přechodové zóny a tak výrazný útlak periferní zóny prostaty. ',
    conclusion: 'Velmi pokročilá hyperplázie prostaty. ',
    color: red,
  },
];

export const aSeminalVesicles = [
  {
    value: 0,
    text: 'intaktní',
    description: 'intaktní',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'pravý infiltrovaný',
    description: 'pravý infiltrovaný',
    conclusion: '',
    color: red,
  },
  {
    value: 2,
    text: 'levý infiltrovaný',
    description: 'levý infiltrovaný',
    conclusion: '',
    color: red,
  },
  {
    value: 3,
    text: 'oba infiltrované',
    description: 'oba infiltrované',
    conclusion: '',
    color: red,
  },
  {
    value: 3,
    text: 'nehodnotitelné pro chabou náplň',
    description: 'pro chabou náplň nehodnotitelné',
    conclusion: '',
    color: yellow,
  },
];

export const aSegments = [
  {
    value: 0,
    text: 'PZa',
  },
  {
    value: 1,
    text: 'PZp',
  },
  {
    value: 2,
    text: 'PZm',
  },
  {
    value: 3,
    text: 'TZa',
  },
  {
    value: 4,
    text: 'TZp',
  },
  {
    value: 5,
    text: 'CZ',
  },
  {
    value: 6,
    text: 'AFS',
  },
  {
    value: 7,
    text: 'S',
  },
  {
    value: 8,
    text: 'U',
  },
];

export const aLevel = [
  {
    value: 0,
    text: 'baze',
    description: 'v úrovni baze prostaty ',
    conclusion: 'baze',
  },
  {
    value: 1,
    text: 'baze/střed',
    description: 'v úrovni rozhraní baze a střední etáže prostaty ',
    conclusion: 'rozhraní baze a střední etáž',
  },
  {
    value: 2,
    text: 'střední etáž',
    description: 'v úrovni střední etáže prostaty ',
    conclusion: 'střední etáž',
  },
  {
    value: 3,
    text: 'střed/apex',
    description: 'v úrovni střední etáže a apexu prostaty ',
    conclusion: 'rozhraní střední etáž a apex',
  },
  {
    value: 4,
    text: 'apex',
    description: 'v úrovni apexu prostaty ',
    conclusion: 'apex',
  },
];

export const aSite = [
  {
    value: 0,
    text: 'vpravo',
    description: 'vpravo ',
    conclusion: '',
  },
  {
    value: 1,
    text: 'vlevo',
    description: 'vlevo ',
    conclusion: '',
  },
];

export const aT2 = [
  {
    value: 0,
    text: 'T2 score 2',
    descriptionTZ: 'dobře ohraničený uzel homogenně nízké SI na T2',
    descriptionPZ: 'okrsek mírně nižší SI na T2',
    conclusion: 'T2 score 2',
  },
  {
    value: 1,
    text: 'T2 score 3',
    descriptionTZ: 'místy neohraničený okrsek nehomogenně nízké SI na T2',
    descriptionPZ: 'neohraničený nehomogenní okrsek středně nízké SI na T2',
    conclusion: 'T2 score 3',
  },
  {
    value: 2,
    text: 'T2 score 4',
    descriptionTZ:
      'neohraničený okrsek nehomogenně nízké SI na T2 o velikosti méně než 15mm',
    descriptionPZ:
      'ohraničený okrsek homogenně nízké SI na T2 o velikosti méně než 15mm',
    conclusion: 'T2 score 4',
  },
  {
    value: 3,
    text: 'T2 score 5',
    descriptionTZ:
      'neohraničený okrsek nehomogenně nízké SI na T2 o velikosti 15mm a více',
    descriptionPZ:
      'ohraničený okrsek homogenně nízké SI na T2 o velikosti 15mm a více',
    conclusion: 'T2 score 5',
  },
];

export const aDWI = [
  {
    value: 0,
    text: 'DWI score 2',
    description: 'mírně vyšší SI na DWI bez výraznějšího snížení SI na ADC',
    conclusion: 'DWI score 2',
  },
  {
    value: 1,
    text: 'DWI score 3',
    description: 'nápadně vyšší SI na DWI nebo nápadně nižší SI na ADC',
    conclusion: 'DWI score 3',
  },
  {
    value: 2,
    text: 'DWI score 4',
    description:
      'nápadně vyšší SI na DWI a současně nápadně nižší SI na ADC o velikosti méně než 15mm',
    conclusion: 'DWI score 4',
  },
  {
    value: 3,
    text: 'DWI score 5',
    description:
      'nápadně vyšší SI na DWI a současně nápadně nižší SI na ADC o velikosti 15mm a více',
    conclusion: 'DWI score 5',
  },
];

export const aDCE = [
  {
    value: 0,
    text: 'bez časného sycení',
    description: 'bez časného postkontrastního sycení',
    conclusion: 'DCE-',
  },
  {
    value: 1,
    text: 'časné sycení',
    description: 'vykazující časné postkontrastní sycení',
    conclusion: 'DCE+',
  },
];

export const aExtraprostatic = [
  {
    value: 0,
    text: 'bez šíření mimo prostatu',
    description: ', bez šíření mimo prostatu',
    conclusion: '',
  },
  {
    value: 1,
    text: 's šířením mimo prostatu',
    description: ', s šířením mimo prostatu',
    conclusion: ', extraprostatické šíření',
  },
];

export const aScore = [
  {
    value: 0,
    text: 'PI-RADS 1',
    description: '',
    conclusion: 'PI-RADS score 1',
  },
  {
    value: 1,
    text: 'PI-RADS 2',
    description: '',
    conclusion: 'PI-RADS score 2',
  },
  {
    value: 2,
    text: 'PI-RADS 3',
    description: '',
    conclusion: 'PI-RADS score 3',
  },
  {
    value: 3,
    text: 'PI-RADS 4',
    description: '',
    conclusion: 'PI-RADS score 4',
  },
  {
    value: 4,
    text: 'PI-RADS 5',
    description: '',
    conclusion: 'PI-RADS score 5',
  },
];

export const aLNSize = [
  {
    value: 0,
    text: 'hraniční',
  },
  {
    value: 1,
    text: 'mírně zvětšená',
  },
  {
    value: 2,
    text: 'zvětšená',
  },
];

export const aLN = [
  {
    value: 0,
    text: 'normální',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční',
    description: 'hraniční velikosti',
    conclusion: 'hraniční velikosti',
    color: yellow,
  },
  {
    value: 2,
    text: 'zvětšené',
    description: 'zvětšené',
    conclusion: 'zvětšené',
    color: red,
  },
];

export const aLNcount = [
  {
    value: 0,
    text: '1',
    description: '1',
    conclusion: 'jedna ',
    color: orange,
  },
  {
    value: 1,
    text: '2',
    description: '2',
    conclusion: 'dvě ',
    color: orange,
  },
  {
    value: 2,
    text: '3',
    description: '3',
    conclusion: 'tři ',
    color: orange,
  },
  {
    value: 3,
    text: '>3',
    description: '>3',
    conclusion: 'více než 3 ',
    color: orange,
  },
];

export const aTechQuality = [
  {
    value: 0,
    text: 'dobrá',
    description: 'dobrá',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'špatná',
    description: 'špatná',
    conclusion: '',
    color: red,
  },
];

export const aMotionArtefacts = [
  {
    value: 0,
    text: 'bez artefaktů',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírné artefakty',
    description: 'mírná limitace pohybovými artefakty',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'výrazné artefakty',
    description: 'výrazná limitace pohybovými artefakty',
    conclusion: '',
    color: orange,
  },
];

export const aTEP = [
  {
    value: 0,
    text: 'bez TEP',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'TEP vpravo',
    description: 'kovový artefakt z TEP pravého kyčelního kloubu',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'TEP vlevo',
    description: 'kovový artefakt z TEP levého kyčelního kloubu',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'TEP bilat.',
    description: 'kovový artefakt z TEP obou kyčelních kloubů',
    conclusion: '',
    color: orange,
  },
];

export const aRectalGas = [
  {
    value: 0,
    text: 'bez limitace',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná ',
    description: 'kovový artefakt z TEP pravého kyčelního kloubu',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'TEP vlevo',
    description: 'kovový artefakt z TEP levého kyčelního kloubu',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'TEP bilat.',
    description: 'kovový artefakt z TEP obou kyčelních kloubů',
    conclusion: '',
    color: orange,
  },
];

export const aPzone = [
  {
    value: 0,
    text: 'bez pozánětlivých změn. ',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '<<< vpravo pozánětlivé změny',
    description: 'vpravo pozánětlivé změny',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'vlevo pozánětlivé změny >>>',
    description: 'vlevo pozánětlivé změny',
    conclusion: '',
    color: yellow,
  },
  {
    value: 3,
    text: 'bilat. pozánětlivé změny',
    description: 'bilat. pozánětlivé změny',
    conclusion: '',
    color: yellow,
  },
];

export const aTzone = [
  {
    value: 0,
    text: 'bez hyperplázie',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná hyperplázie',
    description: 'mírná hyperplázie',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'středně pokročilá hyperplázie',
    description: 'středně pokročilá hyperplázie',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'výrazná hyperplázie',
    description: 'výrazná hyperplázie',
    conclusion: '',
    color: red,
  },
];
