// Custom scripts
import { aStenosis, aArteries } from "./CT_CTA_lower_limbs_arrays";

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  contentWidth,
} from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

export const generateReport = (props) => {
  const { exam, indication, contrast, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //
  let aPatArteries = aArteries.map((item, index) => {
    return props.arteries[item.text];
  });

  aPatArteries = aPatArteries.map((item, index) => {
    return item > 0
      ? aArteries[index].description + " = " + aStenosis[item].description
      : null;
  });

  let aPatArteriesRight = aPatArteries.filter(
    (item, index) => item !== null && index <= 15
  );
  let aPatArteriesLeft = aPatArteries.filter(
    (item, index) => item !== null && index >= 16
  );

  importDescription1(
    "Pravá dolní končetina: " +
      (aPatArteriesRight.length === 0 ? "přiměřený nález na tepnách" : "")
  );

  aPatArteriesRight.map((item) => {
    return importDescription1(item);
  });

  importDescription1(
    "Levá dolní končetina: " +
      (aPatArteriesLeft.length === 0 ? "přiměřený nález na tepnách" : "")
  );

  aPatArteriesLeft.map((item) => {
    return importDescription1(item);
  });

  importConclusion2("závěr...");

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
