// Custom scripts
import {
  aBursa,
  aPatType,
  aPatSide,
  aPatSiteHorizontal,
  aPatSiteVertical,
  aArthrosisHS,
  aArthrosisAC,
  aSubchondralEdema,
  aSynovialFluidHS,
  aSynovialFluidAC,
  aAcromion,
  aLabrum,
  aRotatorCuff,
  aBicepsTendinosis,
  aBicepsTendonRupture,
  aMuscleAtrophy,
  aBicepsTendonDislocation,
} from "./MR_shoulder_arrays";

// General scripts
import { indent, br } from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const { tendinopathy, musclesAtrophy } = props;
  const {
    synovialFluidHS,
    arthrosisHS,
    bursa,
    labrum,
    bicepsTendinosis,
    bicepsTendonDislocation,
    bicepsTendonRupture,
  } = props.HS;
  const {
    synovialFluidAC,
    arthrosisAC,
    subchondralEdema,
    acromion,
    osAcromiale,
  } = props.AC;
  const {
    customFindsDescription,
    customFindsConclusion,
    customAddFindsPriority,
  } = props.general;

  // Tendinopatie

  let tendinosisConclusion = tendinopathy.filter((item) => item.patType === 0);
  let lowGradePartialTearConclusion = tendinopathy.filter(
    (item) => item.patType === 1
  );
  let highGradePartialTearConclusion = tendinopathy.filter(
    (item) => item.patType === 2
  );
  let fullThicknessTearConclusion = tendinopathy.filter(
    (item) => item.patType === 3
  );
  let completeTearConclusion = tendinopathy.filter(
    (item) => item.patType === 4
  );

  let tendinopathyDescription = "";
  let tendinopathyConclusion = "";
  let aTendinopathies = [
    completeTearConclusion,
    fullThicknessTearConclusion,
    highGradePartialTearConclusion,
    lowGradePartialTearConclusion,
    tendinosisConclusion,
  ];

  if (
    aTendinopathies
      .map((item) => item.length)
      .reduce(function (a, b) {
        return a + b;
      }, 0) > 0
  ) {
    aTendinopathies.map((item) => {
      if (item.length > 0) {
        tendinopathyDescription +=
          aPatType[item[0].patType].description +
          formatArrayToText(
            item.map(
              (item) =>
                (item.patType !== 4
                  ? item.patSite.length < 3
                    ? formatArrayToText(
                        item.patSite.map(
                          (site) =>
                            (item.tendon === 0
                              ? aPatSiteHorizontal
                              : aPatSiteVertical)[site].description
                        )
                      ) + " porce "
                    : "celého rozsahu úponu "
                  : "") +
                aRotatorCuff[item.tendon].description +
                (item.patType > 0 && item.patType < 3
                  ? aPatSide[item.patSide].description
                  : "")
            )
          );

        tendinopathyConclusion +=
          aPatType[item[0].patType].conclusion +
          formatArrayToText(
            item.map(
              (item) =>
                (item.patType !== 4
                  ? item.patSite.length < 3
                    ? formatArrayToText(
                        item.patSite.map(
                          (site) =>
                            (item.tendon === 0
                              ? aPatSiteHorizontal
                              : aPatSiteVertical)[site].conclusion
                        )
                      ) + " porce "
                    : "celého rozsahu úponu "
                  : "") +
                aRotatorCuff[item.tendon].conclusion +
                (item.patType > 0 && item.patType < 3
                  ? aPatSide[item.patSide].conclusion
                  : "")
            )
          ) +
          ". ";
      }
    });
    tendinopathyDescription +=
      ". Jinak bez přesvědčivých signálových či strukturálních změn šlach rotátorové manžety. Svaly rotátorové manžety jsou zachovalé šíře. ";
  } else {
    tendinopathyDescription +=
      "Šlachy rotátorové manžety mají zachovalou strukturu i signál. ";
  }

  // Atrofie svalů rotátorové manžety
  let muscleAtrophyDescription = "";
  let muscleAtrophyConclusion = "";
  let mildAtrophy = [];
  let moderateAtrophy = [];
  let severeAtrophy = [];
  for (const name in musclesAtrophy) {
    if (musclesAtrophy[name] === 1) {
      mildAtrophy.push(name);
    } else if (musclesAtrophy[name] === 2) {
      moderateAtrophy.push(name);
    } else if (musclesAtrophy[name] === 3) {
      severeAtrophy.push(name);
    }
  }
  muscleAtrophyDescription =
    (severeAtrophy.length > 0
      ? "Těžká redukce objemu m. " + formatArrayToText(severeAtrophy) + ". "
      : "") +
    (moderateAtrophy.length > 0
      ? "Středně těžká redukce objemu m. " +
        formatArrayToText(moderateAtrophy) +
        ". "
      : "") +
    (mildAtrophy.length > 0
      ? "Mírná redukce objemu m. " + formatArrayToText(mildAtrophy) + ". "
      : "") +
    (severeAtrophy.length + moderateAtrophy.length + mildAtrophy.length === 0
      ? "Přiměřená trofika svalů rotátorové manžety. "
      : "");

  muscleAtrophyConclusion =
    (severeAtrophy.length > 0
      ? "Těžká atrofie m. " + formatArrayToText(severeAtrophy) + ". "
      : "") +
    (moderateAtrophy.length > 0
      ? "Středně těžká atrofie m. " + formatArrayToText(moderateAtrophy) + ". "
      : "") +
    (mildAtrophy.length > 0
      ? "Mírná atrofie m. " + formatArrayToText(mildAtrophy) + ". "
      : "");

  // Bursa
  let bursaDescription = aBursa[bursa].description;
  let bursaConclusion = aBursa[bursa].conclusion;

  // Šlacha dlouhé hlavy m. biceps brachii
  let bicepsTendonDescription = "";
  let bicepsTendonConclusion = "";
  bicepsTendonDescription =
    (bicepsTendonRupture > 0
      ? aBicepsTendonRupture[bicepsTendonRupture].description
      : "") +
    (bicepsTendonDislocation > 0
      ? aBicepsTendonDislocation[bicepsTendonDislocation].description
      : "") +
    (bicepsTendinosis > 0
      ? aBicepsTendinosis[bicepsTendinosis].description
      : "") +
    (bicepsTendonRupture + bicepsTendonDislocation + bicepsTendinosis === 0
      ? "Bez přesvědčivých strukturálních či signálových změn šlachy dlouhé hlavy m. biceps brachii, bez její dislokace. "
      : "");
  bicepsTendonConclusion =
    (bicepsTendonRupture > 0
      ? aBicepsTendonRupture[bicepsTendonRupture].conclusion
      : "") +
    (bicepsTendonDislocation > 0
      ? aBicepsTendonDislocation[bicepsTendonDislocation].conclusion
      : "") +
    (bicepsTendinosis > 0
      ? aBicepsTendinosis[bicepsTendinosis].conclusion
      : "");

  // Artróza HS kloubu
  let arthrosisHSDescription = aArthrosisHS[arthrosisHS].description;
  let arthrosisHSConclusion = aArthrosisHS[arthrosisHS].conclusion;

  // Artróza AC kloubu
  let arthrosisACDescription =
    aArthrosisAC[arthrosisAC].description +
    (arthrosisAC > 0
      ? aSubchondralEdema[subchondralEdema ? 1 : 0].description
      : "");
  let arthrosisACConclusion =
    aArthrosisAC[arthrosisAC].conclusion +
    (arthrosisAC > 0
      ? aSubchondralEdema[subchondralEdema ? 1 : 0].conclusion
      : "");

  // Tvar akromia
  let acromionShapeDescription = aAcromion[acromion].description;
  let acromionShapeConclusion = aAcromion[acromion].conclusion;

  // Os acromiale
  let osAcromialeDescription = osAcromiale
    ? "Absence splynutí osifikačního centra akromia (os acromiale). "
    : "";
  let osAcromialeConclusion = osAcromiale
    ? "Os acromiale (může zvyšovat riziko impingement syndromu). "
    : "";

  // Náplň HS kloubu
  let synovialFluidHSDescription =
    aSynovialFluidHS[synovialFluidHS].description;
  let synovialFluiHSConclusion = aSynovialFluidHS[synovialFluidHS].conclusion;

  // Labrum
  let labrumDescription =
    synovialFluidHS === 0
      ? aLabrum[labrum].description
      : "Bez přesvědčivých defektů labra. ";
  let labrumConclusion = aLabrum[labrum].conclusion;

  // Náplň AC kloubu
  let synovialFluidACDescription =
    aSynovialFluidAC[synovialFluidAC].description;
  let synovialFluiACConclusion = aSynovialFluidAC[synovialFluidAC].conclusion;

  // Additional findings
  let addFindsDescription = customFindsDescription;
  let addFindsConclusion = customFindsConclusion;

  importDescription1(
    (customAddFindsPriority ? addFindsDescription : "") +
      tendinopathyDescription +
      muscleAtrophyDescription +
      bursaDescription +
      bicepsTendonDescription
  );
  importDescription1(
    arthrosisHSDescription + synovialFluidHSDescription + labrumDescription
  );
  importDescription1(
    arthrosisACDescription +
      synovialFluidACDescription +
      acromionShapeDescription +
      osAcromialeDescription +
      (!customAddFindsPriority ? addFindsDescription : "")
  );
  importConclusion1(
    (customAddFindsPriority ? addFindsConclusion : "") +
      tendinopathyConclusion +
      muscleAtrophyConclusion +
      bicepsTendonConclusion +
      bursaConclusion +
      labrumConclusion +
      arthrosisHSConclusion +
      synovialFluiHSConclusion +
      arthrosisACConclusion +
      synovialFluiACConclusion +
      acromionShapeConclusion +
      osAcromialeConclusion +
      (!customAddFindsPriority ? addFindsConclusion : "")
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
