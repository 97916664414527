import { green, yellow, orange, red, grey } from '../scripts/GeneralArrays';

export const aListesis = [
  {
    value: 0,
    text: 'bez posunu',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '<<< ventro',
    description: 'ventrální posun obratlového těla ',
    conclusion: 'ventrolistéza ',
    color: orange,
  },
  {
    value: 2,
    text: 'dorzo >>>',
    description: 'dorzální posun obratlového těla ',
    conclusion: 'dorzolistéza ',
    color: orange,
  },
];

export const aDiskDegen = [
  {
    value: 0,
    text: 'normal',
    description: 'zachovalý signál a výše disku, ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'lehká',
    description: 'lehká degenerace disku, ',
    conclusion: 'lehká degenerace disku',
    color: orange,
  },
  {
    value: 2,
    text: 'těžká',
    description: 'těžká degenerace disku, ',
    conclusion: 'těžká degenerace disku',
    color: red,
  },
];

export const aModic = [
  {
    value: 0,
    text: 'normal',
    color: green,
    description: '',
  },
  {
    value: 1,
    text: 'Modic 1',
    color: red,
    description: 'edém kostní dřeně krycích ploten (Modic 1), ',
  },
  {
    value: 2,
    text: 'Modic 2',
    color: yellow,
    description: 'tuková degenerace kostní dřeně krycích ploten (Modic 2), ',
  },
  {
    value: 3,
    text: 'Modic 3',
    color: grey,
    description: 'fibróza/sklerotizace kostní dřeně krycích ploten (Modic 3), ',
  },
];

export const aDiskDirection = [
  {
    value: 0,
    text: 'F',
    symbol: 'FR',
    description: 'vpravo foraminálně',
  },
  {
    value: 1,
    text: 'P',
    symbol: 'PR',
    description: 'vpravo paracentrálně',
  },
  {
    value: 2,
    text: 'C',
    symbol: 'C',
    description: 'centrálně',
  },
  {
    value: 3,
    text: 'P',
    symbol: 'PL',
    description: 'vlevo paracentrálně',
  },
  {
    value: 4,
    text: 'F',
    symbol: 'FL',
    description: 'vlevo foraminálně',
  },
];

export const aDiskMigration = [
  {
    value: 0,
    text: 'nemigruje',
    description: '',
  },
  {
    value: 1,
    text: 'kraniálně',
    description: 'migrující kraniálně',
  },
  {
    value: 2,
    text: 'kaudálně',
    description: 'migrující kaudálně',
  },
];

export const aFacetArthrosis = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: ' s osteofyty okrajů kloubních ploch, ',
    conclusion: 'facetovou',
    color: orange,
  },
  {
    value: 2,
    text: 'edém',
    description: ' se zvýšením signálu kostní dřeně na STIR, ',
    conclusion: 'facetovou',
    color: red,
  },
];

export const aUncoArthrosis = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: ' s osteofyty okrajů kloubních ploch, ',
    conclusion: 'unkovertebrální',
    color: orange,
  },
  {
    value: 2,
    text: 'edém',
    description:
      ' s osteofyty okrajů kloubních ploch a zvýšením signálu kostní dřeně na STIR, ',
    conclusion: 'unkovertebrální',
    color: red,
  },
];

export const aStenosis = [
  {
    value: 0,
    text: '0',
    canalC: '',
    canalCConclusion: '',
    foramenC: '',
    canalLS: '',
    canalLSConclusion: '',
    recessLS: '',
    recessLSConclusion: '',
    foramenLS: '',
    foramenLSConclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '1',
    canalC: 'zúžení páteřního kanálu, ale bez imprese míchy (grade 1), ',
    canalCConclusion: 'lehká stenóza páteřního kanálu z důvodu ',
    foramenC:
      'zúžení s ještě patrným likvorem v durální pochvě míšního kořene (grade 1), ',
    canalLS:
      'zúžení páteřního kanálu bez shlukování míšních kořenů durálního vaku (grade 1), ',
    canalLSConclusion: 'lehká stenóza páteřního kanálu z důvodu ',
    recessLS: 'bez odtlačení míšního kořene (grade 1), ',
    recessLSConclusion: 'lehká ',
    foramenLS: 'v jednom rozměru zaniklý perineurální tuk (grade 1), ',
    foramenLSConclusion: 'lehká ',
    color: yellow,
  },
  {
    value: 2,
    text: '2',
    canalC:
      'zúžení páteřního kanálu s impresí míchy, ale bez přesvědčivých známek myelopatie (grade 2), ',
    canalCConclusion:
      'středně těžká stenóza páteřního kanálu (bez přesvědčivé myelopatie) z důvodu ',
    foramenC:
      'zúžení již bez patrného likvoru v durální pochvě míšního kořene, který ale není přesvědčivě komprimován (grade 2), ',
    canalLS:
      'zúžení páteřního kanálu způsobující shlukování míšních kořenů durálního vaku, mezi kterými je ale stále patrný likvor (grade 2), ',
    canalLSConclusion: 'středně těžká stenóza páteřního kanálu z důvodu ',
    recessLS: 's odtlačením nedeformovaného míšního kořene (grade 2), ',
    recessLSConclusion: 'středně těžká ',
    foramenLS: 'téměř zaniklý perineurální tuk (grade 2), ',
    foramenLSConclusion: 'středně těžká ',
    color: orange,
  },
  {
    value: 3,
    text: '3',
    canalC:
      'zúžení páteřního kanálu se zvýšením signálu míchy na T2 (myelopatie) v této úrovni (grade 3), ',
    canalCConclusion:
      'těžká stenóza páteřního kanálu se susp. myelopatií z důvodu ',
    foramenC:
      'zúžení již bez patrného likvoru v durální pochvě míšního kořene, který je susp. komprimován (grade 3), ',
    canalLS:
      'zúžení páteřního kanálu způsobující způsobující shlukování míšních kořenů durální vaku, mezi kterými již není patrný likvor (grade 3), ',
    canalLSConclusion: 'těžká stenóza páteřního kanálu z důvodu ',
    recessLS: 's odtlačením a deformací míšního kořene (grade 3), ',
    recessLSConclusion: 'těžká ',
    foramenLS: 'z útlaku deformita durální pochvy míšního kořene (grade 3), ',
    foramenLSConclusion: 'těžká ',
    color: red,
  },
];

export const aFractureSite = [
  {
    value: 0,
    text: 'centrální snížení ><',
    description: 'centrální snížení obratlového těla ',
    conclusion: 'centrální komprese ',
    color: orange,
  },
  {
    value: 1,
    text: 'ventrální snížení <<',
    description: 'ventrální klínovité snížení obratlového těla ',
    conclusion: 'ventrální komprese ',
    color: orange,
  },
  {
    value: 2,
    text: 'dorzální snížení >>',
    description: 'dorzální klínovité snížení obratlového těla ',
    conclusion: 'dorzální komprese ',
    color: orange,
  },
];

export const aFractureEndplate = [
  {
    value: 0,
    text: 'kompresí horní krycí plotny',
    description: ' následkem prolomení horní krycí plotny ',
    conclusion: 'horní krycí plotny ',
    color: orange,
  },
  {
    value: 1,
    text: 'kompresí dolní krycí plotny',
    description: ' následkem prolomení dolní krycí plotny ',
    conclusion: 'dolní krycí plotny ',
    color: orange,
  },
  {
    value: 2,
    text: 'kompresí obou krycích ploten',
    description: ' následkem prolomení obou krycích ploten',
    conclusion: 'obou krycích ploten ',
    color: red,
  },
];

export const aFractureDepth = [
  {
    value: 0,
    text: 'o <25%',
    description: ' o méně než 25% původní výše, ',
    conclusion: 'lehká ',
    color: yellow,
  },
  {
    value: 1,
    text: 'o 25 až 40%',
    description: ' o 25-40% původní výše, ',
    conclusion: 'středně těžká ',
    color: orange,
  },
  {
    value: 2,
    text: 'o >40%',
    description: ' o více než 40% původní výše, ',
    conclusion: 'těžká ',
    color: red,
  },
];

export const aFractureEdema = [
  {
    value: 0,
    text: 'bez edému',
    description: 'na STIR bez zvýšení SI kostní dřeně, ',
    conclusion: 'starší ',
    color: yellow,
  },
  {
    value: 1,
    text: 's edémem',
    description: 'na STIR se zvýšením SI kostní dřeně, ',
    conclusion: 'nedávná ',
    color: red,
  },
];

export const aFractureProminence = [
  {
    value: 0,
    text: 'bez prominence do páteřního kanálu',
    description: 'bez prominence zadní hrany do páteřního kanálu, ',
    conclusion: ', ',
    color: green,
  },
  {
    value: 1,
    text: 's prominencí bez stenózy páteřního kanálu',
    description:
      's diskrétní prominencí zadní hrany (bez stenózy páteřního kanálu), ',
    conclusion: ', ',
    color: green,
  },
  {
    value: 2,
    text: 's prominencí a lehkou stenózou',
    description:
      'způsobující lehkou stenózu páteřního kanálu prominencí zadní hrany obratlového těla, ',
    conclusion: ' způsobující lehkou stenózu páteřního kanálu, ',
    color: yellow,
  },
  {
    value: 3,
    text: 's prominencí a středně těžkou stenózou',
    description:
      'způsobující středně těžkou stenózu páteřního kanálu prominencí zadní hrany obratlového těla, ',
    conclusion: ' způsobující středně těžkou stenózu páteřního kanálu, ',
    color: orange,
  },
  {
    value: 4,
    text: 's prominencí a těžkou stenózou',
    description:
      'způsobující těžkou stenózu páteřního kanálu prominencí zadní hrany obratlového těla, ',
    conclusion: ' způsobující těžkou stenózu páteřního kanálu, ',
    color: red,
  },
];

export const aSignal = [
  {
    value: 0,
    text: '↓↓',
    description: 'výrazně nižší SI na ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '↓',
    description: 'nižší SI na ',
    conclusion: '',
    color: green,
  },
  {
    value: 2,
    text: '⇄',
    description: 'střední SI na ',
    conclusion: '',
    color: green,
  },
  {
    value: 3,
    text: '↑',
    description: 'vyšší SI na ',
    conclusion: '',
    color: green,
  },
  {
    value: 4,
    text: '↑↑',
    description: 'výrazně vyšší SI na ',
    conclusion: '',
    color: green,
  },
];

export const aLaminectomy = [
  {
    value: 0,
    text: '<< bilat.>>',
    description: 'st.p. bilaterální laminektomii',
    conclusion: 'st.p. bilaterální laminektomii',
    color: green,
  },
  {
    value: 1,
    text: '<< vpravo',
    description: 'st.p. pravostranné laminektomii',
    conclusion: 'st.p. pravostranné laminektomii',
    color: green,
  },
  {
    value: 2,
    text: 'vlevo >>',
    description: 'st.p. levostranné laminektomii',
    conclusion: 'st.p. levostranné laminektomii',
    color: green,
  },
];

export const aStabilisation = [
  {
    value: 0,
    text: 'zadní stabilizace',
    description: 'st.p. zadní stabilizaci ',
    conclusion: 'St.p. zadní stabilizaci ',
    color: green,
  },
  {
    value: 1,
    text: 'přední stabilizace',
    description: 'st.p. přední stabilizaci ',
    conclusion: 'St.p. přední stabilizaci ',
    color: green,
  },
  {
    value: 2,
    text: 'přední i zadní',
    description: 'st.p. přední i zadní stabilizaci ',
    conclusion: 'St.p. přední i zadní stabilizaci ',
    color: green,
  },
];
