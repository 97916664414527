import { green, yellow, orange, red } from "../scripts/GeneralArrays";

export const aSynovialFluid = [
  {
    value: 0,
    text: "normální",
    description: "Přiměřená náplň loketního kloubu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvýšená",
    description: "Mírně vyšší náplň loketního kloubu. ",
    conclusion: "Mírně vyšší náplň loketního kloubu. ",
    color: yellow,
  },
  {
    value: 2,
    text: "zvýšená",
    description: "Zvýšená náplň loketního kloubu. ",
    conclusion: "Zvýšená náplň loketního kloubu. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvýšená",
    description: "Výrazně zvýšená náplň loketního kloubu. ",
    conclusion: "Výrazně zvýšená náplň loketního kloubu. ",
    color: red,
  },
];

export const aLateralEpicondylitis = [
  {
    value: 0,
    text: "bez známek",
    description:
      "Na PDFS a T1 přiměřená šíře i SI společného úponu extenzorů na laterální epikondyl humeru. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná",
    description:
      "Rozšíření společného úponu extenzorů na laterální epikondyl humeru, vyšší SI na PDFS a střední SI na T1. ",
    conclusion: "Laterální epikondylitida (tenisový loket). ",
    color: orange,
  },
  {
    value: 2,
    text: "výrazná",
    description:
      "Výrazné rozšíření společného úponu extenzorů na laterální epikondyl humeru, vyšší SI na PDFS a střední SI na T1. ",
    conclusion: "Výrazná laterální epikondylitida (tenisový loket). ",
    color: red,
  },
];

export const aMedialEpicondylitis = [
  {
    value: 0,
    text: "bez známek",
    description:
      "Na PDFS a T1 přiměřená šíře i SI společného úponu flexorů na mediální epikondyl humeru. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná",
    description:
      "Rozšíření společného úponu flexorů na mediální epikondyl humeru, vyšší SI na PDFS a střední SI na T1. ",
    conclusion: "Mediální epikondylitida (golfový loket). ",
    color: orange,
  },
  {
    value: 2,
    text: "výrazná",
    description:
      "Výrazné rozšíření společného úponu flexorů na mediální epikondyl humeru, vyšší SI na PDFS a střední SI na T1. ",
    conclusion: "Výrazná mediální epikondylitida (golfový loket). ",
    color: red,
  },
];
