import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import {
  aGITSegments,
  aLength,
  aWallWidth,
  aEnhancementType,
  aEnhancementAmount,
  aWallEdema,
  aAscites,
  aLimitations,
  aNormalFindings,
} from "./MR_enterography_arrays";
import { generateReport } from "./MR_enterography_functions";

// General scripts
import {
  contentWidth,
  aCardColors,
  aTrueFalse,
  aLN,
  aLNcount,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import CustomCards from "../components/CustomCards";
import DropDown from "../components/DropDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";

// 3rd party components
import {
  Form,
  Row,
  Col,
  Select,
  Button,
  Space,
  Switch,
  Divider,
  Input,
  Checkbox,
} from "antd";

import { debounce } from "lodash";
import ButtonUpDown from "../components/ButtonUpDown";
import ButtonUpDownRow from "../components/ButtonUpDownRow";
const { TextArea } = Input;
const { Option } = Select;

class MR_enterography extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      ascites: 0,
      sigmoidDiverticles: false,
      sigmoidCustomDescription: "",
      sigmoidCustomConclusion: "",
      softTissuesCustomDescription: "",
      softTissuesCustomConclusion: "",
      bonesCustomDescription: "",
      bonesCustomConclusion: "",
      LNparaaortic: 0,
      LNiliacComR: 0,
      LNiliacComL: 0,
      LNiliacExtR: 0,
      LNiliacExtL: 0,
      LNiliacIntR: 0,
      LNiliacIntL: 0,
      LNparaaorticCount: 0,
      LNiliacComRCount: 0,
      LNiliacComLCount: 0,
      LNiliacExtRCount: 0,
      LNiliacExtLCount: 0,
      LNiliacIntRCount: 0,
      LNiliacIntLCount: 0,
      LNcustomDescription: "",
      LNcustomConclusion: "",
      limitations: 0,
      normalFindings: 0,
    },
    GITsegments: [],
    urinaryBladderCustomShow: false,
    sigmoidCustomShow: true,
    softTissuesCustomShow: false,
    bonesCustomShow: false,
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value, segment) => {
    let newRecord;
    if (segment === "GITsegments") {
      let GITsegment = value;
      let length = 5;
      let wallWidth = 0;
      let enhancementType = 0;
      let enhancementAmount = 0;
      let wallEdema = 0;
      let combSign = false;
      let infiltrate = false;
      let abscess = false;
      let fistula = false;
      let stenosis = false;

      newRecord = {
        GITsegment: GITsegment,
        length: length,
        wallWidth: wallWidth,
        enhancementType: enhancementType,
        enhancementAmount: enhancementAmount,
        wallEdema: wallEdema,
        combSign: combSign,
        infiltrate: infiltrate,
        abscess: abscess,
        fistula: fistula,
        stenosis: stenosis,
      };
    }

    this.setState((prevState) => ({
      [segment]: [...prevState[segment], newRecord],
    }));
  };

  updateRow = (index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  };

  updateRowText = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  showCustomInput = (region) => {
    this.setState((prevState) => ({
      [region]: !prevState[region],
    }));
  };

  render() {
    const { GITsegments } = this.state;
    const GIT = [
      {
        title: (
          <Space>
            staging Crohnovy choroby
            <Select
              value="vybrat úsek"
              size="small"
              style={{ width: 150 }}
              onChange={(value) => {
                this.newRow(value, "GITsegments");
              }}
            >
              {aGITSegments.map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
          </Space>
        ),
        color: aCardColors.red,
        rows: [
          [
            {
              label: "",
              input: (
                <>
                  {GITsegments.map((item, index) => {
                    return (
                      <Row>
                        <Col span={24}>
                          <Row>
                            <Col
                              flex="auto"
                              style={{ marginBottom: "10px" }}
                              key={index}
                            >
                              <Space>
                                {index + 1 + ")"}
                                <Select
                                  key={index}
                                  name="GITsegment"
                                  value={item.GITsegment}
                                  defaultValue={item.GITsegment}
                                  size="small"
                                  style={{ width: 135 }}
                                  onChange={(value) =>
                                    this.updateRow(
                                      index,
                                      "GITsegments",
                                      "GITsegment",
                                      value
                                    )
                                  }
                                  showArrow={false}
                                >
                                  {aGITSegments.map((item) => {
                                    return (
                                      <Option
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.text}
                                      </Option>
                                    );
                                  })}
                                </Select>
                                délka úseku:
                                <ButtonUpDownRow
                                  index={index}
                                  name="length"
                                  segment="GITsegments"
                                  value={item.length}
                                  options={aLength}
                                  width={70}
                                  updateRow={this.updateRow}
                                />
                                šíře stěny:
                                <ButtonUpDownRow
                                  index={index}
                                  name="wallWidth"
                                  segment="GITsegments"
                                  value={item.wallWidth}
                                  options={aWallWidth}
                                  width={80}
                                  updateRow={this.updateRow}
                                />
                                sycení stěny:
                                <ButtonUpDownRow
                                  index={index}
                                  name="enhancementType"
                                  segment="GITsegments"
                                  value={item.enhancementType}
                                  options={aEnhancementType}
                                  width={95}
                                  updateRow={this.updateRow}
                                />
                                míra sycení:
                                <ButtonUpDownRow
                                  index={index}
                                  name="enhancementAmount"
                                  segment="GITsegments"
                                  value={item.enhancementAmount}
                                  options={aEnhancementAmount}
                                  width={70}
                                  updateRow={this.updateRow}
                                />
                                SI stěny na T2:
                                <ButtonUpDownRow
                                  index={index}
                                  name="wallEdema"
                                  segment="GITsegments"
                                  value={item.wallEdema}
                                  options={aWallEdema}
                                  width={100}
                                  updateRow={this.updateRow}
                                />
                                comb sign:
                                <Checkbox
                                  onChange={(event) =>
                                    this.updateRow(
                                      index,
                                      "GITsegments",
                                      "combSign",
                                      event.target.checked
                                    )
                                  }
                                />
                              </Space>
                            </Col>
                            <Col flex="auto" align="right">
                              <Button
                                type="primary"
                                size="small"
                                danger
                                onClick={() =>
                                  this.deleteRow(index, "GITsegments")
                                }
                              >
                                x
                              </Button>
                            </Col>
                          </Row>
                          <Row>
                            <Col flex="60px"></Col>
                            <Col flex="auto">
                              <Space>
                                komplikace - zánětlivý pseudotumor:
                                <Checkbox
                                  onChange={(event) =>
                                    this.updateRow(
                                      index,
                                      "GITsegments",
                                      "infiltrate",
                                      event.target.checked
                                    )
                                  }
                                />
                                | píštěl:
                                <Checkbox
                                  onChange={(event) =>
                                    this.updateRow(
                                      index,
                                      "GITsegments",
                                      "fistula",
                                      event.target.checked
                                    )
                                  }
                                />
                                | absces:
                                <Checkbox
                                  onChange={(event) =>
                                    this.updateRow(
                                      index,
                                      "GITsegments",
                                      "absces",
                                      event.target.checked
                                    )
                                  }
                                />
                                | stenóza s presten. dilatací:
                                <Checkbox
                                  onChange={(event) =>
                                    this.updateRow(
                                      index,
                                      "GITsegments",
                                      "stenosis",
                                      event.target.checked
                                    )
                                  }
                                />
                              </Space>
                            </Col>
                            {GITsegments.length > 1 &&
                            index < GITsegments.length - 1 ? (
                              <Divider />
                            ) : null}
                          </Row>
                        </Col>
                      </Row>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    /*const male = [
      {
        title: (
          <Row>
            <Col span={12}>prostata</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("prostateCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "velikost",
              input: (
                <ButtonUpDown
                  name="prostateSize"
                  segment="general"
                  value={this.state.general.prostateSize}
                  options={aProstateSize}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: this.state.prostateCustomShow ? "vlastní popis" : "",
              input: this.state.prostateCustomShow ? (
                <TextArea
                  name="prostateCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.prostateCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "prostateCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.prostateCustomShow ? "vlastní závěr" : "",
              input: this.state.prostateCustomShow ? (
                <TextArea
                  name="prostateCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.prostateCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "prostateCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>skrótum</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("scrotumCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.red,
        rows: [
          [
            {
              label: "hydrokéla",
              input: (
                <ButtonTrueFalse
                  name="scrotumHydrops"
                  segment="general"
                  value={this.state.general.scrotumHydrops}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: this.state.scrotumCustomShow ? "vlastní popis" : "",
              input: this.state.scrotumCustomShow ? (
                <TextArea
                  name="scrotumCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.scrotumCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "scrotumCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.scrotumCustomShow ? "vlastní závěr" : "",
              input: this.state.scrotumCustomShow ? (
                <TextArea
                  name="scrotumCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.scrotumCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "scrotumCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
    ];

    const female = [
      {
        title: (
          <Row>
            <Col span={12}>děloha</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("uterusCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.red,
        rows: [
          [
            {
              label: "děloha",
              input: (
                <ButtonUpDown
                  name="uterusGeneral"
                  segment="general"
                  value={this.state.general.uterusGeneral}
                  options={aUterusGeneral}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: this.state.general.uterusGeneral !== 1 ? "poloha" : "",
              input:
                this.state.general.uterusGeneral !== 1 ? (
                  <ButtonUpDown
                    name="uterusPosition"
                    segment="general"
                    value={this.state.general.uterusPosition}
                    options={aUterusPosition}
                    onChange={this.onChange}
                  />
                ) : (
                  ""
                ),
            },
          ],
          [
            {
              label: this.state.uterusCustomShow ? "vlastní popis" : "",
              input: this.state.uterusCustomShow ? (
                <TextArea
                  name="uterusCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.uterusCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "uterusCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.uterusCustomShow ? "vlastní závěr" : "",
              input: this.state.uterusCustomShow ? (
                <TextArea
                  name="uterusCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.uterusCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "uterusCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>ovaria</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("ovariumCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "pravé ovarium",
              input: (
                <ButtonUpDown
                  name="ovariumR"
                  segment="general"
                  value={this.state.general.ovariumR}
                  options={aOvarium}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "levé ovarium",
              input: (
                <ButtonUpDown
                  name="ovariumL"
                  segment="general"
                  value={this.state.general.ovariumL}
                  options={aOvarium}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.ovariumCustomShow ? "vlastní popis" : "",
              input: this.state.ovariumCustomShow ? (
                <TextArea
                  name="ovariumCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.ovariumCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "ovariumCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.ovariumCustomShow ? "vlastní závěr" : "",
              input: this.state.ovariumCustomShow ? (
                <TextArea
                  name="ovariumCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.ovariumCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "ovariumCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
    ];*/

    const generalGIT_LN = [
      {
        title: (
          <Row>
            <Col span={12}>gastrointestinální trakt</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                defaultChecked={this.state.sigmoidCustomShow}
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("sigmoidCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.limeGreen,
        rows: [
          [
            {
              label: "divert. sigm.",
              input: (
                <ButtonTrueFalse
                  name="sigmoidDiverticles"
                  segment="general"
                  value={this.state.general.sigmoidDiverticles}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: this.state.sigmoidCustomShow ? "vlastní popis" : "",
              input: this.state.sigmoidCustomShow ? (
                <TextArea
                  name="sigmoidCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.sigmoidCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "sigmoidCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.sigmoidCustomShow ? "vlastní závěr" : "",
              input: this.state.sigmoidCustomShow ? (
                <TextArea
                  name="sigmoidCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.sigmoidCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "sigmoidCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>lymfatické uzliny</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("LNcustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "paraaortální",
              input: (
                <Row>
                  <Col span={7}></Col>
                  <Col span={this.state.general.LNparaaortic > 0 ? 8 : 10}>
                    <ButtonUpDown
                      name="LNparaaortic"
                      segment="general"
                      value={this.state.general.LNparaaortic}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNparaaortic > 0 ? (
                    <Col span={2}>
                      <ButtonUpDown
                        name="LNparaaorticCount"
                        segment="general"
                        value={this.state.general.LNparaaorticCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                  <Col span={7}></Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "ilické spol.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacComR"
                      segment="general"
                      value={this.state.general.LNiliacComR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacComRCount"
                        segment="general"
                        value={this.state.general.LNiliacComRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: "ilické spol.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacComL"
                      segment="general"
                      value={this.state.general.LNiliacComL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacComLCount"
                        segment="general"
                        value={this.state.general.LNiliacComLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: "ilické ext.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacExtR"
                      segment="general"
                      value={this.state.general.LNiliacExtR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacExtRCount"
                        segment="general"
                        value={this.state.general.LNiliacExtRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: "ilické ext.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacExtL"
                      segment="general"
                      value={this.state.general.LNiliacExtL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacExtLCount"
                        segment="general"
                        value={this.state.general.LNiliacExtLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: "ilické int.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacIntR"
                      segment="general"
                      value={this.state.general.LNiliacIntR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacIntRCount"
                        segment="general"
                        value={this.state.general.LNiliacIntRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: "ilické int.",
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name="LNiliacIntL"
                      segment="general"
                      value={this.state.general.LNiliacIntL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name="LNiliacIntLCount"
                        segment="general"
                        value={this.state.general.LNiliacIntLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? "vlastní popis" : "",
              input: this.state.LNcustomShow ? (
                <TextArea
                  name="LNcustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.LNcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "LNcustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? "vlastní závěr" : "",
              input: this.state.LNcustomShow ? (
                <TextArea
                  name="LNcustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.LNcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "LNcustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const urinaryBladderGIT = [
      {
        title: (
          <Row>
            <Col span={12}>gastrointestinální trakt</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("sigmoidCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.limeGreen,
        rows: [
          [
            {
              label: "divertikly",
              input: (
                <ButtonTrueFalse
                  name="sigmoidDiverticles"
                  segment="general"
                  value={this.state.general.sigmoidDiverticles}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: this.state.sigmoidCustomShow ? "vlastní popis" : "",
              input: this.state.sigmoidCustomShow ? (
                <TextArea
                  name="sigmoidCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.sigmoidCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "sigmoidCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.sigmoidCustomShow ? "vlastní závěr" : "",
              input: this.state.sigmoidCustomShow ? (
                <TextArea
                  name="sigmoidCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.sigmoidCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "sigmoidCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>močový měchýř</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("urinaryBladderCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "trabekularizace",
              input: (
                <ButtonTrueFalse
                  name="vesicalWall"
                  segment="general"
                  value={this.state.general.vesicalWall}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
          [
            {
              label: this.state.urinaryBladderCustomShow ? "vlastní popis" : "",
              input: this.state.urinaryBladderCustomShow ? (
                <TextArea
                  name="urinaryBladderCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={
                    this.state.general.urinaryBladderCustomDescription
                  }
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "urinaryBladderCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.urinaryBladderCustomShow ? "vlastní závěr" : "",
              input: this.state.urinaryBladderCustomShow ? (
                <TextArea
                  name="urinaryBladderCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={
                    this.state.general.urinaryBladderCustomConclusion
                  }
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "urinaryBladderCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
    ];

    const ascitesLNs = [
      {
        title: "peritoneální dutina",
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: "ascites",
              input: (
                <ButtonUpDown
                  name="ascites"
                  segment="general"
                  value={this.state.general.ascites}
                  options={aAscites}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
      {
        title: <Space>parenchymové orgány</Space>,
        color: aCardColors.green,
        rows: [
          [
            {
              label: "játra",
              input: (
                <ButtonUpDown
                  name="ascites"
                  segment="general"
                  value={this.state.general.ascites}
                  options={aAscites}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
    ];

    const softTissuesBones = [
      {
        title: (
          <Row>
            <Col span={12}>měkké tkáně</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("softTissuesCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.orange,
        rows: [
          [
            {
              label: this.state.softTissuesCustomShow ? "vlastní popis" : "",
              input: this.state.softTissuesCustomShow ? (
                <TextArea
                  name="softTissuesCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.softTissuesCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "softTissuesCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.softTissuesCustomShow ? "vlastní závěr" : "",
              input: this.state.softTissuesCustomShow ? (
                <TextArea
                  name="softTissuesCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.softTissuesCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "softTissuesCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>skelet</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("bonesCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: this.state.bonesCustomShow ? "vlastní popis" : "",
              input: this.state.bonesCustomShow ? (
                <TextArea
                  name="bonesCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.bonesCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "bonesCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.bonesCustomShow ? "vlastní závěr" : "",
              input: this.state.bonesCustomShow ? (
                <TextArea
                  name="bonesCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.bonesCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "bonesCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
    ];

    const limitsOther = [
      {
        title: (
          <Row>
            <Col span={12}>limitace vyšetření a ostatní</Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("limitOtherCustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.limeGreen,
        rows: [
          [
            {
              label: this.state.limitOtherCustomShow ? "vlastní popis" : "",
              input: this.state.limitOtherCustomShow ? (
                <TextArea
                  name="limitOtherCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.limitOtherCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "limitOtherCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.limitOtherCustomShow ? "vlastní závěr" : "",
              input: this.state.limitOtherCustomShow ? (
                <TextArea
                  name="limitOtherCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.limitOtherCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "limitOtherCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: "limitace",
              input: (
                <ButtonUpDown
                  name="limitations"
                  segment="general"
                  value={this.state.general.limitations}
                  options={aLimitations}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "normální nález",
              input: (
                <ButtonUpDown
                  name="normalFindings"
                  segment="general"
                  value={this.state.general.normalFindings}
                  options={aNormalFindings}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}></Col>
            <Col span={12} style={{ paddingRight: "10px" }} align="right">
              <Switch
                size="small"
                checkedChildren="více"
                unCheckedChildren="méně"
                onChange={() => {
                  this.showCustomInput("CustomShow");
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.purple,
        rows: [
          [
            {
              label: this.state.CustomShow ? "vlastní popis" : "",
              input: this.state.CustomShow ? (
                <TextArea
                  name="bonesCustomDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.bonesCustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "bonesCustomDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
          [
            {
              label: this.state.CustomShow ? "vlastní závěr" : "",
              input: this.state.CustomShow ? (
                <TextArea
                  name="bonesCustomConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.bonesCustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "bonesCustomConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : (
                ""
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        {this.state.protocol === 0 || this.state.protocol === 1 ? (
          <CustomCards content={GIT} />
        ) : null}
        <CustomCards content={generalGIT_LN} />
        <CustomCards content={ascitesLNs} />
        <CustomCards content={softTissuesBones} />
        <CustomCards content={limitsOther} />
      </Form>
    );
  }
}

export default MR_enterography;
