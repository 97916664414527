// Custom scripts
import {
  aListesis,
  aDiskDegen,
  aModic,
  aFacetArthrosis,
  aDiskMigration,
  aStenosis,
  aFractureSite,
  aFractureEndplate,
  aFractureDepth,
  aFractureEdema,
  aFractureProminence,
  aSignal,
  aLaminectomy,
  aStabilisation,
} from './MR_spine_arrays';

import {
  aSpineSag,
  aSpineCor,
  aDiskPat,
  aAddFinds,
} from './MR_spine_Th_arrays';

import {
  indent,
  br,
  aSize,
  aVertebrae,
  aSpine,
} from '../scripts/GeneralArrays';

import {
  formatArrayToText,
  getFirstVertebraFromSegment,
  compareNumbers,
  getDiskDirection,
} from '../scripts/GeneralFunctions';

export const generateReport = props => {
  const { exam, indication, contrast, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // Komprese
  let compressions = props.compressions;

  let {
    boneLesions,
    laminectomy,
    stabilisation,
    customSegment,
    customVertebra,
  } = props;
  let boneLesionsConclusion = '';
  let stabilisationConclusion = '';
  let customVertebraConclusion = '';

  // Páteř segmenty

  let segments = props.segments.filter(
    item =>
      item.listesis > 0 ||
      item.diskDegen > 0 ||
      item.Modic > 0 ||
      item.diskPat > 0 ||
      item.yellowLigament > 0 ||
      item.facetArthrosisR + item.facetArthrosisL > 0 ||
      item.foramenR + item.foramenL > 0 ||
      item.spinalCanal > 0 ||
      compressions.filter(compression => compression.vertebra === item.value)
        .length > 0 ||
      boneLesions.filter(boneLesion => boneLesion.vertebra === item.value)
        .length > 0 ||
      laminectomy.filter(laminectomy => laminectomy.segment === item.value)
        .length > 0 ||
      stabilisation.filter(
        stabilisation => stabilisation.vertebraFirst === item.value
      ).length > 0 ||
      customSegment.filter(
        customSegment => customSegment.segment === item.value
      ).length > 0 ||
      customVertebra.filter(
        customVertebra => customVertebra.vertebra === item.value
      ).length > 0
  );

  let laminectomyDescription = '';
  let laminectomyConclusion = '';

  segments.map(item => {
    // Komprese
    let compressionDescription = '';
    let compressionConclusion = '';
    let compressedVertebra = compressions.filter(
      compression => compression.vertebra === item.value
    );
    if (compressedVertebra.length > 0) {
      let vertebra = compressedVertebra[0];
      compressionDescription =
        aFractureSite[vertebra.area].description +
        aVertebrae[vertebra.vertebra].text +
        aFractureEndplate[vertebra.endplate].description +
        aFractureDepth[vertebra.compression].description +
        aFractureEdema[vertebra.edema].description +
        aFractureProminence[vertebra.prominence].description;
      compressionConclusion =
        aFractureEdema[vertebra.edema].conclusion +
        aFractureDepth[vertebra.compression].conclusion +
        aFractureSite[vertebra.area].conclusion +
        aFractureEndplate[vertebra.endplate].conclusion +
        'obratlového těla ' +
        aVertebrae[vertebra.vertebra].text +
        aFractureProminence[vertebra.prominence].conclusion;
    }

    // Ložiska
    let boneLesionDescription = '';
    let boneLesionVertebra = boneLesions.filter(
      boneLesion => boneLesion.vertebra === item.value
    );
    if (boneLesionVertebra.length > 0) {
      let vertebra = boneLesionVertebra[0];
      boneLesionDescription =
        'ložisko v ' +
        aVertebrae[vertebra.vertebra].text +
        ' ' +
        aSignal[vertebra.T2].description +
        'T2, ' +
        aSignal[vertebra.T1].description +
        'T1, ' +
        aSignal[vertebra.STIR].description +
        'STIR, ' +
        (vertebra.size !== '' ? 'velikosti ' + vertebra.size + 'mm, ' : '') +
        vertebra.description;
      boneLesionsConclusion +=
        'Ložisko v ' +
        aVertebrae[vertebra.vertebra].text +
        (vertebra.size !== '' ? ' (' + vertebra.size + 'mm)' : '') +
        ' - ' +
        vertebra.conclusion;
    }

    // Laminektomie
    laminectomyDescription = '';
    laminectomyConclusion = '';
    let laminectomySegment = laminectomy.filter(
      segment => segment.segment === item.value
    );

    if (laminectomySegment.length > 0) {
      let segment = laminectomySegment[0];
      laminectomyDescription =
        aLaminectomy[segment.side].description +
        (segment.description !== ''
          ? ' - ' + segment.description + ', '
          : ', ');
      laminectomyConclusion =
        aLaminectomy[segment.side].conclusion +
        (segment.conclusion !== ''
          ? ' - ' + segment.conclusion + ', '
          : ' s přiměřeným nálezem, ');
    }

    // Stabilizace
    let stabilisationDescription = '';
    let stabilisationSegment = stabilisation.filter(
      segment => segment.vertebraFirst === item.value
    );

    if (stabilisationSegment.length > 0) {
      let segment = stabilisationSegment[0];
      stabilisationDescription =
        aStabilisation[segment.type].description +
        aVertebrae[segment.vertebraFirst].text +
        (segment.vertebraFirst < segment.vertebraLast
          ? ' až ' + aVertebrae[segment.vertebraLast].text
          : '') +
        (segment.description !== ''
          ? ', ' + segment.description + ', '
          : ',  ');
      stabilisationConclusion +=
        aStabilisation[segment.type].conclusion +
        aVertebrae[segment.vertebraFirst].text +
        (segment.vertebraFirst < segment.vertebraLast
          ? ' až ' + aVertebrae[segment.vertebraLast].text
          : '') +
        (segment.conclusion !== ''
          ? ' - ' + segment.conclusion + '. '
          : ' s přiměřeným nálezem. ');
    }

    // Vlastní segment
    let customSegmentDescription = '';
    let customSegmentConclusion = '';
    let customSeg = customSegment.filter(
      segment => segment.segment === item.value
    );

    if (customSeg.length > 0) {
      let segment = customSeg[0];
      customSegmentDescription = segment.description + ', ';
      customSegmentConclusion = segment.conclusion + ', ';
    }

    // Vlastní obratel
    let customVertebraDescription = '';
    let customVert = customVertebra.filter(
      vertebra => vertebra.vertebra === item.value
    );

    if (customVert.length > 0) {
      let vertebra = customVert[0];
      customVertebraDescription = vertebra.description + ', ';
      customVertebraConclusion += vertebra.conclusion;
    }

    // Směr hernie
    let directions = item.diskDirection.map(direction => direction.symbol);
    let directionsText = getDiskDirection(directions);

    let segmentDescription =
      item.listesis +
        item.diskDegen +
        item.diskPat +
        item.yellowLigament +
        item.foramenR +
        item.foramenL +
        item.facetArthrosisR +
        item.facetArthrosisL +
        item.Modic +
        item.spinalCanal >
        0 ||
      customSegmentDescription !== '' ||
      compressionDescription !== '' ||
      laminectomyDescription !== ''
        ? item.name +
          ' - ' +
          customSegmentDescription +
          compressionDescription +
          laminectomyDescription +
          (item.spinalCanal > 0
            ? // stenóza páteřního kanálu
              aStenosis[item.spinalCanal].canalLSConclusion +
              (item.diskPat > 0 && item.diskPat !== 5 /*anulární fisura*/
                ? aDiskPat[item.diskPat].conclusion
                : '') +
              (item.diskPat !== 5
                ? item.diskPat === 4 && directionsText !== ''
                  ? ' asymetricky výraznějšího ' + directionsText
                  : directionsText
                : '') +
              (item.diskPatSize > 0
                ? ' (' + aSize[item.diskPatSize].text + ')'
                : '') +
              (item.diskMigration > 0
                ? ' ' + aDiskMigration[item.diskMigration].description
                : '') +
              (item.diskMigrationSize > 0
                ? ' (' + aSize[item.diskMigrationSize].text + ')'
                : '') +
              (item.diskPat > 0 &&
              (item.listesis > 0 || item.yellowLigament > 0)
                ? ' a '
                : '') +
              (item.listesis > 0 || item.yellowLigament > 0
                ? 'degenerativních změn (' +
                  (item.listesis > 0
                    ? aListesis[item.listesis].conclusion +
                      getFirstVertebraFromSegment(item.name) +
                      (item.listesisSize > 0
                        ? ' o ' + aSize[item.listesisSize].text
                        : '')
                    : '') +
                  (item.listesis > 0 && item.yellowLigament > 0 ? ', ' : '') +
                  (item.yellowLigament > 0 ? 'hypertrofie žlutých vazů' : '') +
                  ')'
                : '') +
              ', '
            : // bez stenózy páteřního kanálu
              (item.diskPat > 0
                ? (item.diskPat !== 5
                    ? aDiskPat[item.diskPat].description
                    : aDiskPat[item.diskPat].conclusion) +
                  (item.diskPat === 4 /*bulging*/ && directionsText != ''
                    ? ' asymetricky výraznější ' + directionsText
                    : directionsText) +
                  (item.diskPatSize > 0
                    ? ' (' + aSize[item.diskPatSize].text + ')'
                    : '') +
                  (item.diskMigration > 0 ? ' ' : ', ') +
                  (item.diskMigration > 0
                    ? aDiskMigration[item.diskMigration].description
                    : '') +
                  (item.diskMigrationSize > 0
                    ? ' (' + aSize[item.diskMigrationSize].text + '), '
                    : '')
                : '') +
              (item.listesis > 0
                ? aListesis[item.listesis].conclusion +
                  getFirstVertebraFromSegment(item.name) +
                  (item.listesisSize > 0
                    ? ' (' + aSize[item.listesisSize].text + '), '
                    : '')
                : '') +
              (item.yellowLigament > 0 ? 'hypertrofie žlutých vazů, ' : '')) +
          (item.foramenR > 0 || item.foramenL > 0
            ? item.foramenR === item.foramenL
              ? aStenosis[item.foramenR].foramenLSConclusion +
                'stenóza obou foramin, '
              : item.foramenR > 0 && item.foramenL > 0
              ? aStenosis[item.foramenR].foramenLSConclusion +
                'stenóza pravého a ' +
                aStenosis[item.foramenL].foramenLSConclusion +
                ' stenóza levého foramina, '
              : item.foramenR > 0
              ? aStenosis[item.foramenR].foramenLSConclusion +
                'stenóza pravého foramina, '
              : aStenosis[item.foramenL].foramenLSConclusion +
                'stenóza levého foramina, '
            : '') +
          aDiskDegen[item.diskDegen].conclusion +
          aModic[item.Modic].description +
          // facetová artróza
          (item.facetArthrosisR > 0 || item.facetArthrosisL > 0
            ? 'artróza ' +
              (item.facetArthrosisR > 0 && item.facetArthrosisL > 0
                ? 'obou facetových kloubů'
                : item.facetArthrosisR > 0
                ? 'pravého facetového kloubu'
                : item.facetArthrosisL > 0
                ? 'levého facetového kloubu'
                : '')
            : '') +
          // dekomp. facetová artróza
          (item.facetArthrosisR === 2 || item.facetArthrosisL === 2
            ? ', edém kostní dřeně ' +
              (item.facetArthrosisR === 2 && item.facetArthrosisL === 2
                ? 'obou facetových kloubů'
                : item.facetArthrosisR === 2
                ? 'pravého facetového kloubu'
                : item.facetArthrosisL === 2
                ? 'levého facetového kloubu'
                : '')
            : '')
        : '';
    importDescription1(segmentDescription);
  });

  // Zkrácený závěr páteře
  // Stenóza páteřního kanálu
  let mildStenosis = [];
  let moderateStenosis = [];
  let severeStenosis = [];

  let spinalCanalStenosisConclusion = '';

  mildStenosis = segments.filter(segment => segment.spinalCanal === 1);
  moderateStenosis = segments.filter(segment => segment.spinalCanal === 2);
  severeStenosis = segments.filter(segment => segment.spinalCanal === 3);

  if (
    severeStenosis.length > 0 &&
    moderateStenosis.length > 0 &&
    mildStenosis.length > 0
  ) {
    spinalCanalStenosisConclusion +=
      'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
      formatArrayToText(severeStenosis.map(item => item.name)) +
      ', středně těžká (bez myelopatie) v úrovni ' +
      formatArrayToText(moderateStenosis.map(item => item.name)) +
      ' a lehká v úrovni ' +
      formatArrayToText(mildStenosis.map(item => item.name)) +
      '. ';
  } else if (moderateStenosis.length > 0 && mildStenosis.length > 0) {
    spinalCanalStenosisConclusion +=
      'Středně těžká stenóza páteřního kanálu (bez myelopatie) v úrovni ' +
      formatArrayToText(moderateStenosis.map(item => item.name)) +
      ' a lehká v úrovni ' +
      formatArrayToText(mildStenosis.map(item => item.name)) +
      '. ';
  } else if (severeStenosis.length > 0 && mildStenosis.length > 0) {
    spinalCanalStenosisConclusion +=
      'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
      formatArrayToText(severeStenosis.map(item => item.name)) +
      ' a lehká v úrovni ' +
      formatArrayToText(mildStenosis.map(item => item.name)) +
      '. ';
  } else if (severeStenosis.length > 0 && moderateStenosis.length > 0) {
    spinalCanalStenosisConclusion +=
      'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
      formatArrayToText(severeStenosis.map(item => item.name)) +
      ' a středně těžká (bez myelopatie) v úrovni ' +
      formatArrayToText(moderateStenosis.map(item => item.name)) +
      '. ';
  } else {
    if (mildStenosis.length > 0) {
      spinalCanalStenosisConclusion +=
        'Lehká stenóza páteřního kanálu v úrovni ' +
        formatArrayToText(mildStenosis.map(item => item.name)) +
        '. ';
    } else if (moderateStenosis.length > 0) {
      spinalCanalStenosisConclusion +=
        'Středně těžká stenóza páteřního kanálu (bez myelopatie) v úrovni ' +
        formatArrayToText(moderateStenosis.map(item => item.name)) +
        '. ';
    } else if (
      severeStenosis.length + moderateStenosis.length + mildStenosis.length >
      0
    ) {
      spinalCanalStenosisConclusion +=
        'Těžká stenóza páteřního kanálu se susp. myelopatií v úrovni ' +
        formatArrayToText(severeStenosis.map(item => item.name)) +
        '. ';
    }
  }

  // Kompresivní fraktury obratlových těl

  // Stenóza foramin
  let foramenStenosisSevere = [];
  let foramenStenosisModerate = [];
  let foramenStenosisMild = [];

  foramenStenosisSevere = segments
    .map(segment => {
      if (segment.foramenR === 3 && segment.foramenL === 3) {
        return 'obou foramin ' + segment.name;
      } else if (segment.foramenR === 3) {
        return 'pravého foramen ' + segment.name;
      } else if (segment.foramenL === 3) {
        return 'levého foramen ' + segment.name;
      }
    })
    .filter(n => n);

  foramenStenosisModerate = segments
    .map(segment => {
      if (segment.foramenR === 2 && segment.foramenL === 2) {
        return 'obou foramin ' + segment.name;
      } else if (segment.foramenR === 2) {
        return 'pravého foramen ' + segment.name;
      } else if (segment.foramenL === 2) {
        return 'levého foramen ' + segment.name;
      }
    })
    .filter(n => n);

  foramenStenosisMild = segments
    .map(segment => {
      if (segment.foramenR === 1 && segment.foramenL === 1) {
        return 'obou foramin ' + segment.name;
      } else if (segment.foramenR === 1) {
        return 'pravého foramen ' + segment.name;
      } else if (segment.foramenL === 1) {
        return 'levého foramen ' + segment.name;
      }
    })
    .filter(n => n);

  let foramenStenosisConclusion = '';
  let foramenArray = [];

  if (foramenStenosisSevere.length > 0) {
    foramenArray.push(
      'Těžká stenóza ' + formatArrayToText(foramenStenosisSevere)
    );
    if (foramenStenosisModerate.length > 0) {
      foramenArray.push(
        'středně těžká ' + formatArrayToText(foramenStenosisModerate)
      );
    }
    if (foramenStenosisMild.length > 0) {
      foramenArray.push('lehká ' + formatArrayToText(foramenStenosisMild));
    }
    foramenStenosisConclusion = formatArrayToText(foramenArray) + '. ';
  } else if (foramenStenosisModerate.length > 0) {
    foramenArray.push(
      'Středně těžká stenóza ' + formatArrayToText(foramenStenosisModerate)
    );
    if (foramenStenosisMild.length > 0) {
      foramenArray.push('lehká ' + formatArrayToText(foramenStenosisMild));
    }
    foramenStenosisConclusion = formatArrayToText(foramenArray) + '. ';
  } else if (foramenStenosisMild.length > 0) {
    foramenArray.push(
      'Lehká stenóza ' + formatArrayToText(foramenStenosisMild)
    );
    foramenStenosisConclusion = formatArrayToText(foramenArray) + '. ';
  }

  // Hernie disků
  let herniasConclusion = '';
  let allHernias = segments
    .map(segment => {
      if (segment.diskPat === 2 && segment.spinalCanal === 0) {
        let directions = segment.diskDirection.map(
          direction => direction.symbol
        );
        return (
          segment.name +
          ' ' +
          getDiskDirection(directions) +
          ' (' +
          segment.diskPatSize +
          'mm' +
          (segment.diskMigration > 0
            ? ', ' + aDiskMigration[segment.diskMigration].description
            : '') +
          (segment.diskMigrationSize > 0
            ? ' ' + aSize[segment.diskMigrationSize].text + ')'
            : ')')
        );
      }
    })
    .filter(n => n);

  if (allHernias.length > 0) {
    herniasConclusion = 'Hernie disku ' + formatArrayToText(allHernias) + '. ';
  }

  // Bulging disků
  let bulgingConclusion = '';
  let allBulgings = segments
    .map(segment => {
      if (segment.diskPat === 4 && segment.spinalCanal === 0) {
        let directions = segment.diskDirection.map(
          direction => direction.symbol
        );
        return (
          segment.name +
          ' ' +
          getDiskDirection(directions) +
          ' (' +
          segment.diskPatSize +
          'mm)'
        );
      }
    })
    .filter(n => n);

  if (allBulgings.length > 0) {
    bulgingConclusion =
      'Bulging disku ' + formatArrayToText(allBulgings) + '. ';
  }

  // Anulární fisury
  let anularFissureConclusion = '';
  let allAnularFissures = segments
    .map(segment => {
      if (segment.diskPat === 5) {
        let directions = segment.diskDirection.map(
          direction => direction.symbol
        );
        return segment.name + ' ' + getDiskDirection(directions);
      }
    })
    .filter(n => n);

  if (allAnularFissures.length > 0) {
    anularFissureConclusion =
      'Anulární fisura disku ' + formatArrayToText(allAnularFissures) + '. ';
  }

  // Listézy
  let listhesisConclusion = '';
  let allVentrolisthesis = segments
    .map(segment => {
      if (segment.listesis === 1) {
        return (
          getFirstVertebraFromSegment(segment.name) +
          ' (' +
          aSize[segment.listesisSize].text +
          ')'
        );
      }
    })
    .filter(n => n);

  let allDorsolisthesis = segments
    .map(segment => {
      if (segment.listesis === 2) {
        return (
          getFirstVertebraFromSegment(segment.name) +
          ' (' +
          aSize[segment.listesisSize].text +
          ')'
        );
      }
    })
    .filter(n => n);

  if (allVentrolisthesis.length + allDorsolisthesis.length > 0) {
    if (allVentrolisthesis.length > 0) {
      listhesisConclusion +=
        'Ventrolistéza ' + formatArrayToText(allVentrolisthesis) + '. ';
    }
    if (allDorsolisthesis.length > 0) {
      listhesisConclusion +=
        'Dorzolistéza ' + formatArrayToText(allDorsolisthesis) + '. ';
    }
  }

  // Akutní dekomp. degenerativních změn
  // // Modic 1
  let decompDegenPlates = segments
    .map(segment => {
      if (segment.Modic === 1) {
        return segment.name;
      }
    })
    .filter(n => n);

  let decompFacetArthrosis = segments
    .map(segment => {
      if (segment.facetArthrosisR === 2 && segment.facetArthrosisL === 2) {
        return segment.name + ' bilat.';
      } else if (segment.facetArthrosisR === 2) {
        return segment.name + ' vpravo';
      } else if (segment.facetArthrosisL === 2) {
        return segment.name + ' vlevo';
      }
    })
    .filter(n => n);

  let decompDegenPlatesFormatted =
    decompDegenPlates.length > 0
      ? 'degen. změn krycích ploten ' + formatArrayToText(decompDegenPlates)
      : null;

  let decompFacetArthrosisFormatted =
    decompFacetArthrosis.length > 0
      ? 'facetové artrózy ' + formatArrayToText(decompFacetArthrosis)
      : null;

  let decompArray = [];
  if (decompDegenPlates.length > 0)
    decompArray.push(decompDegenPlatesFormatted);
  if (decompFacetArthrosis.length > 0)
    decompArray.push(decompFacetArthrosisFormatted);

  let acuteDecompDegenConclusion =
    decompArray.length > 0
      ? 'Akutní dekompenzace ' + formatArrayToText(decompArray) + '. '
      : '';

  // Komprese obratlových těl v závěru
  // Akutní
  let acuteSevereCompressions = formatArrayToText(
    compressions
      .map(vertebra => {
        if (vertebra.edema === 1 && vertebra.compression === 2) {
          return aVertebrae[vertebra.vertebra].text;
        }
      })
      .filter(n => n)
  );
  let acuteModerateCompressions = formatArrayToText(
    compressions
      .map(vertebra => {
        if (vertebra.edema === 1 && vertebra.compression === 1) {
          return aVertebrae[vertebra.vertebra].text;
        }
      })
      .filter(n => n)
  );
  let acuteMildCompressions = formatArrayToText(
    compressions
      .map(vertebra => {
        if (vertebra.edema === 1 && vertebra.compression === 0) {
          return aVertebrae[vertebra.vertebra].text;
        }
      })
      .filter(n => n)
  );
  // Chronická
  let chronicSevereCompressions = formatArrayToText(
    compressions
      .map(vertebra => {
        if (vertebra.edema === 0 && vertebra.compression === 2) {
          return aVertebrae[vertebra.vertebra].text;
        }
      })
      .filter(n => n)
  );
  let chronicModerateCompressions = formatArrayToText(
    compressions
      .map(vertebra => {
        if (vertebra.edema === 0 && vertebra.compression === 1) {
          return aVertebrae[vertebra.vertebra].text;
        }
      })
      .filter(n => n)
  );
  let chronicMildCompressions = formatArrayToText(
    compressions
      .map(vertebra => {
        if (vertebra.edema === 0 && vertebra.compression === 0) {
          return aVertebrae[vertebra.vertebra].text;
        }
      })
      .filter(n => n)
  );

  let compressionsConclusion = '';
  if (acuteSevereCompressions !== '') {
    compressionsConclusion +=
      'Nedávná těžká komprese obratlového těla ' + acuteSevereCompressions;
    if (acuteModerateCompressions !== '' && acuteMildCompressions !== '') {
      compressionsConclusion +=
        ', středně těžká těla ' +
        acuteModerateCompressions +
        ' a lehká těla ' +
        acuteMildCompressions +
        '. ';
    } else if (acuteModerateCompressions !== '') {
      compressionsConclusion +=
        'a středně těžká těla ' + acuteModerateCompressions + '. ';
    } else if (acuteMildCompressions != '') {
      compressionsConclusion += ' a lehká těla ' + acuteMildCompressions + '. ';
    } else {
      compressionsConclusion += '. ';
    }
  } else if (acuteModerateCompressions !== '') {
    compressionsConclusion +=
      'Nedávná středně těžká komprese obratlového těla ' +
      acuteModerateCompressions;
    if (acuteMildCompressions !== '') {
      compressionsConclusion += ' a lehká těla ' + acuteMildCompressions + '. ';
    } else {
      compressionsConclusion += '. ';
    }
  } else if (acuteMildCompressions !== '') {
    compressionsConclusion +=
      'Nedávná lehká komprese obratlového těla ' + acuteMildCompressions + '. ';
  }

  if (chronicSevereCompressions !== '') {
    compressionsConclusion +=
      'Starší těžká komprese obratlového těla ' + chronicSevereCompressions;
    if (chronicModerateCompressions !== '' && chronicMildCompressions !== '') {
      compressionsConclusion +=
        ', středně těžká těla ' +
        chronicModerateCompressions +
        ' a lehká těla ' +
        chronicMildCompressions +
        '. ';
    } else if (chronicModerateCompressions !== '') {
      compressionsConclusion +=
        'a středně těžká těla ' + chronicModerateCompressions + '. ';
    } else if (chronicMildCompressions != '') {
      compressionsConclusion +=
        ' a lehká těla ' + chronicMildCompressions + '. ';
    } else {
      compressionsConclusion += '. ';
    }
  } else if (chronicModerateCompressions !== '') {
    compressionsConclusion +=
      'Starší středně těžká komprese obratlového těla ' +
      chronicModerateCompressions;
    if (chronicMildCompressions !== '') {
      compressionsConclusion +=
        ' a lehká těla ' + chronicMildCompressions + '. ';
    } else {
      compressionsConclusion += '. ';
    }
  } else if (chronicMildCompressions !== '') {
    compressionsConclusion +=
      'Starší lehká komprese obratlového těla ' +
      chronicMildCompressions +
      '. ';
  }
  // Potraumatická stenóza páteřního kanálu
  let severeTraumaticStenosis = compressions
    .map(vertebra => {
      if (vertebra.prominence === 4) {
        return aVertebrae[vertebra.vertebra].text;
      }
    })
    .filter(n => n);

  let moderateTraumaticStenosis = compressions
    .map(vertebra => {
      if (vertebra.prominence === 3) {
        return aVertebrae[vertebra.vertebra].text;
      }
    })
    .filter(n => n);

  let mildTraumaticStenosis = compressions
    .map(vertebra => {
      if (vertebra.prominence === 2) {
        return aVertebrae[vertebra.vertebra].text;
      }
    })
    .filter(n => n);

  if (
    severeTraumaticStenosis.length +
      moderateTraumaticStenosis.length +
      mildTraumaticStenosis.length >
    0
  ) {
    if (severeTraumaticStenosis.length > 0) {
      compressionsConclusion +=
        'Potraumatická těžká stenóza páteřního kanálu v úrovni ' +
        severeTraumaticStenosis;
      if (
        moderateTraumaticStenosis.length > 0 &&
        mildTraumaticStenosis.length > 0
      ) {
        compressionsConclusion +=
          ', středně těžká v úrovni ' +
          moderateTraumaticStenosis +
          ' a lehká v úrovni ' +
          mildTraumaticStenosis +
          '. ';
      } else if (moderateTraumaticStenosis.length > 0) {
        compressionsConclusion +=
          ' a středně těžká v úrovni ' + moderateTraumaticStenosis + '. ';
      } else {
        compressionsConclusion +=
          ' a lehká v úrovni ' + mildTraumaticStenosis + '. ';
      }
    } else if (moderateTraumaticStenosis.length > 0) {
      compressionsConclusion +=
        'Potraumatická středně těžká stenóza páteřního kanálu v úrovni ' +
        moderateTraumaticStenosis;
      if (mildTraumaticStenosis.length > 0) {
        compressionsConclusion +=
          ' a lehká v úrovni ' + mildTraumaticStenosis + '. ';
      } else {
        compressionsConclusion += '. ';
      }
    } else if (mildTraumaticStenosis.length > 0) {
      compressionsConclusion +=
        'Potraumatická lehká stenóza páteřního kanálu v úrovni ' +
        mildTraumaticStenosis +
        '. ';
    }
  } else if (compressions.length > 0) {
    compressionsConclusion += 'Bez potraumatické stenózy páteřního kanálu. ';
  }

  // Páteř obecně
  const {
    spineSag,
    spineCor,
    hemangiomas,
    schmorls,
    addFinds,
    spineCustomDescription,
    spineCustomConclusion,
  } = props.general;

  let hemangiomasFinal = hemangiomas
    .sort(compareNumbers)
    .map(item => aVertebrae[item].text);
  hemangiomasFinal = formatArrayToText(hemangiomasFinal);

  let spineGeneralDescription =
    spineCustomDescription +
    aSpineCor[spineCor].description +
    (segments.filter(item => item.listesis > 0).length > 0
      ? 'Jinak plynulá hrudní kyfóza. '
      : aSpineSag[spineSag].description) +
    (compressions.length > 0
      ? 'Jinak přiměřená výše obratlových těl. '
      : 'Přiměřená výše obratlových těl. ') +
    'Mícha v hrudním úseku pravidelné šíře, bez strukturálních či signálových změn. ' +
    (hemangiomas.length > 0
      ? 'Ložisko na T2 vyšší SI a na T1 bez snížení SI v kostní dřeni obratlového těla ' +
        hemangiomasFinal +
        '. '
      : '') +
    (hemangiomas.length > 0 || boneLesions.length > 0
      ? 'Jinak bez ložiskových změn skeletu. '
      : 'Bez ložiskových změn skeletu. ');

  let spineGeneralConclusion =
    aSpineCor[spineCor].conclusion +
    aSpineSag[spineSag].conclusion +
    (hemangiomas.length > 0
      ? 'Hemangiom obratle ' + hemangiomasFinal + '. '
      : '') +
    spineCustomConclusion;

  // Páteř ostatní
  schmorls.sort(compareNumbers);

  let schmorlsFinal = schmorls.map(item => aSpine[item].text);
  schmorlsFinal = formatArrayToText(schmorlsFinal);

  let spineOtherDescription =
    (schmorls.length > 0
      ? 'Povrchové defekty centrální části krycích ploten segmentu ' +
        schmorlsFinal +
        ' obrazu Schmorlových uzlů. '
      : '') +
    (addFinds.length > 0
      ? addFinds
          .sort(compareNumbers)
          .map(item => aAddFinds[item].description)
          .join('')
      : '');
  let spineOtherConclusion =
    addFinds.length > 0
      ? 'Vedlejší nálezy: ' +
        formatArrayToText(
          addFinds.sort(compareNumbers).map(item => aAddFinds[item].conclusion)
        )
      : '';

  let finalConclusion =
    spinalCanalStenosisConclusion +
    foramenStenosisConclusion +
    herniasConclusion +
    bulgingConclusion +
    anularFissureConclusion +
    listhesisConclusion +
    acuteDecompDegenConclusion;

  // Zkrácený závěr
  importConclusion1(
    segments.length > 0
      ? finalConclusion != ''
        ? finalConclusion
        : 'Nevýrazné degenerativní změny Th páteře. '
      : 'Přiměřený nález na MR Th páteře. '
  );

  importDescription2(spineGeneralDescription + spineOtherDescription);

  importConclusion2(
    customVertebraConclusion +
      stabilisationConclusion +
      spineGeneralConclusion +
      compressionsConclusion +
      boneLesionsConclusion +
      spineOtherConclusion
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
