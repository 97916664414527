export const contentWidth = 1230;

export const colorXRAY = '#a8071a';
export const colorUS = '#fa8c16';
export const colorCT = '#389e0d';
export const colorMRI = '#1890ff';

export const green = '#b7eb8f';
export const yellow = '#ffffb8';
export const orange = '#ffd591';
export const red = '#ffa39e';
export const grey = '#d9d9d9';

export const aExamsXray = [
  {
    value: 0,
    label: 'RTG hrudníku',
    text: 'hrudníku',
  },
  {
    value: 1,
    label: 'RTG ',
    text: 'C páteře',
  },
  {
    value: 2,
    label: 'MR Th páteře',
    text: 'Th páteře',
  },
];

export const aMRExams = [
  {
    value: 0,
    label: 'MR mozku',
    text: 'mozku',
    content: 'MR_brain',
  },
  {
    value: 1,
    label: 'MR C páteře',
    text: 'C páteře',
    content: 'MR_spine_C',
  },
  {
    value: 2,
    label: 'MR Th páteře',
    text: 'Th páteře',
    content: 'MR_spine_Th',
  },
];

export const aGeneral = [
  {
    value: 0,
    text: 'option 1',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'option 2',
    description: '',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'option 3',
    description: '',
    conclusion: '',
    color: red,
  },
];

export const aLesions = [
  {
    value: 0,
    text: 'ložisko',
  },
  {
    value: 1,
    text: 'varieta',
  },
];

export const aContrastMR = [
  {
    value: 0,
    text: 'bez kontrastní látky',
    label: 'bez kontrastní látky',
  },
  {
    value: 1,
    text: 'Prohance',
    label: 'Prohance (gadoteridol)',
  },
  {
    value: 2,
    text: 'Clariscan',
    label: 'Clariscan (gadoterát-meglumin)',
  },
  {
    value: 3,
    text: 'Gadovist',
    label: 'Gadovist (gadobutrol)',
  },
  {
    value: 4,
    text: 'Primovist',
    label: 'Primovist (dinatrium-gadoxetát)',
  },
  {
    value: 5,
    text: 'Vueway',
    label: 'Vueway (Gadopiclenol)',
  },
];

export const aContrastMRLiver = [
  {
    value: 0,
    text: 'bez kontrastní látky',
  },
  {
    value: 1,
    text: 'Primovist',
  },
];

export const aContrastCT = [
  {
    value: 0,
    text: 'bez kontrastní látky',
    label: 'bez kontrastní látky',
  },
  {
    value: 1,
    text: 'Iomeron 350',
    label: 'Iomeron 350',
  },
  {
    value: 2,
    text: 'Iomeron 400',
    label: 'Iomeron 400',
  },
  {
    value: 3,
    text: 'Optiray 350',
    label: 'Optiray 350',
  },
  {
    value: 4,
    text: 'Optiray 400',
    label: 'Optiray 400',
  },
];

export const aContrastMRamount = [
  {
    value: 0,
    text: '5ml',
  },
  {
    value: 1,
    text: '6ml',
  },
  {
    value: 2,
    text: '7ml',
  },
  {
    value: 3,
    text: '8ml',
  },
  {
    value: 4,
    text: '9ml',
  },
  {
    value: 5,
    text: '10ml',
  },
  {
    value: 6,
    text: '11ml',
  },
  {
    value: 7,
    text: '12ml',
  },
  {
    value: 8,
    text: '13ml',
  },
  {
    value: 9,
    text: '14ml',
  },
  {
    value: 10,
    text: '15ml',
  },
  {
    value: 11,
    text: '16ml',
  },
  {
    value: 12,
    text: '17ml',
  },
  {
    value: 13,
    text: '18ml',
  },
  {
    value: 14,
    text: '19ml',
  },
  {
    value: 15,
    text: '20ml',
  },
];

export const aContrastCTamount = [
  {
    value: 0,
    text: '10ml',
  },
  {
    value: 1,
    text: '20ml',
  },
  {
    value: 2,
    text: '30ml',
  },
  {
    value: 3,
    text: '40ml',
  },
  {
    value: 4,
    text: '50ml',
  },
  {
    value: 5,
    text: '60ml',
  },
  {
    value: 6,
    text: '70ml',
  },
  {
    value: 7,
    text: '80ml',
  },
  {
    value: 8,
    text: '90ml',
  },
  {
    value: 9,
    text: '100ml',
  },
];

export const aTrueFalse = [
  {
    value: 0,
    text: 'ne',
    color: '#b7eb8f',
  },
  {
    value: 1,
    text: 'ano',
    color: '#ffa39e',
  },
];
export const aCardColors = {
  red: {
    header: '#ffa39e',
    body: '#fff1f0',
  },
  purple: {
    header: '#cfcaf6',
    body: '#f9f0ff',
  },
  blue: {
    header: '#91d5ff',
    body: '#e6f7ff',
  },
  darkBlue: {
    header: '#95de64',
    body: '#91d5ff',
  },
  limeGreen: {
    header: '#bae637',
    body: '#fcffe6',
  },
  green: {
    header: '#76d0a3',
    body: '#ebf8f2',
  },
  orange: {
    header: '#ffc069',
    body: '#fff7e6',
  },
  yellow: {
    header: '#fffb8f',
    body: '#feffe6',
  },
  cyan: {
    header: '#87e8de',
    body: '#e6fffb',
  },
  magenta: {
    header: '#ff85c0',
    body: '#fff0f6',
  },
  pink: {
    header: '#fa90ba',
    body: '#feeff5',
  },
};

export const aSizemm = [
  {
    value: 0,
    text: '0mm',
  },
  {
    value: 1,
    text: '1mm',
  },
  {
    value: 2,
    text: '2mm',
  },
  {
    value: 3,
    text: '3mm',
  },
  {
    value: 4,
    text: '4mm',
  },
  {
    value: 5,
    text: '5mm',
  },
  {
    value: 6,
    text: '6mm',
  },
  {
    value: 7,
    text: '7mm',
  },
  {
    value: 8,
    text: '8mm',
  },
  {
    value: 9,
    text: '9mm',
  },
  {
    value: 10,
    text: '10mm',
  },
  {
    value: 11,
    text: '11mm',
  },
  {
    value: 12,
    text: '12mm',
  },
  {
    value: 13,
    text: '13mm',
  },
  {
    value: 14,
    text: '14mm',
  },
  {
    value: 15,
    text: '15mm',
  },
  {
    value: 16,
    text: '16mm',
  },
  {
    value: 17,
    text: '17mm',
  },
  {
    value: 18,
    text: '18mm',
  },
  {
    value: 19,
    text: '19mm',
  },
  {
    value: 20,
    text: '20mm',
  },
  {
    value: 21,
    text: '21mm',
  },
  {
    value: 22,
    text: '22mm',
  },
  {
    value: 23,
    text: '23mm',
  },
  {
    value: 24,
    text: '24mm',
  },
  {
    value: 25,
    text: '25mm',
  },
  {
    value: 26,
    text: '26mm',
  },
  {
    value: 27,
    text: '27mm',
  },
  {
    value: 28,
    text: '28mm',
  },
  {
    value: 29,
    text: '29mm',
  },
  {
    value: 30,
    text: '30mm',
  },
  {
    value: 31,
    text: '31mm',
  },
  {
    value: 32,
    text: '32mm',
  },
  {
    value: 33,
    text: '33mm',
  },
  {
    value: 34,
    text: '34mm',
  },
  {
    value: 35,
    text: '35mm',
  },
  {
    value: 36,
    text: '36mm',
  },
  {
    value: 37,
    text: '37mm',
  },
  {
    value: 38,
    text: '38mm',
  },
  {
    value: 39,
    text: '39mm',
  },
  {
    value: 40,
    text: '40mm',
  },
];

export const aSizecm = [
  {
    value: 0,
    text: '1cm',
  },
  {
    value: 1,
    text: '2cm',
  },
  {
    value: 2,
    text: '3cm',
  },
  {
    value: 3,
    text: '4cm',
  },
  {
    value: 4,
    text: '5cm',
  },
  {
    value: 5,
    text: '6cm',
  },
  {
    value: 6,
    text: '7cm',
  },
  {
    value: 7,
    text: '8cm',
  },
  {
    value: 8,
    text: '9cm',
  },
  {
    value: 9,
    text: '10cm',
  },
  {
    value: 10,
    text: '11cm',
  },
  {
    value: 11,
    text: '12cm',
  },
  {
    value: 12,
    text: '13cm',
  },
  {
    value: 13,
    text: '14cm',
  },
  {
    value: 14,
    text: '15cm',
  },
];

export const aSpine = [
  { value: 0, text: 'C1/2' },
  { value: 1, text: 'C2/3' },
  { value: 2, text: 'C3/4' },
  { value: 3, text: 'C4/5' },
  { value: 4, text: 'C5/6' },
  { value: 5, text: 'C6/7' },
  { value: 6, text: 'C7/Th1' },
  { value: 7, text: 'Th1/2' },
  { value: 8, text: 'Th2/3' },
  { value: 9, text: 'Th3/4' },
  { value: 10, text: 'Th4/5' },
  { value: 11, text: 'Th5/6' },
  { value: 12, text: 'Th6/7' },
  { value: 13, text: 'Th7/8' },
  { value: 14, text: 'Th8/9' },
  { value: 15, text: 'Th9/10' },
  { value: 16, text: 'Th10/11' },
  { value: 17, text: 'Th11/12' },
  { value: 18, text: 'Th12/L1' },
  { value: 19, text: 'L1/2' },
  { value: 20, text: 'L2/3' },
  { value: 21, text: 'L3/4' },
  { value: 22, text: 'L4/5' },
  { value: 23, text: 'L5/S1' },
];

export const aVertebrae = [
  { value: 0, text: 'C1' },
  { value: 1, text: 'C2' },
  { value: 2, text: 'C3' },
  { value: 3, text: 'C4' },
  { value: 4, text: 'C5' },
  { value: 5, text: 'C6' },
  { value: 6, text: 'C7' },
  { value: 7, text: 'Th1' },
  { value: 8, text: 'Th2' },
  { value: 9, text: 'Th3' },
  { value: 10, text: 'Th4' },
  { value: 11, text: 'Th5' },
  { value: 12, text: 'Th6' },
  { value: 13, text: 'Th7' },
  { value: 14, text: 'Th8' },
  { value: 15, text: 'Th9' },
  { value: 16, text: 'Th10' },
  { value: 17, text: 'Th11' },
  { value: 18, text: 'Th12' },
  { value: 19, text: 'L1' },
  { value: 20, text: 'L2' },
  { value: 21, text: 'L3' },
  { value: 22, text: 'L4' },
  { value: 23, text: 'L5' },
  { value: 24, text: 'S1' },
  { value: 25, text: 'S2' },
  { value: 26, text: 'S3' },
];

export const aSize = [
  { value: 0, text: '0mm', color: green },
  { value: 1, text: '1mm', color: yellow },
  { value: 2, text: '2mm', color: yellow },
  { value: 3, text: '3mm', color: yellow },
  { value: 4, text: '4mm', color: yellow },
  { value: 5, text: '5mm', color: yellow },
  { value: 6, text: '6mm', color: orange },
  { value: 7, text: '7mm', color: orange },
  { value: 8, text: '8mm', color: orange },
  { value: 9, text: '9mm', color: orange },
  { value: 10, text: '10mm', color: red },
  { value: 11, text: '11mm', color: red },
  { value: 12, text: '12mm', color: red },
  { value: 13, text: '13mm', color: red },
  { value: 14, text: '14mm', color: red },
  { value: 15, text: '15mm', color: red },
];

export const aLN = [
  {
    value: 0,
    text: 'normální',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční',
    description: 'hraniční velikosti',
    conclusion: 'hraniční velikosti',
    color: yellow,
  },
  {
    value: 2,
    text: 'zvětšené',
    description: 'zvětšené',
    conclusion: 'zvětšené',
    color: red,
  },
];

export const aLNcount = [
  {
    value: 0,
    text: '1',
    description: '1',
    conclusion: 'jedna ',
    color: orange,
  },
  {
    value: 1,
    text: '2',
    description: '2',
    conclusion: 'dvě ',
    color: orange,
  },
  {
    value: 2,
    text: '3',
    description: '3',
    conclusion: 'tři ',
    color: orange,
  },
  {
    value: 3,
    text: '4',
    description: '4',
    conclusion: 'čtyři ',
    color: orange,
  },
  {
    value: 4,
    text: '5',
    description: '5',
    conclusion: 'pět ',
    color: orange,
  },
  {
    value: 5,
    text: '6',
    description: '6',
    conclusion: '6',
    color: orange,
  },
  {
    value: 6,
    text: '7',
    description: '7',
    conclusion: '7',
    color: orange,
  },
];

export const indent = '   ';

export const br = '\n';
