import React from "react";
import { Select } from "antd";
const { Option } = Select;

class DropDown extends React.Component {
  state = {
    value: this.props.options[
      !this.props.multiselect ? this.props.defaultValue : 0
      /*typeof this.props.defaultValue === "undefined"
        ? 0
        : this.props.defaultValue*/
    ].value,
  };

  onChange = (value) => {
    if (Array.isArray(value)) {
      value.sort((a, b) => a - b);
    }
    this.setState({ value: value });
    this.props.onChange({
      segment: this.props.segment,
      name: this.props.name,
      value: value,
    });
  };
  render() {
    const menu = this.props.options.map((option) => {
      return (
        <Option key={option.value} value={option.value}>
          {option.text}
        </Option>
      );
    });
    return (
      <Select
        mode={this.props.multiselect ? "multiple" : null}
        defaultValue={this.props.defaultValue}
        placeholder={this.props.multiselect ? "vyberte..." : null}
        onChange={this.onChange}
        size="small"
        style={{ width: "100%" }}
      >
        {menu}
      </Select>
    );
  }
}

export default DropDown;
