import React from "react";

// Custom scripts
import { aStenosis, aArteries, aCADRADS } from "../CT/CT_CTA_cardiac_arrays";

import { Tooltip } from "antd";

class CAD_RADS_map extends React.Component {
  state = {
    RCA1: 0,
    RCA2: 0,
    RCA3: 0,
    RCA4: 0,
    RCA16: 0,
    LCA5: 0,
    LCA6: 0,
    LCA7: 0,
    LCA8: 0,
    LCA9: 0,
    LCA10: 0,
    LCA11: 0,
    LCA12: 0,
    LCA13: 0,
    LCA14: 0,
    LCA15: 0,
    LCA17: 0,
    LCA18: 0,
  };

  onChange = (event) => {
    let name = event.target.id;
    if (event.nativeEvent.which === 1) {
      // Left Click
      if (this.state[name] < this.props.options.length - 1) {
        this.setState((prevState) => {
          return { ...prevState, [name]: prevState[name] + 1 };
        });
        this.props.onChange({
          segment: "arteries",
          name: name,
          value: this.props.options[this.state[name] + 1].value,
        });
      }
    } else if (event.nativeEvent.which === 3) {
      // Right Click
      event.preventDefault();
      if (this.state[name] > 0) {
        this.setState((prevState) => {
          return { ...prevState, [name]: prevState[name] - 1 };
        });
        this.props.onChange({
          segment: "arteries",
          name: name,
          value: this.props.options[this.state[name] - 1].value,
        });
      }
    }
  };

  render() {
    const {
      RCA1,
      RCA2,
      RCA3,
      RCA4,
      RCA16,
      LCA5,
      LCA6,
      LCA7,
      LCA8,
      LCA9,
      LCA10,
      LCA11,
      LCA12,
      LCA13,
      LCA14,
      LCA15,
      LCA17,
      LCA18,
    } = this.props.arteries;

    return (
      <div style={{ margin: 0 }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height={350}
          version="1.1"
          viewBox="12 105 150 75"
        >
          <>
            {aArteries.map((item, index) => {
              return (
                <a
                  id={index}
                  onClick={this.onChange}
                  onContextMenu={this.onChange}
                >
                  <Tooltip
                    id={item.text}
                    placement="left"
                    title={
                      <span>
                        {item.description +
                          " (" +
                          aCADRADS[this.state[item.text]].description1 +
                          ")"}
                      </span>
                    }
                  >
                    <path
                      id={item.text}
                      d={item.path}
                      fill={aCADRADS[this.state[item.text]].color}
                      stroke="black"
                      stroke-width="0.3px"
                    />
                  </Tooltip>
                </a>
              );
            })}
          </>
          // kořen aorty
          <ellipse
            cx="57.481"
            cy="130.72"
            rx="12.191"
            ry="12.289"
            fill="white"
            stroke="black"
            stroke-width="0.3px"
          />
          <path
            d="m68.099 124.87h-0.10504l-10.505 6.0403-0.0525 12.238"
            fill="none"
            stroke="black"
            stroke-width="0.3px"
          />
          <path
            d="m46.774 124.6 10.715 6.3029"
            fill="none"
            stroke="black"
            stroke-width="0.3px"
          />
        </svg>
      </div>
    );
  }
}

export default CAD_RADS_map;
