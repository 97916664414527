import { green, yellow, orange, red, grey } from '../scripts/GeneralArrays';

export const aHeart = [
  {
    value: 0,
    text: 'normální velikosti',
    description: 'Přiměřená šíře srdečního stínu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční šíře',
    description: 'Hraniční šíře srdečního stínu. ',
    conclusion: 'Hraniční šíře srdečního stínu. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'mírně rozšířené',
    description: 'Mírné rozšíření srdečního stínu. ',
    conclusion: 'Mírné rozšíření srdečního stínu. ',
    color: orange,
  },
  {
    value: 3,
    text: 'rozšířené',
    description: 'Rozšíření srdečního stínu. ',
    conclusion: 'Rozšíření srdečního stínu. ',
    color: red,
  },
  {
    value: 4,
    text: 'výrazně rozšířené',
    description: 'Výrazné rozšíření srdečního stínu. ',
    conclusion: 'Výrazné rozšíření srdečního stínu. ',
    color: red,
  },
];

export const aHeartFailure = [
  {
    value: 0,
    text: 'bez městnání',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '1. stupeň',
    description: 'Oboustranně perihilózně rozšířené plicní žíly. ',
    conclusion: 'Lehké městnání v malém oběhu 1. stupně. ',
    color: yellow,
  },
  {
    value: 2,
    text: '2. stupeň',
    description:
      'Oboustranně perihilózně výrazně rozšířené plicní žíly neostrých kontur. ',
    conclusion:
      'Středně pokročilé městnání v malém oběhu (intersticiální plicní edém). ',
    color: orange,
  },
  {
    value: 3,
    text: '3. stupeň',
    description: 'Oboustranně perihilózní a parakardiální konsolidace. ',
    conclusion: 'Těžké městnání v malém oběhu (alveolární plicní edém). ',
    color: red,
  },
];

export const aFluidothorax = [
  {
    value: 0,
    text: 'není',
    description1: '',
    description2: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'malý',
    description1:
      'bazálně v dolním plicním poli snížení transparence zneostřující konturu bránice. ',
    description2:
      'laterobazálně v dolním plicním poli vzlínající zastínění hladinkou. ',
    conclusion: 'obraz malého fluidothoraxu. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'středně velký',
    description1:
      'snížení transparence dolního a části středního plicního pole zneostřující konturu bránice. ',
    description2:
      'na rozhraní středního a dolního plicního pole vzlínající zastínění hladinkou. ',
    conclusion: 'obraz středně velkého fluidothoraxu. ',
    color: orange,
  },
  {
    value: 3,
    text: 'velký',
    description1: 'snížení transparence téměř celého hemisthoraxu. ',
    description2:
      've středním plicním poli a výše vzlínající zastínění hladinkou. ',
    conclusion: 'obraz velkého fluidothoraxu. ',
    color: red,
  },
];

export const aConsolidation = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'Neostře ohraničené zastínění ',
    conclusion: 'Susp. zánětlivá konsolidace ',
    color: red,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově neostře ohraničené zastínění ',
    conclusion: 'Nově susp. zánětlivá konsolidace ',
    color: red,
  },
  {
    value: 3,
    text: 'progrese',
    description: 'Progrese neostře ohraničeného zastínění ',
    conclusion: 'Progrese zánětlivé konsolidace ',
    color: red,
  },
  {
    value: 4,
    text: 'částečná regrese',
    description: 'Částečná regrese neostře ohraničeného zastínění ',
    conclusion: 'Částečná regrese zánětlivé konsolidace ',
    color: orange,
  },
  {
    value: 5,
    text: 'úplná regrese',
    description: 'Úplná regrese neostře ohraničeného zastínění ',
    conclusion: 'Úplná regrese zánětlivé konsolidace ',
    color: yellow,
  },
];

export const aHypoventilation = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'Zastínění charakteru hypoventilace ',
    conclusion: 'Hypoventilace ',
    color: red,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově zastínění charakteru hypoventilace ',
    conclusion: 'Nově hypoventilace ',
    color: red,
  },
  {
    value: 3,
    text: 'progrese',
    description: 'Progrese zastínění charakteru hypoventilace ',
    conclusion: 'Progrese hypoventilace ',
    color: red,
  },
  {
    value: 4,
    text: 'částečná regrese',
    description: 'Částečná regrese zastínění charakteru hypoventilace ',
    conclusion: 'Částečná regrese hypoventilace ',
    color: orange,
  },
  {
    value: 5,
    text: 'úplná regrese',
    description: 'Úplná regrese zastínění charakteru hypoventilace ',
    conclusion: 'Úplná regrese hypoventilace ',
    color: yellow,
  },
];

export const aConsolidations = [
  {
    value: 0,
    text: 'RUL',
    description: 'periferně v pravém horním',
    conclusion: '',
  },
  {
    value: 1,
    text: 'RUM',
    description: 'paramediastinálně v pravém horním',
    conclusion: '',
  },
  {
    value: 2,
    text: 'LUM',
    description: 'paramediastinálně v levém horním',
    conclusion: '',
  },
  {
    value: 3,
    text: 'LUL',
    description: 'periferně v levém horním',
    conclusion: '',
  },
  {
    value: 4,
    text: 'RML',
    description: 'periferně v pravém středním',
    conclusion: '',
  },
  {
    value: 5,
    text: 'RMM',
    description: 'perihilózně v pravém středním',
    conclusion: '',
  },
  {
    value: 6,
    text: 'LMM',
    description: 'perihilózně v levém středním',
    conclusion: '',
  },
  {
    value: 7,
    text: 'LML',
    description: 'periferně v levém středním',
    conclusion: '',
  },
  {
    value: 8,
    text: 'RLL',
    description: 'periferně v pravém dolním',
    conclusion: '',
  },
  {
    value: 9,
    text: 'RLM',
    description: 'parakardiálně v pravém dolním',
    conclusion: '',
  },
  {
    value: 10,
    text: 'LLM',
    description: 'parakardiálně v levém dolním',
    conclusion: '',
  },
  {
    value: 11,
    text: 'LLL',
    description: 'periferně v levém dolním',
    conclusion: '',
  },
];

export const aCZKvsR = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description:
      'CŽK zprava cestou v. subclavia, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description:
      'Nově zaveden CŽK zprava cestou v. subclavia, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'Nově vpravo odstraněn CŽK, původně cestou v. subclavia. ',
    conclusion: '',
    color: grey,
  },
];

export const aCZKvjR = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description:
      'CŽK zprava cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description:
      'Nově zaveden CŽK zprava cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'Nově vpravo odstraněn CŽK, původně cestou v. jugularis int. ',
    conclusion: '',
    color: grey,
  },
];

export const aCZKvsL = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description:
      'CŽK zleva cestou v. subclavia, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description:
      'Nově zaveden CŽK zleva cestou v. subclavia, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'Nově zleva odstraněn CŽK, původně cestou v. subclavia. ',
    conclusion: '',
    color: grey,
  },
];

export const aCZKvjL = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description:
      'CŽK zleva cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description:
      'Nově zaveden CŽK zleva cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'Nově zleva odstraněn CŽK, původně cestou v. jugularis int. ',
    conclusion: '',
    color: grey,
  },
];

export const aSheatR = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description:
      'Sheat zprava cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description:
      'Nově zaveden sheat zprava cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description:
      'Nově vpravo odstraněn sheat, původně cestou v. jugularis int. ',
    conclusion: '',
    color: grey,
  },
];

export const aSheatL = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description:
      'Sheat zleva cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description:
      'Nově zaveden sheat zleva cestou v. jugularis int., koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description:
      'Nově zleva odstraněn sheat, původně cestou v. jugularis int. ',
    conclusion: '',
    color: grey,
  },
];

export const aETK = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'ETK koncem přiměřeně nad karinou. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově ETK, koncem přiměřeně nad karinou. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'ETK ex. ',
    conclusion: '',
    color: grey,
  },
];

export const aTSK = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'TSK ve vyhovující pozici. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově TSK, ve vyhovující pozici. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'TSK ex. ',
    conclusion: '',
    color: grey,
  },
];

export const aPICCR = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'PICC zprava, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově zprava zaveden PICC, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'Nově vpravo odstraněn PICC. ',
    conclusion: '',
    color: grey,
  },
];

export const aPICCL = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'PICC zleva, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově zleva zaveden PICC, koncem v úrovni horní duté žíly. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'Nově vlevo odstraněn PICC. ',
    conclusion: '',
    color: grey,
  },
];

export const aHDR = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'HD zprava. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově HD zprava. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'HD zprava ex. ',
    conclusion: '',
    color: grey,
  },
];

export const aHDL = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'ano',
    description: 'HD zleva. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'nově',
    description: 'Nově HD zleva. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'ex',
    description: 'HD zleva ex. ',
    conclusion: '',
    color: grey,
  },
];

export const aHD = [
  {
    value: 0,
    text: 'HDR1',
    description: '',
    conclusion: '',
  },
  {
    value: 1,
    text: 'HDR2',
    description: '',
    conclusion: '',
  },
];

export const aPMR = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '1 dutin.',
    description:
      'Jednodutinový kardiostimulátor zprava, elektroda sumačně koncem v úrovni pravé komory srdeční. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: '2 dutin.',
    description:
      'Dvoudutinový kardiostimulátor zprava, jedna elektroda sumačně koncem v úrovni pravé komory a druhá v úrovni ouška pravé síně srdeční. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'biventr.',
    description:
      'Biventrikulární resynchronizační kardiostimulátor zprava, jedna elektroda sumačně koncem v úrovni pravé komory, druhá v úrovni ouška pravé síně a třetí cestou koronárního sinu k levé komoře srdeční. ',
    conclusion: '',
    color: orange,
  },
];

export const aPML = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '1 dutin.',
    description:
      'Jednodutinový kardiostimulátor zleva, elektroda sumačně koncem v úrovni pravé komory srdeční. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: '2 dutin.',
    description:
      'Dvoudutinový kardiostimulátor zleva, jedna elektroda sumačně koncem v úrovni pravé komory srdeční a druhá v úrovni ouška pravé síně. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'biventr.',
    description:
      'Biventrikulární resynchronizační kardiostimulátor zleva, jedna elektroda sumačně koncem v úrovni pravé komory srdeční, druhá v úrovni ouška pravé síně a třetí cestou koronárního sinu k levé komoře srdeční. ',
    conclusion: '',
    color: orange,
  },
];

export const aScoliosis = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '<',
    description: 'Mírná dextroskolióza Th páteře. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: '>',
    description: 'Mírná sinistroskolióza Th páteře. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: '<<',
    description: 'Výrazná dextroskolióza Th páteře. ',
    conclusion: '',
    color: red,
  },
  {
    value: 4,
    text: '>>',
    description: 'Výrazná sinistroskolióza Th páteře. ',
    conclusion: '',
    color: red,
  },
];

export const aSpondylosis = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná',
    description: 'Mírná deformační spondylóza Th páteře. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'pokročilá',
    description: 'Pokročilá deformační spondylóza Th páteře. ',
    conclusion: '',
    color: red,
  },
];

export const aScars = [
  {
    value: 0,
    text: 'není',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'sternotomie',
    description: 'St.p. střední sternotomii. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'clamshell thorakotomie',
    description: 'St.p. clamshell thorakotomii. ',
    conclusion: '',
    color: orange,
  },
];

export const aObscured = [
  {
    value: 0,
    text: 'bez limitace',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'apexy',
    description: 'Apexy mimo formát snímku. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'kostofrenické úhly',
    description: 'Kostofrenické úhly mimo formát snímku. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 3,
    text: 'pravý kostofrenický úhel',
    description: 'Pravý kostofrenický úhel mimo formát snímku. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 4,
    text: 'levý kostofrenický úhel',
    description: 'Levý kostofrenický úhel mimo formát snímku. ',
    conclusion: '',
    color: orange,
  },
];

export const aPatientRotation = [
  {
    value: 0,
    text: 'bez rotace',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'rotace',
    description: 'Pacient na snímku rotován. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 1,
    text: '<<< rotace doprava',
    description: 'Pacient na snímku rotován doprava. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'rotace doleva >>>',
    description: 'Pacient na snímku rotován doleva. ',
    conclusion: '',
    color: orange,
  },
];

export const aCompareToLast = [
  {
    value: 0,
    text: 'bez srovnání',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'je nález stac.',
    description: 'Oproti minule stac. ',
    conclusion: 'Oproti minule bez přesvědčivých změn. ',
    color: green,
  },
  {
    value: 2,
    text: 'nyní nově...',
    description: 'Oproti minule nyní nově ',
    conclusion: 'Oproti minule nyní nově ',
    color: orange,
  },
];

export const aECMO = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'VA ECMO',
    description:
      'Kanyly venoarteriálního mimotělního oběhu ve vyhovujícím postavení. ',
    conclusion: '',
    color: orange,
  },
  {
    value: 2,
    text: 'VV ECMO',
    description:
      'Kanyly venovenózního mimotělního oběhu ve vyhovujícím postavení. ',
    conclusion: '',
    color: orange,
  },
];

export const aAo = [
  {
    value: 0,
    text: 'Ao',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'Ao',
    description: 'aortální',
    conclusion: 'aortální',
    color: red,
  },
];

export const aMi = [
  {
    value: 0,
    text: 'Mi',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'Mi',
    description: 'mitrální',
    conclusion: 'mitrální',
    color: red,
  },
];

export const aTri = [
  {
    value: 0,
    text: 'Tri',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'Tri',
    description: 'trikuspidální',
    conclusion: 'trikuspidální',
    color: red,
  },
];

export const aPul = [
  {
    value: 0,
    text: 'Pul',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'Pul',
    description: 'pulmonální',
    conclusion: 'pulmonální',
    color: red,
  },
];

export const aSGcath = [
  {
    value: 0,
    text: 'ne',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: '<<<',
    description: ', koncem v úrovni pravé plicní tepny. ',
    conclusion: '',
    color: red,
  },
  {
    value: 2,
    text: '>>>',
    description: ', koncem v úrovni levé plicní tepny. ',
    conclusion: '',
    color: red,
  },
];
