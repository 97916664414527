import React, { useState } from "react";
import { Row, Col, Card, Button } from "antd";

import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";

function CustomCards(props) {
  const content = props.content;
  const [show, setShow] = useState(true);

  return (
    <Row gutter={1} type="flex">
      {content.map((card) => {
        return (
          <Col
            span={24 / content.length}
            style={{ backgroundColor: card.color.body }}
          >
            <Card
              title={card.title}
              headStyle={{ backgroundColor: card.color.header }}
              bodyStyle={{ backgroundColor: card.color.body }}
              size="small"
              //hoverable
              style={{ width: "100%" }}
              bordered={false}
              extra={
                card.extra === undefined ? (
                  <Button
                    size="small"
                    type="text"
                    icon={
                      show ? <MinusSquareOutlined /> : <PlusSquareOutlined />
                    }
                    onClick={() => setShow(!show)}
                  />
                ) : (
                  card.extra
                )
              }
            >
              {show &&
                card.rows.map((row) => {
                  return (
                    <Row type="flex">
                      {row.map((segment) => {
                        return (
                          <Col span={24 / row.length}>
                            <Row style={{ marginBottom: "1px" }}>
                              {segment.label !== "" ? (
                                <>
                                  <Col
                                    flex="100px"
                                    style={{
                                      textAlign: "right",
                                      paddingRight: "5px",
                                    }}
                                  >
                                    {segment.label}:
                                  </Col>
                                  <Col flex="auto">{segment.input}</Col>
                                </>
                              ) : (
                                <Col span={24}>{segment.input}</Col>
                              )}
                            </Row>
                          </Col>
                        );
                      })}
                    </Row>
                  );
                })}
            </Card>
          </Col>
        );
      })}
    </Row>
  );
}

export default CustomCards;
