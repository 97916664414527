import { green, yellow, orange, red, neutral } from "../scripts/GeneralArrays";

// Custom

export const aSelect = [
  {
    value: 0,
    text: "select 1",
    description1: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "select 2",
    description1: "",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "select 3",
    description1: "",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "select 4",
    description1: "",
    conclusion: "",
    color: red,
  },
];
