import { green, yellow, orange, red } from "../scripts/GeneralArrays";

export const aLiverSize = [
  {
    value: 0,
    text: "normální",
    description: "Játra normální velikosti, ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvětšena",
    description: "Játra mírně zvětšena, ",
    conclusion: "Mírně zvětšená játra. ",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšena",
    description: "Játra zvětšena, ",
    conclusion: "Zvětšená játra. ",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvětšena",
    description: "Játra výrazně zvětšena, ",
    conclusion: "Výrazně zvětšená játra. ",
    color: red,
  },
];

export const aLiverParenchyma = [
  {
    value: 0,
    text: "normální",
    description:
      "na T1 out-of-phase a in-phase sekvenci podobného signálu parenchymu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "steatóza difuzní",
    description:
      "na T1 out-of-phase sekvenci difuzně nižšího signálu parenchymu oproti in-phase sekvenci. ",
    conclusion: "Difuzní steatóza jaterní. ",
    color: orange,
  },
  {
    value: 2,
    text: "steatóza mapovitá",
    description:
      "na T1 out-of-phase sekvenci mapovitě nižšího signálu parenchymu oproti in-phase sekvenci. ",
    conclusion: "Mapovitá steatóza jaterní. ",
    color: orange,
  },
];

export const aLiverLesions = [
  {
    value: 0,
    text: "cysta",
    description:
      "dobře ohraničené pravidelně konturované ložisko signálu tekutiny, bez postkontrastního sycení",
    conclusion: "Cysta ",
    color: green,
  },
  {
    value: 1,
    text: "hemangiom",
    description:
      "dobře ohraničené ložisko vysokého signálu na T2, během arteriální a portální fáze se sytící postupně centripetálně, v hepatospecifické fázi se nesytící",
    conclusion: "Hemangiom ",
    color: yellow,
  },
  {
    value: 2,
    text: "FNH",
    description:
      "ložisko na T2 pouze mírně vyššího signálu, v arteriální fázi se výrazně sytící, v portální fázi se sytící stejně či mírně výrazněji oproti okolnímu jaternímu parenchymu, v hepatospecifické fázi se sytící",
    conclusion: "Fokální nodulární hyperplázie ",
    color: orange,
  },
  {
    value: 3,
    text: "fokální steatóza",
    description:
      "ložisko na T1 OOP nižšího signálu oproti IP sekvenci, bez výrazně odlišného sycení oproti okolnímu jaternímu parenchymu",
    conclusion: "Fokální steatóza ",
    color: yellow,
  },
  {
    value: 4,
    text: "fokální non-steatóza",
    description:
      "ložisko na T1 OOP oproti nízkému signálu jaterního parenchymu vyšší SI, na IP podobné SI jako okolní jaterní parenchym, bez výrazně odlišného sycení oproti okolnímu jaternímu parenchymu",
    conclusion: "Fokální non-steatóza ",
    color: yellow,
  },
  {
    value: 5,
    text: "metastáza",
    description:
      "ložisko na T2 pouze mírně vyššího signálu, v arteriální fázi se sytící, v portální fázi se sytící méně než okolní jaterní parenchym (washout), v hepatospecifické fázi se nesytící",
    conclusion: "Metastáza ",
    color: red,
  },
  {
    value: 6,
    text: "vlastní",
    description: "",
    conclusion: "",
    color: red,
  },
];

export const aLiverSegments = [
  {
    value: 0,
    text: "segment 1",
    description: "V jaterním segmentu 1 ",
    conclusion: "jaterním segmentu 1",
    color: green,
  },
  {
    value: 1,
    text: "segment 2",
    description: "V jaterním segmentu 2 ",
    conclusion: "jaterním segmentu 2",
    color: green,
  },
  {
    value: 2,
    text: "segment 3",
    description: "V jaterním segmentu 3 ",
    conclusion: "jaterním segmentu 3",
    color: green,
  },
  {
    value: 3,
    text: "segment 4a",
    description: "V jaterním segmentu 4a ",
    conclusion: "jaterním segmentu 4a",
    color: green,
  },
  {
    value: 4,
    text: "segment 4b",
    description: "V jaterním segmentu 4b ",
    conclusion: "jaterním segmentu 4b",
    color: green,
  },
  {
    value: 5,
    text: "segment 5",
    description: "V jaterním segmentu 5 ",
    conclusion: "jaterním segmentu 5",
    color: green,
  },
  {
    value: 6,
    text: "segment 6",
    description: "V jaterním segmentu 6 ",
    conclusion: "jaterním segmentu 6",
    color: green,
  },
  {
    value: 7,
    text: "segment 7",
    description: "V jaterním segmentu 7 ",
    conclusion: "jaterním segmentu 7",
    color: green,
  },
  {
    value: 8,
    text: "segment 8",
    description: "V jaterním segmentu 8 ",
    conclusion: "jaterním segmentu 8",
    color: green,
  },
];

export const aGB = [
  {
    value: 0,
    text: "normální",
    description:
      "Žlučník normální velikosti, s jemnou stěnou, bez patologického obsahu. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "po CHCE",
    description: "Žlučník nedif. ",
    conclusion: "St.p. CHCE. ",
    color: yellow,
  },
  {
    value: 2,
    text: "cholecystolitiáza",
    description:
      "Žlučník normální velikosti, s jemnou stěnou, v jeho lumen na T2 hyposignální kulovity defekt. ",
    conclusion: "Cholecystolitiáza. ",
    color: orange,
  },
];

export const aBiliaryTract = [
  {
    value: 0,
    text: "normální",
    description:
      "Přiměřená šíře ductus hepatocholedochus a intrahepatických žlučových cest. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně rozšířené",
    description:
      "Mírné rozšířený ductus hepatocholedochus a intrahepatické žlučové cesty. ",
    conclusion: "Obraz mírného městnání na žlučových cestách. ",
    color: orange,
  },
  {
    value: 2,
    text: "rozšířené",
    description:
      "Rozšířený ductus hepatocholedochus a intrahepatické žlučové cesty. ",
    conclusion: "Obraz městnání na žlučových cestách. ",
    color: red,
  },
];
