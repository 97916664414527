// General scripts
import { indent, br } from "./../scripts/GeneralArrays";
import { formatArrayToText } from "./../scripts/GeneralFunctions";

// Custom scripts
import {
  aArteries,
  aCADRADS,
  aPremedication,
  aAcquisition,
  aQuality,
  aDomination,
  aBetablocker,
  aMetoprololAmount,
  aEsmololAmount,
  aFlow,
} from "./CT_CTA_cardiac_arrays";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const { arteries } = props;

  const {
    isoket,
    isBetablocker,
    typeBetablocker,
    amountBetablocker,
    acquisition,
    quality,
    heartRate,
    domination,
    flow,
  } = props.general;

  const {
    RCA1,
    RCA2,
    RCA3,
    RCA4,
    RCA16,
    LCA5,
    LCA6,
    LCA7,
    LCA8,
    LCA9,
    LCA10,
    LCA11,
    LCA12,
    LCA13,
    LCA14,
    LCA15,
    LCA17,
    LCA18,
  } = props.arteries;

  // Premedikace
  importDescription1(
    (isoket || isBetablocker ? "Premedikace: " : "") +
      (isoket ? "Isoket sprey 2 vstřiky sublinguálně" : "") +
      (isoket && isBetablocker ? " a " : "") +
      (isBetablocker
        ? aBetablocker[typeBetablocker].text +
          (typeBetablocker === 0
            ? aMetoprololAmount[amountBetablocker].text
            : aEsmololAmount[amountBetablocker].text) +
          " i.v."
        : "")
  );
  importDescription1(
    "Akvizice: " +
      aAcquisition[acquisition].description +
      "při tepové frekvenci přibližně " +
      heartRate +
      "/min" +
      aQuality[quality].description
  );

  // Koronární tepny
  // Pravá koronární tepna

  var oRCA1 = { id: 0, CADRADS: 0, stent: 0, plaque: 0 };
  var oRCA2 = { id: 1, CADRADS: 0, stent: 0, plaque: 0 };
  var oRCA3 = { id: 2, CADRADS: 0, stent: 0, plaque: 0 };
  var oRCA4 = { id: 3, CADRADS: 0, stent: 0, plaque: 0 };
  var oRCA16 = { id: 4, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA5 = { id: 5, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA6 = { id: 6, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA7 = { id: 7, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA8 = { id: 8, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA9 = { id: 9, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA10 = { id: 10, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA11 = { id: 11, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA12 = { id: 12, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA13 = { id: 13, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA14 = { id: 14, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA15 = { id: 15, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA17 = { id: 16, CADRADS: 0, stent: 0, plaque: 0 };
  var oLCA18 = { id: 17, CADRADS: 0, stent: 0, plaque: 0 };

  let aRIA = [oLCA6, oLCA7, oLCA8, oLCA9, oLCA10];
  let aRCx = [oLCA11, oLCA12, oLCA13, oLCA14, oLCA15, oLCA18];
  let aACD = [oRCA1, oRCA2, oRCA3, oRCA4, oRCA16];
  let aCoronaries = [
    oRCA1,
    oRCA2,
    oRCA3,
    oRCA4,
    oRCA16,
    oLCA5,
    oLCA6,
    oLCA7,
    oLCA8,
    oLCA9,
    oLCA10,
    oLCA11,
    oLCA12,
    oLCA13,
    oLCA14,
    oLCA15,
    oLCA17,
    oLCA18,
  ];

  function getSegmentsDescription(segments) {
    return segments
      .map((item) =>
        arteries[aArteries[item.id].text] > 0
          ? aArteries[item.id].description +
            " " +
            aCADRADS[arteries[aArteries[item.id].text]].description1
          : ""
      )
      .filter((elm) => elm);
  }

  let aRIAdescription = getSegmentsDescription(aRIA);
  let RIAdescription = "bez stenózy";
  if (aRIAdescription.length > 0) {
    RIAdescription = aRIAdescription.join(", ");
  }

  let aRCxDescription = getSegmentsDescription(aRCx);
  let RCxDescription = "bez stenózy";
  if (aRCxDescription.length > 0) {
    RCxDescription = aRCxDescription.join(", ");
  }

  let aACDdescription = getSegmentsDescription(aACD);
  let ACDdescription = "bez stenózy";
  if (aACDdescription.length > 0) {
    ACDdescription = aACDdescription.join(", ");
  }

  // CAD-RADS conclusion
  let finalCADRADS = 0;
  // CAD-RADS N
  function getCADRADSmaxValue(segments, CADRADS) {
    for (let i = 0; i < segments.length; i++) {
      if (arteries[aArteries[segments[i].id].text] === CADRADS) {
        return true;
      }
    }
    return false;
  }

  if (getCADRADSmaxValue(aCoronaries, 5)) {
    finalCADRADS =
      "CAD-RADS 5 - zvážit invazivní koronarografii a viabilitu myokardu";
  } else if (
    LCA5 >= 3 ||
    (getCADRADSmaxValue(aRIA, 4) &&
      getCADRADSmaxValue(aRCx, 4) &&
      getCADRADSmaxValue(aACD, 4))
  ) {
    finalCADRADS = "CAD-RADS 4B - doporučena invazivní koronarografie";
  } else if (getCADRADSmaxValue(aCoronaries, 4)) {
    finalCADRADS =
      "CAD-RADS 4A - zvážit invazivní koronarografii nebo funkční vyšetření";
  } else if (getCADRADSmaxValue(aCoronaries, 3)) {
    finalCADRADS = "CAD-RADS 3 - zvážit funkční vyšetření";
  } else if (getCADRADSmaxValue(aCoronaries, 2)) {
    finalCADRADS =
      "CAD-RADS 2 - konzervativní postup, bez nutnosti dalšího dovyšetření";
  } else if (getCADRADSmaxValue(aCoronaries, 1)) {
    finalCADRADS =
      "CAD-RADS 1 - konzervativní postup, bez nutnosti dalšího dovyšetření";
  } else {
    finalCADRADS = "CAD-RADS 0 - bez nutnosti dalšího dovyšetření";
  }

  console.log(getCADRADSmaxValue(aRIA, 5));

  importDescription1("ACS");
  importDescription1("- Kmen: " + aCADRADS[LCA5].description1);
  importDescription1("- RIA: " + RIAdescription);
  importDescription1("- RCx: " + RCxDescription);
  importDescription1("- RIM: " + aCADRADS[LCA17].description1);
  importDescription1("ACD: " + ACDdescription);

  importConclusion1(finalCADRADS);

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
