import React from 'react';
import cloneDeep from 'lodash/cloneDeep';

// Custom scripts
import {
  aPzone,
  aTzone,
  aTechQuality,
  aTEP,
  aRectalGas,
  aHyperplasia,
  aSeminalVesicles,
  aSegments,
  aLevel,
  aSite,
  aT2,
  aDWI,
  aDCE,
  aExtraprostatic,
  aScore,
  aLN,
  aLNcount,
  aMotionArtefacts,
} from './MR_prostate_arrays';
import { generateReport } from './MR_prostate_functions';

// General scripts
import {
  aCardColors,
  contentWidth,
  aTrueFalse,
} from '../scripts/GeneralArrays';
import { formatCustomInput } from '../scripts/GeneralFunctions';

// Custom components
import CustomCards from '../components/CustomCards';
import ButtonUpDown from '../components/ButtonUpDown';
import ButtonTrueFalse from '../components/ButtonTrueFalse';

// 3rd party components
import {
  Table,
  Row,
  Col,
  Select,
  Form,
  Input,
  Button,
  Image,
  Space,
  Switch,
} from 'antd';
import { debounce } from 'lodash';
import PIRADSmap from '../images/PIRADSmap.jpg';
import TextArea from 'antd/lib/input/TextArea';
const { Option } = Select;

class MR_prostate extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      technicalQuality: 0,
      technicalQualityExplenation: '',
      motionArtefacts: 0,
      TEP: 0,
      rectalGas: 0,
      size: '',
      PSA: '',
      pZone: 0,
      pZoneDescription: '',
      tZone: 0,
      tZoneDescription: '',
      hyperplasia: 0,
      Pzone: '',
      Tzone: '',
      bleeding: false,
      seminalVesicles: 0,
      boneMetastasis: false,
      kidneysDescription: '',
      kidneysConclusion: '',
      bladderDescription: '',
      bladderConclusion: '',
      rectosigmaDescription: '',
      rectosigmaConclusion: '',
      bonesDescription: '',
      bonesConclusion: '',
      ascitesDescription: '',
      ascitesConclusion: '',
      LNparaaortic: 0,
      LNiliacComR: 0,
      LNiliacComL: 0,
      LNiliacExtR: 0,
      LNiliacExtL: 0,
      LNiliacIntR: 0,
      LNiliacIntL: 0,
      LNinguinalR: 0,
      LNinguinalL: 0,
      LNparaaorticCount: 0,
      LNiliacComRCount: 0,
      LNiliacComLCount: 0,
      LNiliacExtRCount: 0,
      LNiliacExtLCount: 0,
      LNiliacIntRCount: 0,
      LNiliacIntLCount: 0,
      LNinguinalRCount: 0,
      LNinguinalLCount: 0,
      LNcustomDescription: '',
      LNcustomConclusion: '',
    },
    lesions: [],
  };

  initialState = cloneDeep(this.state);

  onChange = event => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value, segment) => {
    let newRecord;
    let array = [...this.state[segment]];
    if (segment === 'lesions') {
      newRecord = {
        area: [value],
        site: 0,
        level: 2,
        lesionSize: '',
        slice: '',
        T2: 1,
        DWI: 1,
        DCE: 0,
        extraprostatic: 0,
        score: 2,
      };
      array.push(newRecord);
      array.sort((a, b) => a.score - b.score);
    }

    this.setState(prevState => ({
      [segment]: [...prevState[segment], newRecord],
    }));
  };

  updateRow = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;
    array.sort((a, b) => b.score - a.score);

    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  getDefault = () => {
    return 0;
  };

  render() {
    const examQuality = [
      {
        title: 'technická kvalita',
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: 'tech. kvalita',
              input: (
                <Row>
                  <Col span={6}>
                    <ButtonUpDown
                      name='technicalQuality'
                      segment='general'
                      value={this.state.general.technicalQuality}
                      options={aTechQuality}
                      onChange={this.onChange}
                    />
                  </Col>
                  <Col span={1}></Col>
                  <Col span={17}>
                    {this.state.general.technicalQuality > 0 ? (
                      <TextArea
                        name='technicalQualityExplenation'
                        size='small'
                        placeholder='důvod špatné technické kvality...'
                        defaultValue={
                          this.state.general.technicalQualityExplenation
                        }
                        autoSize={{ minRows: 1, maxRows: 3 }}
                        onBlur={event =>
                          this.onChange({
                            segment: 'general',
                            name: 'technicalQualityExplenation',
                            value: formatCustomInput(event.target.value),
                          })
                        }
                      />
                    ) : null}
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: 'limitace vyšetření',
        color: aCardColors.green,
        rows: [
          [
            {
              label: 'pohyb. art.',
              input: (
                <ButtonUpDown
                  name='motionArtefacts'
                  segment='general'
                  value={this.state.general.motionArtefacts}
                  options={aMotionArtefacts}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: 'TEP kyč. kl.',
              input: (
                <ButtonUpDown
                  name='TEP'
                  segment='general'
                  value={this.state.general.TEP}
                  options={aTEP}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'plyn v rektu',
              input: (
                <ButtonUpDown
                  name='rectalGas'
                  segment='general'
                  value={this.state.general.rectalGas}
                  options={aRectalGas}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: '',
              input: '',
            },
          ],
        ],
      },
    ];

    const prostateGeneral = [
      {
        title: 'prostata obecně',
        color: aCardColors.red,
        rows: [
          [
            {
              label: 'velikost',
              input: (
                <Input
                  name='size'
                  size='small'
                  placeholder='AP x LL x KK'
                  suffix='mm'
                  defaultValue={this.state.general.size}
                  onChange={event =>
                    this.onChange({
                      segment: 'general',
                      name: 'size',
                      value: event.target.value,
                    })
                  }
                  style={{ width: 195 }}
                />
              ),
            },
            {
              label: 'PSA',
              input: (
                <Input
                  name='size'
                  size='small'
                  placeholder=''
                  suffix='ng/mL'
                  defaultValue={this.state.general.PSA}
                  onChange={event =>
                    this.onChange({
                      segment: 'general',
                      name: 'PSA',
                      value: event.target.value,
                    })
                  }
                  style={{ width: 195 }}
                />
              ),
            },
          ],
          [
            {
              label: 'perif. zóna',
              input: (
                <Row>
                  <table width='100%'>
                    <row>
                      <Col>
                        <ButtonUpDown
                          name='pZone'
                          segment='general'
                          value={this.state.general.pZone}
                          options={aPzone}
                          onChange={this.onChange}
                        />
                      </Col>
                    </row>
                    <row>
                      <Col>
                        <TextArea
                          name='pZoneDescription'
                          size='small'
                          placeholder='vlastní popis...'
                          defaultValue={this.state.general.pZoneDescription}
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          onBlur={event =>
                            this.onChange({
                              segment: 'general',
                              name: 'pZoneDescription',
                              value: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </row>
                  </table>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'tranz. zóna',
              input: (
                <Row>
                  <table width='100%'>
                    <row>
                      <Col>
                        <ButtonUpDown
                          name='tZone'
                          segment='general'
                          value={this.state.general.tZone}
                          options={aTzone}
                          onChange={this.onChange}
                        />
                      </Col>
                    </row>
                    <row>
                      <Col>
                        <TextArea
                          name='pToneDescription'
                          size='small'
                          placeholder='vlastní popis...'
                          defaultValue={this.state.general.pToneDescription}
                          autoSize={{ minRows: 1, maxRows: 3 }}
                          onBlur={event =>
                            this.onChange({
                              segment: 'general',
                              name: 'pToneDescription',
                              value: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </row>
                  </table>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'prokrvácení',
              input: (
                <ButtonTrueFalse
                  name='bleeding'
                  segment='general'
                  value={this.state.general.bleeding}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: 'semen. váčky',
              input: (
                <ButtonUpDown
                  name='seminalVesicles'
                  segment='general'
                  value={this.state.general.seminalVesicles}
                  options={aSeminalVesicles}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: (
          <Row>
            <Col span={12}>lymfadenopatie, kostní metastázy</Col>
            <Col span={12} style={{ paddingRight: '10px' }} align='right'>
              <Switch
                size='small'
                checkedChildren='více'
                unCheckedChildren='méně'
                onChange={() => {
                  this.showCustomInput('LNcustomShow');
                }}
              />
            </Col>
          </Row>
        ),
        color: aCardColors.orange,
        rows: [
          [
            {
              label: 'paraaortální',
              input: (
                <Row>
                  <Col span={7}></Col>
                  <Col span={this.state.general.LNparaaortic > 0 ? 8 : 10}>
                    <ButtonUpDown
                      name='LNparaaortic'
                      segment='general'
                      value={this.state.general.LNparaaortic}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNparaaortic > 0 ? (
                    <Col span={2}>
                      <ButtonUpDown
                        name='LNparaaorticCount'
                        segment='general'
                        value={this.state.general.LNparaaorticCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                  <Col span={7}></Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'ilické spol.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacComR'
                      segment='general'
                      value={this.state.general.LNiliacComR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacComRCount'
                        segment='general'
                        value={this.state.general.LNiliacComRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'ilické spol.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacComL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacComL'
                      segment='general'
                      value={this.state.general.LNiliacComL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacComL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacComLCount'
                        segment='general'
                        value={this.state.general.LNiliacComLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'ilické ext.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacExtR'
                      segment='general'
                      value={this.state.general.LNiliacExtR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacExtRCount'
                        segment='general'
                        value={this.state.general.LNiliacExtRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'ilické ext.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacExtL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacExtL'
                      segment='general'
                      value={this.state.general.LNiliacExtL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacExtL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacExtLCount'
                        segment='general'
                        value={this.state.general.LNiliacExtLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'ilické int.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacIntR'
                      segment='general'
                      value={this.state.general.LNiliacIntR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacIntRCount'
                        segment='general'
                        value={this.state.general.LNiliacIntRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'ilické int.',
              input: (
                <Row>
                  <Col span={this.state.general.LNiliacIntL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNiliacIntL'
                      segment='general'
                      value={this.state.general.LNiliacIntL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNiliacIntL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNiliacIntLCount'
                        segment='general'
                        value={this.state.general.LNiliacIntLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: 'inguinální',
              input: (
                <Row>
                  <Col span={this.state.general.LNinguinalR > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNinguinalR'
                      segment='general'
                      value={this.state.general.LNinguinalR}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNinguinalR > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNinguinalRCount'
                        segment='general'
                        value={this.state.general.LNinguinalRCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
            {
              label: 'inguinální',
              input: (
                <Row>
                  <Col span={this.state.general.LNinguinalL > 0 ? 18 : 24}>
                    <ButtonUpDown
                      name='LNinguinalL'
                      segment='general'
                      value={this.state.general.LNinguinalL}
                      options={aLN}
                      onChange={this.onChange}
                    />
                  </Col>
                  {this.state.general.LNinguinalL > 0 ? (
                    <Col span={6}>
                      <ButtonUpDown
                        name='LNinguinalLCount'
                        segment='general'
                        value={this.state.general.LNinguinalLCount}
                        options={aLNcount}
                        onChange={this.onChange}
                      />
                    </Col>
                  ) : null}
                </Row>
              ),
            },
          ],
          [
            {
              label: '----------------',
              input:
                '---------------------------------------------------------------------------------------',
            },
          ],
          [
            {
              label: 'kostní meta',
              input: (
                <ButtonTrueFalse
                  name='boneMetastasis'
                  segment='general'
                  value={this.state.general.boneMetastasis}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní popis' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomDescription'
                  placeholder='vlastní text do popisu...'
                  defaultValue={this.state.general.LNcustomDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomDescription',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
          [
            {
              label: this.state.LNcustomShow ? 'vlastní závěr' : '',
              input: this.state.LNcustomShow ? (
                <TextArea
                  name='LNcustomConclusion'
                  placeholder='vlastní text do závěru...'
                  defaultValue={this.state.general.LNcustomConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={event =>
                    this.onChange({
                      name: 'LNcustomConclusion',
                      segment: 'general',
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ) : null,
            },
          ],
        ],
      },
    ];

    const otherFindings = [
      {
        title: 'vedlejší nálezy',
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: 'ledviny',
              input: (
                <Row>
                  <Col span={12}>
                    <TextArea
                      name='kidneysDescription'
                      size='small'
                      placeholder='popis...'
                      defaultValue={this.state.general.kidneysDescription}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'kidneysDescription',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name='kidneysConclusion'
                      size='small'
                      placeholder='závěr...'
                      defaultValue={this.state.general.kidneysConclusion}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'kidneysConclusion',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'moč. měchýř',
              input: (
                <Row>
                  <Col span={12}>
                    <TextArea
                      name='bladderDescription'
                      size='small'
                      placeholder='popis...'
                      defaultValue={this.state.general.bladderDescription}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'bladderDescription',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name='bladderConclusion'
                      size='small'
                      placeholder='závěr...'
                      defaultValue={this.state.general.bladderConclusion}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'bladderConclusion',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'rektosigma',
              input: (
                <Row>
                  <Col span={12}>
                    <TextArea
                      name='rectosigmaDescription'
                      size='small'
                      placeholder='popis...'
                      defaultValue={this.state.general.rectosigmaDescription}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'rectosigmaDescription',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name='rectosigmaConclusion'
                      size='small'
                      placeholder='závěr...'
                      defaultValue={this.state.general.rectosigmaConclusion}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'rectosigmaConclusion',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'ascites',
              input: (
                <Row>
                  <Col span={12}>
                    <TextArea
                      name='ascitesDescription'
                      size='small'
                      placeholder='popis...'
                      defaultValue={this.state.general.ascitesDescription}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'ascitesDescription',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name='ascitesConclusion'
                      size='small'
                      placeholder='závěr...'
                      defaultValue={this.state.general.ascitesConclusion}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'ascitesConclusion',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: 'skelet',
              input: (
                <Row>
                  <Col span={12}>
                    <TextArea
                      name='bonesDescription'
                      size='small'
                      placeholder='popis...'
                      defaultValue={this.state.general.bonesDescription}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'bonesDescription',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                  <Col span={12}>
                    <TextArea
                      name='bonesConclusion'
                      size='small'
                      placeholder='závěr...'
                      defaultValue={this.state.general.bonesConclusion}
                      autoSize={{ minRows: 1, maxRows: 3 }}
                      onBlur={event =>
                        this.onChange({
                          segment: 'general',
                          name: 'bonesConclusion',
                          value: formatCustomInput(event.target.value),
                        })
                      }
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    const prostateLesions = [
      {
        title: (
          <Space>
            ložiska podle PI-RADS 2.1
            <Select
              value='přidat ložisko'
              size='small'
              onChange={value => {
                this.newRow(value, 'lesions');
              }}
            >
              {aSegments.map(item => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
            <Image
              width='4%'
              style={{ marginLeft: '0px' }}
              src={PIRADSmap}
              alt='PIRADS map'
            />
          </Space>
        ),
        color: aCardColors.green,
        rows: [
          [
            {
              label: '',
              input: (
                <>
                  {this.state.lesions.map((item, index) => {
                    return (
                      <Col
                        span={24}
                        style={{ marginBottom: '2px' }}
                        key={index}
                      >
                        <Row>
                          <Col flex='15px'>{index + 1 + ')'}</Col>
                          <Col flex='75px'>
                            <Select
                              key={index}
                              name='area'
                              value={item.area}
                              defaultValue={item.area}
                              size='small'
                              style={{ width: '100%' }}
                              mode='multiple'
                              onChange={value =>
                                this.updateRow(index, 'lesions', 'area', value)
                              }
                            >
                              {aSegments.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                          <Col flex='110px'>
                            <Select
                              key={index}
                              name='level'
                              value={item.level}
                              defaultValue={item.level}
                              size='small'
                              style={{ width: '100%' }}
                              onChange={value =>
                                this.updateRow(index, 'lesions', 'level', value)
                              }
                            >
                              {aLevel.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                          <Col flex='85px'>
                            <Select
                              key={index}
                              name='site'
                              value={item.site}
                              defaultValue={item.site}
                              size='small'
                              style={{ width: '100%' }}
                              onChange={value =>
                                this.updateRow(index, 'lesions', 'site', value)
                              }
                            >
                              {aSite.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                          <Col flex='75px'>
                            <Select
                              key={index}
                              name='T2'
                              value={item.T2}
                              defaultValue={item.T2}
                              size='small'
                              style={{ width: '100%' }}
                              onChange={value =>
                                this.updateRow(index, 'lesions', 'T2', value)
                              }
                            >
                              {aT2.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                          <Col flex='75px'>
                            <Select
                              key={index}
                              name='DWI'
                              value={item.DWI}
                              defaultValue={item.DWI}
                              size='small'
                              style={{ width: '100%' }}
                              onChange={value =>
                                this.updateRow(index, 'lesions', 'DWI', value)
                              }
                            >
                              {aDWI.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                          {/*}
                          <Col flex="75px">
                            <Select
                              key={index}
                              name="DCE"
                              value={item.DCE}
                              defaultValue={item.DCE}
                              size="small"
                              style={{ width: "100%" }}
                              onChange={(value) =>
                                this.updateRow(index, "lesions", "DCE", value)
                              }
                            >
                              {aDCE.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                            {*/}
                          <Col flex='75px'>
                            <Select
                              key={index}
                              name='extraprostatic'
                              value={item.extraprostatic}
                              defaultValue={item.sitextraprostatice}
                              size='small'
                              style={{ width: '100%' }}
                              onChange={value =>
                                this.updateRow(
                                  index,
                                  'lesions',
                                  'extraprostatic',
                                  value
                                )
                              }
                            >
                              {aExtraprostatic.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                          <Col flex='110px'>
                            <Input
                              name='lesionSize'
                              size='small'
                              placeholder='velikost'
                              suffix='mm'
                              onChange={event =>
                                this.updateRow(
                                  index,
                                  'lesions',
                                  'lesionSize',
                                  event.target.value
                                )
                              }
                            />
                          </Col>
                          <Col flex='110px'>
                            <Input
                              name='slice'
                              size='small'
                              placeholder='série/řez na T2'
                              onChange={event =>
                                this.updateRow(
                                  index,
                                  'lesions',
                                  'slice',
                                  event.target.value
                                )
                              }
                            />
                          </Col>
                          <Col>
                            <Select
                              key={index}
                              name='score'
                              value={item.score}
                              defaultValue={item.score}
                              size='small'
                              style={{ width: '100%' }}
                              onChange={value =>
                                this.updateRow(index, 'lesions', 'score', value)
                              }
                            >
                              {aScore.map(item => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          </Col>
                          <Col flex='35px' align='right'>
                            <Button
                              type='primary'
                              size='small'
                              danger
                              onClick={() => this.deleteRow(index, 'lesions')}
                            >
                              x
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    );
                  })}
                </>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form style={{ width: contentWidth }}>
        <CustomCards content={examQuality} />
        <CustomCards content={prostateGeneral} />
        <CustomCards content={prostateLesions} />
        <CustomCards content={otherFindings} />
      </Form>
    );
  }
}

export default MR_prostate;
