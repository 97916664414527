import { green, yellow, orange, red } from '../scripts/GeneralArrays';

export const aLesions = [
  {
    value: 0,
    text: 'játra',
  },
  {
    value: 1,
    text: 'žlučník',
  },
  {
    value: 2,
    text: 'pankreas',
  },
  {
    value: 3,
    text: 'ledvina P',
  },
  {
    value: 4,
    text: 'ledvina L',
  },
  {
    value: 5,
    text: 'slezina',
  },
  {
    value: 6,
    text: 'močový měchýř',
  },
  {
    value: 7,
    text: 'appendix',
  },
  {
    value: 8,
    text: 'tračník',
  },
];

export const aLiverParenchyma = [
  {
    value: 0,
    text: 'normální',
    description: 's přiměřenou echogenitou parenchymu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná steatóza',
    description: 'mírně difuzně vyšší echogenity parenchymu, ',
    conclusion: 'Mírná steatóza ',
    color: yellow,
  },
  {
    value: 2,
    text: 'steatóza',
    description: 'difuzně vyšší echogenity parenchymu, ',
    conclusion: 'Steatóza ',
    color: yellow,
  },
  {
    value: 3,
    text: 'pokročilá steatóza',
    description:
      'výrazně difuzně vyšší echogenity parenchymu s horší penetrací pro UZ, ',
    conclusion: 'Pokročilá steatóza ',
    color: yellow,
  },
  {
    value: 4,
    text: 'cirhóza',
    description: 'zhrubělé nehomogenní až uzlovité přestavby parenchymu',
    conclusion: 'Obraz cirhózy ',
    color: red,
  },
];

export const aLiverSize = [
  {
    value: 0,
    text: 'normální',
    description: 'Játra normální velikosti, ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírně zvětšena',
    description: 'Játra mírně zvětšena, ',
    conclusion: 'mírně zvětšených ',
    color: yellow,
  },
  {
    value: 2,
    text: 'zvětšena',
    description: 'Játra zvětšena, ',
    conclusion: 'zvětšených ',
    color: orange,
  },
  {
    value: 3,
    text: 'výrazná zvětšena',
    description: 'Játra výrazně zvětšena, ',
    conclusion: 'výrazně zvětšených ',
    color: red,
  },
];

export const aSpleenSize = [
  {
    value: 0,
    text: 'normální',
    description:
      'Slezina normální velikosti, s přiměřenou echogenitou parenchymu. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční',
    description:
      'Slezina hraniční velikosti, s přiměřenou echogenitou parenchymu. ',
    conclusion: 'Slezina hraniční velikosti. ',
    color: green,
  },
  {
    value: 2,
    text: 'mírně zvětšena',
    description:
      'Slezina mírně zvětšena, s přiměřenou echogenitou parenchymu. ',
    conclusion: 'Slezina mírně zvětšena. ',
    color: yellow,
  },
  {
    value: 3,
    text: 'zvětšena',
    description: 'Slezina zvětšena, s přiměřenou echogenitou parenchymu. ',
    conclusion: 'Slezina zvětšena. ',
    color: orange,
  },
  {
    value: 4,
    text: 'výrazná zvětšena',
    description:
      'Slezina výrazně zvětšena, s přiměřenou echogenitou parenchymu. ',
    conclusion: 'Slezina výrazně zvětšena. ',
    color: red,
  },
];

export const aGBwall = [
  {
    value: 0,
    text: 'normální',
    description: 's jemnou stěnou, ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírně rozšířena',
    description: 's difuzně mírně rozšířenou stěnou, ',
    conclusion: 'Mírná cholecystitida. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'rozšířena',
    description: 's difuzně rozšířenou stěnou, ',
    conclusion: 'Cholecystitida. ',
    color: orange,
  },
  {
    value: 3,
    text: 'výrazně rozšířena',
    description: 's difuzně výrazně rozšířenou stěnou, ',
    conclusion: 'Pokročilá cholecystitida. ',
    color: red,
  },
];

export const aGBcontent = [
  {
    value: 0,
    text: 'normální',
    description: 's anechogenním obsahem',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'sludge',
    description: 'v lumen s echogenním sedimentem',
    conclusion: 'Sludge v žlučníku. ',
    color: yellow,
  },
  {
    value: 2,
    text: '1 konkrement',
    description: 'v lumen stínící hyperechogenita',
    conclusion: 'Cholecystolitiáza. ',
    color: orange,
  },
  {
    value: 3,
    text: 'konkrementy',
    description: 'v lumen několik stínících hyperechogenit',
    conclusion: 'Vícečetná cholecystolitiáza. ',
    color: red,
  },
];

export const aGBsize = [
  {
    value: 0,
    text: 'přiměřená',
    description: 's přiměřenou náplní, ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'bez náplně',
    description: 'téměř bez náplně, ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'chabá náplň',
    description: 's chabou náplní, ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 3,
    text: 'hydrops žlučníku',
    description:
      'dilatován výraznou náplní (na tlak sondy je oblast bolestivá), ',
    conclusion: '',
    color: red,
  },
];

export const aBiliaryEHSize = [
  {
    value: 0,
    text: 'normální šíře',
    description: 'Ductus hepatocholedochus normální šíře. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční šíře',
    description: 'Hraniční šíře ductus hepatocholedochus. ',
    conclusion: 'Hraniční šíře ductus hepatocholedochus. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'kapacitně prostornější',
    description:
      'Mírně prostornější ductus hepatocholedochus, susp. kapacitně. ',
    conclusion:
      'Mírně prostornější ductus hepatocholedochus, susp. kapacitně. ',
    color: orange,
  },
  {
    value: 3,
    text: 'rozšířený',
    description: 'Rozšířený ductus hepatocholedochus. ',
    conclusion: 'Rozšířený ductus hepatocholedochus. ',
    color: red,
  },
  {
    value: 4,
    text: 'výrazně rozšířený',
    description: 'Výrazně rozšířený ductus hepatocholedochus. ',
    conclusion: 'Výrazně rozšířený ductus hepatocholedochus. ',
    color: red,
  },
];

export const aBiliaryIHSize = [
  {
    value: 0,
    text: 'normální šíře',
    description: 'Intrahepatické žlučové cesty nerozšířeny. ',
    conclusion: 'Intrahepatické žlučové cesty nerozšířeny. ',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční šíře',
    description: 'Hraniční šíře intrahepatických žlučových cest. ',
    conclusion: 'Hraniční šíře intrahepatických žlučových cest. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'centrálně prostornější',
    description: 'Centrálně prostornější intrahepatické žlučové cesty. ',
    conclusion: 'Centrálně prostornější intrahepatické žlučové cesty. ',
    color: orange,
  },
  {
    value: 3,
    text: 'rozšířené',
    description: 'Rozšířené intrahepatické žlučové cesty. ',
    conclusion: 'Rozšířené prostornější intrahepatické žlučové cesty. ',
    color: red,
  },
  {
    value: 4,
    text: 'výrazně rozšířené',
    description: 'Výrazně rozšířené intrahepatické žlučové cesty. ',
    conclusion: 'Výrazně rozšířené intrahepatické žlučové cesty. ',
    color: red,
  },
];

export const aBiliarySize = [
  {
    value: 0,
    text: 'normální',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční',
    color: yellow,
  },
  {
    value: 2,
    text: 'centrálně prostornější',
    color: orange,
  },
  {
    value: 3,
    text: 'rozšířené',
    color: red,
  },
  {
    value: 4,
    text: 'výrazně rozšířené',
    color: red,
  },
];

export const aKidneyParenchyma = [
  {
    value: 0,
    text: 'normální',
    description: 'zachovalé šíře parenchymu',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'mírná redukce',
    description: 's mírnou redukcí parenchymu',
    conclusion: 'mírnou redukcí parenchymu',
    color: yellow,
  },
  {
    value: 2,
    text: 'redukce',
    description: 's redukcí parenchymu',
    conclusion: 'redukcí parenchymu',
    color: orange,
  },
  {
    value: 3,
    text: 'výrazná redukce',
    description: 's výraznou redukcí parenchymu',
    conclusion: 'výraznou redukcí parenchymu',
    color: red,
  },
];

export const aKidneyKPS = [
  {
    value: 0,
    text: 'normální',
    description: 'bez dilatace KPS systému',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hypotonie',
    description: 's mírně prostornější pánvičkou, ale bez rozšíření kalichů',
    conclusion: 'obrazem hypotonie KPS',
    color: green,
  },
  {
    value: 2,
    text: 'mírné rozšíření',
    description: 's mírně rozšířeným KPS systémem',
    conclusion: 'obrazem mírného městnání',
    color: orange,
  },
  {
    value: 3,
    text: 'dilatace',
    description: 's dilatací KPS systému',
    conclusion: 'obrazem městnání',
    color: red,
  },
];

export const aPancreasLimitation = [
  {
    value: 0,
    text: 'pankreas dobře přehledný',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'kauda pankreatu nehodnotitelná',
    description:
      'Pro stínění střevním obsahem není kauda pankreatu hodnotitelná. ',
    conclusion: '',
    color: yellow,
  },
  {
    value: 2,
    text: 'pankreas zcela nehodnotitelný',
    description:
      'Pro stínění střevním obsahem je pankreas zcela nehodnotitelný. ',
    conclusion: '',
    color: red,
  },
];

export const aPancreasParenchyma = [
  {
    value: 0,
    text: 'normální',
    description:
      'Pankreas přiměřené echogenity parenchymu, v hodnotitelném rozsahu bez přesvědčivých ložiskových změn. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'lipodystrofie',
    description:
      'Difuzně vyšší echogenita parenchymu pankreatu, v hodnotitelném rozsahu bez přesvědčivých ložiskových změn. ',
    conclusion: 'Lipodystrofie pankreatu. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'atrofie',
    description:
      'Redukce parenchymu pankreatu, v hodnotitelném rozsahu bez přesvědčivých ložiskových změn. ',
    conclusion: 'Atrofie pankreatu. ',
    color: orange,
  },
];

export const aPancreasDuctus = [
  {
    value: 0,
    text: 'normální',
    description: 'Ductus pancreaticus není rozšířen. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'hraniční šíře',
    description: 'Ductus pancreaticus hraniční šíře. ',
    conclusion: 'Hraniční šíře ductus pancreaticus. ',
    color: yellow,
  },
  {
    value: 1,
    text: 'mírně rozšířen',
    description: 'Ductus pancreaticus mírně rozšířen. ',
    conclusion: 'Mírné rozšíření ductus pancreaticus. ',
    color: orange,
  },
  {
    value: 2,
    text: 'rozšířen',
    description: 'Ductus pancreaticus rozšířen. ',
    conclusion: 'Rozšíření ductus pancreaticus. ',
    color: red,
  },
  {
    value: 3,
    text: 'výrazně rozšířen',
    description: 'Ductus pancreaticus výrazně rozšířen. ',
    conclusion: 'Výrazné rozšíření ductus pancreaticus. ',
    color: red,
  },
];

export const aPancreasLimit = [
  {
    value: 0,
    text: 'dobrá',
    color: green,
  },
  {
    value: 1,
    text: 'kauda nehodnot.',
    color: yellow,
  },
  {
    value: 2,
    text: 'celý nehodnot.',
    color: orange,
  },
];

export const aAppendix = [
  {
    value: 0,
    text: 'nehodnocen',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'nediff.',
    description:
      'Pericékálně pro stínění střevním plynem bez přesvědčivě patrné tubulární aperistaltické struktury obrazu appendixu. ',
    conclusion:
      'Appendix přímo nezobrazen, pericékální oblast ale v hodnotitelném rozsahu klidného obrazu. ',
    color: green,
  },
  {
    value: 2,
    text: 'normální',
    description:
      'Dobře kompresibilní tubulární aperistaltická struktura s jemnou stěnou, bez prosáknutí okolního tuku, uložena ',
    conclusion: 'Appendix bez známek zánětu. ',
    color: green,
  },
  {
    value: 3,
    text: 'zánět',
    description:
      'Nekompresibilní aperstaltická tubulární struktura s prosáknutím okolního tuku, probíhající ',
    conclusion: 'Zánětlivé změny appendixu. ',
    color: red,
  },
];

export const aAppendixPos = [
  {
    value: 0,
    text: 'mediocékálně',
    description: 'mediocékálně',
    conclusion: 'mediocékálně. ',
    color: green,
  },
  {
    value: 1,
    text: 'laterocékálně',
    description: 'laterocékálně',
    conclusion: 'laterocékálně. ',
    color: green,
  },
  {
    value: 2,
    text: 'retrocékálně',
    description: 'retrocékálně',
    conclusion: 'retrocékálně. ',
    color: green,
  },
];

export const aSigmoid = [
  {
    value: 0,
    text: 'nehodnoceno',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'normální',
    description: 'V zobrazeném rozsahu přiměřená šíře stěny sigmatu. ',
    conclusion: 'Bez přesvědčivých zánětlivých změn sigmatu. ',
    color: green,
  },
  {
    value: 2,
    text: 'mírný zánět',
    description: 'Difuzní zesílení stěny sigmatu ',
    conclusion: 'Obraz mírných zánětlivých změn sigmatu',
    color: orange,
  },
  {
    value: 3,
    text: 'zánět',
    description: 'Difuzní zesílení stěny sigmatu ',
    conclusion: 'Obraz zánětlivých změn sigmatu',
    color: red,
  },
];

export const aUrinaryBladder = [
  {
    value: 0,
    text: 'normální',
    description: 'Močový měchýř s anechogenním obsahem, jemnou stěnou. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 's chabou náplní',
    description:
      'Chabá náplň močového měchýře, oblast pánve je tak velmi omezeně hodnotitelná. ',
    conclusion: '',
    color: green,
  },
  {
    value: 2,
    text: 'bez náplně',
    description:
      'Močový měchýř bez náplně, oblast pánve je tak velmi omezeně hodnotitelná. ',
    conclusion: '',
    color: green,
  },
  {
    value: 3,
    text: 'bez náplně s PMK',
    description:
      'Močový měchýř při PMK bez náplně, oblast pánve je tak velmi omezeně hodnotitelná. ',
    conclusion: '',
    color: green,
  },
  {
    value: 4,
    text: 'trabekularizace stěny',
    description:
      'Difuzně nepravidelně zesílená vnitřní stěna močového měchýře trabekularizací, v.s. sekundárně při zvětšené prostatě. ',
    conclusion:
      'Trabekularizace stěny močového měchýře, v.s. sekundárně při zvětšené prostatě. ',
    color: yellow,
  },
  {
    value: 5,
    text: 'mírný zánět',
    description:
      'Močový měchýř s anechogenním obsahem, s mírně difuzně zesílenou stěnou. ',
    conclusion:
      'Mírné difuzní zesílení stěny močového měchýře, susp. zánětlivě. ',
    color: orange,
  },
  {
    value: 6,
    text: 'zánět',
    description:
      'Močový měchýř s anechogenním obsahem, s difuzně zesílenou stěnou. ',
    conclusion: 'Difuzní zesílení stěny močového měchýře, susp. zánětlivě. ',
    color: red,
  },
];

export const aIntraabdominalFluid = [
  {
    value: 0,
    text: 'bez volné tekutiny',
    description: 'Intraabdominálně bez volné tekutiny. ',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'na dně pánve',
    description: 'Malé množství volné tektuiny na dně pánve. ',
    conclusion: 'Malé množství volné tekutiny na dně pánve. ',
    color: yellow,
  },
  {
    value: 2,
    text: 'v pánvi mezi kličkami',
    description:
      'Volná tekutina mezi kličkami střevními v oblasti malé pánve. ',
    conclusion: 'Ascites mezi kličkami střevními v oblasti pánve. ',
    color: orange,
  },
  {
    value: 3,
    text: 'v pánvi a kolem jater',
    description: 'Volná tekutina mezi kličkami střevními a kolem jater. ',
    conclusion: 'Ascites dosahující k játrům. ',
    color: red,
  },
];

export const aPelvisOther = [
  {
    value: 0,
    text: 'bez nálezu',
    color: green,
  },
  {
    value: 1,
    text: 'zvětšená prostata',
    description: 'Zvětšená prostata. ',
    conclusion: 'Zvětšená prostata. ',
    color: orange,
  },
  {
    value: 2,
    text: 'myomy dělohy',
    description: 'Expanzivní ložiska v myometriu dělohy. ',
    conclusion: 'Myomy dělohy. ',
    color: orange,
  },
  {
    value: 3,
    text: 'cysta P ovaria',
    description:
      'Ovoidní anechogenní ložisko v pravé části malé pánve, s jemnou stěnou. ',
    conclusion: 'Cysta pravého ovaria. ',
    color: orange,
  },
  {
    value: 4,
    text: 'cysta L ovaria',
    description:
      'Ovoidní anechogenní ložisko v levé části malé pánve, s jemnou stěnou. ',
    conclusion: 'Cysta levého ovaria. ',
    color: orange,
  },
];

export const aLiverDoppler = [
  {
    value: 0,
    text: 'neproveden',
    color: green,
  },
  {
    value: 1,
    text: 'normální nález',
    description: '',
    conclusion: '',
    color: yellow,
  },
];

export const aHabitus = [
  {
    value: 0,
    text: 'bez limitace',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'limitace hodnotitelnosti habitem pacienta',
    description: 'jeho habitem',
    conclusion: 'habitus pacienta',
    color: orange,
  },
];

export const aGas = [
  {
    value: 0,
    text: 'bez limitace',
    description: '',
    conclusion: '',
    color: green,
  },
  {
    value: 1,
    text: 'limitace zvýšenou plynnou náplní střev',
    description: 'zvýšenou plynnou náplní jeho střev',
    conclusion: 'zvýšená plynná náplň střev',
    color: orange,
  },
];
