import {
  aFazekas,
  aGliosisInfratentorial,
  aGCA,
  aMTA,
  aKoedam,
  aFocalAtrophy,
  aPituitary,
  aMastoid,
  aSeptumNasi,
  aConchaBullosa,
  aPerivascSpaces,
  aSeptumPellucidum,
  aCalvarium,
  aLymbic,
  aIntraaxial,
  aArteriesVariety,
  aDemyelinSupra,
  aDemyelinSupraFilled,
  aDemyelinInfra,
  aDemyelinInfraFilled,
  aDemyelinIntramed,
  aDemyelinIntramedFilled,
  aLatVentAsymetry,
} from './CT_brain_arrays';

import { indent, br } from './../scripts/GeneralArrays';
import { formatArrayToText } from './../scripts/GeneralFunctions';

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: '',
    },
    description2: {
      forUI: [],
      forPaste: '',
    },
    conclusion1: {
      forUI: [],
      forPaste: '',
    },
    conclusion2: {
      forUI: [],
      forPaste: '',
    },
  };

  const importDescription1 = text => {
    report.description1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description1.forPaste += '')
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = text => {
    report.description2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.description2.forPaste += '')
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = text => {
    report.conclusion1.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion1.forPaste += '')
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = text => {
    report.conclusion2.forUI.push(<div className='indentText'>{text}</div>);
    text === ''
      ? (report.conclusion2.forPaste += '')
      : (report.conclusion2.forPaste += indent + text + br);
  };

  // Custom
  const { custom } = props;
  let customLesion = custom.filter(item => item.patType === '0');
  let customAtrophy = custom.filter(item => item.patType === '1');
  let customOther = custom.filter(item => item.patType === '2');
  let customVariety = custom.filter(item => item.patType === '3');

  // Glióza, ložiska
  const { Fazekas, gliosisInfratentorial } = props.general;
  // Demyelinizace
  const {
    demyelinSupraDescription,
    demyelinSupraConclusion,
    demyelinSupraButton,
    demyelinInfraDescription,
    demyelinInfraConclusion,
    demyelinInfraButton,
    demyelinIntramedDescription,
    demyelinIntramedConclusion,
    demyelinIntramedButton,
  } = props.general;
  // Atrofie
  const { GCA, MTAright, MTAleft, Koedam, focalAtrophy } = props.general;
  // Ostatní
  const {
    pituitary,
    RathkeCystSize,
    pinealCyst,
    pinealCystSize,
    atherosclerosis,
    cavernoma,
    DVA,
    xanthogranulomas,
    mastoidRight,
    mastoidLeft,
    lensRight,
    lensLeft,
    sinusFR,
    sinusFL,
    sinusMR,
    sinusML,
    sinusER,
    sinusEL,
    sinusSR,
    sinusSL,
    perivascSpaces,
    septumPellucidum,
    calvarium,
    ependymitisGranularis,
    lymbic,
    megacisterna,
    falxCalc,
    septumNasi,
    concha,
    latVentAsymetry,
    brainArtDescription,
    brainArtConclusion,
    A1R,
    A1L,
    P1R,
    P1L,
    VR,
    VL,
  } = props.general;

  // Normální nález
  let normalFindings = '';

  if (
    Fazekas +
      gliosisInfratentorial +
      GCA +
      MTAright +
      MTAleft +
      Koedam +
      focalAtrophy +
      pituitary +
      cavernoma ===
      0 &&
    protocol === 0
  ) {
    normalFindings = 'Přiměřený nález na CT mozku. ';
  }
  if (customLesion.length === 0) {
    if (protocol === 1 || protocol === 2) {
      normalFindings =
        'Bez intrakraniálního krvácení či přesvědčivých známek rozvinuté akutní ischemie mozkové. ';
    } else if (protocol === 3) {
      normalFindings =
        'Bez intrakraniálního krvácení či čerstvých traumatických změn na zobrazeném skeletu lebky. ';
    }
  }

  // Vlastní záznam

  let customLesionDescription = customLesion
    .map(item => item.description)
    .join('');
  let customLesionConclusion = customLesion
    .map(item => item.conclusion)
    .join('');

  // RS

  let gliosisDescription = '';
  let gliosisConclusion = '';

  let demyelinisationDescription = '';
  let demyelinisationConclusion = '';

  if (protocol === 5) {
    demyelinisationDescription +=
      demyelinSupraDescription +
      (demyelinSupraDescription != ''
        ? aDemyelinSupraFilled[demyelinSupraButton].description
        : aDemyelinSupra[demyelinSupraButton].description) +
      demyelinInfraDescription +
      (demyelinInfraDescription != ''
        ? aDemyelinInfraFilled[demyelinInfraButton].description
        : aDemyelinInfra[demyelinInfraButton].description) +
      demyelinIntramedDescription +
      (demyelinIntramedDescription != ''
        ? aDemyelinIntramedFilled[demyelinIntramedButton].description
        : aDemyelinIntramed[demyelinIntramedButton].description);

    demyelinisationConclusion +=
      demyelinSupraConclusion +
      demyelinInfraConclusion +
      demyelinIntramedConclusion +
      (demyelinSupraConclusion === '' &&
      demyelinInfraConclusion === '' &&
      demyelinIntramedConclusion === ''
        ? 'Oproti '
        : 'Jinak oproti ') +
      (demyelinSupraButton + demyelinInfraButton + demyelinIntramedButton === 0
        ? 'minule bez přesvědčivé progrese velikosti či počtu supratentoriálních, infratentoriálních a intramedulárních ložisek obrazu demyelinizace. '
        : demyelinSupraButton === 0 &&
          demyelinInfraButton === 0 &&
          demyelinIntramedButton > 0
        ? 'minule bez progrese velikosti či počtu supratentoriálních a infratentoriálních ložisek obrazu demyelinizace. Bez přesvědčivých intramedulárních ložisek. '
        : demyelinSupraButton === 0 &&
          demyelinInfraButton > 0 &&
          demyelinIntramedButton > 0
        ? 'minule bez progrese velikosti či počtu supratentoriálních ložisek obrazu demyelinizace. Bez přesvědčivých infratentoriálních a intramedulárních ložisek. '
        : demyelinSupraButton === 0 &&
          demyelinInfraButton > 0 &&
          demyelinIntramedButton === 0
        ? 'minule bez progrese velikosti či počtu supratentoriálních a intramedulárních ložisek obrazu demyelinizace. Bez přesvědčivých infratentoriálních ložisek. '
        : null);
  } else {
    gliosisDescription +=
      aFazekas[Fazekas].description +
      aGliosisInfratentorial[gliosisInfratentorial].description +
      (Fazekas + gliosisInfratentorial + customLesion.length === 0
        ? 'Bez přesvědčivých strukturálních změn či alterací denzity mozku supratentoriálně a nebo infratentoriálně. '
        : 'Jinak bez přesvědčivých strukturálních změn či alterací denzity mozku supratentoriálně a nebo infratentoriálně. ') +
      aPituitary[pituitary].description +
      (pituitary === 3 ? RathkeCystSize + 'mm. ' : '') +
      (pinealCyst
        ? 'Ovoidní ložisko denzit tekutiny v oblasti epifýzy' +
          (pinealCystSize !== '' ? ' velikosti ' + pinealCystSize + 'mm' : '') +
          '. '
        : '');
  }

  gliosisConclusion +=
    aFazekas[Fazekas].conclusion +
    aGliosisInfratentorial[gliosisInfratentorial].conclusion;

  // Atrofie

  let atrophyDescription = '';
  let atrophyConclusion = '';

  atrophyDescription =
    GCA +
      Koedam +
      focalAtrophy +
      MTAleft +
      MTAright +
      latVentAsymetry +
      customAtrophy.length ===
    0
      ? 'Věku přiměřená šíře subarachnoidálních prostor a komorového systému. ' +
        (customLesion.length === 0 ? 'Bez deviace středových struktur. ' : '')
      : customAtrophy.map(item => item.description).join('') +
        aGCA[GCA].description +
        aKoedam[Koedam].description +
        aFocalAtrophy[focalAtrophy].description +
        aLatVentAsymetry[latVentAsymetry].description +
        'Jinak věku přiměřená šíře subarachnoidálních prostor a komorového systému. ' +
        (customLesion.length === 0 ? 'Bez deviace středových struktur' : '') +
        (MTAleft === MTAright
          ? 'Bilat. ' + aMTA[MTAright].description
          : 'Vpravo ' +
            aMTA[MTAright].description +
            'Vlevo ' +
            aMTA[MTAleft].description);

  atrophyConclusion +=
    customAtrophy.map(item => item.conclusion).join('') +
    aGCA[GCA].conclusion +
    (MTAright === 0 && MTAleft === 0
      ? ''
      : MTAright === MTAleft
      ? 'Mesiální temporální atrofie (MTA bilat. ' +
        aMTA[MTAright].conclusion +
        '). '
      : MTAright > 0 && MTAleft > 0
      ? 'Mesiální temporální atrofie (MTA vpravo ' +
        aMTA[MTAright].conclusion +
        ', vlevo ' +
        aMTA[MTAleft].conclusion +
        '). '
      : MTAright === 0
      ? 'Mesiální temporální atrofie vlevo (MTA ' +
        aMTA[MTAleft].conclusion +
        '). '
      : 'Mesiální temporální atrofie vpravo (MTA ' +
        aMTA[MTAright].conclusion +
        '). ') +
    aKoedam[Koedam].conclusion +
    aFocalAtrophy[focalAtrophy].conclusion;

  // Ostatní

  let otherDescription = '';
  let otherConclusion = '';

  let sinuses = [];
  if (sinusFR && sinusFL) {
    sinuses.push('obou frontálních sinů');
  } else if (sinusFR) {
    sinuses.push('pravého frontálního sinu');
  } else if (sinusFL) {
    sinuses.push('levého frontálního sinu');
  }

  if (sinusMR && sinusML) {
    sinuses.push('obou maxilárních sinů');
  } else if (sinusMR) {
    sinuses.push('pravého maxilárního sinu');
  } else if (sinusML) {
    sinuses.push('levého maxilárního sinu');
  }

  if (sinusER && sinusEL) {
    sinuses.push('obou etmoidálních sinů');
  } else if (sinusER) {
    sinuses.push('pravého etmoidálního sinu');
  } else if (sinusEL) {
    sinuses.push('levého etmoidálního sinu');
  }

  if (sinusSR && sinusSL) {
    sinuses.push('obou sfenoidálních sinů');
  } else if (sinusSR) {
    sinuses.push('pravého sfenoidálního sinu');
  } else if (sinusSL) {
    sinuses.push('levého sfenoidálního sinu');
  }

  otherDescription +=
    (atherosclerosis ? 'Hyperdenzní pláty ve stěně karotických tepen. ' : '') +
    (customOther.length > 0
      ? customOther.map(item => item.description).join('')
      : '') +
    (cavernoma > 0
      ? 'Na SWI kulovitý okrsek artefaktů z hemosiderinu v bílé hmotě ' +
        aIntraaxial[cavernoma].description
      : '') +
    (DVA > 0
      ? 'Na SWI větvící se artefakty z hemosiderinu v bílé hmotě ' +
        aIntraaxial[DVA].description
      : '') +
    (xanthogranulomas
      ? 'V choroidálním plexu trigona pravé i levé postranní komory na T2 a DWI kulovité ložisko vysoké SI (xanthogranulomy choroidálních plexů). '
      : '') +
    (protocol === 1
      ? 'Bez strukturálních či signálových změn oblasti mostomozečkových koutů, vnitřních zvukovodů a nebo vnitřního ucha bilat. '
      : '') +
    (sinuses.length > 0
      ? 'Nepravidelné na T2 hypersignální rozšíření sliznice ' +
        formatArrayToText(sinuses) +
        '. '
      : 'Paranazální siny bez patologického obsahu. ') +
    aSeptumNasi[septumNasi].description +
    aConchaBullosa[concha].description +
    (mastoidRight === 0 && mastoidLeft === 0
      ? 'Mastoidální sklípky ' + aMastoid[mastoidRight].description
      : mastoidRight > 0 && mastoidRight === mastoidLeft
      ? 'Mastoidální sklípky bilat. ' + aMastoid[mastoidRight].description
      : 'Pravostranné mastoidální sklípky ' +
        aMastoid[mastoidRight].description +
        'Levostranné mastoidální sklípky ' +
        aMastoid[mastoidLeft].description) +
    (!lensRight && !lensLeft
      ? ''
      : lensRight && lensLeft
      ? 'Náhrada obou očních čoček. '
      : lensRight
      ? 'Náhrada pravé oční čočky. '
      : lensLeft
      ? 'Náhrada levé oční čočky. '
      : '') +
    'Normální obsah orbit. ';

  otherConclusion +=
    (atherosclerosis ? 'Atheroskleróza tepen. ' : '') +
    (customOther.length > 0
      ? customOther.map(item => item.conclusion).join('')
      : '') +
    (cavernoma > 0
      ? 'Susp. kavernom ' + aIntraaxial[cavernoma].conclusion
      : '') +
    (DVA > 0
      ? 'Vývojová žilní anomálie (DVA) ' + aIntraaxial[DVA].conclusion
      : '') +
    aPituitary[pituitary].conclusion +
    (pituitary === 3 ? '(' + RathkeCystSize + 'mm). ' : '') +
    (pinealCyst
      ? 'Pineální cysta' +
        (pinealCystSize !== '' ? ' (' + pinealCystSize + 'mm). ' : '. ')
      : '') +
    (sinuses.length > 0
      ? 'Hyperplastické slizniční změny ' + formatArrayToText(sinuses) + '. '
      : '') +
    (mastoidRight === 0 && mastoidLeft === 0
      ? ''
      : mastoidRight > 0 && mastoidRight === mastoidLeft
      ? 'Mastoidální sklípky bilat. ' + aMastoid[mastoidRight].conclusion
      : mastoidRight > 0 && mastoidLeft > 0
      ? 'Pravostranné mastoidální sklípky ' +
        aMastoid[mastoidRight].conclusion +
        'Levostranné mastoidální sklípky ' +
        aMastoid[mastoidLeft].conclusion
      : mastoidRight > 0
      ? 'Pravostranné mastoidální sklípky ' + aMastoid[mastoidRight].conclusion
      : 'Levostranné mastoidální sklípky ' + aMastoid[mastoidLeft].conclusion);

  // Mozkové tepny

  let arteriesDescription = '';
  let arteriesConclusion = '';
  if (brainArtDescription !== '') {
    arteriesDescription +=
      brainArtDescription +
      'Jinak přiměřený nález na mozkových tepnách, bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  } else {
    arteriesDescription +=
      'Přiměřený nález na mozkových tepnách, bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  }

  if (brainArtConclusion !== '') {
    arteriesConclusion +=
      brainArtConclusion +
      'Jinak mozkové tepny bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  } else {
    arteriesConclusion +=
      'Mozkové tepny bez průkazu uzávěru, významné stenózy či přesvědčivých aneuryzmat. ';
  }

  if (A1R + A1L + P1R + P1L + VR + VL > 0) {
    if (A1R > 0)
      arteriesDescription +=
        aArteriesVariety[A1R].description + 'pravého A1 segmentu. ';
    if (A1L > 0)
      arteriesDescription +=
        aArteriesVariety[A1L].description + 'levého A1 segmentu. ';
    if (P1R > 0)
      arteriesDescription +=
        aArteriesVariety[P1R].description + 'pravého P1 segmentu. ';
    if (P1L > 0)
      arteriesDescription +=
        aArteriesVariety[P1L].description + 'levého P1 segmentu. ';
    if (VR > 0)
      arteriesDescription +=
        aArteriesVariety[VR].description +
        'koncového úseku pravé vertebrální tepny. ';
    if (VL > 0)
      arteriesDescription +=
        aArteriesVariety[VL].description +
        'koncového úseku levé vertebrální tepny. ';
  }

  // Variety

  let varietiesDescription =
    (customVariety.length > 0
      ? customVariety.map(item => item.description).join('')
      : '') +
    aPerivascSpaces[perivascSpaces].description +
    (ependymitisGranularis
      ? 'Poměrně symetrické lemy vyšší SI na T2 a FLAIR podél frontálních rohů postranních komor (varietní ependymitis granularis). '
      : '') +
    aSeptumPellucidum[septumPellucidum].description +
    aCalvarium[calvarium].description +
    aLymbic[lymbic].description +
    (megacisterna ? 'Megacisterna magna (varieta). ' : '') +
    (falxCalc
      ? 'Hrudkovité hyperdenzity v průběhu falx cerebri (degenerativní kalcifikace falx cerebri). '
      : '');

  importDescription1(
    customLesionDescription +
      demyelinisationDescription +
      gliosisDescription +
      atrophyDescription
  );
  importDescription1(otherDescription + varietiesDescription);
  importDescription1(protocol === 2 ? arteriesDescription : '');

  importConclusion1(
    normalFindings +
      demyelinisationConclusion +
      customLesionConclusion +
      gliosisConclusion +
      atrophyConclusion +
      otherConclusion
  );
  importConclusion1(protocol === 2 ? arteriesConclusion : '');

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
