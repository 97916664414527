import React from "react";
import { Button } from "antd";

class ButtonTrueFalse extends React.Component {
  state = {
    value: this.props.options[this.props.value ? 1 : 0].value,
  };

  onChange = (event) => {
    if (event.nativeEvent.which === 1 || event.nativeEvent.which === 3) {
      event.preventDefault();
      if (this.state.value === 0) {
        this.setState({ value: 1 });
      } else {
        this.setState({ value: 0 });
      }
      this.props.onChange({
        segment: this.props.segment,
        name: this.props.name,
        value: this.state.value === 1 ? false : true,
      });
    }
  };

  render() {
    return (
      <Button
        type="text"
        shape="round"
        size="small"
        block
        onClick={this.onChange}
        onContextMenu={this.onChange}
        value={this.state.value}
        style={{
          backgroundColor: this.props.options[this.state.value].color,
        }}
      >
        {this.props.options[this.state.value].text}
      </Button>
    );
  }
}

export default ButtonTrueFalse;
