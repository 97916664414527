import React from "react";
import { Input, Button } from "antd";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { CopyOutlined } from "@ant-design/icons";

import {
  aContrastMR,
  aContrastMRamount,
  indent,
  br,
} from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

const Report_new = (props) => {
  const modality = props.data.report.examList[0].Selector.slice(0, 2);
  const getReportForPaste = () => {
    return (
      (modality === "MR"
          ? "MR "
          : modality === "CT"
          ? "CT "
          : modality === "UZ"
          ? "UZ "
          : modality === "RT"
          ? "RTG "
          : "") +
          formatArrayToText(
            props.data.panes.map((item) => item.protocols[item.protocol].report)
          )+
      br +
      (props.data.report.indication !== ""
        ? indent + "Indikace: " + props.data.report.indication + br
        : "") +
      (props.data.report.contrast > 0
        ? indent +
          "Kontrastní látka i.v.: " +
          props.data.report.contrastArray[props.data.report.contrast].label +
          " " +
          props.data.report.contrastAmountArray[
            props.data.report.contrastAmount
          ].text +
          br
        : "") +
      br +
      props.data.panes
        .map((item) => {
          return item.description_1_for_Paste;
        })
        .join("") +
      props.data.panes
        .map((item) => {
          return item.description_2_for_Paste;
        })
        .join("") +
      br +
      "Závěr: " +
      br +
      props.data.panes
        .map((item) => {
          return item.conclusion_1_for_Paste;
        })
        .join("") +
      props.data.panes
        .map((item) => {
          return item.conclusion_2_for_Paste;
        })
        .join("")
    );
  };

  return (
    <div
      style={{
        marginTop: "10px",
        marginLeft: "10px",
        marginBottom: "10px",
      }}
    >
      <b>
        {(modality === "MR"
          ? "MR "
          : modality === "CT"
          ? "CT "
          : modality === "UZ"
          ? "UZ "
          : modality === "RT"
          ? "RTG "
          : "") +
          formatArrayToText(
            props.data.panes.map((item) => item.protocols[item.protocol].report)
          )}
      </b>
      <br />
      {props.data.report.indication !== "" ? (
        <div className="indentText">
          {"Indikace: " + props.data.report.indication}
        </div>
      ) : null}
      {props.data.report.contrast > 0 ? (
        <div className="indentText">
          {"Kontrastní látka i.v.: " +
            props.data.report.contrastArray[props.data.report.contrast].label +
            " " +
            props.data.report.contrastAmountArray[
              props.data.report.contrastAmount
            ].text}
        </div>
      ) : null}
      <br />
      {props.data.panes.map((item) => {
        return <>{item.description_1_for_UI}</>;
      })}
      {props.data.panes.map((item) => {
        return item.description_2_for_UI !== null ? (
          <>{item.description_2_for_UI}</>
        ) : null;
      })}
      <br />
      Závěr:
      {props.data.panes.map((item) => {
        return <>{item.conclusion_1_for_UI}</>;
      })}
      {props.data.panes.map((item) => {
        return item.conclusion_2_for_UI !== null ? (
          <>{item.conclusion_2_for_UI}</>
        ) : null;
      })}
      <div style={{ marginTop: "15px" }}>
        <Input.TextArea
          hidden
          autoSize
          rows="15"
          key="1"
          value={getReportForPaste()}
        />
        <CopyToClipboard style={{}} text={getReportForPaste()}>
          <Button type="primary" color="blue" icon={<CopyOutlined />}>
            Kopírovat
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

export default Report_new;
