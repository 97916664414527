import React from "react";
import { Button } from "antd";

class ButtonUpDown extends React.Component {
  state = {
    value:
      this.props.options[
        typeof this.props.value === "undefined" ? 0 : this.props.value
      ].value,
  };

  onChange = (event) => {
    if (event.nativeEvent.which === 1) {
      // Left Click
      if (this.state.value < this.props.options.length - 1) {
        this.setState((prevState) => {
          return { ...prevState, value: prevState.value + 1 };
        });
        this.props.onChange({
          segment: this.props.segment,
          name: this.props.name,
          value: this.props.options[this.state.value + 1].value,
        });
      }
    } else if (event.nativeEvent.which === 3) {
      // Right Click
      event.preventDefault();
      if (this.state.value > 0) {
        this.setState((prevState) => {
          return { ...prevState, value: prevState.value - 1 };
        });
        this.props.onChange({
          segment: this.props.segment,
          name: this.props.name,
          value: this.props.options[this.state.value - 1].value,
        });
      }
    }
  };

  render() {
    return (
      <Button
        key={this.props.segment + this.props.name}
        type="text"
        shape="round"
        size="small"
        block
        onClick={this.onChange}
        onContextMenu={this.onChange}
        value={this.state.value}
        defaultValue={this.props.defaultValue}
        style={{
          backgroundColor: this.props.options[this.state.value].color,
        }}
      >
        {this.props.options[this.state.value].text}
      </Button>
    );
  }
}

export default ButtonUpDown;
