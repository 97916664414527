import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import {
  contentWidth,
  colorXRAY,
  colorUS,
  colorCT,
  colorMRI,
} from "./scripts/GeneralArrays";

import "./App.css";
import { Layout, Menu, Row, Col } from "antd";
import AuthNav from "./components/auth-nav";
import ProtectedRoute from "./auth/protected-route";
import ExamHolder_MR from "./MR/ExamHolder_MR";
import ExamHolder_CT from "./CT/ExamHolder_CT";
import ExamHolder_XRAY from "./XRAY/ExamHolder_XRAY";
import ExamHolder_US from "./US/ExamHolder_US";

const { Footer, Content } = Layout;

class App extends React.Component {
  state = {
    collapsed: false,
    key: 0,
    current: "MR",
  };

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  handleNavbarClick = (event) => {
    this.setState({ current: event.key });
  };

  render() {
    const { current } = this.state;

    return (
      <Router>
        <Layout>
          <Content
            style={{
              textAlign: "left",
              margin: "auto",
              width: contentWidth,
            }}
          >
            <Row align="middle" style={{ backgroundColor: "black" }}>
              <Col>
                <Menu
                  onClick={this.handleNavbarClick}
                  selectedKeys={[current]}
                  mode="horizontal"
                  theme="dark"
                >
                  <Menu.Item key="1" style={{ background: colorXRAY }}>
                    <Link to="/XRAY">RTG</Link>
                  </Menu.Item>
                  <Menu.Item key="2" style={{ background: colorUS }}>
                    <Link to="/US">UZ</Link>
                  </Menu.Item>
                  <Menu.Item key="3" style={{ background: colorCT }}>
                    <Link to="/CT">CT</Link>
                  </Menu.Item>
                  <Menu.Item key="4" style={{ background: colorMRI }}>
                    <Link to="/MRI">MR</Link>
                  </Menu.Item>
                </Menu>
              </Col>
              <Col flex="auto" align="right">
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "white",
                    textAlign: "right",
                    margin: "auto",
                  }}
                >
                  Radiolo
                </span>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bolder",
                    color: colorCT,
                    textAlign: "right",
                    margin: "auto",
                  }}
                >
                  J
                </span>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bolder",
                    color: colorUS,
                    textAlign: "right",
                    margin: "auto",
                  }}
                >
                  e
                </span>
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bolder",
                    color: colorMRI,
                    textAlign: "right",
                    paddingRight: "12px",
                    margin: "auto",
                  }}
                >
                  t
                </span>
              </Col>
              <Col style={{ paddingRight: "12px" }}>
                <AuthNav />
              </Col>
            </Row>
            <Row>
              <Col span={24} style={{ backgroundColor: "white" }}>
                <Switch>
                  <ProtectedRoute path="/XRAY" component={ExamHolder_XRAY} />
                  <ProtectedRoute path="/US" component={ExamHolder_US} />
                  <ProtectedRoute path="/CT" component={ExamHolder_CT} />
                  <ProtectedRoute path="/MRI" component={ExamHolder_MR} />
                </Switch>
              </Col>
            </Row>
          </Content>
          <Footer
            style={{
              textAlign: "center",
              margin: "auto",
              width: contentWidth,
              paddingTop: "5px",
              paddingBottom: "5px",
            }}
          >
            RadioloJet ©2020-{new Date().getFullYear()} created by yoshito
          </Footer>
        </Layout>
      </Router>
    );
  }
}

export default App;
