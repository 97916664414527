import React from "react";

// Custom scripts
import { aSelect, aLNmediastinum } from "./CT_chest_general_arrays";
import { generateReport } from "./CT_chest_functions";

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import DropDown from "../components/DropDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";
import ButtonUpDown from "../components/ButtonUpDown";
import CustomCards from "../components/CustomCards";

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
} from "antd";
import { debounce } from "lodash";
import { CloseCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;

class CT_chest extends React.Component {
  state = {
    key: 0,
    report: {
      exam: this.props.exam,
      protocol: this.props.protocol,
      contrast: this.props.contrast,
    },
    general: {
      LNmediastinumEnlarged: [],
      LNmediastinumMarginal: [],
      descriptionCustomLungR: "",
      descriptionCustomLungL: "",
      conclusionCustomLungR: "",
      conclusionCustomLungL: "",
      descriptionCustomBones: "",
      conclusionCustomBones: "",
      selection_1: 0,
      selection_2: 0,
      selection_3: false,
      selection_4: "",
    },
  };

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState(
      {
        [segment]: {
          ...this.state[segment],
          [name]: value,
        },
      },
      () =>
        this.props.askReport(
          generateReport(this.state, this.props.protocol, this.props.contrast)
        )
    );
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  render() {
    const LNmediastinum = [
      {
        title: "lymfatické uzliny - mediastinální a hilové",
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "zvětšené",
              input: (
                <DropDown
                  name="LNmediastinumEnlarged"
                  segment="general"
                  defaultValue={this.state.general.LNmediastinum}
                  options={aLNmediastinum}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "hraniční",
              input: (
                <DropDown
                  name="LNmediastinumMarginal"
                  segment="general"
                  defaultValue={this.state.general.LNmediastinum}
                  options={aLNmediastinum}
                  multiselect={true}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
    ];

    const Lungs = [
      {
        title: "plíce",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "pravá plíce",
              input: (
                <TextArea
                  size="small"
                  name="descriptionCustomLungR"
                  segment="general"
                  defaultValue={this.state.general.descriptionCustomLungR}
                  placeholder="vlastní popis pravé plíce..."
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "descriptionCustomLungR",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
            {
              label: "levá plíce",
              input: (
                <TextArea
                  size="small"
                  name="descriptionCustomLungL"
                  segment="general"
                  defaultValue={this.state.general.descriptionCustomLungL}
                  placeholder="vlastní popis levé plíce..."
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "descriptionCustomLungL",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "pravá plíce",
              input: (
                <TextArea
                  size="small"
                  name="conclusionCustomLungR"
                  segment="general"
                  defaultValue={this.state.general.conclusionCustomLungR}
                  placeholder="vlastní závěr pravé plíce..."
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "conclusionCustomLungR",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
            {
              label: "levá plíce",
              input: (
                <TextArea
                  size="small"
                  name="conclusionCustomLungL"
                  segment="general"
                  defaultValue={this.state.general.conclusionCustomLungL}
                  placeholder="vlastní závěr levé plíce..."
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "conclusionCustomLungL",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    const Bones = [
      {
        title: "skelet a hrudní stěna",
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "popis",
              input: (
                <TextArea
                  size="small"
                  name="descriptionCustomBones"
                  segment="general"
                  defaultValue={this.state.general.descriptionCustomBones}
                  placeholder="vlastní popis skeletu..."
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "descriptionCustomBones",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "závěr",
              input: (
                <TextArea
                  size="small"
                  name="conclusionCustomBones"
                  segment="general"
                  defaultValue={this.state.general.conclusionCustomBones}
                  placeholder="vlastní závěr skeletu..."
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "conclusionCustomBones",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={Lungs} />
        <CustomCards content={LNmediastinum} />
        <CustomCards content={Bones} />
      </Form>
    );
  }
}

export default CT_chest;
