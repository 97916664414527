import { indent, br } from "../scripts/GeneralArrays";
import { formatArrayToText } from "../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const { selection_1 } = props.general;

  importDescription1(
    "Krční prostory nativně i postkontrastně bez přesvědčivých strukturálních či signálových změn, normálního obsahu, bez patrných expanzí, infiltrátů či tekutinových kolekcí. Faryngeální mukózní prostor je symetrický, bez přesvědčivých strukturálních změn. V zobrazeném rozsahu jsou lymfatické uzliny přiměřené velikosti a ušlechtilého tvaru. Signál kostní dřeně není na T2FS patologicky zvýšen. "
  );

  importConclusion1("Přiměřený nález na MR krku. ");

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_1_for_Paste: report.conclusion2.forPaste,
  });
};
