import React from "react";

// Custom scripts
import {
  aArteries,
  aCADRADS,
  aPremedication,
  aAcquisition,
  aQuality,
  aDomination,
  aBetablocker,
  aMetoprololAmount,
  aEsmololAmount,
  aFlow,
} from "./CT_CTA_cardiac_arrays";
import { generateReport } from "./CT_CTA_cardiac_functions";

import { Tooltip } from "antd";

// General scripts
import {
  aTrueFalse,
  aCardColors,
  contentWidth,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "../scripts/GeneralFunctions";

// Custom components
import DropDown from "../components/DropDown";
import ButtonTrueFalse from "../components/ButtonTrueFalse";
import ButtonUpDown from "../components/ButtonUpDown";
import CustomCards from "../components/CustomCards";
import CAD_RADS_map from "../SVGs/CAD_RADS_map";

// 3rd party components
import {
  Row,
  Col,
  Menu,
  Button,
  Dropdown,
  Input,
  Tag,
  Divider,
  Form,
} from "antd";
import { debounce } from "lodash";
import { CloseCircleOutlined } from "@ant-design/icons";
const { TextArea } = Input;

class CT_template extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    general: {
      isoket: true,
      isBetablocker: true,
      typeBetablocker: 0,
      amountBetablocker: 0,
      acquisition: 0,
      flow: 0,
      quality: 0,
      heartRate: "60",
      domination: 0,
      cascore: 0,
    },
    arteries: {
      RCA1: 0,
      RCA2: 0,
      RCA3: 0,
      RCA4: 0,
      RCA16: 0,
      LCA5: 0,
      LCA6: 0,
      LCA7: 0,
      LCA8: 0,
      LCA9: 0,
      LCA10: 0,
      LCA11: 0,
      LCA12: 0,
      LCA13: 0,
      LCA14: 0,
      LCA15: 0,
      LCA17: 0,
      LCA18: 0,
    },
  };

  onChange = (event) => {
    const name = event.name;
    const segment = event.segment;
    const value = event.value;

    this.setState(
      {
        [segment]: {
          ...this.state[segment],
          [name]: value,
        },
      },
      () =>
        this.props.askReport(
          generateReport(this.state, this.props.protocol, this.props.contrast)
        )
    );
  };

  onSVGclick = (event) => {
    let name = event.target.id;
    if (event.nativeEvent.which === 1) {
      // Left Click
      if (this.state.arteries[name] < this.props.options.length - 1) {
        this.setState((prevState) => {
          return { ...prevState, [name]: prevState.arteries[name] + 1 };
        });
        this.props.onChange({
          segment: "arteries",
          name: name,
          value: this.props.options[this.state.arteries[name] + 1].value,
        });
      }
    } else if (event.nativeEvent.which === 3) {
      // Right Click
      event.preventDefault();
      if (this.state.arteries[name] > 0) {
        this.setState((prevState) => {
          return { ...prevState, [name]: prevState.arteries[name] - 1 };
        });
        this.props.onChange({
          segment: "arteries",
          name: name,
          value: this.props.options[this.state.arteries[name] - 1].value,
        });
      }
    }
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  render() {
    const technicalData = [
      {
        title: "premedikace",
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "Isoket",
              input: (
                <Row>
                  <Col>
                    <ButtonTrueFalse
                      name="isoket"
                      segment="general"
                      value={this.state.general.isoket}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "betablokátor",
              input: (
                <Row>
                  <Col>
                    <ButtonTrueFalse
                      name="isBetablocker"
                      segment="general"
                      value={this.state.general.isBetablocker}
                      options={aTrueFalse}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: "",
              input: this.state.general.isBetablocker ? (
                <Row>
                  <Col>
                    <ButtonUpDown
                      name="typeBetablocker"
                      segment="general"
                      options={aBetablocker}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ) : null,
            },
            {
              label: "",
              input: this.state.general.isBetablocker ? (
                <Row>
                  <Col>
                    <ButtonUpDown
                      name="amountBetablocker"
                      segment="general"
                      options={
                        this.state.general.typeBetablocker === 0
                          ? aMetoprololAmount
                          : aEsmololAmount
                      }
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ) : null,
            },
          ],
        ],
      },
      {
        title: "parametry vyšetření",
        color: aCardColors.blue,
        rows: [
          [
            {
              label: "rychlost k.l.",
              input: (
                <Row>
                  <Col>
                    <ButtonUpDown
                      name="flow"
                      segment="general"
                      options={aFlow}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: "akvizice",
              input: (
                <Row>
                  <Col>
                    <ButtonUpDown
                      name="acquisition"
                      segment="general"
                      options={aAcquisition}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
          [
            {
              label: "akvizice",
              input: (
                <Row>
                  <Col>
                    <ButtonUpDown
                      name="acquisition"
                      segment="general"
                      options={aAcquisition}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: "kvalita vyš.",
              input: (
                <Row>
                  <Col>
                    <ButtonUpDown
                      name="quality"
                      segment="general"
                      options={aQuality}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];
    const myContent3 = [
      {
        title: "CAD-RADS",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "",
              input: (
                <Row>
                  <Col>
                    <CAD_RADS_map
                      name="CAD_RADS_map"
                      options={aCADRADS}
                      arteries={this.state.arteries}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
      {
        title: "CAD-RADS",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "tep. frekvence",
              input: (
                <Row>
                  <Col>
                    <Input
                      name="heartRate"
                      value={this.state.general.heartRate}
                      size="small"
                      suffix="/min"
                      onChange={(event) =>
                        this.onChange({
                          segment: "general",
                          name: "heartRate",
                          value: event.target.value,
                        })
                      }
                      style={{ width: 97 }}
                    />
                  </Col>
                </Row>
              ),
            },
            {
              label: "dominance",
              input: (
                <Row>
                  <Col>
                    <ButtonUpDown
                      name="domination"
                      segment="general"
                      options={aDomination}
                      onChange={this.onChange}
                    />
                  </Col>
                </Row>
              ),
            },
          ],
        ],
      },
    ];

    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={technicalData} />
        <CustomCards content={myContent3} />
      </Form>
    );
  }
}

export default CT_template;
