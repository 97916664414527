import { green, yellow, orange, red, neutral } from "../scripts/GeneralArrays";

// Custom

export const aOrganSize = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lehce větší",
    description: "",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "větší",
    description: "",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně větší",
    description: "",
    conclusion: "",
    color: red,
  },
];

export const aOrganAtrophy = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná atrofie",
    description: "s mírnou redukcí parenchymu",
    conclusion: "mírná redukce parenchymu",
    color: yellow,
  },
  {
    value: 2,
    text: "atrofie",
    description: "s redukcí parenchymu",
    conclusion: "redukce parenchymu",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná atrofie",
    description: "s výraznou redukcí parenchymu",
    conclusion: "výrazná redukce parenchymu",
    color: red,
  },
];

export const aLiverSize = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvětšena",
    description: "mírně zvětšena",
    conclusion: "mírně zvětšena",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšena",
    description: "zvětšena",
    conclusion: "zvětšena",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvětšena",
    description: "výrazně zvětšena",
    conclusion: "výrazně zvětšena",
    color: red,
  },
  {
    value: 4,
    text: "menší",
    description: "menší velikosti",
    conclusion: "menší velikosti",
    color: orange,
  },
];

export const aLiverParenchyma = [
  {
    value: 0,
    text: "normální",
    description:
      "na T1 out-of-phase oproti in-phase bez výraznějšího rozdílu SI parenchymu",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná steatóza",
    description:
      "na T1 out-of-phase oproti in-phase mírně difuzně nižší SI prenchymu",
    conclusion: "s obrazem mírné steatózy",
    color: yellow,
  },
  {
    value: 2,
    text: "steatóza difuzní",
    description:
      "na T1 out-of-phase oproti in-phase difuzně nižší SI prenchymu",
    conclusion: "s obrazem difuzní steatózy",
    color: yellow,
  },
  {
    value: 3,
    text: "steatóza mapovitá",
    description:
      "na T1 out-of-phase oproti in-phase mapovitě nižší SI prenchymu",
    conclusion: "s obrazem mapovité steatózy",
    color: yellow,
  },
  {
    value: 4,
    text: "cirhóza",
    description: "s nodulární přestavbou parenchymu",
    conclusion: "s obrazem cirhózy",
    color: red,
  },
];

export const aGBsize = [
  {
    value: 0,
    text: "normální",
    description: "přiměřené náplně",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "zvýšená",
    description: "zvětšen výraznější náplní",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "snížená",
    description: "zmenšen chabou náplní",
    conclusion: "",
    color: yellow,
  },
];

export const aGBcontent = [
  {
    value: 0,
    text: "normální",
    description: "v lumen bez defektů v signálu tekutiny",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "konkrement",
    description: "v lumen s ohraničeným kulovitým výpadkem signálu",
    conclusion: "Cholecystolitiáza. ",
    color: orange,
  },
  {
    value: 2,
    text: "konkrementy",
    description: "v lumen s ohraničenými kulovitými výpadky signálu",
    conclusion: "Cholecystolitiáza. ",
    color: orange,
  },
  {
    value: 3,
    text: "sludge",
    description: "v lumen s hyposignálním sedimentem",
    conclusion: "Sludge v žlučníku. ",
    color: orange,
  },
];

export const aGBwall = [
  {
    value: 0,
    text: "normální",
    description: "s tenkou stěnou",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "zesílena",
    description: "s difuzně zesílenou stěnou",
    conclusion: "Cholecystitida. ",
    color: orange,
  },
  {
    value: 2,
    text: "zesílena a prosak okolí",
    description:
      "s difuzně zesílenou stěnou a zvýšené SI okolního tuku a lůžka žlučníku",
    conclusion: "Cholecystitida a pericholecystitida. ",
    color: orange,
  },
];

export const aBTextraSize = [
  {
    value: 0,
    text: "normální",
    description: "Přiměřené šíře lumen ductus hepatocholedochus. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční šíře",
    description: "Hraniční šíře lumen ductus hepatocholedochus. ",
    conclusion: "Hraniční šíře ductus hepatocholedochus. ",
    color: orange,
  },
  {
    value: 2,
    text: "dilatován",
    description: "Dilatace lumen ductus hepatocholedochus. ",
    conclusion: "Dilatace ductus hepatocholedochus. ",
    color: red,
  },
];

export const aBTintraSize = [
  {
    value: 0,
    text: "normální",
    description: "Intrahepatické žlučové cesty nejsou rozšířeny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční šíře",
    description: "Intrahepatické žlučové cesty hraniční šíře lumen. ",
    conclusion: "Hraniční šíře intrahepatických žlučových cest. ",
    color: orange,
  },
  {
    value: 2,
    text: "dilatovány",
    description: "Intrahepatické žlučové cesty dilatovány. ",
    conclusion: "Dilatace intrahepatických žlučových cest. ",
    color: red,
  },
];

export const aApendix = [
  {
    value: 0,
    text: "normální",
    description: "Apendix přiměřené šíře, bez reaktivních změn okolního tuku. ",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "po APPE",
    description: "Apendix nedif. - susp. st.p. APPE",
    conclusion: "",
    color: yellow,
  },
  {
    value: 2,
    text: "zánět",
    description:
      "Difuzní zesílení stěny apendixu se infiltrativním zvýšením denzity okolního tuku. ",
    conclusion: "Appendicitida. ",
    color: red,
  },
  {
    value: 3,
    text: "apendikulární absces",
    description:
      "Ohraničená kolekce tekutiny se sytící se stěnou v místě předpokládaného průběhu apendixu, s infiltrativním zvýšením denzity okolního tuku. ",
    conclusion: "Apendikulární absces. ",
    color: red,
  },
];

export const aColon = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "divertikulóza sigmatu",
    description:
      "Výchlipky stěny sigmatu, bez rozšíření stěny či infiltrativního zvýšení SI okolního tuku. ",
    conclusion: "Divertikulóza sigmatu. ",
    color: orange,
  },
  {
    value: 2,
    text: "divertikulóza tračníku",
    description:
      "Výchlipky stěny tračníku, bez rozšíření stěny či infiltrativního zvýšení SI okolního tuku. ",
    conclusion: "Divertikulóza tračníku. ",
    color: orange,
  },
  {
    value: 3,
    text: "divertikulitida sigmatu",
    description:
      "V terénu četných divertiklů sigmatu rozšíření stěny střevní s infiltrativním zvýšením SI okolního tuku. ",
    conclusion: "Divertikulitida sigmatu. ",
    color: red,
  },
];

export const aSpleenSize = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírně zvětšena",
    description: "mírně zvětšena",
    conclusion: "mírně zvětšena",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšena",
    description: "zvětšena",
    conclusion: "zvětšena",
    color: orange,
  },
  {
    value: 3,
    text: "výrazně zvětšena",
    description: "výrazně zvětšena",
    conclusion: "výrazně zvětšena",
    color: red,
  },
  {
    value: 4,
    text: "menší",
    description: "menší velikosti",
    conclusion: "menší velikosti",
    color: orange,
  },
];

export const aPancreasSize = [
  {
    value: 0,
    text: "normální",
    description: "normální velikosti",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná atrofie",
    description: "mírně atrofický",
    conclusion: "mírně atrofický",
    color: yellow,
  },
  {
    value: 2,
    text: "atrofie",
    description: "atrofický",
    conclusion: "atrofický",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná atrofie",
    description: "výrazně atrofický",
    conclusion: "výrazně atrofický",
    color: red,
  },
];

export const aPancreasParenchyma = [
  {
    value: 0,
    text: "normální",
    description: "nativně přiměřené SI parenchymu",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "lipodystrofie",
    description:
      "nativně parenchym na T1 out-of-phase nižší SI oproti in-phase",
    conclusion: "s obrazem lipodystrofie",
    color: yellow,
  },
];

export const aKidneyParenchyma = [
  {
    value: 0,
    text: "normální",
    description: "bez redukce parenchymu",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "mírná atrofie",
    description: "s mírnou redukcí parenchymu",
    conclusion: "mírnou redukcí parenchymu",
    color: yellow,
  },
  {
    value: 2,
    text: "atrofie",
    description: "s redukcí parenchymu",
    conclusion: "redukcí parenchymu",
    color: orange,
  },
  {
    value: 3,
    text: "výrazná atrofie",
    description: "s výraznou redukcí parenchymu",
    conclusion: "výraznou redukcí parenchymu",
    color: red,
  },
];

export const aKidneyKPS = [
  {
    value: 0,
    text: "normální",
    description: "s normální šíří kalichopánvičkového systému",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hypotonie",
    description: "s rozšířením pánvičky, ale s přiměřenou šíří kalichů",
    conclusion: "obrazem hypotonie kalichopánvičkového systému",
    color: green,
  },
  {
    value: 2,
    text: "mírná dilatace",
    description: "s mírným rozšířením kalichopánvičkového systému",
    conclusion: "obrazem mírného městnání",
    color: orange,
  },
  {
    value: 3,
    text: "dilatace",
    description: "s rozšířením Kkalichopánvičkového systémuPS",
    conclusion: "obrazem městnání",
    color: red,
  },
  {
    value: 3,
    text: "výrazná dilatace",
    description: "s výrazným rozšířením kalichopánvičkového systému",
    conclusion: "obrazem výrazného městnání",
    color: red,
  },
];

export const aOvarium = [
  {
    value: 0,
    text: "nehodnoceno",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description: "ovarium s věku přiměřeným nálezem. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nedif.",
    description: "ovarium není přesvědčivě diferencovatelné. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 3,
    text: "cysta",
    description:
      "ovarium obsahující tekutinovou kolekci ohraničenou tenkou stěnou, celkové velikosti ",
    conclusion: "ovarium s cystou",
    color: orange,
  },
  {
    value: 4,
    text: "cysty",
    description:
      "ovarium obsahující několik tekutinových kolekcí ohraničených tenkou stěnou, největší velikosti ",
    conclusion: "ovarium s cystami",
    color: orange,
  },
];

export const aUrinaryBladder = [
  {
    value: 0,
    text: "nehodnocen",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description:
      "Močový měchýř s normálním obsahem, bez přesvědčivého patologického zesílení stěny. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nehodnotitelný (chabá náplň)",
    description: "Pro chabou náplň je močový měchýř nehodnotitelný. ",
    conclusion: "",
    color: orange,
  },
  {
    value: 3,
    text: "zánět",
    description: "Difuzní zesílení stěny močového měchýře. ",
    conclusion: "Zánětlivé změny močového měchýře. ",
    color: red,
  },
];

export const aUterus = [
  {
    value: 0,
    text: "nehodnocena",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description:
      "Věku přiměřená velikost dělohy, bez přesvědčivých ložiskových změn. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nedif.",
    description: "Děloha chybí, susp. st.p. hysterektomii. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 3,
    text: "myomatóza",
    description: "Na T2 hyposignální ložiska v myometriu dělohy. ",
    conclusion: "Myomy dělohy. ",
    color: orange,
  },
];

export const aProstate = [
  {
    value: 0,
    text: "nehodnocena",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description: "Věku přiměřená velikost prostaty. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "nedif.",
    description: "Prostata nedif., susp. st.p. prostatektomii. ",
    conclusion: "",
    color: yellow,
  },
  {
    value: 3,
    text: "zvětšená",
    description: "Zvětšená prostata. ",
    conclusion: "Zvětšená prostata, susp. benigní hyperplázií. ",
    color: orange,
  },
];

export const aSigma = [
  {
    value: 0,
    text: "nehodnoceno",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "normální",
    description:
      "Pravidelná přiměřená šíře stěny sigmatu, bez reaktivních změn okolního tuku. ",
    conclusion: "",
    color: green,
  },
  {
    value: 2,
    text: "divertikly",
    description:
      "Divertikly sigmatu, ale bez zesílení jeho stěny či reaktivních změn okolního tuku. ",
    conclusion: "Divertikly sigmatu. ",
    color: orange,
  },
  {
    value: 3,
    text: "divertikulitida",
    description:
      "Zesílení stěny sigmatu s četnými divertikly, s reaktivním prosáknutím okolního tuku. ",
    conclusion: "Divertikulitida sigmatu. ",
    color: red,
  },
];

export const aLN = [
  {
    value: 0,
    text: "normální",
    description: "",
    conclusion: "",
    color: green,
  },
  {
    value: 1,
    text: "hraniční",
    description: "hraniční velikosti",
    conclusion: "hraniční velikosti",
    color: yellow,
  },
  {
    value: 2,
    text: "zvětšené",
    description: "zvětšené",
    conclusion: "zvětšené",
    color: red,
  },
];

export const aLNcount = [
  {
    value: 0,
    text: "1",
    description: "1",
    conclusion: "jedna ",
    color: orange,
  },
  {
    value: 1,
    text: "2",
    description: "2",
    conclusion: "dvě ",
    color: orange,
  },
  {
    value: 2,
    text: "3",
    description: "3",
    conclusion: "tři ",
    color: orange,
  },
  {
    value: 3,
    text: ">3",
    description: ">3",
    conclusion: "více než 3 ",
    color: orange,
  },
];
