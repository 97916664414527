import { indent, br } from "../scripts/GeneralArrays";
import {
  formatArrayToText,
  capitelizeFirstLetter,
} from "../scripts/GeneralFunctions";
import {
  aLigamentTear,
  aLigamentAge,
  aTCdegen,
  aTCfluid,
  aOCDgrade,
  aATtendinosis,
  aATperitendonitis,
} from "./MR_ankle_arrays";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  const {
    ATFLage,
    CFLage,
    PTFLage,
    ATFLtear,
    CFLtear,
    PTFLtear,
    deltoidtear,
    deltoidage,
  } = props.ligaments;

  const {
    TCfluid,
    TCdegen,
    PBTtear,
    PBTage,
    OCDgrade,
    OCDsize,
    calcar,
    plantFasc,
    retrokalkBursitis,
    ATwidth,
    ATtendinosis,
    ATperitendonitis,
    tsFHL,
  } = props.general;

  // laterální ligamentózní komplex - ATFL
  let descriptionATFL;
  let conclusionATFL;
  if (ATFLtear === 0) {
    descriptionATFL =
      "Lig. talofibulare ant. je na PDFS normální šíře a SI, bez zvýšení SI okolního tuku. ";
    conclusionATFL = "";
  } else if (ATFLtear === 1) {
    if (ATFLage === 0) {
      descriptionATFL =
        "Lig. talofibulare ant. je na PDFS normální šíře, s lemem vyšší SI okolního tuku. ";
      conclusionATFL =
        "Akutní low grade parciální ruptura lig. talofibulare ant. ";
    } else {
      descriptionATFL =
        "Lig. talofibulare ant. je na PDFS lehce rozšířeno, bez lemu vyšší SI okolního tuku. ";
      conclusionATFL =
        "Susp. chronická low grade parciální ruptura lig. talofibulare ant. ";
    }
  } else if (ATFLtear === 2)
    if (ATFLage === 0) {
      descriptionATFL =
        "Lig. talofibulare ant. je na PDFS nehomogenně rozšířeno, s lemem vyšší SI okolního tuku. ";
      conclusionATFL =
        "Akutní high grade parciální ruptura lig. talofibulare ant. ";
    } else {
      descriptionATFL =
        "Lig. talofibulare ant. je na PDFS výrazně rozšířeno, bez lemu vyšší SI okolního tuku. ";
      conclusionATFL =
        "Susp. chronická high grade parciální ruptura lig. talofibulare ant. ";
    }
  else {
    if (ATFLage === 0) {
      descriptionATFL =
        "Lig. talofibulare ant. je na PDFS kompletně přerušeno, s lemem vyšší SI okolního tuku. ";
      conclusionATFL = "Akutní v.s. kompletní ruptura lig. talofibulare ant. ";
    } else {
      descriptionATFL =
        "Lig. talofibulare ant. je na PDFS kompletně přerušeno, bez lemu vyšší SI okolního tuku. ";
      conclusionATFL =
        "Susp. chronická kompletní ruptura lig. talofibulare ant. ";
    }
  }

  // laterální ligamentózní komplex - CFL
  let descriptionCFL;
  let conclusionCFL;
  if (CFLtear === 0) {
    descriptionCFL =
      "Lig. calcaneofibulare je na PDFS normální šíře a SI, bez zvýšení SI okolního tuku. ";
    conclusionCFL = "";
  } else if (CFLtear === 1) {
    if (CFLage === 0) {
      descriptionCFL =
        "Lig. calcaneofibulare je na PDFS normální šíře, s lemem vyšší SI okolního tuku. ";
      conclusionCFL =
        "Akutní low grade parciální ruptura lig. calcaneofibulare. ";
    } else {
      descriptionCFL =
        "Lig. calcaneofibulare je na PDFS lehce rozšířeno, bez lemu vyšší SI okolního tuku. ";
      conclusionCFL =
        "Susp. chronická low grade parciální ruptura lig. calcaneofibulare. ";
    }
  } else if (CFLtear === 2)
    if (CFLage === 0) {
      descriptionCFL =
        "Lig. calcaneofibulare je na PDFS nehomogenně rozšířeno, s lemem vyšší SI okolního tuku. ";
      conclusionCFL =
        "Akutní high grade parciální ruptura lig. calcaneofibulare. ";
    } else {
      descriptionCFL =
        "Lig. calcaneofibulare je na PDFS výrazně rozšířeno, bez lemu vyšší SI okolního tuku. ";
      conclusionCFL =
        "Susp. chronická high grade parciální ruptura lig. calcaneofibulare. ";
    }
  else {
    if (CFLage === 0) {
      descriptionCFL =
        "Lig. calcaneofibulare je na PDFS kompletně přerušeno, s lemem vyšší SI okolního tuku. ";
      conclusionCFL = "Akutní v.s. kompletní ruptura lig. calcaneofibulare. ";
    } else {
      descriptionCFL =
        "Lig. calcaneofibulare je na PDFS kompletně přerušeno, bez lemu vyšší SI okolního tuku. ";
      conclusionCFL =
        "Susp. chronická kompletní ruptura lig. calcaneofibulare. ";
    }
  }

  // laterální ligamentózní komplex - PTFL
  let descriptionPTFL;
  let conclusionPTFL;
  if (PTFLtear === 0) {
    descriptionPTFL =
      "Lig. talofibulare post. je na PDFS normální šíře a SI, bez zvýšení SI okolního tuku. ";
    conclusionPTFL = "";
  } else if (PTFLtear === 1) {
    if (PTFLage === 0) {
      descriptionPTFL =
        "Lig. talofibulare post. je na PDFS normální šíře, s lemem vyšší SI okolního tuku. ";
      conclusionPTFL =
        "Akutní low grade parciální ruptura lig. talofibulare post. ";
    } else {
      descriptionPTFL =
        "Lig. talofibulare post. je na PDFS lehce rozšířeno, bez lemu vyšší SI okolního tuku. ";
      conclusionPTFL =
        "Susp. chronická low grade parciální ruptura lig. talofibulare post. ";
    }
  } else if (PTFLtear === 2)
    if (PTFLage === 0) {
      descriptionPTFL =
        "Lig. talofibulare post. je na PDFS nehomogenně rozšířeno, s lemem vyšší SI okolního tuku. ";
      conclusionPTFL =
        "Akutní high grade parciální ruptura lig. talofibulare post. ";
    } else {
      descriptionPTFL =
        "Lig. talofibulare post. je na PDFS výrazně rozšířeno, bez lemu vyšší SI okolního tuku. ";
      conclusionPTFL =
        "Susp. chronická high grade parciální ruptura lig. talofibulare post. ";
    }
  else {
    if (PTFLage === 0) {
      descriptionPTFL =
        "Lig. talofibulare post. je na PDFS kompletně přerušeno, s lemem vyšší SI okolního tuku. ";
      conclusionPTFL = "Akutní v.s. kompletní ruptura lig. talofibulare post. ";
    } else {
      descriptionPTFL =
        "Lig. talofibulare post. je na PDFS kompletně přerušeno, bez lemu vyšší SI okolního tuku. ";
      conclusionPTFL =
        "Susp. chronická kompletní ruptura lig. talofibulare post. ";
    }
  }
  let descriptionLigamentsLateral = "";
  descriptionLigamentsLateral +=
    (ATFLtear > 0 ? descriptionATFL : "") +
    (CFLtear > 0 ? descriptionCFL : "") +
    (PTFLtear > 0 ? descriptionPTFL : "") +
    (ATFLtear + CFLtear + PTFLtear === 0
      ? "Vazy laterálního ligamentózního komplexu jsou normální šíře a signálu, na PDFS bez zvýšení SI okolních měkkých tkání. "
      : "");

  // mediální (deltoidní) ligamentózní komplex
  let descriptiondeltoid;
  let conclusiondeltoid;
  if (deltoidtear === 0) {
    descriptiondeltoid =
      "Mediální (deltoidní) ligamentózní komplex je na PD dobře ohraničený, na PDFS bez zvýšení SI komplexu nebo okolních měkkých tkání. ";
    conclusiondeltoid = "";
  } else if (deltoidtear === 1) {
    if (deltoidage === 0) {
      descriptiondeltoid =
        "Mediální (deltoidní) ligamentózní komplex je na PD lehce neostrých kontur, s lemem vyšší SI okolního tuku. ";
      conclusiondeltoid =
        "Akutní spíše low grade parciální ruptura mediálního (deltoidního) ligamentózního komplexu. ";
    } else {
      descriptiondeltoid =
        "Mediální (deltoidní) ligamentózní komplex je na PD lehce neostrých kontur, bez lemu vyšší SI okolního tuku. ";
      conclusiondeltoid =
        "Susp. chronická low grade parciální ruptura mediálního (deltoidního) ligamentózního komplexu. ";
    }
  } else if (deltoidtear === 2)
    if (deltoidage === 0) {
      descriptiondeltoid =
        "Mediální (deltoidní) ligamentózní komplex je na PD výrazně neostrých kontur, s lemem vyšší SI okolního tuku. ";
      conclusiondeltoid =
        "Akutní spíše high grade parciální ruptura mediálního (deltoidního) ligamentózního komplexu. ";
    } else {
      descriptiondeltoid =
        "Mediální (deltoidní) ligamentózní komplex je na PD výrazně neostrých kontur, bez lemu vyšší SI okolního tuku. ";
      conclusiondeltoid =
        "Susp. chronická high grade parciální ruptura mediálního (deltoidního) ligamentózního komplexu. ";
    }
  else {
    if (deltoidage === 0) {
      descriptiondeltoid =
        "Mediální (deltoidní) ligamentózní komplex je na PDFS kompletně přerušen, s lemem vyšší SI okolního tuku. ";
      conclusiondeltoid =
        "Akutní v.s. kompletní ruptura mediálního (deltoidního) ligamentózního komplexu. ";
    } else {
      descriptiondeltoid =
        "Mediální (deltoidní) ligamentózní komplex je na PDFS susp. kompletně přerušen, bez lemu vyšší SI okolního tuku. ";
      conclusiondeltoid =
        "Susp. chronická high grade parciální či kompletní ruptura mediálního (deltoidního) ligamentózního komplexu. ";
    }
  }

  let descriptionLigaments = descriptionLigamentsLateral + descriptiondeltoid;
  let conclusionLigaments =
    conclusionATFL + conclusionCFL + conclusionPTFL + conclusiondeltoid;

  // talokrurální kloub - náplň
  let descriptionTCfluid = aTCfluid[TCfluid].description1;
  let conclusionTCfluid = aTCfluid[TCfluid].conclusion;

  // talokrurální kloub - degenerace
  let descriptionTCdegen = aTCdegen[TCdegen].description1;
  let conclusionTCdegen = aTCdegen[TCdegen].conclusion;

  // peroneus brevis - ruptura
  let descriptionPBT;
  let conclusionPBT;
  if (PBTtear === 0) {
    descriptionPBT =
      "Šlacha m. peroneus brevis je za zevním kotníkem na PDFS normální šíře a SI, bez zvýšení SI okolního tuku. ";
    conclusionPBT = "";
  } else if (PBTtear === 1) {
    if (PBTage === 0) {
      descriptionPBT =
        "Šlacha m. peroneus brevis je za zevním kotníkem průběhem šlachy m. peroneus longus lehce oploštělá, na PDFS se zvýšením SI okolních měkkých tkání. ";
      conclusionPBT =
        "Akutní low grade parciální podélná (split) ruptura šlachy m. peroneus brevis. ";
    } else {
      descriptionPBT =
        "Šlacha m. peroneus brevis je za zevním kotníkem průběhem šlachy m. peroneus longus lehce oploštělá, na PDFS bez zvýšení SI okolních měkkých tkání. ";
      conclusionPBT =
        "Chronická low grade parciální podélná (split) ruptura šlachy m. peroneus brevis. ";
    }
  } else if (PBTtear === 2)
    if (PBTage === 0) {
      descriptionPBT =
        "Šlacha m. peroneus brevis je za zevním kotníkem průběhem šlachy m. peroneus longus poměrně výrazně deformována ('cashew nut sign'), na PDFS se zvýšením SI okolních měkkých tkání. ";
      conclusionPBT =
        "Akutní high grade podélná (split) parciální ruptura šlachy m. peroneus brevis. ";
    } else {
      descriptionPBT =
        "Šlacha m. peroneus brevis je za zevním kotníkem průběhem šlachy m. peroneus longus poměrně výrazně deformována ('cashew nut sign'), na PDFS bez zvýšení SI okolních měkkých tkání. ";
      conclusionPBT =
        "Chronická high grade podélná (split) parciální ruptura šlachy m. peroneus brevis. ";
    }
  else {
    if (PBTage === 0) {
      descriptionPBT =
        "Šlacha m. peroneus brevis je za zevním kotníkem průběhem šlachy m. peroneus longus podélně rozpolcena, na PDFS se zvýšením SI okolních měkkých tkání. ";
      conclusionPBT =
        "Akutní v.s. kompletní podélná (split) ruptura šlachy m. peroneus brevis. ";
    } else {
      descriptionPBT =
        "Šlacha m. peroneus brevis je za zevním kotníkem průběhem šlachy m. peroneus longus podélně rozpolcena, na PDFS bez zvýšení SI okolních měkkých tkání. ";
      conclusionPBT =
        "Chronická kompletní podélná (split) ruptura šlachy m. peroneus brevis. ";
    }
  }

  // OCD talu
  let descriptionOCD =
    aOCDgrade[OCDgrade].description1 +
    (OCDsize != ""
      ? ", rozsahu cca " + OCDsize + "mm. "
      : OCDgrade > 0
      ? ". "
      : "");
  let conclusionOCD =
    aOCDgrade[OCDgrade].conclusion +
    (OCDsize != "" ? " (" + OCDsize + "mm). " : OCDgrade > 0 ? ". " : "");

  // Calcar calcanei  plantaris
  let descriptionCalcar = calcar ? "Calcar calcanei plantaris. " : "";
  let conclusionCalcar = descriptionCalcar;

  // Plantární fasciitida
  let descriptionPlantFasc = plantFasc
    ? "Rozšíření plantární fascie při kalkaneálním úponu, vyšší SI na PDFS a střední SI na T1. "
    : "";
  let conclusionPlantFasc = plantFasc ? "Plantární fasciitida. " : "";

  // Retrokalkaneální bursitida
  let descriptionRetrokalkBrusitis = retrokalkBursitis
    ? "Zvýšená náplň retrokalkaneální burzy. "
    : "";
  let conclusionRetrokalkBrusitis = retrokalkBursitis
    ? "Retrokalkaneální bursitida. "
    : "";

  // Achillova šlacha
  let descriptionAchillesTendon = "";
  descriptionAchillesTendon +=
    "Achillova šlacha je ventrodorzálně " +
    (ATwidth == "" ? "normální šíře" : "šíře " + ATwidth + "mm") +
    ", na PDFS je " +
    aATtendinosis[ATtendinosis].description1 +
    (ATperitendonitis > 0
      ? aATperitendonitis[ATperitendonitis].description1
      : ". ");
  let aAchillesTendonPathology = [];
  if (ATtendinosis > 0)
    aAchillesTendonPathology.push(aATtendinosis[ATtendinosis].conclusion);
  if (ATperitendonitis > 0)
    aAchillesTendonPathology.push(
      aATperitendonitis[ATperitendonitis].conclusion
    );

  let conclusionAchillesTendon =
    aAchillesTendonPathology.length > 0
      ? capitelizeFirstLetter(
          formatArrayToText(aAchillesTendonPathology) + " Achillovy šlachy. "
        )
      : "";

  // Tenosynovitida šlachy m. flexor hallucis longus
  let descriptionTSFHL = tsFHL
    ? "Zvýšená náplň šlachové pochvy m. flexor hallucis longus. "
    : "";
  let conclusionTSFHL = tsFHL
    ? "Tenosynovitida m. flexor hallucis longus. "
    : "";

  importDescription1(
    "Postavení v tibiotalárním kloubu a tarzálních kloubech není porušeno. Na PDFS je SI kostní dřeně přiměřená. " +
      descriptionLigaments +
      descriptionTSFHL +
      descriptionTCdegen +
      descriptionOCD +
      descriptionTCfluid +
      descriptionCalcar +
      descriptionPlantFasc +
      descriptionRetrokalkBrusitis +
      descriptionAchillesTendon
  );

  importConclusion1(
    conclusionLigaments +
      conclusionTCdegen +
      conclusionPBT +
      conclusionOCD +
      conclusionTCfluid +
      conclusionCalcar +
      conclusionPlantFasc +
      conclusionRetrokalkBrusitis +
      conclusionAchillesTendon +
      conclusionTSFHL +
      (conclusionLigaments +
        conclusionTCdegen +
        conclusionPBT +
        conclusionOCD +
        conclusionTCfluid +
        conclusionCalcar +
        conclusionPlantFasc +
        conclusionRetrokalkBrusitis +
        conclusionAchillesTendon +
        conclusionTSFHL ==
      ""
        ? "Přiměřený nález na MR " +
          (protocol === 0 ? "pravého" : "levého") +
          " hlezna. "
        : "")
  );

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
