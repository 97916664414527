import React from "react";
import { Row, Col, Card, Input, Button } from "antd";

import DropDown from "./DropDown";
import CustomCards from "./CustomCards";

import { aCardColors } from "./../scripts/GeneralArrays";

const { TextArea } = Input;

class ExamHeader extends React.Component {
  state = {
    report: {
      exam: 0,
      indication: this.props.indication,
      contrast: this.props.contrast,
      contrastAmount: this.props.contrastAmount,
    },
  };

  onChange = (event) => {
    const name = event.name;
    const value =
      event.name !== "indication" ? event.value : event.value.target.value;
    this.setState({ [name]: value });
    this.props.onChange({ name: name, segment: "report", value: value });
  };

  onReset = () => {
    this.props.onReset();
  };

  render() {
    const myContent = [
      {
        title: "Vyšetření",
        extra: (
          <Button type="primary" danger size="small" onClick={this.onReset}>
            Reset
          </Button>
        ),
        color: aCardColors.purple,
        rows: [
          [
            {
              label: "název",
              input: (
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <DropDown
                          name="exam"
                          segment="report"
                          defaultValue={this.state.report.exam}
                          options={this.props.exam}
                          onChange={this.onChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <DropDown
                          name="contrast"
                          segment="report"
                          defaultValue={this.state.report.contrast}
                          options={this.props.contrastType}
                          onChange={this.onChange}
                        />
                      </Col>
                      <Col span={12}>
                        {this.props.contrast > 0 ? (
                          <DropDown
                            name="contrastAmount"
                            segment="report"
                            options={this.props.contrastTypeAmount}
                            defaultValue={this.state.report.contrastAmount}
                            onChange={this.onChange}
                          />
                        ) : null}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ),
            },
            {
              label: "indikace",
              input: (
                <TextArea
                  name="indication"
                  segment="report"
                  placeholder="indikace..."
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  onBlur={(event) =>
                    this.onChange({ name: "indication", value: event })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];

    return <CustomCards content={myContent} />;
  }
}

export default ExamHeader;
