import React from "react";
import cloneDeep from "lodash/cloneDeep";

// Custom scripts
import {
  aArthrosisHS,
  aArthrosisAC,
  aRotatorCuff,
  aSynovialFluidHS,
  aSynovialFluidAC,
  aBursa,
  aPatType,
  aPatSide,
  aPatSiteHorizontal,
  aPatSiteVertical,
  aAcromion,
  aLabrum,
  aBicepsTendinosis,
  aBicepsTendonDislocation,
  aBicepsTendonRupture,
  aMuscleAtrophy,
} from "./MR_shoulder_arrays";
import { generateReport } from "./MR_shoulder_functions";

// General scripts
import {
  contentWidth,
  aCardColors,
  aTrueFalse,
} from "../scripts/GeneralArrays";
import { formatCustomInput } from "./../scripts/GeneralFunctions";

// Custom components
import ButtonTrueFalse from "../components/ButtonTrueFalse";
import CustomCards from "../components/CustomCards";
import ButtonUpDown from "../components/ButtonUpDown";

// 3rd party components
import { debounce } from "lodash";
import { Row, Col, Form, Button, Select, Space, Input, Switch } from "antd";
const { TextArea } = Input;
const { Option } = Select;

class MR_shoulder extends React.Component {
  state = {
    key: 0,
    protocol: this.props.protocol,
    report: {
      exam: this.props.exam,
      contrast: this.props.contrast,
    },
    AC: {
      synovialFluidAC: 0,
      arthrosisAC: 0,
      subchondralEdema: false,
      acromion: 1,
      osAcromiale: false,
    },
    HS: {
      synovialFluidHS: 0,
      arthrosisHS: 0,
      bursa: 0,
      labrum: 0,
      bicepsTendinosis: 0,
      bicepsTendonDislocation: 0,
      bicepsTendonRupture: 0,
      atrophySupraspinatus: 0,
      atrophyInfraspinatus: 0,
      atrophySubscapularis: 0,
      atrophyTeresMinor: 0,
    },
    general: {
      customFindsDescription: "",
      customFindsConclusion: "",
      customAddFindsPriority: false,
    },
    tendinopathy: [],
    musclesAtrophy: {
      supraspinatus: 0,
      infraspinatus: 0,
      subscapularis: 0,
      teresMinor: 0,
    },
  };

  initialState = cloneDeep(this.state);

  onChange = (event) => {
    const segment = event.segment;
    const name = event.name;
    const value = event.value;

    this.setState({
      [segment]: {
        ...this.state[segment],
        [name]: value,
      },
    });
  };

  componentDidMount = () => {
    let result = generateReport(
      this.state,
      this.props.protocol,
      this.props.contrast
    );
    this.setState({ result: result }, () => this.props.askReport(result));
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (this.state.protocol !== this.props.protocol) {
      this.setState({ protocol: this.props.protocol });
    } else if (this.props.reset) {
      this.initialState.key += 1;
      this.props.resetReply();
      this.setState(this.initialState);
    }
    if (prevState !== this.state) {
      this.props.askReport(
        generateReport(this.state, this.props.protocol, this.props.contrast)
      );
    }
  };

  newRow = (value, segment) => {
    let newRecord;
    let array;
    if (segment === "tendinopathy") {
      newRecord = {
        tendon: value,
        patType: 0,
        patSite: [0],
        patSide: 0,
      };
    }

    array = [...this.state[segment]];
    array.push(newRecord);
    this.setState((prevState) => ({
      [segment]: [...prevState[segment], newRecord],
    }));
  };

  updateRow = debounce((index, segment, name, value) => {
    let array = [...this.state[segment]];

    array[index][name] = value;

    this.setState({ [segment]: array });
  }, 500);

  deleteRow = (index, segment) => {
    let array = [...this.state[segment]];
    array.splice(index, 1);
    this.setState({ [segment]: array });
  };

  render() {
    const { protocol } = this.props;
    const jointsGeneral = [
      {
        title: "ramenní kloub",
        color: aCardColors.red,
        rows: [
          [
            {
              label: "degenerace",
              input: (
                <ButtonUpDown
                  name="arthrosisHS"
                  segment="HS"
                  value={this.state.HS.arthrosisHS}
                  options={aArthrosisHS}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "náplň kloubu",
              input: (
                <ButtonUpDown
                  name="synovialFluidHS"
                  segment="HS"
                  value={this.state.HS.synovialFluidHS}
                  options={aSynovialFluidHS}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "SA burza",
              input: (
                <ButtonUpDown
                  name="bursa"
                  segment="HS"
                  value={this.state.HS.bursa}
                  options={aBursa}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "labrum",
              input: (
                <ButtonUpDown
                  name="labrum"
                  segment="HS"
                  value={this.state.HS.labrum}
                  options={aLabrum}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "akromioklavikulární kloub",
        color: aCardColors.green,
        rows: [
          [
            {
              label: "degenerace",
              input: (
                <ButtonUpDown
                  name="arthrosisAC"
                  segment="AC"
                  value={this.state.AC.arthrosisAC}
                  options={aArthrosisAC}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "náplň kloubu",
              input: (
                <ButtonUpDown
                  name="synovialFluidAC"
                  segment="AC"
                  value={this.state.AC.synovialFluidAC}
                  options={aSynovialFluidAC}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "dekomp. deg.",
              input: (
                <ButtonTrueFalse
                  name="subchondralEdema"
                  segment="AC"
                  value={this.state.AC.subchondralEdema}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "os acromiale",
              input: (
                <ButtonTrueFalse
                  name="osAcromiale"
                  segment="AC"
                  value={this.state.AC.osAcromiale}
                  options={aTrueFalse}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "tvar akromia",
              input: (
                <ButtonUpDown
                  name="acromion"
                  segment="AC"
                  value={this.state.AC.acromion}
                  options={aAcromion}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
    ];

    const rotatorCuff = [
      {
        title: (
          <Space>
            rotátorová manžeta
            <Select
              value="vybrat..."
              size="small"
              onChange={(value) => {
                this.newRow(value, "tendinopathy");
              }}
              style={{ width: 130 }}
            >
              {aRotatorCuff.map((item) => (
                <Option value={item.value}>{item.text}</Option>
              ))}
            </Select>
          </Space>
        ),
        color: aCardColors.cyan,
        rows: [
          [
            {
              label: "",
              input: this.state.tendinopathy
                .sort(
                  (a, b) =>
                    b["patType"] - a["patType"] || a["tendon"] - b["tendon"]
                )
                .map((item, index) => {
                  return (
                    <Row>
                      <Col span={23}>
                        <Space size={3} align="start">
                          <Select
                            key={index}
                            value={item.tendon}
                            size="small"
                            style={{ width: 130 }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "tendinopathy",
                                "tendon",
                                value
                              )
                            }
                          >
                            {aRotatorCuff.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          <Select
                            key={index}
                            value={item.patType}
                            size="small"
                            style={{ width: 220 }}
                            onChange={(value) =>
                              this.updateRow(
                                index,
                                "tendinopathy",
                                "patType",
                                value
                              )
                            }
                          >
                            {aPatType.map((item) => {
                              return (
                                <Option key={item.value} value={item.value}>
                                  {item.text}
                                </Option>
                              );
                            })}
                          </Select>
                          {item.patType !== 4 ? (
                            <Select
                              key={index}
                              value={item.patSite}
                              size="small"
                              style={{ width: 130 }}
                              mode="multiple"
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "tendinopathy",
                                  "patSite",
                                  value
                                )
                              }
                            >
                              {(item.tendon === 0
                                ? aPatSiteHorizontal
                                : aPatSiteVertical
                              ).map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          ) : null}

                          {item.patType > 0 && item.patType < 3 ? (
                            <Select
                              key={index}
                              value={item.patSide}
                              size="small"
                              style={{ width: 130 }}
                              onChange={(value) =>
                                this.updateRow(
                                  index,
                                  "tendinopathy",
                                  "patSide",
                                  value
                                )
                              }
                            >
                              {aPatSide.map((item) => {
                                return (
                                  <Option key={item.value} value={item.value}>
                                    {item.text}
                                  </Option>
                                );
                              })}
                            </Select>
                          ) : null}
                        </Space>
                      </Col>
                      <Col span={1} align="right">
                        <Button
                          type="primary"
                          size="small"
                          danger
                          onClick={() => this.deleteRow(index, "tendinopathy")}
                        >
                          x
                        </Button>
                      </Col>
                    </Row>
                  );
                }),
            },
          ],
        ],
      },
    ];

    const bicepsTendon = [
      {
        title: "atrofie svalů rotátorové manžety",
        color: aCardColors.orange,
        rows: [
          [
            {
              label: "supraspinatus",
              input: (
                <ButtonUpDown
                  name="supraspinatus"
                  segment="musclesAtrophy"
                  value={this.state.musclesAtrophy.supraspinatus}
                  options={aMuscleAtrophy}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "infraspinatus",
              input: (
                <ButtonUpDown
                  name="infraspinatus"
                  segment="musclesAtrophy"
                  value={this.state.musclesAtrophy.infraspinatus}
                  options={aMuscleAtrophy}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "subscapularis",
              input: (
                <ButtonUpDown
                  name="subscapularis"
                  segment="musclesAtrophy"
                  value={this.state.musclesAtrophy.subscapularis}
                  options={aMuscleAtrophy}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "teres minor",
              input: (
                <ButtonUpDown
                  name="teres minor"
                  segment="musclesAtrophy"
                  value={this.state.musclesAtrophy.teresMinor}
                  options={aMuscleAtrophy}
                  onChange={this.onChange}
                />
              ),
            },
          ],
        ],
      },
      {
        title: "šlacha dlouhé hlavy m. biceps brachii",
        color: aCardColors.magenta,
        rows: [
          [
            {
              label: "tendinóza",
              input: (
                <ButtonUpDown
                  name="bicepsTendinosis"
                  segment="HS"
                  value={this.state.HS.bicepsTendinosis}
                  options={aBicepsTendinosis}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "dislokace",
              input: (
                <ButtonUpDown
                  name="bicepsTendonDislocation"
                  segment="HS"
                  value={this.state.HS.bicepsTendonDislocation}
                  options={aBicepsTendonDislocation}
                  onChange={this.onChange}
                />
              ),
            },
          ],
          [
            {
              label: "ruptura šlachy",
              input: (
                <ButtonUpDown
                  name="bicepsTendonRupture"
                  segment="HS"
                  value={this.state.HS.bicepsTendonRupture}
                  options={aBicepsTendonRupture}
                  onChange={this.onChange}
                />
              ),
            },
            {
              label: "",
              input: "",
            },
          ],
        ],
      },
    ];

    const otherFindings = [
      {
        title: (
          <Space>
            vlastní nález - priorita
            <Switch
              name="customAddFindsPriority"
              size="small"
              checkedChildren="vysoká"
              unCheckedChildren="nízká"
              onChange={(event) =>
                this.onChange({
                  name: "customAddFindsPriority",
                  segment: "general",
                  value: event,
                })
              }
            />
          </Space>
        ),
        color: aCardColors.yellow,
        rows: [
          [
            {
              label: "vlastní popis",
              input: (
                <TextArea
                  name="customFindsDescription"
                  placeholder="vlastní text do popisu..."
                  defaultValue={this.state.general.customFindsDescription}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "customFindsDescription",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
          [
            {
              label: "vlastní závěr",
              input: (
                <TextArea
                  name="customFindsConclusion"
                  placeholder="vlastní text do závěru..."
                  defaultValue={this.state.general.customFindsConclusion}
                  autoSize={{ minRows: 1, maxRows: 5 }}
                  onBlur={(event) =>
                    this.onChange({
                      name: "customFindsConclusion",
                      segment: "general",
                      value: formatCustomInput(event.target.value),
                    })
                  }
                />
              ),
            },
          ],
        ],
      },
    ];
    return (
      <Form key={this.state.key} style={{ width: contentWidth }}>
        <CustomCards content={rotatorCuff} />
        <CustomCards content={jointsGeneral} />
        <CustomCards content={bicepsTendon} />
        <CustomCards content={otherFindings} />
      </Form>
    );
  }
}

export default MR_shoulder;
