// Custom scripts
import { aExams } from "./XRAY_abdomen_arrays";

// General scripts
import {
  indent,
  br,
  aContrast,
  aCardColors,
  aTrueFalse,
  contentWidth,
  aSizemm,
  aSizecm,
} from "./../scripts/GeneralArrays";
import {} from "./../scripts/GeneralFunctions";

export const generateReport = (props, protocol, contrast) => {
  const { exam, indication, contrastAmount } = props.report;
  let report = {
    header: {
      exam: exam,
      indication: indication,
      contrast: contrast,
      contrastAmount: contrastAmount,
    },
    description1: {
      forUI: [],
      forPaste: "",
    },
    description2: {
      forUI: [],
      forPaste: "",
    },
    conclusion1: {
      forUI: [],
      forPaste: "",
    },
    conclusion2: {
      forUI: [],
      forPaste: "",
    },
  };

  const importDescription1 = (text) => {
    report.description1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description1.forPaste += "")
      : (report.description1.forPaste += indent + text + br);
  };

  const importDescription2 = (text) => {
    report.description2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.description2.forPaste += "")
      : (report.description2.forPaste += indent + text + br);
  };

  const importConclusion1 = (text) => {
    report.conclusion1.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion1.forPaste += "")
      : (report.conclusion1.forPaste += indent + text + br);
  };

  const importConclusion2 = (text) => {
    report.conclusion2.forUI.push(<div className="indentText">{text}</div>);
    text === ""
      ? (report.conclusion2.forPaste += "")
      : (report.conclusion2.forPaste += indent + text + br);
  };

  //

  const {} = props.general;

  let description = "";
  let conclusion = "";
  if (protocol === 0) {
    description =
      "Pravá i levá polovina bránice klenutá, ostře konturovaná. Pod bránicí bez srpkovitého projasnění volným plynem. Přiměřená šíře lumen střevních kliček, bez hladinek. ";
    conclusion =
      "Bez obrazu poruchy pasáže gastrointestinálního traktu. Bez známek pneumoperitonea. ";
  } else if (protocol === 1) {
    description =
      "V bočné projekci bez přesvědčivého projasnění pod břišní stěnou obrazu volného plynu. Přiměřená šíře lumen střevních kliček, v bočné projekci bez hladinek. ";
    conclusion =
      "Bez obrazu poruchy pasáže gastrointestinálního traktu. Bez přesvědčivých známek pneumoperitonea. ";
  }

  importDescription1(description);
  importConclusion1(conclusion);

  //return report;
  return (report = {
    exam: exam,
    description_1_for_UI: report.description1.forUI,
    description_2_for_UI: report.description2.forUI,
    description_1_for_Paste: report.description1.forPaste,
    description_2_for_Paste: report.description2.forPaste,
    conclusion_1_for_UI: report.conclusion1.forUI,
    conclusion_2_for_UI: report.conclusion2.forUI,
    conclusion_1_for_Paste: report.conclusion1.forPaste,
    conclusion_2_for_Paste: report.conclusion2.forPaste,
  });
};
